import { NextRouter } from 'next/router';
import jsCookie from 'js-cookie';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import numeral from 'numeral';
import { PreciseRangeValueObject } from 'dayjs';
import * as Sentry from '@sentry/react';
import { EventHint } from '@sentry/react';
import { ErrorEvent } from '@sentry/types';
import { getIPLocation } from 'react-ip-location';
import { WS_USER_ACCESS_TOKEN_KEY, isProduction } from '@source/constants/common';
import { getTrimedS3Url, toUrlSegment } from '@design-system/utils/utils';
import { ICarUrlInfo, TRegionKey, TLanguageKey, IRegionOptions, ILogSentryError } from '@source/interface';
import {
  DEFAULT_LANGUAGE_MAP_BY_COUNTRY,
  AVAILABLE_LANGUAGES,
  AVAILABLE_COUNTRIES,
  SUPPORTED_COUNTRIES,
  DEFAULT_COUNTRY_KEY,
  SUPPORTED_LANGUAGES_MAP_BY_COUNTRY,
  CR_WEB_CARRO_DOMAIN,
  SUPPORTED_LANGUAGES,
} from '@source/constants/config';
import { getSelectedLanguageFromCookie, isSupportedLanguage } from '@source/services/Language';
import { NEXT_PUBLIC_ENV_URL_MY, NEXT_PUBLIC_WEB_CR } from '@source/constants/env';
import { BUY_URL_NAME, CARS_URL_NAME, SELL_URL_NAME } from '@source/constants/urlConfig';
import Logger from '@source/services/Logger';
import { startsWith, toUpper } from 'lodash';
import { ListingDetails } from '@source/interface/listing';
/**
 *  On creating ticket, pass additional data of where user is from
 * */
export const getSource = (country?: string): { prev_url: string; source_url: string; source: string | null } => {
  const prevUrl: string = jsCookie.get('referrer') || '';
  const refQuery: string = jsCookie.get('referrerQuery') || '';
  const sourceUrl = `${window.location.origin}${window.location.pathname}${refQuery || ''}`;
  let source: string | null = null;

  if (country === 'th') {
    source = 'direct';
  }

  if (prevUrl) {
    if (prevUrl.includes('facebook')) source = 'facebook';
    else if (prevUrl.includes('google')) source = 'google';
    else if (prevUrl.includes('instagram')) source = 'instagram';
    else if (prevUrl.includes('youtube')) source = 'youtube';
    else source = 'others';
  }

  return { prev_url: prevUrl, source_url: sourceUrl, source };
};

/**
 *  on page load, save referrer where user is from
 *  do not save referrer if from carro/mytukar domain
 *
 *  to be used on ticket creation
 */
export const saveReferrer = (): void => {
  const referrer: string = document.referrer || '';
  const ownDomain = ['carro', 'mytukar'];
  const isFromOwnDomain = ownDomain.some((domain) => referrer.includes(domain));

  if (referrer && !isFromOwnDomain) {
    jsCookie.set('referrer', referrer, { expires: 30 });
  }
  const refQuery = window.location.search;
  if (refQuery) {
    const prevParams = new URLSearchParams(jsCookie.get('referrerQuery') || '');
    const currentParams = new URLSearchParams(refQuery);
    const newParams = new URLSearchParams({
      ...Object.fromEntries(prevParams),
      ...Object.fromEntries(currentParams),
    });
    jsCookie.set('referrerQuery', `?${newParams.toString()}`, { expires: 30 });
  }
};

/**
 *  get user device info
 *
 *  to be used on ticket creation
 */
export const getDeviceInfo = (
  ipAddress: string,
): {
  device_screen_height: number;
  device_screen_width: number;
  device_browser: string;
  device_browser_version: number;
  device_os: string;
  device_os_version: number;
  device_ip_address: string;
  device_current_url: string;
  device_agent: string;
} => {
  const matchItem = (string: string, data: Array<{ name: string; value: string; version: string }>) => {
    let i = 0;
    let j = 0;
    let regex;
    let regexv;
    let match;
    let matches;
    let version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(`${data[i].version}[- /:;]([\\d._]+)`, 'i');
        matches = string.match(regexv);
        version = '';
        if (matches) {
          if (matches[1]) {
            // eslint-disable-next-line prefer-destructuring
            matches = matches[1];
          }
        }
        if (matches) {
          matches = (matches as string).split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += `${matches[j]}.`;
            } else {
              version += matches[j];
            }
          }
        } else {
          version = '0';
        }
        return {
          name: data[i].name,
          version: parseFloat(version),
        };
      }
    }
    return { name: 'unknown', version: 0 };
  };

  const { navigator } = window;

  const osList = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' },
  ];

  const browserList = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
  ];

  const header = [navigator.platform, navigator.userAgent, navigator.vendor];

  const agent = header.join(' ');
  const os = matchItem(agent, osList);
  const browser = matchItem(agent, browserList);

  const deviceInfo = {
    device_screen_height: window.innerHeight,
    device_screen_width: window.innerWidth,
    device_browser: browser.name,
    device_browser_version: browser.version,
    device_os: os.name,
    device_os_version: os.version,
    device_ip_address: ipAddress,
    device_current_url: window.location.href,
    device_agent: agent,
  };

  return deviceInfo;
};

/**
 *
 * @param {array} query - { slug: ['q', 'slug'] }
 * @param {string} [shortUrlSymbol='q'] - Short Url Symbol
 * @returns {string | boolean} slug | false
 */
export const getSlugFromShortUrl = (query: any, shortUrlSymbol = 'q') => {
  const { slug } = query || {};

  if (slug?.length !== 2) return null;
  const isShortUrl = slug[0] === shortUrlSymbol;
  const carSlug = slug[1].split('.')[0]; // in case of slug.html
  return isShortUrl ? carSlug : false;
};

/**
 * Get slug by region
 */
export const getSlugFromQuery = (query: any) => {
  const { slug, region } = query || {};
  if (!slug) {
    return null;
  }
  const title = slug[slug.length - 1];
  let splitTitle = title ? title.split('-') : '';
  splitTitle = splitTitle ? splitTitle[splitTitle.length - 1] : '';
  if (region === 'th' && splitTitle?.includes('.html') > 0) {
    splitTitle = splitTitle.split('.')?.[0]; // slugId in th 5GV47E.html
  }
  return splitTitle;
};

export const carURLInfo = (car: any): ICarUrlInfo => ({
  make: car?.inventory?.make,
  model: car?.inventory?.model,
  title: car?.listing?.title || car?.title,
  slug: car?.listing?.slug || car?.objectID,
});

export const getCarsUrlNameByLocalLanguage = (countryCode: TRegionKey | undefined, lang: TLanguageKey | undefined) => {
  switch (countryCode) {
    case 'id':
      return lang === 'id' ? CARS_URL_NAME.id : `cars`;

    case 'my':
      return lang === 'ms' ? CARS_URL_NAME.my : `cars`;

    default:
      return `cars`;
  }
};

export const getCarDetailsUrlFormat = (
  { make = 'get', model = 'best', title = 'deal', slug, utmQuery }: any,
  countryCode: TRegionKey | undefined = undefined,
  lang: TLanguageKey | undefined = undefined,
) => {
  const utmInfor = utmQuery ? `?${utmQuery}` : '';
  const carsUrlName = getCarsUrlNameByLocalLanguage(countryCode, lang);
  return `${carsUrlName}/${toUrlSegment(make)}/${toUrlSegment(model)}/${toUrlSegment(title)}/${slug}${utmInfor}`;
};

export const getNewCarDetailsUrlFormat = ({ make = 'get', model = 'best', title = 'deal', slug, utmQuery }: any) => {
  const utmInfor = utmQuery ? `?${utmQuery}` : '';
  return `buy-new/${toUrlSegment(make)}/${toUrlSegment(model)}/${toUrlSegment(title)}/${slug}${utmInfor}`;
};

/**
 *  having issue with setting Authorization to headers
 *  https://github.com/axios/axios/issues/4193
 * */
interface IAxiosRequestConfig extends Omit<AxiosRequestConfig, 'headers'> {
  headers?: any;
}

/**
 *  Get a custom axios instance which has Authorization header with access token
 *  @param {string} [baseURL] - base url for the request
 *  @param {string} [getAccessToken] - callback function to get access token
 *  @returns {AxiosInstance} a custom axios instance
 */
export const getAxiosInstance = (
  baseURL?: string,
  getAccessToken?: (tokenKey?: string) => string | null,
  getAuthenticationType?: () => string | null,
  tokenKey?: string,
) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    const accessToken: string | null | undefined = getAccessToken?.(tokenKey);

    let instanceConfig: IAxiosRequestConfig = { ...config };

    if (accessToken) {
      instanceConfig = {
        ...instanceConfig,
        headers: {
          common: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      };
    }

    return instanceConfig;
  });

  const authType: string | null | undefined = getAuthenticationType?.();
  if (authType) {
    instance.interceptors.request.use((config) => {
      let instanceConfig: IAxiosRequestConfig = { ...config };
      instanceConfig = {
        ...instanceConfig,
        headers: {
          common: {
            'Auth-type': authType,
          },
        },
      };
      return instanceConfig;
    });
  }

  return instance;
};

/**
 *  return access token from cookie
 *
 *  @param tokenKey {string} property key of cookie
 *  @returns {string} access token or null if not available
 */
export const getAccessToken = (tokenKey?: string) => {
  if (typeof window !== 'undefined') {
    const cookiesJSON = jsCookie.get();
    return cookiesJSON[tokenKey || WS_USER_ACCESS_TOKEN_KEY];
  }
  return null;
};

export const IMAGE_KIT_URL = 'https://cdn.carro.co';

export const getImageKitURLFixedDimension = (width: number, height?: number | string, aspectRatio = 16 / 9) => {
  const getHeight = height || Math.ceil(width / aspectRatio); //  if not height, to get height based on 16:9 aspect ratio)
  return `${IMAGE_KIT_URL}/tr:w-${width},h-${getHeight},pr-true/`;
};

export const getImageKitURLFixedRatio = (width: number, height: number) =>
  `${IMAGE_KIT_URL}/tr:ar-${width}-${height},h-1000,pr-true/`;

export const getImageKitURLFixedRatioByWidth = (arWidth: number, arHeight: number, width?: number) =>
  `${IMAGE_KIT_URL}/tr:ar-${arWidth}-${arHeight},w-${width},pr-true/`;

export const getUrlPrefixRatioByWidth = (arWidth: number, arHeight: number, width?: number) =>
  `/tr:ar-${arWidth}-${arHeight},w-${width},pr-true/`;

export const reduceImageSizeByContainer = (imageSrc: string, imageSrcPrefix: string) => {
  const newSrc = `${imageSrcPrefix}${getTrimedS3Url(imageSrc)}`;
  return newSrc;
};

export const getFormattedPrice = (price: number, locale = 'en', isFromCarCard = false) => {
  if (!price) return price;
  switch (locale) {
    case 'id': {
      return price && `${isFromCarCard ? '' : 'Rp'}${numeral(price).format('0,0').replace(/,/g, '.')}`;
    }
    case 'zh-TW': {
      const formatedPrice = numeral(price).value();
      return formatedPrice && `${formatedPrice / 10000}${isFromCarCard ? '' : '萬'}`;
    }
    case 'jp':
    case 'ja':
      return price && `${isFromCarCard ? '' : '¥'}${numeral(price).format('0,0')}`;
    case 'hk':
    case 'zh-Hant-HK':
    case 'zh-Hans-HK':
      return price && `${isFromCarCard ? '' : '$'}${numeral(price).format('0,0')}`;
    case 'th':
      return price && `${isFromCarCard ? '' : '฿'}${numeral(price).format('0,0').replace(/,/g, '.')}`;

    case 'en':
    default:
      return price && `${isFromCarCard ? '' : '$'}${numeral(price).format('0,0')}`;
  }
};

export const getInspectionUrl = (title: string, slug: string) => `inspection/${toUrlSegment(title)}-${slug}`;

export const getInspectionSlug = (query: any) => {
  if (!query || !query.slug) return null;
  return query?.slug.split('-').pop();
};

/**
 * @param {langId}
 * input: zh-tw | en | ms
 * output: zh-TW | en | ms
 *
 */
export const formatLanguageCodeInUrl = (langId: TLanguageKey): TLanguageKey => {
  let language = langId;
  // in case langid = en | ms | id | th
  if (language && AVAILABLE_LANGUAGES.includes(language)) {
    return language as TLanguageKey;
  }
  // in case langid = zh-tw -> return zh-TW
  if (langId?.includes('-') && !AVAILABLE_LANGUAGES.includes(langId)) {
    const prefix = langId.split('-')?.[0];
    const suffix = langId.split('-')?.[1];
    let lang = `${prefix}-${suffix?.toUpperCase()}` as TLanguageKey;
    if (langId.split('-')?.length === 3) {
      const postfix = langId.split('-')?.[2];
      // eslint-disable-next-line no-unsafe-optional-chaining
      lang = `${prefix}-${suffix[0]?.toUpperCase() + suffix.substring(1)}-${postfix.toUpperCase()}` as TLanguageKey;
    }
    language = lang;
  }
  // invalid language code
  return language;
};

/**
 * Get language id in asPath url
 * @param asPath
 * `/en/cars/honda/jazz/-qa--2017-honda-jazz-e-1-5at-n-35/G0XNXD`
 * @returns: en
 */
export const getLanguageInURL = (asPath: string): string => {
  let langId = '';
  if (asPath) {
    const langIdUrl = asPath.split('/')?.[1]?.split('?')?.[0];
    langId = langIdUrl;
  }
  return langId;
};
/**
 * returns the dimension of a HTML Element
 *
 * @param element {HTMLELEMENT}
 * @returns {height, offsetTop, offsetBottom}
 */
export const getElementDimensions = (
  element: HTMLElement,
): { height: number; offsetTop: number; offsetBottom: number } => {
  if (!element)
    return {
      height: 0,
      offsetTop: 0,
      offsetBottom: 0,
    };

  const { height } = element.getBoundingClientRect();
  const { offsetTop } = element;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};
/**
 * detect and return region + language in router/storage
 * eg: carro.co/ -> region = ipaddress region; language = storedLang_of_current_region or defaultLang_of_current_region
 *     carro.co/sg -> region = sg; language = storedLang_of_SG or defaultLang_of_SG
 *
 */
export const getRegionLanguageInRouter = (router: any, countryCode: TRegionKey) => {
  const { locale, query } = router;
  const region = !locale || locale === DEFAULT_COUNTRY_KEY ? countryCode : locale;

  const urlLanguage = query?.lang && formatLanguageCodeInUrl(query?.lang);

  const storedLanguage = getSelectedLanguageFromCookie(region);
  const langId = urlLanguage || storedLanguage;
  const defaultLang = DEFAULT_LANGUAGE_MAP_BY_COUNTRY?.[countryCode as TRegionKey]?.code;
  const language = langId && isSupportedLanguage(region, langId) ? langId : defaultLang;

  return {
    region,
    language,
  };
};

/**
 *
 * @param urlRegion: server locale
 * @param cookieRegion: req.cookies
 * @returns
 */
export const getCurrentRegion = async ({ urlRegion, cookieRegion, isMY }: IRegionOptions) => {
  if (isMY) {
    return SUPPORTED_COUNTRIES.MY.code;
  }

  const isDefaultRegion = !urlRegion || urlRegion === 'default';
  let currentRegion = isDefaultRegion ? cookieRegion : urlRegion;
  try {
    if (isDefaultRegion && !cookieRegion) {
      const ip = await getIPLocation();
      const detectLocale = ip?.country?.toLowerCase() || null;
      currentRegion =
        detectLocale && AVAILABLE_COUNTRIES.includes(detectLocale) ? detectLocale : SUPPORTED_COUNTRIES.SG.code;
    }
  } catch (error) {
    Logger.error(error);
  }
  currentRegion = AVAILABLE_COUNTRIES.includes(currentRegion) ? currentRegion : SUPPORTED_COUNTRIES.SG.code;
  return currentRegion;
};

export const getCurrentRegionV2 = ({
  urlRegion,
  cookieRegion,
  cloudflareCountry,
}: IRegionOptions & { cloudflareCountry: TRegionKey }) => {
  const isDefaultRegion = !urlRegion || urlRegion === 'default';
  let currentRegion: TRegionKey | null = isDefaultRegion ? cookieRegion : urlRegion;
  if (isDefaultRegion && !cookieRegion) {
    const cfCountry = (cloudflareCountry?.toLowerCase() as TRegionKey) || null;
    currentRegion = cfCountry && AVAILABLE_COUNTRIES.includes(cfCountry) ? cfCountry : null;
  }

  // able to detect user region based on url/cookie/cloudflare header
  const isDetected = currentRegion && AVAILABLE_COUNTRIES.includes(currentRegion as TRegionKey);

  currentRegion = isDetected ? currentRegion : SUPPORTED_COUNTRIES.SG.code;

  return {
    currentRegion,
    isDetected,
  };
};

export const isMyTukarWeb = (webOrigin: string) => NEXT_PUBLIC_ENV_URL_MY?.includes(webOrigin);

/**
 * format reqUrl in server side request
 * @param reqUrl
 * reqUrl = '/_next/data/development/my/en/cars/nissan/cefiro/nissan-cefiro-cefiro-2002/E1XPYE.json?slug=nissan&slug=cefiro&slug=nissan-cefiro-cefiro-2002&slug=E1XPYE',
 * or: '/ms/cars/nissan/cefiro/nissan-cefiro-cefiro-2002/E1XPYE',
 * output: /ms/cars/nissan/cefiro/nissan-cefiro-cefiro-2002/E1XPYE
 */
export const formatReqUrl = (reqUrl: string, region: TRegionKey) => {
  const nextPrefix = `/_next/data/development/`;
  if (reqUrl.includes(`${nextPrefix}${region}/`)) {
    return reqUrl.split(`${nextPrefix}${region}`)?.[1];
  }
  return reqUrl;
};

export const getFormattedPreciseRange = (
  diffObj: PreciseRangeValueObject,
  units: Array<keyof PreciseRangeValueObject>,
  unitObj?: { [key: string]: string },
) => {
  const unitString = unitObj || {
    years: 'yrs',
    months: 'mths',
    days: 'days',
    hours: 'hrs',
    minutes: 'mins',
    seconds: 'secs',
    firstDateWasLater: '',
  };
  return units.reduce((acc, unit) => acc.concat(diffObj[unit] ? `${diffObj[unit]}${unitString[unit]}` : ''), '');
};

export const getBuyUrlNameByLocalLanguage = (countryCode: TRegionKey, lang: TLanguageKey) => {
  switch (countryCode) {
    case 'id':
      return lang === 'id' ? BUY_URL_NAME.id : `buy`;

    case 'my':
      return lang === 'ms' ? BUY_URL_NAME.my : `buy`;

    default:
      return `buy`;
  }
};

export const getSellUrlNameByLocalLanguage = (countryCode: TRegionKey, lang: TLanguageKey) => {
  switch (countryCode) {
    case 'id':
      return lang === 'id' ? SELL_URL_NAME.id : `sell`;

    case 'my':
      return lang === 'ms' ? SELL_URL_NAME.my : `sell`;

    default:
      return `sell`;
  }
};

export const logSentryError = ({ errorMessage, region, data, req, resolvedUrl, query }: ILogSentryError) => {
  if (isProduction) {
    const message = `Error | ${region?.toUpperCase() || ''} | ${errorMessage || ''}`;
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event) => {
        const headers = req?.headers;
        delete headers?.cookie;
        return {
          ...event,
          request: {
            ...event.request,
            url: resolvedUrl,
            method: req?.method,
            headers: headers as Record<string, string>,
            query_string: query as Record<string, string>,
          },
        };
      });

      scope.setExtras({
        region,
        data,
      });

      Sentry.captureException(new Error(message, data as any));
    });
  }
};

export const sentryUnhandledRejectionFormatter = (event: ErrorEvent, hint: EventHint) => {
  if (isProduction) {
    const customEvent = { ...event };
    const eventExceptionValues = event?.exception?.values as any[];
    const hintException = hint?.originalException as any;

    if (hintException?.name && hintException?.message) {
      if (eventExceptionValues?.[0]?.type === 'UnhandledRejection') {
        // custom issue title with name + message
        eventExceptionValues[0].value = `${hintException.name}: ${hintException.message}`;

        // custom fingerprint to group request issues
        const fingerprint = ['UnhandledRejection'];
        ['name', 'message'].forEach((field) => {
          if (hintException?.[field]) {
            fingerprint.push(hintException?.[field]);
          }
        });

        customEvent.fingerprint = fingerprint;
      }
    }

    return customEvent;
  }
  return null;
};

export const axiosErrorParser = (error: AxiosError) => {
  if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response;
  }

  if (error?.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return error.request;
  }
  // Something happened in setting up the request that triggered an Error
  return error.message;
};

// get html lang in formatted [lang]-[REGION]
export const getHTMLLang = (lang: TLanguageKey, country?: TRegionKey) => {
  if (['zh-MY', 'zh-SG', 'ms-SG', 'zh-Hant-HK', 'zh-Hans-HK'].includes(lang)) {
    return lang;
  }

  return `${lang}-${toUpper(country || '')}`;
};

export const getUrlNoQuery = (url: string) => url.split('?')?.[0];

const translateCarsPath = (urlPath: NextRouter['asPath'], country: TRegionKey, lang: TLanguageKey) =>
  urlPath.replace('/cars/', `/${getCarsUrlNameByLocalLanguage(country, lang)}/`);

export const getAlterLinks = (country: TRegionKey, asPath: NextRouter['asPath']) => {
  const countryCode = country || 'sg';

  const langsByRegion =
    (SUPPORTED_LANGUAGES_MAP_BY_COUNTRY[countryCode] || SUPPORTED_LANGUAGES_MAP_BY_COUNTRY.sg).map(
      ({ code }) => code,
    ) || [];
  const currentLang = langsByRegion.find((code) => startsWith(asPath, `/${code.toLowerCase()}`))?.toLowerCase();

  let path = `${asPath}`;
  if (currentLang) {
    path = path.replace(`/${currentLang}`, '');
  }

  const linksByLangs = langsByRegion.map((lang) => ({
    hrefLang: getHTMLLang(lang, countryCode)?.toLowerCase(),
    href: `${NEXT_PUBLIC_WEB_CR}/${countryCode}/${lang?.toLowerCase()}${translateCarsPath(path, countryCode, lang)}`,
  }));

  const defaultLang = DEFAULT_LANGUAGE_MAP_BY_COUNTRY[countryCode].code;
  const linkDefault = {
    hrefLang: 'x-default',
    href: `${NEXT_PUBLIC_WEB_CR}/${countryCode}/${defaultLang}${translateCarsPath(path, countryCode, defaultLang)}`,
  };

  return [...linksByLangs, linkDefault];
};

export const getListingDetailHeadMetaData = (carDetails: ListingDetails) => {
  if (!carDetails) return null;

  const { carInfoSection, carMediaSection, inspectionSection, flagSection, financingSection, overViewSection } =
    carDetails || {};
  const { photos } = carMediaSection;
  const { title, slug, originalPrice } = carInfoSection;
  const { make, model, yearOfManufacture, primaryColor } = inspectionSection;
  const { isComingSoon, isSold, isPendingSale } = flagSection;
  const { askingPrice } = financingSection;
  const { inventory } = overViewSection;
  const { type } = inventory || {};

  return {
    title,
    slug,
    originalPrice,
    make,
    model,
    yearOfManufacture,
    primaryColor,
    photos,
    isComingSoon,
    isSold,
    isPendingSale,
    askingPrice,
    inventoryType: type,
  };
};

export const getAllRegionalUrls = () =>
  AVAILABLE_COUNTRIES.reduce(
    (acc: string[], countryCode) => [
      ...acc,
      ...SUPPORTED_LANGUAGES_MAP_BY_COUNTRY[countryCode].map(
        ({ code }) => `${CR_WEB_CARRO_DOMAIN}/${countryCode}/${code === 'ms-SG' ? 'ms' : code?.toLowerCase()}`,
      ),
    ],
    [],
  );

export const getDefaultLangByRegion = (region: TRegionKey) =>
  DEFAULT_LANGUAGE_MAP_BY_COUNTRY[region]?.code || SUPPORTED_LANGUAGES.EN.code;
