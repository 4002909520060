import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar, Drawer } from 'antd';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Typography } from '@design-system/components';
import { useTranslation } from 'react-i18next';

import CustomDropdownMenu from '@source/pages/Home/components/CustomDropdownMenu';
import DownloadComponent from '@source/pages/Home/components/DownloadComponent';
import FlagRegionSelector from '@source/pages/Home/components/FlagRegionSelector';
import useRegionConfig from '@source/hooks/useRegionConfig';
import LoginContainer from '@design-system/components/Login/LoginContainer';

const StyledContainer = styled.div`
  ${(props) => props.theme.typo.style.mainButtonOrLink};
  ${(props) => props.theme.typo.familyGoogle.semiBold};
`;

const StyledDrawer = styled(Drawer)<{ isHidden?: boolean }>`
  .ant-drawer-header {
    background-color: ${(props) => props.theme.color.primaryBase};
    display: ${(props) => (props.isHidden ? 'none' : 'block')};
  }

  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-body {
    padding: 0 0 20px 0;
  }
`;

const StyledTitleDrawer = styled.div`
  display: flex;
  align-items: center;

  .side-navigation-avatar {
    background-color: transparent;
    color: ${(props) => props.theme.color.background};
    border: 1px solid;
    border-color: ${(props) => props.theme.color.background};
  }

  .side-navigation-title {
    margin: 0 0 0 10px;
    color: ${(props) => props.theme.color.background};
    ${(props) => props.theme.typo.style.mainButtonOrLink}
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    &:hover {
      cursor: pointer;
    }
  }
`;

const StyleContent = styled.div`
  ${(props) => props.theme.typo.style.mainButtonOrLink}
  ${(props) => props.theme.typo.familyGoogle.semiBold};

  .side-navigation-content-color {
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  }

  .side-navigation-row-item {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 0.5px solid;
    border-color: ${(props) => props.theme.color.onBackgroundLowEmphasis};

    &:hover {
      cursor: pointer;
    }

    .side-navigation-content-icons {
      color: ${(props) => props.theme.color.primaryBase};
      ${(props) => props.theme.typo.style.sHeader}
      margin-right: 10px;
      display: flex;
      justify-content: center;
    }
  }

  .side-navigation-download {
    margin-top: 20px;

    .side-navigation-download-app {
      display: flex;
      margin-top: 10px;
    }

    .side-navigation-download-image {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .side-navigation-other-region {
    margin-top: 20px;
  }
`;

const StyledSmallItem = styled.div`
  margin: 0 20px;
  border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
`;

const TitleDrawer = () => {
  const { t } = useTranslation();

  return (
    <StyledTitleDrawer>
      <Avatar className="side-navigation-avatar" size={18} icon={<UserOutlined />} />
      <Typography variant="mainButtonOrLink" className="side-navigation-title">
        {t('home.sideNavigation.login')}
      </Typography>
    </StyledTitleDrawer>
  );
};

// enable onclick action on div tag
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const Content = ({ onClose, currentCountryConfig }: { onClose: () => void; currentCountryConfig: any }) => {
  const { t } = useTranslation();
  const {
    countryConfig: { country: currentRegion },
  } = useRegionConfig();

  if (!currentRegion) {
    return null;
  }

  return (
    <StyleContent>
      <CustomDropdownMenu listItems={currentCountryConfig} />

      <StyledSmallItem>
        {currentRegion === 'sg' && <DownloadComponent onClose={onClose} content={t('home.sideNavigation.download')} />}
        <FlagRegionSelector content={t('home.sideNavigation.visit')} />
      </StyledSmallItem>
    </StyleContent>
  );
};

interface SharedSideNavigationProps {
  visibleDrawer: boolean;
  onClose: () => void;
  currentCountryConfig: any;
  isPlacementRightDrawer?: boolean;
}

const SharedSideNavigation: FC<SharedSideNavigationProps> = ({
  visibleDrawer,
  onClose,
  currentCountryConfig,
  isPlacementRightDrawer,
}) => {
  const [placement, setPlacement] = useState<'left' | 'right' | 'top' | 'bottom'>('left');

  const {
    countryConfig: { country: currentRegion },
  } = useRegionConfig();

  useEffect(() => {
    if (isPlacementRightDrawer) {
      setPlacement('right');
    } else {
      setPlacement('left');
    }
  }, [isPlacementRightDrawer]);

  if (!currentRegion) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledDrawer
        closeIcon={<CloseOutlined />}
        title={<TitleDrawer />}
        placement={placement}
        width="80%"
        onClose={onClose}
        visible={visibleDrawer}
        isHidden
        destroyOnClose
      >
        <Content onClose={onClose} currentCountryConfig={currentCountryConfig} />
      </StyledDrawer>
      {visibleDrawer && <LoginContainer />}
    </StyledContainer>
  );
};

export default SharedSideNavigation;

SharedSideNavigation.defaultProps = {
  isPlacementRightDrawer: false,
};
