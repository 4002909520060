import { ICountry, ILanguage, TRegionKey } from '@source/interface';
import flagSGV2 from '@source/pages/Home/assets/images/flags/SG_v2.svg';
import flagMYV2 from '@source/pages/Home/assets/images/flags/MY_v3.svg';
import flagIDV2 from '@source/pages/Home/assets/images/flags/ID_v2.svg';
import flagTHV2 from '@source/pages/Home/assets/images/flags/TH_v2.svg';
// import flagTWV2 from '@source/pages/Home/assets/images/flags/TW_v2.svg';
import flagJPV2 from '@source/pages/Home/assets/images/flags/JP_v2.svg';

import flagSG from '@source/pages/Home/assets/images/flags/SG.svg';
import flagMY from '@source/pages/Home/assets/images/flags/MY.svg';
import flagID from '@source/pages/Home/assets/images/flags/ID.svg';
import flagTH from '@source/pages/Home/assets/images/flags/TH.svg';
import flagTW from '@source/pages/Home/assets/images/flags/TW.svg';
import flagJP from '@source/pages/Home/assets/images/flags/JP.svg';
import flagHK from '@source/pages/Home/assets/images/flags/HK.svg';

import {
  NEXT_PUBLIC_INSURANCE_URL_MY,
  NEXT_PUBLIC_INSURANCE_URL_SG,
  NEXT_PUBLIC_INSURANCE_OMNI_URL_SG,
  NEXT_PUBLIC_INSURANCE_OMNI_URL_MY,
  NEXT_PUBLIC_WEB_CR,
  NEXT_PUBLIC_WEB_CR_MY,
  NEXT_PUBLIC_SELLER_PORTAL_URL_MY,
  NEXT_PUBLIC_BLOG_URL_MY,
  NEXT_PUBLIC_BLOG_URL_HK,
} from '@source/constants/env';
// Disabling due to eslint bug
// eslint-disable-next-line no-shadow
export enum SUPPORTED_COUNTRIES_KEYS {
  SG = 'sg',
  TH = 'th',
  ID = 'id',
  MY = 'my',
  JP = 'jp',
  HK = 'hk',
}

export const CR_WEB_CARRO_DOMAIN = NEXT_PUBLIC_WEB_CR;
export const CR_WEB_MYTUKAR_DOMAIN = NEXT_PUBLIC_WEB_CR_MY;

export const SUPPORTED_LANGUAGES: Record<
  'EN' | 'ID' | 'MY' | 'TH' | 'ZH-SG' | 'ZH-MY' | 'JA' | 'MS-SG' | 'ZH-HANT-HK' | 'ZH-HANS-HK',
  ILanguage
> = {
  EN: {
    displayName: 'en',
    code: 'en',
    icon: flagSG,
    longDisplayName: 'English',
  },
  ID: {
    displayName: 'id',
    code: 'id',
    icon: flagID,
    longDisplayName: 'Bahasa Indonesia',
  },
  TH: {
    displayName: 'ไทย',
    code: 'th',
    icon: flagTH,
    longDisplayName: 'ไทย',
  },
  MY: {
    displayName: 'BM',
    code: 'ms',
    icon: flagMY,
    longDisplayName: 'Bahasa Malaysia',
  },
  [`ZH-SG`]: {
    displayName: '中文',
    code: 'zh-SG',
    icon: flagTW,
    longDisplayName: '中文',
  },
  [`ZH-MY`]: {
    displayName: '中文',
    code: 'zh-MY',
    icon: flagTW,
    longDisplayName: '中文',
  },
  JA: {
    displayName: '日本語',
    code: 'ja',
    icon: flagJP,
    longDisplayName: '日本語',
  },
  'MS-SG': {
    displayName: 'BM',
    code: 'ms-SG',
    icon: flagMY,
    longDisplayName: 'Bahasa Melayu',
  },
  'ZH-HANT-HK': {
    displayName: '繁體中文',
    code: 'zh-Hant-HK',
    icon: flagHK,
    longDisplayName: '繁體中文',
  },
  'ZH-HANS-HK': {
    displayName: '简体中文',
    code: 'zh-Hans-HK',
    icon: flagHK,
    longDisplayName: '简体中文',
  },
};

export const SUPPORTED_COUNTRIES: Record<'SG' | 'ID' | 'MY' | 'TH' | 'JP' | 'HK', ICountry> = {
  SG: {
    displayName: 'Singapore',
    code: 'sg',
    icon: flagSG,
    iconV2: flagSGV2,
    webUrl: `${CR_WEB_CARRO_DOMAIN}/${SUPPORTED_COUNTRIES_KEYS.SG}`,
  },
  ID: {
    displayName: 'Indonesia',
    code: 'id',
    icon: flagID,
    iconV2: flagIDV2,
    webUrl: `${CR_WEB_CARRO_DOMAIN}/${SUPPORTED_COUNTRIES_KEYS.ID}`,
  },
  TH: {
    displayName: 'Thailand',
    code: 'th',
    icon: flagTH,
    iconV2: flagTHV2,
    webUrl: `${CR_WEB_CARRO_DOMAIN}/${SUPPORTED_COUNTRIES_KEYS.TH}`,
  },
  MY: {
    displayName: 'Malaysia',
    code: 'my',
    icon: flagMY,
    iconV2: flagMYV2,
    webUrl: `${CR_WEB_CARRO_DOMAIN}/${SUPPORTED_COUNTRIES_KEYS.MY}`,
  },
  JP: {
    displayName: 'Japan',
    code: 'jp',
    icon: flagJP,
    iconV2: flagJPV2,
    webUrl: `${CR_WEB_CARRO_DOMAIN}/${SUPPORTED_COUNTRIES_KEYS.JP}`,
  },
  HK: {
    displayName: 'HongKong',
    code: 'hk',
    icon: flagHK,
    iconV2: flagHK,
    webUrl: `${CR_WEB_CARRO_DOMAIN}/${SUPPORTED_COUNTRIES_KEYS.HK}`,
  },
};

export const SUPPORTED_LANGUAGES_MAP_BY_COUNTRY: Record<SUPPORTED_COUNTRIES_KEYS, ILanguage[]> = {
  [SUPPORTED_COUNTRIES_KEYS.SG]: [SUPPORTED_LANGUAGES.EN, SUPPORTED_LANGUAGES['ZH-SG'], SUPPORTED_LANGUAGES['MS-SG']],
  [SUPPORTED_COUNTRIES_KEYS.MY]: [SUPPORTED_LANGUAGES.EN, SUPPORTED_LANGUAGES.MY, SUPPORTED_LANGUAGES['ZH-MY']],
  [SUPPORTED_COUNTRIES_KEYS.ID]: [SUPPORTED_LANGUAGES.ID, SUPPORTED_LANGUAGES.EN],
  [SUPPORTED_COUNTRIES_KEYS.TH]: [SUPPORTED_LANGUAGES.TH, SUPPORTED_LANGUAGES.EN],
  // [SUPPORTED_COUNTRIES_KEYS.TW]: [SUPPORTED_LANGUAGES.TW, SUPPORTED_LANGUAGES.EN],
  [SUPPORTED_COUNTRIES_KEYS.JP]: [SUPPORTED_LANGUAGES.JA, SUPPORTED_LANGUAGES.EN],
  [SUPPORTED_COUNTRIES_KEYS.HK]: [
    SUPPORTED_LANGUAGES['ZH-HANT-HK'],
    SUPPORTED_LANGUAGES.EN,
    SUPPORTED_LANGUAGES['ZH-HANS-HK'],
  ],
};

export const DEFAULT_LANGUAGE_MAP_BY_COUNTRY: Record<SUPPORTED_COUNTRIES_KEYS, ILanguage> = {
  [SUPPORTED_COUNTRIES_KEYS.SG]: SUPPORTED_LANGUAGES.EN,
  [SUPPORTED_COUNTRIES_KEYS.MY]: SUPPORTED_LANGUAGES.EN,
  [SUPPORTED_COUNTRIES_KEYS.ID]: SUPPORTED_LANGUAGES.ID,
  [SUPPORTED_COUNTRIES_KEYS.TH]: SUPPORTED_LANGUAGES.TH,
  // [SUPPORTED_COUNTRIES_KEYS.TW]: SUPPORTED_LANGUAGES.TW,
  [SUPPORTED_COUNTRIES_KEYS.JP]: SUPPORTED_LANGUAGES.JA,
  [SUPPORTED_COUNTRIES_KEYS.HK]: SUPPORTED_LANGUAGES['ZH-HANT-HK'],
};

export const DEFAULT_COUNTRY_KEY = 'default';

const CONFIG = {
  SUPPORTED_COUNTRIES_KEYS,
  SUPPORTED_LANGUAGES_MAP_BY_COUNTRY,
  SUPPORTED_COUNTRIES,
};

export const AVAILABLE_COUNTRIES = [
  SUPPORTED_COUNTRIES.SG.code,
  SUPPORTED_COUNTRIES.MY.code,
  SUPPORTED_COUNTRIES.ID.code,
  SUPPORTED_COUNTRIES.TH.code,
  // SUPPORTED_COUNTRIES.TW.code,
  SUPPORTED_COUNTRIES.JP.code,
  SUPPORTED_COUNTRIES.HK.code,
];
export const AVAILABLE_LANGUAGES = [
  SUPPORTED_LANGUAGES.EN.code,
  SUPPORTED_LANGUAGES.MY.code,
  SUPPORTED_LANGUAGES.ID.code,
  SUPPORTED_LANGUAGES.TH.code,
  // SUPPORTED_LANGUAGES.TW.code,
  SUPPORTED_LANGUAGES['ZH-SG'].code,
  SUPPORTED_LANGUAGES['ZH-MY'].code,
  SUPPORTED_LANGUAGES.JA.code,
  SUPPORTED_LANGUAGES['MS-SG'].code,
  SUPPORTED_LANGUAGES['ZH-HANT-HK'].code,
  SUPPORTED_LANGUAGES['ZH-HANS-HK'].code,
];

export const INSURANCE_3RD_PARTY_URL = {
  [SUPPORTED_COUNTRIES.SG.code]: NEXT_PUBLIC_INSURANCE_URL_SG,
  [SUPPORTED_COUNTRIES.MY.code]: NEXT_PUBLIC_INSURANCE_URL_MY,
  [SUPPORTED_COUNTRIES.ID.code]: undefined,
  [SUPPORTED_COUNTRIES.TH.code]: undefined,
  [SUPPORTED_COUNTRIES.JP.code]: undefined,
  [SUPPORTED_COUNTRIES.HK.code]: undefined,
};

export const INSURANCE_OMNI_3RD_PARTY_URL = {
  [SUPPORTED_COUNTRIES.SG.code]: NEXT_PUBLIC_INSURANCE_OMNI_URL_SG,
  [SUPPORTED_COUNTRIES.MY.code]: NEXT_PUBLIC_INSURANCE_OMNI_URL_MY,
  [SUPPORTED_COUNTRIES.ID.code]: undefined,
  [SUPPORTED_COUNTRIES.TH.code]: undefined,
  [SUPPORTED_COUNTRIES.JP.code]: undefined,
  [SUPPORTED_COUNTRIES.HK.code]: undefined,
};

export const SELLER_PORTAL_URL: Record<TRegionKey, string | undefined> = {
  sg: undefined,
  my: NEXT_PUBLIC_SELLER_PORTAL_URL_MY,
  id: undefined,
  th: undefined,
  jp: undefined,
  hk: undefined,
};

export const BLOG_URL: Record<TRegionKey, string | undefined> = {
  sg: undefined,
  my: NEXT_PUBLIC_BLOG_URL_MY,
  id: undefined,
  th: undefined,
  jp: undefined,
  hk: NEXT_PUBLIC_BLOG_URL_HK,
};

export default CONFIG;
