// import axios from 'axios';
import qs from 'qs';

import { API_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import { WHOLESALE_AXIOS_USER_AUTH } from './UserProfile';

export default {
  getTimeSlots: (country: TRegionKey, startDate: string, endDate: string, locationId: number) => {
    const queryString = qs.stringify({ start_date: startDate, end_date: endDate, location_id: locationId });

    const timeSlotAPI = API_URL(country).timeSlot;
    // const timeSlotAPI = 'http://localhost:8000/time-slots';

    return WHOLESALE_AXIOS_USER_AUTH.get(`${timeSlotAPI}?${queryString}`);
  },
  callback: (country: TRegionKey, ticketSlug: string) => {
    const apiUrl = `${API_URL(country).regionPathUrl}/sell-car/${ticketSlug}/callback`;

    return WHOLESALE_AXIOS_USER_AUTH.post(`${apiUrl}`);
  },
};
