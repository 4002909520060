/* eslint-disable no-template-curly-in-string */
import {
  getDataConfigsCountry,
  getFiltersTranslationPath,
} from '@source/pages/Home/components/Filters/configs/constants';

const countryCode = 'th';

const FILTER_CONFIGS_TH = {
  loading: false,
  initialFilterSettings: [
    {
      name: 'price_range',
      algoliaFetching: true,
      requireDefaults: true,
      filters: [
        {
          name: 'listing.asking_price',
          customName: 'listing.asking_price',
          prefix: '฿',
          filterSelectedHeader: '฿[selectedMinInput] - ฿[selectedMaxInput]',
          detailsText: {
            average: '',
          },
          selectedCustomLabel: getFiltersTranslationPath('priceRangeCustomLabelTh'),
        },
      ],
    },
    {
      name: 'manufacture_year',
      filters: [
        {
          name: 'inventory.manufacture_year',
          facetStats: {
            min: 1990,
            max: 2022,
            avg: 2018,
            sum: 446102,
          },
        },
      ],
    },
    {
      name: 'category',
      active: true,
      activeMobile: true,
      header: getFiltersTranslationPath('categories'),
      algoliaFetching: false,
      filters: [
        {
          name: 'flags',
          customName: 'categories',
          isTrueOption: true,
          options: [
            {
              label: 'New',
              value: 'listing.flags.is_new',
            },
            {
              label: 'Hot Deal',
              value: 'listing.flags.is_good_deal',
              antdIcon: 'FireFilled',
            },
          ],
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'colours',
      filters: [
        {
          name: 'inventory.color',
          options: [
            { label: getFiltersTranslationPath('options.black'), value: 'ดำ', name: 'black' },
            { label: getFiltersTranslationPath('options.white'), value: 'ขาว', name: 'white' },
            { label: getFiltersTranslationPath('options.brown'), value: 'น้ำตาล', name: 'brown' },
            { label: getFiltersTranslationPath('options.grey'), value: 'เทา', name: 'gray' },
            { label: getFiltersTranslationPath('options.silver'), value: 'เงิน', name: 'silver' },
            { label: getFiltersTranslationPath('options.blue'), value: 'น้ำเงิน', name: 'blue' },
            { label: getFiltersTranslationPath('options.red'), value: 'แดง', name: 'red' },
            { label: getFiltersTranslationPath('options.green'), value: 'เขียว', name: 'green' },
            { label: getFiltersTranslationPath('options.orange'), value: 'ส้ม', name: 'orange' },
            { label: getFiltersTranslationPath('options.beige'), value: 'เบจ', name: 'beige' },
          ],
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'availability_button',
      filters: [
        {
          name: 'flags',
          customName: 'listing',
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'FILTER_AVAILABLE',
            listFields: ['listing.flags.is_available', 'listing.flags.is_coming_soon', 'listing.flags.is_pending_sale'],
          }),
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'mileage',
      filters: [
        {
          name: 'inventory.mileage',
          customName: 'listing.mileage',
          detailsText: {
            average: '',
          },
          suffix: getFiltersTranslationPath('km'),
        },
      ],
    },
    {
      name: 'tags',
      algoliaFetching: false,
      header: getFiltersTranslationPath('carroExclusives'),
      headerMobile: getFiltersTranslationPath('carroExclusives'),
      filters: [
        {
          name: 'flags',
          customName: 'listing',
          header: getFiltersTranslationPath('exclusives'),
          headerMobile: getFiltersTranslationPath('exclusives'),
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'TAGS',
            listFields: ['listing.flags.is_ccpo.th', 'listing.is_360', 'listing.mechanic_video'],
          }),
        },
      ],
    },
    {
      name: 'promotions',
      active: true,
      activeMobile: true,
      algoliaFetching: true,
      requireDefaults: true,
      filters: [
        {
          name: 'promotion_name',
          customName: 'promotion_name',
          type: 'button',
          filterSelectedHeader: '[label]',
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'body_button',
      filters: [
        {
          name: 'inventory.car_type',
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'BODY_TYPE',
            listFields: [
              'hatchback',
              'hybrid',
              'luxury_sedan',
              'mid_sized_sedan',
              'mpv',
              'sedan',
              'sports',
              'stationwagon',
              'suv',
              'electric',
              'van',
              'pickup',
              'eco_car',
            ],
          }),
        },
      ],
    },
    {
      name: 'fuel_button',
      filters: [
        {
          name: 'inventory.fuel_type',
          type: 'button',
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'FUEL_TYPE',
            listFields: ['diesel', 'petrol', 'hybrid'],
          }),
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'transmission',
      algoliaFetching: false,
      filters: [
        {
          name: 'inventory.transmission',
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'TRANSMISSION',
          }),
        },
      ],
    },
  ],
  attributesToRetrieve: ['*'],
  mobileFilters: [
    'make',
    'make_model',
    'availability_button',
    'category',
    'fuel_button',
    'transmission',
    'seats',
    'body_button',
    'colours',
    'mileage',
    'manufacture_year',
    'price_range',
    'tags',
  ],
  otherFilters: ['category', 'fuel_button', 'transmission', 'seats', 'colours', 'mileage', 'tags'],
  desktopFilters: [
    'make',
    'make_model',
    'body_button',
    'price_range',
    'manufacture_year',
    // 'promotions',
    'availability_button',
  ],
  isAlgoliaOptionsLoaded: true,
  isInitalSettingsLoaded: true,
  autocompleteHits: [],
  query: '',
  hitsPerPage: 24,
};

export default FILTER_CONFIGS_TH;
