/* eslint-disable import/prefer-default-export */
import { KeyboardEvent, useState } from 'react';
import numeral from 'numeral';
import get from 'lodash/get';
import upperCase from 'lodash/upperCase';

import { parsePhoneNumber } from 'libphonenumber-js/core';
import type { CountryCode, MetadataJson } from 'libphonenumber-js/core';
import metaDataCustom from '@source/assets/libphone-metadata.custom.json';

import type {
  DefaultOptionProps,
  FilterProps,
  GroupSettingProps,
  QSFilterProps,
  SettingProps,
} from '@design-system/components/FiltersV1/FilterProps';
import { TRegionKey } from '@source/interface';
import Logger from '@source/services/Logger';

/**
 * export label from custom pattern label and the reference object
 *
 * @param customLabel
 * @param item
 * @returns
 */
export const getCustomLabel = (
  customLabel: string | undefined,
  item: FilterProps | QSFilterProps | DefaultOptionProps,
) => {
  if (!customLabel) return null;

  const regex = /\${([^$]+)\}/gm;

  const results = (customLabel.match(regex) as any[]) || [];

  const getWordsBetweenCurlies = (str: string) => {
    const arr = [];
    const re = /{([^}]+)}/g;
    let text;

    // eslint-disable-next-line no-cond-assign
    while ((text = re.exec(str))) {
      arr.push(text[1]);
    }
    return arr;
  };

  return results.reduce((customStr, matchString) => {
    const args = getWordsBetweenCurlies(matchString);
    const firstMatch = args?.[0];
    const value = get(item, firstMatch);
    return customStr.replace(matchString, value);
  }, customLabel);
};

export const hasFilterOptions = (group: GroupSettingProps) =>
  group?.settings?.every((setting: SettingProps) =>
    setting?.filters?.every((filter: FilterProps) => !filter?.options || filter?.options?.length),
  );

const formatNumber = (
  integer?: number | null | string,
  requireComma?: boolean,
  displayMultiplier?: number,
  numberOfDecimal?: number,
) => {
  if (!integer) return integer;

  const newInteger = parseFloat(integer.toString()) * (displayMultiplier || 1);
  const numberFormat = requireComma ? '0,0' : '0';
  const decimalFormat =
    numberOfDecimal && numberOfDecimal > 0 ? Array(numberOfDecimal).reduce((prev) => `${prev}0`, {}) : '';

  return numeral(newInteger).format(`${numberFormat}[.]${decimalFormat}`);
};

formatNumber.defaultProps = {
  integer: 0,
  requireComma: true,
  numberOfDecimal: 0,
  displayMultiplier: 1,
};

export default formatNumber;

export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj || {}));
}

export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      Logger.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      Logger.log(error);
    }
  };
  return [storedValue, setValue];
}

// eslint-disable-next-line prefer-regex-literals
const MATCH_SQUARE_BRACKET_REGEX = new RegExp(/\[(.*?)\]/); // ["[avg]", "avg"]

/**
 * Get formatted string by using string pattern
 *
 * @param string
 * @param valuesObject
 * @param fallbackValue
 * @returns
 */
export const getFormattedString = (string: string, valuesObject: any, fallbackValue = '') => {
  if (
    (typeof string !== 'string' && !valuesObject) ||
    typeof valuesObject !== 'object' ||
    Object.keys(valuesObject).length <= 0
  ) {
    // nothing to format
    return string;
  }

  let newString = string;
  let valuesLength = Object.values(valuesObject).length;
  while (valuesLength > 0) {
    const match = newString.match(MATCH_SQUARE_BRACKET_REGEX);
    if (!match) {
      break;
    }
    newString = newString.replace(match[0], valuesObject[match[1]] || fallbackValue);
    // eslint-disable-next-line no-plusplus
    valuesLength--;
  }

  return newString;
};

export const toUrlSegment = (input: string) => {
  if (!input) return '';

  return input
    .toString()
    .trim()
    .toLowerCase()
    .replace('[opc]-', '')
    .replace(/[^\w]/gi, '-')
    .replace(/-+/gi, '-')
    .replace(/(^-|-$)/gi, '');
};

export const IMAGE_KIT_URL = 'https://cdn.carro.co';

export const getImageKitURLFixedDimension = (width: string, height: string) =>
  `${IMAGE_KIT_URL}/tr:w-${width},h-${height},pr-true/`;

export const getTrimedS3Url = (url: string) => {
  // const trimedUrl = url ? url.replace('https://s3-ap-southeast-1.amazonaws.com/trustycars.com/', '') : url;
  const trimedUrl = url ? url.replace('https://s3-ap-southeast-1.amazonaws.com/carro.co/', '') : url;
  return trimedUrl;
};

export const getRecaptchaToken = (onSuccess: any, onFailure: any, action: any) =>
  new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, { action })
          .then((token: string) => {
            resolve(onSuccess(token));
          })
          .catch(() => {
            reject(onFailure());
          });
      });
    } else {
      resolve(onFailure());
    }
  });

export const convertIntegerToComma = (integer: number) => {
  const newInteger = parseFloat(String(integer));
  if (!Number.isInteger(newInteger)) {
    return integer;
  }
  return numeral(newInteger).format('0,0');
};

export const getStaticCDN = (path: string) => `${process.env.NEXT_PUBLIC_CARRO_WEB_CDN}${path}`;

export const getStaticCDNFixedDimension = (path: string, width: number | string, height: number | string) =>
  `${process.env.NEXT_PUBLIC_CARRO_WEB_CDN}/tr:w-${width},h-${height},pr-true/${path}`;

/**
 * Check the given string is dot notation
 * eg: abc => not
 * abc.def => dot notation
 *
 * source: https://stackoverflow.com/questions/25799238/check-string-for-object-dot-notation
 * @param str
 * @returns
 */
export const isDotNotation = (str?: string | null) => {
  if (!str) return false;

  return !!(/[\w+]\.[\w+]/gi.test(str) && !/\s+/g.test(str));
};

/**
 *
 * Format international number by using libphonenumber/core
 *
 * @param phone the phone number
 * @param country the country
 * @returns string
 */
export const formatInternationalPhoneNumber = (phone?: string | null, country?: TRegionKey) => {
  if (!phone) return null;

  const phoneNumberInstance = parsePhoneNumber(
    `${phone}`,
    upperCase(country) as CountryCode,
    metaDataCustom as MetadataJson,
  );

  return phoneNumberInstance.formatInternational();
};

export const onKeyDownNumberOnly = (event: KeyboardEvent<HTMLInputElement>) => {
  const { code } = event;

  const arrCodeNotWanted = ['KeyE', 'Minus', 'Equal', 'Period'];

  if (arrCodeNotWanted.includes(code)) event.preventDefault();
};

export const maskString = (input: string, option?: { start?: number; end?: number; masker?: string }): string => {
  const maskStringOption = {
    start: 3,
    end: input.length - 1,
    masker: '*',
    ...option,
  };

  return (
    input.slice(0, maskStringOption.start) +
    maskStringOption.masker.repeat(Math.max(maskStringOption.end - maskStringOption.start + 1, 0)) +
    input.slice(maskStringOption.end + 1)
  );
};
