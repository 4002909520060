/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import type { FormInstance } from 'antd/lib/form/Form';

import CustomButton from '@source/components/CustomButton/CustomButton';
import FormItems from '@source/components/FormItems/FormItems';

// import useGTMEvent from '@source/hooks/useGTMEvent';
import { TRegionKey } from '@source/interface';
import { getFormInput } from '@source/constants/forms';
import useOTPService from '@source/hooks/useOTPService';
import CountdownTimer from '@source/pages/CarDetail/components/BuyNow/CountdownTimer';
import { getLoginTypesOptionsByCountry } from '../constants';

const StyledForm = styled(Form)`
  text-align: left;
  .ant-statistic-content {
    ${(props) => props.theme.typo.style.captionRegular}
    ${(props) => props.theme.typo.familyGoogle.regular};
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
  }
  .error-message {
    text-align: left;
    margin-bottom: 15px;
    ${(props) => props.theme.typo.style.body}
    ${(props) => props.theme.typo.familyGoogle.regular};
    color: ${(props) => props.theme.color.errorHighEmphasis};
  }
`;

const StyledButton = styled(CustomButton)`
  width: 100%;
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-weight: 600;
`;

const StyledDesc = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  ${(props) => props.theme.typo.familyGoogle.bold};
  font-size: 16px;
`;

const StyledNote = styled.div`
  margin-top: 20px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-size: 12px;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};

  text-align: center;
`;

const LoginStepPhone = ({
  form,
  countryCode,
  onFormSubmit,
}: {
  form: FormInstance;
  countryCode: TRegionKey;
  onFormSubmit: React.MouseEventHandler<HTMLElement> | undefined;
}) => {
  const { t } = useTranslation();
  // const { pushGTMEvent } = useGTMEvent();

  const { otpState, otpCountdownDone, clearError } = useOTPService();
  const { loading, error, isCountdownOTP, countdownTimer, phone: phoneNumber, type: verifyType } = otpState;

  const [isButtonDisabled, setIsButtonDisabled] = useState(!phoneNumber);

  const { phone, otp_channel: otpChannel } = getFormInput(countryCode || 'sg', t);

  const loginTypesByCountry = getLoginTypesOptionsByCountry(countryCode);

  const formItems = useMemo(() => {
    const hasOptionWA = loginTypesByCountry?.some((option) => option?.value === 'whatsapp');

    if (hasOptionWA) {
      return [phone, otpChannel];
    }
    return [phone];
  }, [loginTypesByCountry, otpChannel, phone]);

  // clear all error when first load step phone
  useEffect(() => {
    clearError();
  }, []);

  const handleOnFieldsChange = () => {
    // cuz only one phone field here so I don't check specific field change.
    setIsButtonDisabled(!isEmpty(form.getFieldError('phone')) || isEmpty(form.getFieldValue('phone'))); // If form.getFieldError return [] means that no error
  };

  return (
    <StyledForm
      form={form}
      initialValues={{ phone: phoneNumber, otp_channel: verifyType }}
      layout="vertical"
      name="step_phone_form_login"
      onFieldsChange={handleOnFieldsChange}
    >
      <Row>
        <StyledDesc>{t('shared.login.stepLoginDesc')}</StyledDesc>
        <FormItems form={form} formItems={formItems} />

        {error && <div className="error-message">{error}</div>}

        <Col xs={24}>
          <div className="step-actions">
            <StyledButton onClick={onFormSubmit} loading={loading} disabled={isButtonDisabled}>
              {t('shared.login.getOTP')}
            </StyledButton>
          </div>
          <StyledNote>
            <span className="loginNote">{t(`${error ? '' : 'shared.login.stepLoginNote'}`)}</span>
            {error && isCountdownOTP && (
              <CountdownTimer
                value={countdownTimer}
                format={`${t('details.buyNow.otp.getNewOTPIn')}`}
                onFinish={() => otpCountdownDone()}
              />
            )}
          </StyledNote>
        </Col>
      </Row>
    </StyledForm>
  );
};

export default LoginStepPhone;
