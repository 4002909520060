import React, { FC, memo } from 'react';
import { Typography } from '@design-system/components';
import Image from 'next/image';
import styled from 'styled-components';
import { SUPPORTED_COUNTRIES, DEFAULT_LANGUAGE_MAP_BY_COUNTRY } from '@source/constants/config';
import { TRegionKey } from '@source/interface';
import { CRAZY_RABBIT_WEB_URL } from '@source/constants/common';
import { removeSelectedLocationOnStorage } from '@source/services/Language';

const StyledWrapper = styled.div`
  .side-navigation-other-region {
    .side-navigation-region {
      display: flex;
      margin-top: 15px;
      flex-wrap: wrap;
      /* justify-content: space-between; */
      gap: 8px;

      .side-navigation-region-flag {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .side-navigation-region-flag-image {
    border: 0.1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis} !important;

    &.icon-v2 {
      border: none !important;
    }
  }
`;

interface FlagRegionSelectorProps {
  content?: any;
}

// no need to validate "content" prop
// eslint-disable-next-line react/prop-types
const FlagRegionSelector: FC<FlagRegionSelectorProps> = memo(({ content }) => {
  const getUrl = (countryFlag: TRegionKey): string => {
    const defaultLang = DEFAULT_LANGUAGE_MAP_BY_COUNTRY?.[countryFlag as TRegionKey]?.code;
    return `${CRAZY_RABBIT_WEB_URL[countryFlag]}/${countryFlag}/${defaultLang}`;
  };

  const handleClick = () => {
    removeSelectedLocationOnStorage();
  };

  return (
    <StyledWrapper>
      <div className="side-navigation-other-region">
        <Typography variant="mainButtonOrLink" className="side-navigation-content-color">
          {content}
        </Typography>
        <div className="side-navigation-region">
          {Object.values(SUPPORTED_COUNTRIES).map((region) => (
            <div className="side-navigation-region-flag" key={region.code}>
              <a href={getUrl(region.code)} aria-label={region.code}>
                <div onClick={handleClick} aria-hidden="true">
                  <Image
                    src={region.iconV2}
                    alt="apple"
                    className="side-navigation-region-flag-image icon-v2"
                    width={51}
                    height={33}
                    layout="fixed"
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
});

FlagRegionSelector.defaultProps = {
  content: null,
};

export default FlagRegionSelector;
