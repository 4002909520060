/* eslint-disable react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react';
import { Form, Select, Col, Input, Checkbox, Slider, CheckboxOptionType, Radio } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { FormInstance } from 'antd/es/form';

import { DefaultOptionProps } from '@design-system/components/FiltersV1/FilterProps';

import { IFormItemProps } from '@source/interface/form';

import DatePicker from '@source/components/FormItems/DatePicker';

import { THEME } from '@source/constants/theme';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;

  &.hidden {
    display: none !important;
  }

  .field-value-only {
    ${(props) => props.theme.typo.familyGoogle.bold};
  }

  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.5);
    border: none;
    background: #f5f5f5 !important;
  }

  .ant-picker,
  .ant-select-selector,
  .ant-input {
    border-radius: 8px !important;
    height: 42px !important;

    input {
      height: 42px !important;
    }
  }

  .ant-input,
  .ant-input-lg,
  .ant-select-selection-placeholder,
  .ant-picker-large .ant-picker-input > input,
  .ant-select-selection-search,
  .ant-checkbox + span {
    ${(props) => props.theme.typo.familyGoogle.regular};
    ${(props) => props.theme.typo.style.body};
  }

  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      padding: 0 11px;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};

    .ant-input {
      line-height: 40px !important;
      height: 40px !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        height: 40px !important;
        line-height: 40px !important;
      }
      .ant-select-selection-search {
        input {
          height: 40px !important;
          line-height: 40px !important;
        }
      }
    }
  }

  .ant-form-item-control {
    flex: 1 1 auto !important;
  }

  .ant-form-item-required::after {
    display: inline-block !important;
    content: '*';
    line-height: 1;
    font-size: 9px;
    color: ${(props) => props.theme.color.errorHighEmphasis};
    position: relative;
    top: -3.5px;
  }

  .ant-form-item-required::before {
    display: none !important;
  }

  /* slider */
  .ant-slider-mark-text:first-child {
    left: 5% !important;
  }

  .ant-slider-mark-text:last-child {
    left: 95% !important;
  }

  .ant-slider-track {
    background-color: ${(props) => props.theme.color.primaryBase};
  }

  .ant-slider-handle {
    border-color: ${(props) => props.theme.color.primaryBase};
  }

  .ant-form-item-label {
    padding: 0px;
    margin-bottom: 4px;
  }

  .ant-form-item-label label {
    ${(props) => props.theme.typo.style.body};
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    ${(props) => props.theme.typo.familyGoogle.regular};
    text-transform: capitalize;
  }

  .ant-input {
    &[type='number'] {
      &.no-controls {
        /* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        -moz-appearance: textfield;
      }
    }
  }
  textarea.ant-input {
    height: unset !important;
  }
`;

const OverrideFormItemStyle = createGlobalStyle<{ fontFamilyRegular: any; styleBody: any }>`
  .ant-select-dropdown {
    padding: 0;
    .ant-select-item-option-content {
      ${(props) => props.fontFamilyRegular};
      ${(props) => props.styleBody};
    }
  }
`;

// Todo: Will use below from reuseable configs
const dateTimePickerDefaultProps = {
  style: { width: '100%' },
  showTime: { format: 'hh:mm' },
  format: 'DD/MM/YYYY, hh:mm A',
};

const datePickerDefaultProps = {
  style: { width: '100%' },
  format: 'DD/MM/YYYY',
};

const renderSelectOptions = (options: DefaultOptionProps[]) =>
  options.map((option: DefaultOptionProps, index: number) => (
    // Todo: temp use key here because not sure which value should be used for key here
    // eslint-disable-next-line react/no-array-index-key
    <Select.Option key={index} value={option.value} disabled={!!option.disabled}>
      {option.title}
    </Select.Option>
  ));

const renderRadioOptions = (options: DefaultOptionProps[]) =>
  options.map((option: DefaultOptionProps, index: number) => (
    // Todo: temp use key here because not sure which value should be used for key here
    // eslint-disable-next-line react/no-array-index-key
    <Radio key={index} value={option.value}>
      {option.title}
    </Radio>
  ));

export type FormItemCustomProps = IFormItemProps & { formItemStyle?: CSSProperties };

type Props = {
  form: FormInstance;
  formItems: FormItemCustomProps[];
  defaultColProps?: Record<string, any>;
  defaultReadonly?: boolean;
};

const FormItems = ({ form, formItems, defaultColProps, defaultReadonly }: Props) => (
  <>
    {formItems.map((item) => {
      const {
        type,
        formItemProps,
        formItemStyle,
        colProps,
        controlProps,
        options,
        readonly = defaultReadonly,
        renderCustomItem = () => {},
        useCustomFormItem,
        visible,
      } = item || {};
      const { name } = formItemProps || {};
      const colPropsConfig = colProps || defaultColProps;

      if (visible === false) {
        return null;
      }

      if (readonly) {
        return (
          <Col key={name} {...colPropsConfig}>
            <Form.Item {...formItemProps}>
              <div>{form.getFieldValue(name)}</div>
            </Form.Item>
          </Col>
        );
      }

      let itemContent;

      switch (type) {
        case 'input':
          itemContent = <Input {...controlProps} />;
          break;
        case 'textarea':
          itemContent = <Input.TextArea {...controlProps} />;
          break;
        case 'select':
          itemContent = <Select {...controlProps}>{renderSelectOptions(options || [])}</Select>;
          break;
        case 'date':
          itemContent = <DatePicker {...datePickerDefaultProps} {...controlProps} />;
          break;
        case 'datetime':
          itemContent = <DatePicker {...dateTimePickerDefaultProps} {...controlProps} />;
          break;
        case 'checkbox':
          itemContent = <Checkbox.Group options={options as CheckboxOptionType[]} {...controlProps} />;
          break;
        case 'radio':
          itemContent = <Radio.Group {...controlProps}>{renderRadioOptions(options || [])}</Radio.Group>;
          break;
        case 'slider':
          itemContent = <Slider {...controlProps} />;
          break;
        case 'customItem':
          itemContent = renderCustomItem({ form });
          break;
        default:
          break;
      }

      if (!itemContent) {
        return null;
      }

      return (
        <Col key={name} {...colPropsConfig}>
          <OverrideFormItemStyle
            fontFamilyRegular={THEME.typo.familyGoogle.regular}
            styleBody={THEME.typo.style.body}
          />

          {!useCustomFormItem ? (
            <StyledFormItem {...formItemProps} style={formItemStyle || {}}>
              {itemContent}
            </StyledFormItem>
          ) : (
            itemContent
          )}
        </Col>
      );
    })}
  </>
);

FormItems.defaultProps = {
  defaultColProps: { xs: 24 },
  defaultReadonly: false,
};

export default FormItems;
