export interface DifferenceService {
  icon: string;
  title: string;
  subTitle: string;
  desc: string;
  notes?: string;
}
export interface ServicesDataTable {
  headers: {
    name: string;
    width: number;
    desc?: string;
  }[];
  data: {
    category: string;
    maintenancePackage: null | number;
    airConditionService: null | number;
    brakeService: null | number;
    typreService: null | number;
    bodyAndPaintService: null | number;
  }[];
}
export interface myTukarCenterAddressProps {
  [key: string]: {
    name: string;
    address: string;
    openingHours: string;
    tel: string;
    viewInMap: string;
  };
}

/* eslint-disable no-shadow */
export enum TitlePlacement {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export interface LocationPointProps {
  title?: string;
  point: {
    title: string;
  };
  styles: {
    width?: number;
    height?: number;
    top: number;
    left: number;
  };
  titlePlacement?: TitlePlacement;
  onClick?: () => void;
}
