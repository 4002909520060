import { TRegionKey } from '@source/interface';
import { LOCAL_STORAGE_KEYS } from '@source/constants/common';

const useStoreSearchFilterHistory = () => {
  const setSearchedFiltered = ({ query, filters, region }: any) => {
    const historyLocalStorage = localStorage.getItem(`${LOCAL_STORAGE_KEYS.SEARCH_FILTER_CARS}_${region}`);

    const existingHistory = historyLocalStorage ? JSON.parse(historyLocalStorage) : [];
    let history = existingHistory;

    history = [
      ...history,
      {
        query,
        filters,
        search_time: new Date(),
      },
    ];

    localStorage.setItem(`${LOCAL_STORAGE_KEYS.SEARCH_FILTER_CARS}_${region}`, JSON.stringify(history));
  };

  const clearHistory = (region: TRegionKey) => {
    const history: Array<any> = [];
    localStorage.setItem(`${LOCAL_STORAGE_KEYS.SEARCH_FILTER_CARS}_${region}`, JSON.stringify(history));
  };

  const getHistory = (region: TRegionKey) => {
    let listHistory = [];
    if (typeof window !== 'undefined') {
      const filtered = localStorage.getItem(`${LOCAL_STORAGE_KEYS.SEARCH_FILTER_CARS}_${region}`);
      if (filtered) {
        listHistory = JSON.parse(filtered);
      }
    }
    return listHistory;
  };

  return {
    setSearchedFiltered,
    clearHistory,
    getHistory,
  };
};

export default useStoreSearchFilterHistory;
