/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { Hit } from '@algolia/client-search';

import FILTER_CONFIGS, { FilterConfigProps } from '@source/pages/Home/components/Filters/configs';
import { SelectedSearchFilterType } from '@source/types';
import { SettingProps, FilterProps } from '@design-system/components/FiltersV1/FilterProps';
import { LocationOptionProps } from '@source/pages/Home/components/FilterLocationButton/FilterButton';
import DataQuery from '@source/services/DataQuery';
import { TLanguageKey, TRegionKey } from '@source/interface';
import { AxiosError } from 'axios';
import { IErrorResponse } from '@source/interface/apiResponse';

const sliceName = 'BROWSE_CARS';

export type CarroExclusivesTag = {
  display_name: string;
  algolia_key: string;
};

type BrowseInitialStateProps = {
  currentQuery: string;
  selectedSearchFilters: SelectedSearchFilterType;
  currentFilterSettings: SettingProps[];
  initialFilterSettings: FilterProps[];
  currentFilterConfigs: FilterConfigProps;
  syncUpUrlToFilter: boolean;
  filterChangeCount: number;
  notifyMeSearchFilterKeywords: any;
  isFromNotifyMe: boolean;
  popularCars: {
    data: Hit<object>[] | null;
    isLoading: boolean;
    isLoaded: boolean;
  };
  filterLocationOptions: LocationOptionProps[];
};

const INITIAL_STATE: BrowseInitialStateProps = {
  currentQuery: '',
  syncUpUrlToFilter: true,
  selectedSearchFilters: {
    make: [],
    make_model: [],
    body_type: [],
    category: [],
  },
  currentFilterSettings: FILTER_CONFIGS.sg.initialFilterSettings,
  filterChangeCount: 0,
  currentFilterConfigs: { ...FILTER_CONFIGS.sg },
  initialFilterSettings: [],
  notifyMeSearchFilterKeywords: '',
  isFromNotifyMe: false,
  popularCars: {
    data: [],
    isLoading: false,
    isLoaded: false,
  },
  filterLocationOptions: [],
};

// Todo: Refactor actions but using only one action and pass the needed property in the param later.
const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentQuery: (state, { payload }) => {
      state.currentQuery = payload || '';
    },
    setSelectedSearchFilters: (state, { payload }) => {
      state.selectedSearchFilters = payload || [];
    },
    setCurrentFilterSettings: (state, { payload }) => {
      state.currentFilterSettings = payload || [];
    },
    setInitialFilterSettings: (state, { payload }) => {
      state.initialFilterSettings = payload || [];
    },
    setFilterChangeCount: (state, { payload }) => {
      state.filterChangeCount = payload || 0;
    },
    setCurrentFilterConfigs: (state, { payload }) => {
      state.currentFilterConfigs = payload || [];
    },

    setSyncUpUrlToFilter: (state, { payload }) => {
      state.syncUpUrlToFilter = payload || false;
    },

    setNotifyMeSearchFilterKeywords: (state, { payload }) => {
      state.notifyMeSearchFilterKeywords = payload || [];
    },
    setIsFromNotifyMe: (state, { payload }) => {
      state.isFromNotifyMe = payload || [];
    },
    setPopularCars: (state, { payload }) => {
      state.popularCars = payload;
    },
    setFilterLocationOptions: (state, { payload }) => {
      state.filterLocationOptions = payload;
    },
  },
});

const {
  setCurrentQuery,
  setCurrentFilterSettings,
  setInitialFilterSettings,
  setFilterChangeCount,
  setSyncUpUrlToFilter,
  setSelectedSearchFilters,
  setCurrentFilterConfigs,
  setNotifyMeSearchFilterKeywords,
  setIsFromNotifyMe,
  setPopularCars,
  setFilterLocationOptions,
} = slice.actions;
export {
  setCurrentQuery,
  setCurrentFilterSettings,
  setInitialFilterSettings,
  setFilterChangeCount,
  setSyncUpUrlToFilter,
  setSelectedSearchFilters,
  setCurrentFilterConfigs,
  setNotifyMeSearchFilterKeywords,
  setIsFromNotifyMe,
  setPopularCars,
  setFilterLocationOptions,
};

export default slice.reducer;
