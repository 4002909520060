import { getDataConfigsCountry, getFiltersTranslationPath } from './constants';

const countryCode = 'my';
/* eslint-disable no-template-curly-in-string */

const INITIAL_FILTER_SETTINGS = [
  {
    name: 'promotions',
    active: true,
    activeMobile: true,
    algoliaFetching: true,
    requireDefaults: true,
    filters: [
      {
        name: 'promotion_name',
        customName: 'promotion_name',
        type: 'button',
        filterSelectedHeader: '[label]',
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'price_range',
    active: true,
    activeMobile: true,
    group: 'cost_details',
    groupMobile: 'cost_details',
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    hasDivider: true,
    filters: [
      {
        name: 'listing.asking_price',
        type: 'range_input',
        prefix: 'RM',
        headerFormat: true,
        requireCommaFormat: true,
        filterSelectedHeader: 'RM[selectedMinInput] - RM[selectedMaxInput]',
        detailsText: {
          average: getFiltersTranslationPath('priceRangeAverageMy'),
        },
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        placeholderMin: 'Min. price',
        placeholderMax: 'Max. price',
        selectedCustomLabel: getFiltersTranslationPath('priceRangeCustomLabelMy'),
      },
    ],
  },
  {
    name: 'manufacture_year',
    algoliaFetching: true,
    requireDefaults: true,
    filters: [
      {
        name: 'inventory.year_of_manufacture',
        customName: 'inventory.manufacture_year',
        type: 'range_input',
      },
    ],
  },
  {
    name: 'mileage',
    active: true,
    activeMobile: true,
    group: 'history',
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        headerFormat: true,
        name: 'listing.mileage',
        detailsText: {
          average: '',
        },
        requireCommaFormat: true,
        suffix: 'KM',
        type: 'range_input',
        isNumeric: true,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        placeholderMin: 'Min. distance',
        placeholderMax: 'Max. distance',
        selectedCustomLabel: getFiltersTranslationPath('mileageCustomLabel'),
      },
    ],
  },
  {
    name: 'body_button',
    active: true,
    activeMobile: true,
    group: 'body_button',
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        name: 'listing.body_type',
        customName: 'inventory.car_type',
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'BODY_TYPE',
          listFields: [
            'convertible',
            'coupe',
            'hatchback',
            'hybrid',
            'luxury_sedan',
            'mid_sized_sedan',
            'mpv',
            'pickup',
            'sedan',
            'sports',
            'sports_hatchback',
            'stationwagon',
            'suv',
            'electric',
          ],
        }),
      },
    ],
  },
  {
    name: 'installment',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('monthlyPayment'),
    groupHeader: getFiltersTranslationPath('monthlyPayment'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        name: 'listing.installment',
        header: getFiltersTranslationPath('monthlyPayment'),
        headerMobile: getFiltersTranslationPath('monthlyPayment'),
        detailsText: {
          average: getFiltersTranslationPath('installmentAverageMy'),
        },
        prefix: 'RM',
        type: 'range_input',
        isNumeric: true,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        selectedCustomLabel: getFiltersTranslationPath('installmentCustomLabelMy'),
      },
    ],
  },
  {
    name: 'seats',
    active: true,
    activeMobile: true,
    algoliaFetching: false,
    filters: [
      {
        name: 'inventory.seats',
        options: getDataConfigsCountry({ countryCode, dataName: 'SEATS_OPTIONS' }),
      },
    ],
  },
  {
    name: 'tags',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('myTukarExclusives'),
    group: 'exclusives',
    groupHeader: getFiltersTranslationPath('myTukarExclusives'),
    groupHeaderMobile: getFiltersTranslationPath('myTukarExclusives'),
    algoliaFetching: false,
    filters: [
      {
        name: 'listing',
        header: getFiltersTranslationPath('exclusives'),
        headerMobile: getFiltersTranslationPath('exclusives'),
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'TAGS',
          listFields: ['listing.flags.is_ccpo', 'is_promo', 'listing.is_360'],
        }),
      },
    ],
  },
  {
    name: 'make_model',
    active: true,
    activeMobile: true,
    algoliaFetching: false,
    filters: [
      {
        name: 'inventory.make_model',
        type: 'make_model',
      },
    ],
  },
  {
    name: 'availability_button',
    active: true,
    activeMobile: true,
    algoliaFetching: false,
    filters: [
      {
        name: 'listing',
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'FILTER_AVAILABLE',
          listFields: ['listing.flags.is_available', 'listing.flags.is_pending_sale'],
        }),
      },
    ],
  },
  {
    name: 'transmission',
    algoliaFetching: false,
    filters: [
      {
        name: 'inventory.transmission',
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'TRANSMISSION',
        }),
      },
    ],
  },
  {
    name: 'fuel_button',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('fuelType'),
    group: 'fuel_button',
    groupHeader: getFiltersTranslationPath('fuelType'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        header: getFiltersTranslationPath('fuelType'),
        headerMobile: getFiltersTranslationPath('fuelType'),
        name: 'inventory.fuel_type',
        type: 'button',
        filterSelectedHeader: '[label]',
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'FUEL_TYPE',
          listFields: [
            'diesel',
            'diesel-hijau',
            'petrol',
            'petrol-electric',
            'petrol-with-ngv',
            'petrol-with-hybrid',
            'hybrid-electric',
            'electric',
          ],
        }),
        selectedOptions: null,
      },
    ],
  },
  // For tukar_je campaign
  // {
  //   name: 'category',
  //   algoliaFetching: false,
  //   activeMobile: true,
  //   active: true,
  //   filters: [
  //     {
  //       header: getFiltersTranslationPath('categories'),
  //       headerMobile: getFiltersTranslationPath('categories'),
  //       name: 'categories',
  //       options: getDataConfigsCountry({
  //         countryCode,
  //         dataName: 'CATEGORIES',
  //         listFields: ['tukar_je_eco_friendly', 'tukar_je_fuel_efficiency', 'tukar_je_performance', 'tukar_je_family'],
  //       }),
  //     },
  //   ],
  // },
];

const ATTRIBUTES_TO_RETRIEVE = [
  // attribute will be returned
  'created_at',
  'promo_label',
  'promo_images',
  'promotion',
  'promotion.promo_blurb.url',
  'hits',
  'categories',
  'listing.id',
  'listing.title',
  'listing.slug',
  'listing.mileage',
  'listing.asking_price',
  'listing.original_price',
  'listing.photos',
  'listing.installment',
  'listing.flags',
  'listing.flags.is_mytukar_guaranteed',
  'listing.thumbnail',
  'listing.promotional_price',
  'listing.key_words',
  'listing.status',
  'listing.city',
  'listing.is_new_arrival',
  'listing.has_360_interior_photos',
  'listing.has_360_exterior_photos',
  'listing.body_type',
  'inventory.make',
  'inventory.model',
  'inventory.make_model',
  'inventory.submodel',
  'inventory.number_of_owners',
  'inventory.year_of_manufacture',
  'inventory.seats',
  'inventory.color',
  'inventory.car_type',
  'inventory.transmission',
  'inventory.fuel_type',
  'inventory.engine_capacity',
  'location',
  'is_promo',
  'inside_badges',
];

const OTHER_FILTERS_ORDER = [
  // 'category',
  'fuel_button',
  'availability_button',
  'transmission',
  'body_button',
  'colours',
  'tags',
];

export const DESKTOP_FILTERS_ORDER = [
  'make',
  'make_model',
  'installment',
  'mileage',
  'manufacture_year',
  'price_range',
  // 'promotions_button',
  '360view',
];

export const MOBILE_FILTERS_ORDER = [
  // 'category',
  'make',
  'make_model',
  'body_button',
  'price_range',
  'installment',
  'mileage',
  'manufacture_year',
  'colours',
  'fuel_button',
  'transmission',
  'availability_button',
  'tags',
];

const FILTER_CONFIGS_MY = {
  loading: false,
  initialFilterSettings: INITIAL_FILTER_SETTINGS,
  attributesToRetrieve: ATTRIBUTES_TO_RETRIEVE,
  mobileFilters: MOBILE_FILTERS_ORDER,
  otherFilters: OTHER_FILTERS_ORDER,
  desktopFilters: DESKTOP_FILTERS_ORDER,
  isAlgoliaOptionsLoaded: true,
  isInitalSettingsLoaded: true,
  autocompleteHits: [],
  query: '',
  hitsPerPage: 24,
};

export default FILTER_CONFIGS_MY;
