import { TRegionKey, TLanguageKey } from '@source/interface';

interface URLConfig {
  region: TRegionKey;
  lang: TLanguageKey | string;
  path?: string;
  query?: string;
}

export const URL_CONFIG = ({ region, lang, path, query }: URLConfig) => {
  const newLang = lang === 'ms-SG' && region === 'sg' ? 'ms' : lang;
  const prefix = `/${region || 'sg'}/${(newLang || 'en').toLowerCase()}`;
  return {
    home: `${prefix}${query ? `?${query}` : ''}`,
    buy: `${prefix}/buy${query ? `?${query}` : ''}`,
    sell: `${prefix}/sell`,
    details: `${prefix}/cars`,
    formatted: `${prefix}${path ? `/${path}` : ''}${query ? `?${query}` : ''}`,
    profile: `${prefix}/profile`,
    certified: `${prefix}/carro-certified`,
    workshop: `${prefix}/workshop`,
    buyNew: `${prefix}/buy-new${query ? `?${query}` : ''}`,
    finance: `${prefix}/finance`,
    careers: `${prefix}/careers`,
  };
};

export const CARS_URL_NAME = {
  my: 'kereta-terpakai',
  id: 'mobil-bekas',
};

export const BUY_URL_NAME = {
  my: 'beli-kereta-terpakai',
  id: 'beli-mobil-bekas',
};

export const SELL_URL_NAME = {
  my: 'jual-kereta',
  id: 'jual-mobil',
};

export default {};
