// Catch clause variable type annotation must be 'any' or 'unknown' if specified
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRegionKey } from '@source/interface';
import UserProfile from '@source/services/UserProfile';

const sliceName = 'USER_PROFILE/VIEWING_HISTORY';

export const fetchViewingHistory = createAsyncThunk(
  `${sliceName}/FETCH_VIEWING_HISTORY`,
  async (
    { country, data, isAppendList = true }: { country: TRegionKey; data: any; isAppendList?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await UserProfile.fetchViewingHistory({ country, data });
      const payload = response.data;
      return { ...payload, isAppendList };
    } catch (error: any) {
      if (!error.resposne) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

export const uploadViewingHistory = createAsyncThunk(
  `${sliceName}/UPLOAD_VIEWING_HISTORY`,
  async ({ country, data }: { country: TRegionKey; data: any }) => {
    await UserProfile.uploadViewingHistory({ country, data });
  },
);

interface ViewingHistoryProps {
  viewingHistoryList: any;
  viewingHistoryListUnique: [];
  loading: boolean;
  pagination: any;
  stickedDate: string | null;
  category: string | null;
}

const initialState = {
  viewingHistoryList: [],
  viewingHistoryListUnique: [],
  loading: true,
  pagination: {},
  stickedDate: null,
  category: null,
} as ViewingHistoryProps;

const viewHistorySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
    setStickedDate: (state, { payload }) => {
      state.stickedDate = payload;
    },
    setCategory: (state, { payload }) => {
      state.category = payload;
    },
  },
  extraReducers: (builder) => {
    // fetch viewing history
    builder.addCase(fetchViewingHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchViewingHistory.fulfilled, (state, { payload }) => {
      const { data, meta, isAppendList } = payload;
      state.loading = false;
      state.viewingHistoryList =
        state.viewingHistoryList && isAppendList ? state.viewingHistoryList?.concat(data) : data;
      state.pagination = meta.pagination;
    });
    builder.addCase(fetchViewingHistory.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { actions } = viewHistorySlice;

export default viewHistorySlice.reducer;
