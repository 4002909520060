/* eslint-disable react/default-props-match-prop-types */
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import type { ButtonProps } from '@design-system/components/Button/ButtonProps';
import { Button } from '@design-system/components';

const StyledButton = styled(Button)<ButtonProps>`
  ${(props) =>
    props.size === 'small' &&
    css`
      border-radius: 8px;
      ${props.theme.typo.familyGoogle.semiBold}
      font-size: 12px;
      line-height: 20px;
    `};

  ${(props) =>
    props.size === 'large' &&
    css`
      border-radius: 12px;
      ${props.theme.typo.familyGoogle.semiBold}
      font-size: 14px;
      line-height: 22px;
    `};

  ${(props) =>
    props.variant === 'contained' &&
    css`
      box-shadow: 0px 4px 4px rgba(161, 161, 161, 0.25);

      &:hover {
        box-shadow: 0px 8px 8px rgba(161, 161, 161, 0.25);
      }
    `};

  ${(props) =>
    props.variant === 'outline' &&
    css`
      box-shadow: unset;

      &:focus {
        box-shadow: unset;
      }
    `};

  ${(props) => props.customstyles}
`;

const CustomButton: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  size,
  variant,
  href,
  block,
  loading,
  type,
  onClick,
  customstyles,
}) => (
  <StyledButton
    className={className}
    size={size}
    variant={variant}
    disabled={disabled}
    href={href}
    block={block}
    loading={loading}
    type={type}
    onClick={onClick}
    customstyles={customstyles}
  >
    {children}
  </StyledButton>
);

CustomButton.defaultProps = {
  size: 'large',
  variant: 'contained',
  disabled: false,
  block: false,
};

export default CustomButton;
