import {
  NEXT_PUBLIC_STRAPI_URL_SG,
  NEXT_PUBLIC_STRAPI_URL_MY,
  NEXT_PUBLIC_STRAPI_URL_ID,
  NEXT_PUBLIC_STRAPI_URL_TH,
  NEXT_PUBLIC_STRAPI_URL_JP,
  NEXT_PUBLIC_STRAPI_URL_HK,
  NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_WHATSAPP_NUMBER_SG,
  NEXT_PUBLIC_WHATSAPP_NUMBER_MY,
  NEXT_PUBLIC_WHATSAPP_NUMBER_ID,
  NEXT_PUBLIC_WHATSAPP_NUMBER_HK,
  NEXT_PUBLIC_ENV_URL_SG,
  NEXT_PUBLIC_ENV_URL_MY,
  NEXT_PUBLIC_ENV_URL_ID,
  NEXT_PUBLIC_ENV_URL_TH,
  NEXT_PUBLIC_ENV_URL_JP,
  NEXT_PUBLIC_SHORT_URL,
  NEXT_PUBLIC_SHORT_URL_KEY,
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH,
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP,
  NEXT_PUBLIC_WEB_CR,
  NEXT_PUBLIC_PHONE_NUMBER_SG,
  NEXT_PUBLIC_PHONE_NUMBER_ID,
  NEXT_PUBLIC_PHONE_NUMBER_MY,
  NEXT_PUBLIC_PHONE_NUMBER_TH,
  NEXT_PUBLIC_PHONE_NUMBER_JP,
} from '@source/constants/env';

export const isProduction = NEXT_PUBLIC_APP_ENV === 'production';

export const LOCAL_STORAGE_KEYS = {
  SELECTED_LOCATION: 'selectedLocation',
  VIEWED_CARS: 'viewed_cars',
  SEARCH_FILTER_CARS: 'search_filter_cars',
  SUBMIT_SEARCH_FILTER: 'is_submit_search_filter',
};

export type TSort = {
  asc: string;
  desc: string;
};

export type TAlgoliaIndex = {
  staging: { [key: string]: TSort };
  qa: { [key: string]: TSort };
  production: { [key: string]: TSort };
};

export type TRegionIds = {
  sg: string;
  my: string;
  id: string;
  th: string;
  jp: string;
  hk: string;
};

// TH/TW/JP don't have Whatsapp number
export const WHATSAPP_NUMBER = {
  sg: NEXT_PUBLIC_WHATSAPP_NUMBER_SG || '+6591824761',
  id: NEXT_PUBLIC_WHATSAPP_NUMBER_ID || '+6285574678444',
  my: NEXT_PUBLIC_WHATSAPP_NUMBER_MY || '+60162996736',
  th: '',
  jp: '',
  hk: NEXT_PUBLIC_WHATSAPP_NUMBER_HK || '+85230016749',
};

export const PHONE_NUMBER = {
  sg: NEXT_PUBLIC_PHONE_NUMBER_SG || '6714 6652',
  id: NEXT_PUBLIC_PHONE_NUMBER_ID || '021-50996789',
  my: NEXT_PUBLIC_PHONE_NUMBER_MY || '+60162996736',
  th: NEXT_PUBLIC_PHONE_NUMBER_TH || '02-460-9380',
  jp: NEXT_PUBLIC_PHONE_NUMBER_JP || '048-951-4002',
  tw: '',
  hk: '',
};

export const LINE_APP_ADD_FRIEND_ID = {
  sg: '',
  id: '',
  my: '',
  th: NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH || '',
  jp: NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP || '',
  hk: '',
};

interface ENVURL {
  [key: string]: string;
}

// OLD RABBIT URLS
export const ENV_URL: ENVURL = {
  sg: NEXT_PUBLIC_ENV_URL_SG || 'https://carro.sg',
  my: NEXT_PUBLIC_ENV_URL_MY || 'https://mytukar.com',
  id: NEXT_PUBLIC_ENV_URL_ID || 'https://carro.id',
  th: NEXT_PUBLIC_ENV_URL_TH || 'https://th.carro.co',
  jp: NEXT_PUBLIC_ENV_URL_JP || 'https://jp.carro.co',
  hk: '' || 'https://carro.co',
};

export const STRAPI_URL = {
  sg: NEXT_PUBLIC_STRAPI_URL_SG || '',
  my: NEXT_PUBLIC_STRAPI_URL_MY || '',
  id: NEXT_PUBLIC_STRAPI_URL_ID || '',
  th: NEXT_PUBLIC_STRAPI_URL_TH || '',
  jp: NEXT_PUBLIC_STRAPI_URL_JP || '',
  hk: NEXT_PUBLIC_STRAPI_URL_HK || '',
};

export const CRAZY_RABBIT_WEB_URL = {
  sg: NEXT_PUBLIC_WEB_CR || 'https://carro.co',
  id: NEXT_PUBLIC_WEB_CR || 'https://carro.co',
  th: NEXT_PUBLIC_WEB_CR || 'https://carro.co',
  jp: NEXT_PUBLIC_WEB_CR || 'https://carro.co',
  my: NEXT_PUBLIC_WEB_CR || 'https://carro.co',
  hk: NEXT_PUBLIC_WEB_CR || 'https://carro.co',
};
export const IK_URL = 'https://cdn.carro.co/tr:q-60,pr-true/';
export const IK_URL_1000_563 = 'https://cdn.carro.co/tr:w-1000,h-563/';
export const IK_URL_800_600 = 'https://cdn.carro.co/tr:w-800,h-600/';
export const IK_URL_952_535 = 'https://cdn.carro.co/tr:w-952,h-535/';
export const IK_URL_850_634 = 'https://cdn.carro.co/tr:w-850,h-634/';
export const IK_URL_600_600 = 'https://cdn.carro.co/tr:w-600,h-600/';
export const IK_URL_340_240 = 'https://cdn.carro.co/tr:w-340,h-240/';
export const IK_URL_400_300 = 'https://cdn.carro.co/tr:w-400,h-300,f-jpg,pr-true/';
export const IK_URL_1200_630 = 'https://cdn.carro.co/tr:w-1200,h-630,f-jpg,pr-true/';
export const IK_URL_600_400 = 'https://cdn.carro.co/tr:w-600,h-400,fo-auto,f-jpg,pr-true/';
export const IK_URL_JPG_PR = 'https://cdn.carro.co/tr:w-400-jpg,pr-true/';
export const IK_URL_400_225_FO_BOTTOM = 'https://cdn.carro.co/tr:w-400,h-225,fo-bottom,f-jpg,pr-true/';
export const IK_URL_RATIO_16_9_H_1000 = 'https://cdn.carro.co/tr:ar-16-9,h-1000,fo-bottom,f-jpg,pr-true/';
export const IK_URL_RATIO_16_9 = 'https://cdn.carro.co/tr:ar-16-9,fo-bottom,f-jpg,pr-true/';
export const IK_URL_420_265 = 'https://cdn.carro.co/tr:w-420,h-265,f-jpg,pr-true/';

interface FAVICONURL {
  [key: string]: string;
}
export const FAVICON_URL: FAVICONURL = {
  sg: '/assets/shared/icons/favicon_carro.svg',
  th: '/assets/shared/icons/favicon_carro.svg',
  id: '/assets/shared/icons/favicon_carro.svg',
  // my: '/assets/my/icons/favicon_mytukar.svg',
  my: '/assets/shared/icons/favicon_carro.svg',
  jp: '/assets/shared/icons/favicon_carro.svg',
  hk: '/assets/shared/icons/favicon_carro.svg',
};

export const WS_USER_ACCESS_TOKEN_KEY = 'login_access_token';
export const WS_USER_PROFILE_KEY = 'user_profile';
export const WS_USER_CONTACT_ID_KEY = 'user_contact_id';
export const WS_USER_PROFILE_COUNTRY_KEY = 'user_profile_country';
export const WS_SELLER_PORTAL_ACCESS_TOKEN_KEY = 'auth_token';

export const CARRO_MOBILE_APP_KEY = 'carro_mobile_app';

export const COOKIE_KEYS = {
  STORED_REGION: 'STORED_REGION',
  STORED_LANGUAGE_BY_REGION: 'STORED_LANGUAGE_BY_REGION',
  USER_REGION: 'USER_REGION',
  FIRST_TIME_ACCESS: 'FIRST_TIME_ACCESS',
  FORCE_NO_MAINTENANCE: 'force_no_maintenance',
};

export default LOCAL_STORAGE_KEYS;

export const SHORT_URL = NEXT_PUBLIC_SHORT_URL;
export const SHORT_URL_KEY = NEXT_PUBLIC_SHORT_URL_KEY;

export const BUILDER_PUBLIC_API_KEY = '3c13f86aeb3f4354854d7ba1ae94f04d';
