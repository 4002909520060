import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'antd';
import MobileDrawer from '@design-system/components/MobileDrawer/MobileDrawer';
import MobileModal from '@design-system/components/MobileModal/MobileModal';
import FirstTimeAccessContent from '@source/pages/Home/components/FistTimeAccess/FirstTimeAccessContent';
import Logger from '@source/services/Logger';

const { useBreakpoint } = Grid;

interface IContent {
  headerTitle: string;
  content: JSX.Element;
}

interface IContents {
  main: IContent;
}

interface IFirstTimeAccessContainer {
  isOpen: boolean;
  onClose?: any;
}

const FirstTimeVisitModalContainer: React.FC<IFirstTimeAccessContainer> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [visible, setVisible] = useState<boolean>(isOpen);
  const onCloseModal = (): void => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  const onConfirm = async (): Promise<void> => {
    Logger.log('Confirm');
  };

  /**
   *  main content
   */
  const contents: IContents = {
    main: {
      headerTitle: t(`home.firstTimeAccess.title`),
      content: <FirstTimeAccessContent onClose={onCloseModal} />,
    },
  };
  const { headerTitle, content }: { headerTitle: string; content: JSX.Element } = contents.main;

  return (
    <div>
      {screens.md ? (
        <MobileModal
          title={headerTitle}
          // confirmBtnLabel={btnLabel}
          visible={visible}
          // visible={isOpen}
          hasFooter={false}
          hasFixHeight={false}
          hasStickyHeader={false}
          maskClosable
          onClose={onCloseModal}
          width={576}
        >
          {content}
        </MobileModal>
      ) : (
        <MobileDrawer
          title={headerTitle}
          // confirmBtnLabel={btnLabel}
          visible={visible}
          // visible={isOpen}
          customFooter={() => undefined}
          onConfirm={onConfirm}
          onClose={onCloseModal}
        >
          {content}
        </MobileDrawer>
      )}
    </div>
  );
};

export default FirstTimeVisitModalContainer;
