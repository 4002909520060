import FireFilled from '@ant-design/icons/FireFilled';
import {
  ExclusivesFilterProps,
  RangeFilterProps,
  SelectedFilterProps,
} from '@design-system/components/FiltersV1/FilterProps';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import set from 'lodash/set';

dayjs.extend(utc);

export const getFiltersTranslationPath = (key: string) => `home.filters.${key}`;
const getListByName = (fieldNames: any, allFields: any, key: string) =>
  fieldNames.map((fieldName: any) => allFields.find((f: any) => f?.[key] === fieldName));

// Temp set icon here
export const ANTD_ICONS_KEYS = {
  FireFilled: <FireFilled />,
};

// eslint-disable-next-line import/prefer-default-export
const generateYearRange = () => {
  const rangeYears = [];

  for (let i = 2005; i <= new Date().getFullYear(); i += 1) {
    rangeYears.push(i);
  }

  return rangeYears;
};

const priceRange = [
  0, 20000, 40000, 60000, 80000, 100000, 120000, 140000, 160000, 180000, 200000, 300000, 500000, 800000, 2000000,
];
const depreciationRange = [
  0, 2000, 4000, 6000, 8000, 10000, 12000, 14000, 16000, 18000, 20000, 22000, 24000, 26000, 28000, 30000, 32000, 34000,
  36000, 38000, 40000,
];
const regYearRange = [1980, ...generateYearRange()];
const milageRange = [0, 10000, 20000, 30000, 40000, 50000, 70000, 90000, 110000, 120000, 150000, 300000];
const OWNERS = [1, 2, 3, 4, 5];
const TRANSMISSION = [
  { label: getFiltersTranslationPath('options.automatic'), value: 'automatic' },
  { label: getFiltersTranslationPath('options.manual'), value: 'manual' },
];
// const fuelType = ['petrol', 'diesel', 'petrol-electric', 'cng'];

export const CONTENT = {
  useStyleCustomButtonMobile: false,
  showIcon: false,
  filterConfirmText: 'home.filters.content.filterConfirmText',
  clearText: 'home.filters.content.clearText',
  clearAllText: 'home.filters.content.clearAllText',
  filterText: 'home.filters.content.filterText',
  moreFilterText: 'home.filters.content.moreFilterText',
  showActiveBar: false,
  showHitsCars: 'home.filters.content.showHitsCars',
  showHitsCarsText: '',
};

const keywords = ['11.11'];
const installmentMinRange = [0, 1.5, 2, 3, 4, 5, 6, 7];
const installmentMaxRange = [0, 1, 2, 3, 4, 5, 6, 7, 10];

const toCurrency = (input: any) => {
  if (input > 0) {
    if (input / 1000000 >= 1) {
      return `Rp. ${input / 1000000} Juta`;
    }
    return `Rp. ${input / 1000} Ribu`;
  }
  return `Rp. 0`;
};

const toKm = (input: any) => `${input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} km`;

const getMinOptions = (values: any, transformTitle = (value: any) => value) =>
  values.slice(0, values.length - 1).map((value: any) => ({ label: transformTitle(value), value }));
const getMaxOptions = (values: any, transformTitle = (value: any) => value) =>
  values.slice(1, values.length).map((value: any) => ({ label: transformTitle(value), value }));

const getOptions = (values: any, transformTitle = (value: any) => value) =>
  values.map((value: any) => {
    if (value === 'cng') return { label: 'CNG', value };
    return { label: transformTitle(value), value };
  });

/* --------------------- FILTER ---------------------*/
export const PRICE_RANGE_MIN = getMinOptions(priceRange, toCurrency);
export const PRICE_RANGE_MAX = getMaxOptions(priceRange, toCurrency);
export const DEPRECIATION_RANGE_MIN = getMinOptions(depreciationRange, toCurrency);
export const DEPRECIATION_RANGE_MAX = getMaxOptions(depreciationRange, toCurrency);
export const REG_YEAR_RANGE_MIN = getMinOptions(regYearRange);
export const REG_YEAR_RANGE_MAX = getMaxOptions(regYearRange);
export const MILEAGE_RANGE_MIN = getMinOptions(milageRange, toKm);
export const MILEAGE_RANGE_MAX = getMaxOptions(milageRange, toKm);
export const INSTALLMENT_RANGE_MIN = getMinOptions(installmentMinRange, toCurrency);
export const INSTALLMENT_RANGE_MAX = getMaxOptions(installmentMaxRange, toCurrency);

const SEATS: Record<string, any> = {
  sg: getOptions([2, 4, 5, 6, 7, 8]),
  id: getOptions([2, 5, 7]),
  my: getOptions([2, 4, 5, 7, 8]),
};

const getBodyTypesByCountry = (listFields: string[]) => {
  const allBodyTypes = [
    { label: getFiltersTranslationPath('options.hatchback'), value: 'hatchback' },
    { label: getFiltersTranslationPath('options.hybrid'), value: 'hybrid' },
    { label: getFiltersTranslationPath('options.luxurySedan'), value: 'luxury_sedan' },
    { label: getFiltersTranslationPath('options.midSizedSedan'), value: 'mid_sized_sedan' },
    { label: getFiltersTranslationPath('options.mpv'), value: 'mpv' },
    { label: getFiltersTranslationPath('options.pickup'), value: 'pickup' },
    { label: getFiltersTranslationPath('options.sedan'), value: 'sedan' },
    { label: getFiltersTranslationPath('options.sports'), value: 'sports' },
    { label: getFiltersTranslationPath('options.sportsHatchback'), value: 'sports_hatchback' },
    { label: getFiltersTranslationPath('options.stationwagon'), value: 'stationwagon' },
    { label: getFiltersTranslationPath('options.suv'), value: 'suv' },
    { label: getFiltersTranslationPath('options.electric'), value: 'electric' },
    { label: getFiltersTranslationPath('options.van'), value: 'van' },
    { label: getFiltersTranslationPath('options.ecoCar'), value: 'eco_car' },
    { label: getFiltersTranslationPath('options.convertible'), value: 'convertible' },
    { label: getFiltersTranslationPath('options.coupe'), value: 'coupe' },
  ];

  return getListByName(listFields, allBodyTypes, 'value');
};

export const COE_RENEWED = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
export const PLATE_ODD_EVEN = [
  { label: getFiltersTranslationPath('options.plateOdd'), value: 'odd' },
  { label: getFiltersTranslationPath('options.plateEven'), value: 'even' },
];
export const OFFPEAK_CAR = [
  { label: 'Regular', value: 0 },
  { label: 'OPC', value: 1 },
];

const getFuelTypesByCountry = (countryCode: string, listFields: string[]) => {
  const isTWAndJP = ['jp', 'hk'].includes(countryCode);
  const isSGAndID = ['sg', 'id'].includes(countryCode);
  const isMY = countryCode === 'my';

  let petrolElectricPath = 'options.electricFuel';

  if (isSGAndID) {
    petrolElectricPath = 'options.hybrid';
  } else if (isTWAndJP) {
    petrolElectricPath = 'options.petrolElectric';
  }

  const allFuelTypes = [
    {
      label: getFiltersTranslationPath('options.diesel'),
      value: 'diesel',
      name: 'diesel',
    },
    {
      label: getFiltersTranslationPath('options.dieselHijau'),
      value: 'diesel_hijau',
      name: 'diesel-hijau',
    },
    {
      label: getFiltersTranslationPath('options.petrol'),
      value: 'petrol',
      name: 'petrol',
      ...(isTWAndJP && { isMultiSelect: false }),
    },
    {
      label: getFiltersTranslationPath('options.petrolWithNGV'),
      value: 'petrol_with_ngv',
      name: 'petrol-with-ngv',
      ...(isTWAndJP && { isMultiSelect: false }),
    },
    {
      label: getFiltersTranslationPath(isMY ? 'options.hybridFuel' : 'options.petrolWithHybrid'),
      value: 'petrol_with_hybrid',
      name: 'petrol-with-hybrid',
      ...(isTWAndJP && { isMultiSelect: false }),
    },
    {
      label: getFiltersTranslationPath(petrolElectricPath),
      // label: getFiltersTranslationPath(isTWAndJP ? 'options.petrolElectric' : 'options.electricFuel'),
      value: 'petrol-electric',
      name: 'petrol-electric',
      ...(isTWAndJP && { isMultiSelect: false }),
    },
    {
      label: getFiltersTranslationPath('options.electricFuel'),
      value: 'electric',
      name: 'electric',
      ...(isTWAndJP && { isMultiSelect: false }),
    },
    {
      label: getFiltersTranslationPath('options.hybridFuel'),
      value: 'hybrid',
      name: 'hybrid',
    },
    {
      label: getFiltersTranslationPath('options.hybridElectric'),
      value: 'hybrid_electric',
      name: 'hybrid-electric',
    },
    {
      label: getFiltersTranslationPath('options.cngFuel'),
      value: 'cng',
      name: 'cng',
      ...(isTWAndJP && { isMultiSelect: false }),
    },
  ];

  return getListByName(listFields, allFuelTypes, 'name');
};

const getTagsByCountry = (countryCode: string, listFields: string[]) => {
  const isMY = countryCode === 'my';
  const allTags = [
    {
      label: getFiltersTranslationPath('options.isCcpo'),
      value: 'listing.is_ccpo',
      name: 'listing.is_ccpo',
    },
    {
      label: getFiltersTranslationPath('options.isCcpo'),
      value: 'flags.is_ccpo',
      name: 'flags.is_ccpo',
    },
    {
      label: getFiltersTranslationPath('options.isCcpo'),
      value: 'listing.flags.is_ccpo',
      name: 'listing.flags.is_ccpo.th',
    },
    {
      label: getFiltersTranslationPath('options.isCcpoMy'),
      value: 'listing.flags.is_ccpo',
      name: 'listing.flags.is_ccpo',
    },
    {
      label: getFiltersTranslationPath('options.isAgentWarranty'),
      value: 'listing.is_agent_warranty',
      customValue: `listing.agent_warranty.expired_date_timestamp >= ${Math.floor(dayjs.utc().valueOf() / 1000)}`,
      name: 'listing.is_agent_warranty.sg',
    },
    {
      label: getFiltersTranslationPath('options.isAgentWarranty'),
      value: 'listing.is_agent_warranty',
      name: 'listing.is_agent_warranty',
    },
    {
      label: getFiltersTranslationPath('options.isAgentServiced'),
      value: 'listing.is_agent_serviced',
      name: 'listing.is_agent_serviced',
    },
    {
      label: getFiltersTranslationPath('options.is360'),
      value: 'listing.has_360_interior_photos=1 OR listing.has_360_exterior_photos',
      name: 'listing.is_360',
      showInDesktop: !isMY,
    },
    {
      label: getFiltersTranslationPath('options.is360'),
      value: 'has_360_interior_photos=1 OR has_360_exterior_photos',
      name: 'flags.is_360',
      showInDesktop: !isMY,
    },
    {
      label: getFiltersTranslationPath('options.mechanicVideo'),
      value: 'listing.has_mechanic_video_notes',
      name: 'listing.mechanic_video',
    },
    {
      label: getFiltersTranslationPath('options.mechanicVideo'),
      value: 'flags.has_mechanic_video_notes',
      name: 'flags.mechanic_video',
      showInDesktop: true,
    },
    {
      label: getFiltersTranslationPath('options.isGreatBuy'),
      value: 'listing.is_great_buy',
      name: 'listing.is_great_buy',
    },
    {
      label: getFiltersTranslationPath('options.isValueBuy'),
      value: 'listing.is_value_buy',
      name: 'listing.is_value_buy',
    },
    {
      label: getFiltersTranslationPath('options.isPromo'),
      value: 'is_promo',
      name: 'is_promo',
      showInDesktop: !isMY,
    },
    {
      label: getFiltersTranslationPath('options.cpa'),
      value: 'listing.is_cpa',
      name: 'listing.is_cpa',
    },
  ];

  return getListByName(listFields, allTags, 'name');
};

export const URGENT_SALE = [{ label: 'Urgent Sale', value: 1 }];
export const KEYWORDS = getOptions(keywords);
export const CARRO_OWNER = [{ label: 'Carro Selection', value: 1 }];
export const PRICE_OPTIONS = [
  { label: '< 100 Juta', value: 0, range: { min: 0, max: 100000000 } },
  {
    label: '100 - 200 Juta',
    value: 1,
    range: { min: 100000000, max: 200000000 },
  },
  {
    label: '200 - 300 Juta',
    value: 2,
    range: { min: 200000000, max: 300000000 },
  },
  {
    label: '300 - 400 Juta',
    value: 3,
    range: { min: 300000000, max: 400000000 },
  },
  { label: '> 400 Juta', value: 4, range: { min: 400000000, max: null } },
];

export const BRANDS_OPTIONS: Record<string, any> = {
  id: ['Toyota', 'Honda', 'Mitsubishi'],
  my: ['Proton', 'Perodua', 'Honda'],
};

const getCategoriesByCountry = (listFields: string[]) => {
  const allCategories = [
    { label: getFiltersTranslationPath('options.lowMileage'), value: 'low_mileage' },
    { label: getFiltersTranslationPath('options.under9kDepre'), value: 'under_9k_depre' },
    { label: getFiltersTranslationPath('options.deluxeAndClassy'), value: 'deluxe_and_classy' },
    { label: getFiltersTranslationPath('options.budgetFriendly'), value: 'budget_friendly' },
    { label: getFiltersTranslationPath('options.renewedCoe'), value: 'renewed_coe' },
    { label: getFiltersTranslationPath('options.funToDrive'), value: 'fun_to_drive' },
    { label: getFiltersTranslationPath('options.7Seaters'), value: '7_seaters' },
    { label: getFiltersTranslationPath('options.1OwnerOnly'), value: '1_owner_only' },
    { label: getFiltersTranslationPath('options.0OwnerOnly'), value: '0_owner_only' },
    { label: getFiltersTranslationPath('options.familyFriendly'), value: 'family_friendly' },
    { label: getFiltersTranslationPath('options.highHorsepower'), value: 'high_horsepower' },
    { label: getFiltersTranslationPath('options.fitsMore'), value: 'fits_more' },
    { label: getFiltersTranslationPath('options.smallAndNimble'), value: 'small_and_nimble' },
    { label: getFiltersTranslationPath('options.luxuryVehicle'), value: 'luxury_vehicle' },
    { label: getFiltersTranslationPath('options.familyCar'), value: 'family_car' },
    { label: getFiltersTranslationPath('options.highPerformance'), value: 'high_performance' },
    { label: getFiltersTranslationPath('options.continentalCar'), value: 'continental_car' },

    // For mytukar je campaign
    // Use default is EN first. Will use translation files when the translations are available
    { label: 'Tukar-Je Eco Friendly', value: 'tukar_je_eco_friendly' },
    { label: 'Tukar-Je Fuel Efficiency', value: 'tukar_je_fuel_efficiency' },
    { label: 'Tukar-Je Luxury', value: 'tukar_je_performance' },
    { label: 'Tukar-Je Family', value: 'tukar_je_family' },
  ];

  return getListByName(listFields, allCategories, 'value');
};

export const LOCATIONS = [
  { label: 'Harapan Indah', value: 'carro-automall-harapan-indah' },
  { label: 'BSD', value: 'carro-automall-point-(bsd)' },
  { label: 'Karawaci', value: 'carro-automall-point-(karawaci)' },
  { label: 'Semarang', value: 'carro-semarang' },
  { label: 'Pondok Indah', value: 'carro-pondok-indah' },
];

const getAvailabilityByCountry = (countryCode: string, listFields: string[]) => {
  const isTH = countryCode === 'th';
  const allAvailability = [
    {
      label: getFiltersTranslationPath('options.available'),
      value: isTH ? 'listing.flags.is_available' : 'listing.is_available',
    },
    {
      label: getFiltersTranslationPath('options.comingSoon'),
      value: isTH ? 'listing.flags.is_coming_soon' : 'listing.is_coming_soon',
    },
    {
      label: getFiltersTranslationPath('options.pendingSale'),
      value: isTH ? 'listing.flags.is_pending_sale' : 'listing.is_pending_sale',
    },
    { label: getFiltersTranslationPath('options.available'), value: 'listing.flags.is_available' },
    { label: getFiltersTranslationPath('options.pendingSale'), value: 'listing.flags.is_pending_sale' },
  ];

  return getListByName(listFields, allAvailability, 'value');
};

export const getDataConfigsCountry = ({
  countryCode,
  dataName,
  listFields = [],
}: {
  countryCode: string;
  dataName: string;
  listFields?: string[];
}) => {
  let dataSrc;

  switch (dataName) {
    case 'BODY_TYPE':
      dataSrc = getBodyTypesByCountry(listFields);
      break;
    case 'CATEGORIES':
      dataSrc = getCategoriesByCountry(listFields);
      break;
    case 'FILTER_AVAILABLE':
      dataSrc = getAvailabilityByCountry(countryCode, listFields);
      break;
    case 'FUEL_TYPE':
      dataSrc = getFuelTypesByCountry(countryCode, listFields);
      break;
    case 'PLATE_ODD_EVEN':
      dataSrc = PLATE_ODD_EVEN;
      break;
    case 'SEATS_OPTIONS':
      dataSrc = SEATS[countryCode];
      // useGetOptions = true;
      break;
    case 'TAGS':
      dataSrc = getTagsByCountry(countryCode, listFields);
      break;
    case 'BRANDS_OPTIONS':
      dataSrc = BRANDS_OPTIONS[countryCode];
      break;
    case 'TRANSMISSION':
      dataSrc = TRANSMISSION;
      break;
    case 'OWNERS':
      dataSrc = OWNERS;
      break;
    case 'CONTENT':
      dataSrc = CONTENT;
      break;
    default:
      break;
  }

  return dataSrc;
};

export const RANGE_FILTER_CONFIG: RangeFilterProps = {
  default: {
    mileage: { filterName: 'listing.mileage', urlName: 'mileage' },
    installment: { filterName: 'listing.installment', urlName: 'instalment' },
    price_range: { filterName: 'listing.asking_price', urlName: 'priceRange' },
    year: { filterName: 'inventory.first_registration_year', urlName: 'year' },
    coe_left: { filterName: 'listing.months_left', urlName: 'coeLeft' },
    depreciation: { filterName: 'listing.current_depreciation', urlName: 'depreciation' },
    manufacture_year: { filterName: 'inventory.manufacture_year', urlName: 'year' },
  },
  hk: {
    mileage: { filterName: 'listing.mileage', urlName: 'mileage' },
    installment: { filterName: 'listing.installment', urlName: 'instalment' },
    price_range: { filterName: 'listing.asking_price', urlName: 'priceRange' },
    year: { filterName: 'inventory.first_registration_year', urlName: 'year' },
    coe_left: { filterName: 'listing.months_left', urlName: 'coeLeft' },
    depreciation: { filterName: 'listing.current_depreciation', urlName: 'depreciation' },
    manufacture_year: { filterName: 'inventory.manufacture_year', urlName: 'manufactureYear' },
  },
};

export const NUMBER_FILTER_CONFIG: SelectedFilterProps = {
  owner: { filterName: 'inventory.number_of_owners', urlName: 'pastOwners' },
};

export const BUTTON_FILTER_CONFIG: SelectedFilterProps = {
  availability_button: { filterName: 'listing', urlName: 'availability' },
  category: { filterName: 'categories', urlName: 'categories' },
  fuel_button: { filterName: 'inventory.fuel_type', urlName: 'fuelType' },
  body_button: { filterName: 'inventory.car_type', urlName: 'bodyType' },
  seats: { filterName: 'inventory.seats', urlName: 'seats' },
  colours: { filterName: 'inventory.color', urlName: 'colors' },
  tags: { filterName: 'listing', urlName: 'exclusives' },
  transmission: { filterName: 'inventory.transmission', urlName: 'transmission' },
  make: { filterName: 'inventory.make', urlName: 'brands' },
  make_model: { filterName: 'inventory.make_model', urlName: 'models' },
  plate_odd_even: { filterName: 'inventory.plate_number_odd_even', urlName: 'plate' },
  wheel_drives: { filterName: 'inventory.wheel_drives', urlName: 'wheelDrives' },
  promotions: { filterName: 'promotion_name', urlName: 'promotions' },
};

export const BOOLEAN_FILTER_CONFIG: SelectedFilterProps = {
  '360view': { filterName: 'listing.is360view', urlName: '360view' },
  promotions_button: { filterName: 'is_promo', urlName: 'isPromotion' },
};

export const BUTTON_FILTER_CONFIG_HK: SelectedFilterProps = {
  ...BUTTON_FILTER_CONFIG,
  colours: { filterName: 'inventory.base_color_key', urlName: 'colors' },
};

// export const CHECK_BOX_FILTER_CONFIG: SelectedFilterProps = {
//   promotions: { filterName: 'promo_label', urlName: 'promotions' },
// };

export const mapFilterNameFromQS = (countryCode: string) => {
  const rangeFilterConfig = countryCode === 'hk' ? RANGE_FILTER_CONFIG.hk : RANGE_FILTER_CONFIG.default;
  const buttonFilterConfig = countryCode === 'hk' ? BUTTON_FILTER_CONFIG_HK : BUTTON_FILTER_CONFIG;
  const configs: SelectedFilterProps = {
    ...rangeFilterConfig,
    ...NUMBER_FILTER_CONFIG,
    ...buttonFilterConfig,
    ...BOOLEAN_FILTER_CONFIG,
    // ...CHECK_BOX_FILTER_CONFIG,
  };
  const result = {};
  Object.keys(configs).forEach((filterName) => {
    if (filterName === 'manufacture_year') {
      if (countryCode.toLowerCase() !== 'sg') set(result, configs[filterName].urlName, filterName);
    } else {
      set(result, configs[filterName].urlName, filterName);
    }
  });
  return result;
};

export const CARRO_EXCLUSIVES_FILTER_URL_CONFIG: ExclusivesFilterProps = {
  sg: [
    { name: 'listing.is_ccpo', urlValue: 'listing.is_ccpo', filterValue: 'listing.is_ccpo' },
    {
      name: 'listing.is_agent_warranty.sg',
      urlValue: 'listing.is_agent_warranty',
      filterValue: 'listing.is_agent_warranty',
    },
    {
      name: 'listing.is_360',
      urlValue: 'is360View',
      filterValue: 'listing.has_360_interior_photos=1 OR listing.has_360_exterior_photos',
    },
    {
      name: 'listing.mechanic_video',
      urlValue: 'listing.has_mechanic_video_notes',
      filterValue: 'listing.has_mechanic_video_notes',
    },
    { name: 'listing.is_cpa', urlValue: 'listing.is_cpa', filterValue: 'listing.is_cpa' },
  ],
  th: [
    {
      name: 'listing.is_360',
      urlValue: 'is360View',
      filterValue: 'listing.has_360_interior_photos=1 OR listing.has_360_exterior_photos',
    },
    { name: 'listing.flags.is_ccpo.th', urlValue: 'listing.is_ccpo', filterValue: 'listing.flags.is_ccpo' },
    {
      name: 'listing.mechanic_video',
      urlValue: 'listing.has_mechanic_video_notes',
      filterValue: 'listing.has_mechanic_video_notes',
    },
  ],
  id: [
    { name: 'listing.is_ccpo', urlValue: 'listing.is_ccpo', filterValue: 'listing.is_ccpo' },
    { name: 'listing.is_great_buy', urlValue: 'listing.is_great_buy', filterValue: 'listing.is_great_buy' },
    { name: 'listing.is_value_buy', urlValue: 'listing.is_value_buy', filterValue: 'listing.is_value_buy' },
  ],
  my: [
    { name: 'listing.flags.is_ccpo', urlValue: 'listing.is_ccpo', filterValue: 'listing.flags.is_ccpo' },
    { name: 'is_promo', urlValue: 'is_promo', filterValue: 'is_promo' },
    {
      name: 'listing.is_360',
      urlValue: 'is360View',
      filterValue: 'listing.has_360_interior_photos=1 OR listing.has_360_exterior_photos',
    },
  ],
};

export const AVAILABILITY_FILTER_URL_CONFIG: ExclusivesFilterProps = {
  sg: [
    { name: 'listing.is_available', urlValue: 'listing.is_available', filterValue: 'listing.is_available' },
    {
      name: 'listing.is_coming_soon',
      urlValue: 'listing.is_coming_soon',
      filterValue: 'listing.is_coming_soon',
    },
    {
      name: 'listing.is_pending_sale',
      urlValue: 'listing.is_pending_sale',
      filterValue: 'listing.is_pending_sale',
    },
  ],
  th: [
    {
      name: 'listing.flags.is_available',
      urlValue: 'listing.is_available',
      filterValue: 'listing.flags.is_available',
    },
    {
      name: 'listing.flags.is_coming_soon',
      urlValue: 'listing.is_coming_soon',
      filterValue: 'listing.flags.is_coming_soon',
    },
    {
      name: 'listing.flags.is_pending_sale',
      urlValue: 'listing.is_pending_sale',
      filterValue: 'listing.flags.is_pending_sale',
    },
  ],
  id: [
    { name: 'listing.is_available', urlValue: 'listing.is_available', filterValue: 'listing.is_available' },
    {
      name: 'listing.is_coming_soon',
      urlValue: 'listing.is_coming_soon',
      filterValue: 'listing.is_coming_soon',
    },
    {
      name: 'listing.is_pending_sale',
      urlValue: 'listing.is_pending_sale',
      filterValue: 'listing.is_pending_sale',
    },
  ],
  my: [
    { name: 'listing.flags.is_available', urlValue: 'listing.is_available', filterValue: 'listing.flags.is_available' },
    {
      name: 'listing.flags.is_pending_sale',
      urlValue: 'listing.is_pending_sale',
      filterValue: 'listing.flags.is_pending_sale',
    },
  ],
  hk: [
    { name: 'listing.is_available', urlValue: 'listing.is_available', filterValue: 'listing.is_available' },
    {
      name: 'listing.is_coming_soon',
      urlValue: 'listing.is_coming_soon',
      filterValue: 'listing.is_coming_soon',
    },
    {
      name: 'listing.is_pending_sale',
      urlValue: 'listing.is_pending_sale',
      filterValue: 'listing.is_pending_sale',
    },
  ],
};
