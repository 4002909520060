import { css } from 'styled-components';

export const POPPINS_REGULAR = 'Poppins Regular';
export const POPPINS_MEDIUM = 'Poppins Medium';
export const POPPINS_SEMIBOLD = 'Poppins SemiBold';
export const POPPINS_BOLD = 'Poppins Bold';

export const NOTO_SANS_REGULAR = 'Noto Sans Regular';
export const NOTO_SANS_MEDIUM = 'Noto Sans Medium';
export const NOTO_SANS_SEMIBOLD = 'Noto Sans SemiBold';
export const NOTO_SANS_BOLD = 'Noto Sans Bold';

export const THEME = {
  color: {
    background: '#FFFFFF',
    onPrimary: '#FFFFFF',
    onSurface: '#363636',
    onError: '#FFFFFF',
    quartzColor: '#4E4744',
    surface: '#F7F7F7',
    surfaceDark: '#F8F7F7',
    surfaceLight: '#E7E7E7',
    surfaceMediumEmphasis: 'rgba(247, 247, 247, 0.5);',
    onBackgroundHighEmphasis: '#363636',
    onBackgroundMediumEmphasis: '#A1A1A1',
    onBackgroundLowEmphasis: '#D4D4D4',
    primaryBase: '#FF4C00',
    primaryHover: '#FFA580',
    primaryPressed: '#DB4100',
    primarySubtle: '#FFF2EB',
    linkHighEmphasis: '#0075FF',
    linkLowEmphasis: '#E5F1FF',
    errorHighEmphasis: '#DE1C22',
    errorLowEmphasis: '#FCE9E9',
    successHighEmphasis: '#59C825',
    successLowEmphasis: '#EFFBE9',
    rateHighEmphasis: '#FFCC15',
    rateLowEmphasis: '#FFF9E5',
    pendingHighEmphasis: '#FF9900',
    pendingLowEmphasis: '#FFF5E5',
    boxShadow: {
      primaryHover: '0px 8px 8px rgba(161, 161, 161, 0.25)',
      primaryFocus: '0px 4px 4px rgba(161, 161, 161, 0.25)',
      defaultBoxshadow: '0px 4px 10px rgba(33,33,33,0.1)',
      socialMedia: '2px 2px #0E9641',
      cardRaiseMiddleShadow: '0px 4px 15px rgba(33, 33, 33, 0.15)',
    },
    border: '#EDEDED',
    borderShadow: 'rgba(33, 33, 33, 0.15)',
    socialMediaBackground: '#26D367',
    lightOrange: '#FF5722',
    darkBackground: '#000000',
    carDetailTopNav: 'rgba(255, 255, 255, 0.8)',
    sliderCTABackground: 'rgba(255, 255, 255, 0.6)',
    sliderCTABorder: 'rgba(255, 255, 255, 0.5)',
    sliderCTAMobileBackround: 'rgba(0, 0, 0, 0.6)',
    sliderCTAMobileBorder: 'rgba(0, 0, 0, 0.5)',
    lightMode: 'rgba(255, 242, 235, 1)',
    webkitScrollBar: '#e3e3e8',
    webkitScrollBarThumb: '#8f90a3',
    pureRed: '#ff0000',
    fbColor: '#415994',
    darkShapeBlue: '#021039',
    gray: '#878787',
    darkGray: '#67696f',
    disabled: '#e7e7e7',
    lightDark: '#212121',
    lightGray: '#6e6e6e',
    lightGreen: '#00b900',
    lightBlue: '#03B3AE',
    detailsBackground: 'rgba(33, 33, 33, 0.02)',
    pointSeparator: '#3B3B3B',
    lightPink: 'rgba(255, 165, 128, 1)',
    lightGrey: '#767676',
    tealHighEmphasis: '#008C95',
  },
  typo: {
    family: {
      regular: POPPINS_REGULAR,
      medium: POPPINS_MEDIUM,
      semiBold: POPPINS_SEMIBOLD,
      bold: POPPINS_BOLD,
    },
    familyGoogle: {
      regular: css`
        font-family: 'Poppins', serif;
        font-weight: 400;
      `,
      medium: css`
        font-family: 'Poppins', serif;
        font-weight: 500 !important;
      `,
      semiBold: css`
        font-family: 'Poppins', serif;
        font-weight: 600 !important;
      `,
      bold: css`
        font-family: 'Poppins', serif;
        font-weight: 700 !important;
      `,
    },
    style: {
      xlHeader: css`
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
      `,
      lHeader: css`
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      `,
      mHeader: css`
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      `,
      sHeader: css`
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      `,
      header: css`
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      `,
      body: css`
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      `,
      mainButtonOrLink: css`
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      `,
      smallButtonOrLink: css`
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      `,
      captionBold: css`
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
      `,
      captionRegular: css`
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      `,
      label: css`
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      `,
    },
  },
  metrics: {
    breakpoints: {
      xs: '375px',
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1280px',
      xxl: '1920px',
    },
    mediaQueries: {
      xs: `(max-width: 375px)`,
      sm: `(min-width: 376px) and (max-width: 576px)`,
      md: `(min-width: 577px) and (max-width: 768px)`,
      lg: `(min-width: 769px) and (max-width: 992px)`,
      xl: `(min-width: 993px) and (max-width: 1280px)`,
      xxl: `(min-width: 1281px)`,
    },
    containers: {
      padding: {
        xs: '16px',
        sm: '16px',
        md: '16px',
        lg: '48px',
        xl: '0',
      },
    },
  },
};

export const THEME_HK = {
  color: {
    background: '#FFFFFF',
    onPrimary: '#FFFFFF',
    onSurface: '#363636',
    onError: '#FFFFFF',
    quartzColor: '#4E4744',
    surface: '#F7F7F7',
    surfaceDark: '#F8F7F7',
    surfaceLight: '#E7E7E7',
    surfaceMediumEmphasis: 'rgba(247, 247, 247, 0.5);',
    onBackgroundHighEmphasis: '#363636',
    onBackgroundMediumEmphasis: '#A1A1A1',
    onBackgroundLowEmphasis: '#D4D4D4',
    primaryBase: '#FF4C00',
    primaryHover: '#FFA580',
    primaryPressed: '#DB4100',
    primarySubtle: '#FFF2EB',
    linkHighEmphasis: '#0075FF',
    linkLowEmphasis: '#E5F1FF',
    errorHighEmphasis: '#DE1C22',
    errorLowEmphasis: '#FCE9E9',
    successHighEmphasis: '#59C825',
    successLowEmphasis: '#EFFBE9',
    rateHighEmphasis: '#FFCC15',
    rateLowEmphasis: '#FFF9E5',
    pendingHighEmphasis: '#FF9900',
    pendingLowEmphasis: '#FFF5E5',
    boxShadow: {
      primaryHover: '0px 8px 8px rgba(161, 161, 161, 0.25)',
      primaryFocus: '0px 4px 4px rgba(161, 161, 161, 0.25)',
      defaultBoxshadow: '0px 4px 10px rgba(33,33,33,0.1)',
      socialMedia: '2px 2px #0E9641',
      cardRaiseMiddleShadow: '0px 4px 15px rgba(33, 33, 33, 0.15)',
    },
    border: '#EDEDED',
    borderShadow: 'rgba(33, 33, 33, 0.15)',
    socialMediaBackground: '#26D367',
    lightOrange: '#FF5722',
    darkBackground: '#000000',
    carDetailTopNav: 'rgba(255, 255, 255, 0.8)',
    sliderCTABackground: 'rgba(255, 255, 255, 0.6)',
    sliderCTABorder: 'rgba(255, 255, 255, 0.5)',
    sliderCTAMobileBackround: 'rgba(0, 0, 0, 0.6)',
    sliderCTAMobileBorder: 'rgba(0, 0, 0, 0.5)',
    lightMode: 'rgba(255, 242, 235, 1)',
    webkitScrollBar: '#e3e3e8',
    webkitScrollBarThumb: '#8f90a3',
    pureRed: '#ff0000',
    fbColor: '#415994',
    darkShapeBlue: '#021039',
    gray: '#878787',
    darkGray: '#67696f',
    disabled: '#e7e7e7',
    lightDark: '#212121',
    lightGray: '#6e6e6e',
    lightGreen: '#00b900',
    lightBlue: '#03B3AE',
    detailsBackground: 'rgba(33, 33, 33, 0.02)',
    pointSeparator: '#3B3B3B',
    lightPink: 'rgba(255, 165, 128, 1)',
    lightGrey: '#767676',
  },
  typo: {
    family: {
      regular: NOTO_SANS_REGULAR,
      medium: NOTO_SANS_MEDIUM,
      semiBold: NOTO_SANS_SEMIBOLD,
      bold: NOTO_SANS_BOLD,
    },
    familyGoogle: {
      regular: css`
        font-family: 'Noto Sans SC', sans-serif;
        font-weight: 400;
      `,
      medium: css`
        font-family: 'Noto Sans SC', sans-serif;
        font-weight: 500 !important;
      `,
      semiBold: css`
        font-family: 'Noto Sans SC', sans-serif;
        font-weight: 600 !important;
      `,
      bold: css`
        font-family: 'Noto Sans SC', sans-serif;
        font-weight: 700 !important;
      `,
    },
    style: {
      xlHeader: css`
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
      `,
      lHeader: css`
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      `,
      mHeader: css`
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      `,
      sHeader: css`
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      `,
      header: css`
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      `,
      body: css`
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      `,
      mainButtonOrLink: css`
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      `,
      smallButtonOrLink: css`
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
      `,
      captionBold: css`
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
      `,
      captionRegular: css`
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      `,
      label: css`
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      `,
    },
  },
  metrics: {
    breakpoints: {
      xs: '375px',
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1280px',
      xxl: '1920px',
    },
    mediaQueries: {
      xs: `(max-width: 375px)`,
      sm: `(min-width: 376px) and (max-width: 576px)`,
      md: `(min-width: 577px) and (max-width: 768px)`,
      lg: `(min-width: 769px) and (max-width: 992px)`,
      xl: `(min-width: 993px) and (max-width: 1280px)`,
      xxl: `(min-width: 1281px)`,
    },
    containers: {
      padding: {
        xs: '16px',
        sm: '16px',
        md: '16px',
        lg: '48px',
        xl: '0',
      },
    },
  },
};

export default THEME;
