/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import Image from 'next/image';
import showAllIcon from '../assets/overlap-image-icon.svg';

interface IShowAll {
  onClick: any;
}
export const ShowAllButton: FC<IShowAll> = ({ onClick }) => (
  <div className="action-btn show-all-images-btn" onClick={onClick}>
    <Image src={showAllIcon} alt="Show All Images" />
  </div>
);
