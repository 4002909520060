/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
// import dynamic, { LoaderComponent } from 'next/dynamic';
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import styled, { keyframes } from 'styled-components';

// Might need to choose other lib which supports ES
// https://github.com/video-react/video-react/pull/426
// Im considering 'react-player'
// import { BigPlayButtonProps, PlayerProps, ShortcutProps } from 'video-react';
import {
  Player,
  BigPlayButton,
  // ControlBar,
  Shortcut,
} from 'video-react';

import 'video-react/dist/video-react.css'; // import css
import { IDetailsVideoProps } from './video.interface';

// const Player = dynamic(() => import('video-react').then((m) => m.Player) as LoaderComponent<PlayerProps>);
// const BigPlayButton = dynamic(
//   () => import('video-react').then((m) => m.BigPlayButton) as LoaderComponent<BigPlayButtonProps>,
// );
// const Shortcut = dynamic(() => import('video-react').then((m) => m.Shortcut) as LoaderComponent<ShortcutProps>);

const videoPlayButtonWidthHeight = 96;
const videoPlayButtonWidthHeightMobile = 36;
const videoPlayButtonHoverBorderWidth = `${videoPlayButtonWidthHeight + 24}`;

const loadingSpinnerWidthHeight = 92;
const loadingSpinnerWidthHeightMobile = 30;

const slowlyAppear = keyframes`
  from {
      opacity: 0;
  }

  to {
      opacity:  1;
  }
`;

const videoReactSpinnerSpin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const videoReactSpinnerFade = keyframes`
  0% {
    border-top-color: #ff4c00;
  }
  20% {
    border-top-color: #FF4C00;
  }
  35% {
    border-top-color: #ff4c00;
  }
  60% {
    border-top-color: #FF4C00;
  }
  100% {
    border-top-color: #FF4C00;
  }
`;

const StyledPlayer = styled(Player)`
  ${(props) => props.theme.typo.familyGoogle.regular};

  &.video-react {
    .video-react-loading-spinner {
      border-color: transparent;
      width: ${loadingSpinnerWidthHeight}px;
      height: ${loadingSpinnerWidthHeight}px;
      border-radius: 100%;
      margin: 0;
      transform: translate(-50%, -50%);

      &:before,
      &:after {
        border-top-color: ${(props) => props.theme.color.primaryBase};
        border-right-color: ${(props) => props.theme.color.primaryBase};
        border-bottom-color: ${(props) => props.theme.color.primaryBase};

        animation: ${videoReactSpinnerSpin} 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
          ${videoReactSpinnerFade} 1.1s linear infinite;
      }
    }

    .video-react-big-play-button-center {
      width: ${videoPlayButtonWidthHeight}px;
      height: ${videoPlayButtonWidthHeight}px;
      background-color: ${(props) => props.theme.color.primaryBase} !important;
      border-radius: 50%;
      border: none !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      transform: translate(-50%, -50%) !important;
      transition: all 0.4s ease;

      &:before {
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        font-size: 50px;
        color: #fff;
      }

      &:hover {
        &:after {
          content: '';
          width: ${videoPlayButtonHoverBorderWidth}px;
          height: ${videoPlayButtonHoverBorderWidth}px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 76, 0, 0.5) !important;
          left: calc((${videoPlayButtonWidthHeight}px - ${videoPlayButtonHoverBorderWidth}px) / 2);
          border-radius: 50%;
          z-index: -1;
          animation: ${slowlyAppear} 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }
    .video-react-play-progress:after {
      display: none;
    }
  }

  @media screen and (max-width: 575px) {
    &.video-react {
      .video-react-loading-spinner {
        width: ${loadingSpinnerWidthHeightMobile}px;
        height: ${loadingSpinnerWidthHeightMobile}px;
      }

      .video-react-big-play-button-center {
        width: ${videoPlayButtonWidthHeightMobile}px;
        height: ${videoPlayButtonWidthHeightMobile}px;

        &:before {
          font-size: 23px;
        }
      }
    }
  }
`;

const Video = (
  { src, type = 'video/mp4', onPlayerClick, isOnlyPlayOnFullScreen = false, getCurrentState }: IDetailsVideoProps,
  ref: any,
) => {
  const playerRef: any = useRef(null);
  const isPlayerClicked = useRef(false);
  const [isIOS, setIOS] = useState(false);

  useImperativeHandle(ref, () => ({
    playerInfo: playerRef.current,
    pause: () => {
      playerRef.current.pause();
    },
    play: () => {
      playerRef.current.play();
    },
  }));

  useEffect(() => {
    if (navigator) {
      setIOS(/(iPad|iPhone|iPod)/g.test(navigator.userAgent));
    }

    if (playerRef.current) {
      playerRef.current.subscribeToStateChange((state: any) => {
        if (getCurrentState && typeof getCurrentState === 'function' && state) {
          getCurrentState(state);
        }
        // if (onPlay && state.readyState === 4) {
        //   onPlay((state && state.hasStarted) ? true : !state.paused);
        // }
      });
    }
  }, []);

  const handlePlayerClick = () => {
    if (!isPlayerClicked.current) {
      if (onPlayerClick) {
        onPlayerClick();
      }
      isPlayerClicked.current = true;
    }

    if (isIOS) {
      // for iOS or Safari, native player will take over
      return;
    }

    if (isOnlyPlayOnFullScreen) {
      // playOnFullScreen();
    }
  };
  return (
    <div onClick={handlePlayerClick} style={{ position: 'relative' }}>
      <StyledPlayer
        ref={(player: any) => {
          playerRef.current = player;
        }}
        // poster={poster}
        autoPlay={false}
        preload="auto"
      >
        {isIOS && <Shortcut dblclickable={false} />}
        <source src={`${src}#t=0.1`} type={type} />
        <BigPlayButton position="center" />
      </StyledPlayer>
    </div>
  );
};

export default forwardRef(Video);
