/* eslint-disable import/prefer-default-export */
// import isEmpty from 'lodash/isEmpty';
import { getStaticCDN } from '@design-system/utils/utils';

export interface ILoginTypeOption {
  title: string;
  value: string;
  imgIcon: string;
  desc: string;
}

export const LOGIN_TYPES_OPTIONS: ILoginTypeOption[] = [
  {
    title: 'WhatsApp',
    value: 'whatsapp',
    imgIcon: getStaticCDN('/assets/shared/user-profile/whatsapp-icon-v2.svg'),
    desc: 'shared.login.whatsAppDesc',
  },
  {
    title: 'SMS',
    value: 'sms',
    imgIcon: getStaticCDN('/assets/shared/user-profile/sms-icon-v2.svg'),
    desc: 'shared.login.smsDesc',
  },

  // Enable LINE when its supported
  // {
  //   title: 'Line',
  //   value: 'line',
  //   imgIcon: getStaticCDN('/assets/shared/user-profile/line-icon.png'),
  //   desc: 'shared.login.lineDesc',
  // },
];

export const getLoginTypesOptionsByCountry = (country: string) => {
  const LOGIN_TYPES_CONFIG: Record<string, string[]> = {
    sg: ['whatsapp', 'sms'],
    id: ['whatsapp', 'sms'],
    th: ['sms'],
    my: ['whatsapp', 'sms'],
    jp: ['sms'],
    hk: ['whatsapp', 'sms'],
  };

  const filteredCards = LOGIN_TYPES_CONFIG?.[country]?.map((typeValue: string) =>
    LOGIN_TYPES_OPTIONS?.find((item) => item?.value === typeValue),
  );

  return filteredCards;
};

export const LOGIN_TYPE_DEFAULT = {
  sg: 'whatsapp',
  id: 'whatsapp',
  my: 'whatsapp',
  th: 'sms', // TH & TW will default to Line when Line is integrated
  jp: 'sms',
  hk: 'whatsapp',
};

/** Form fields configuration */

export const LOGIN_OTP_MAX_ATTEMPTS = 5;
