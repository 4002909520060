/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { Popover } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { Typography } from '@design-system/components';
import { useTranslation } from 'react-i18next';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { ILanguage, TLanguageKey } from '@source/interface';
import { CaretDownOutlined } from '@ant-design/icons';
import { SUPPORTED_LANGUAGES_MAP_BY_COUNTRY } from '@source/constants/config';
import useWindowResize from '@source/hooks/useWindowResize';

interface LanguageContainerProps {
  isFromListingDetailPage?: boolean;
  isOpenSubMenu?: boolean;
  ref?: any;
  onClick: any;
  isFromMobile?: any;
}

const StyledLanguageContainer = styled.div<LanguageContainerProps>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  .desk-nav-drop-down-icon {
    transform: ${(props) => (props.isOpenSubMenu ? 'rotate(180deg)' : 'unset')};
  }

  .multilang-select-buton {
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: ${(props) =>
      props.isFromListingDetailPage ? props.theme.color.background : props.theme.color.onBackgroundHighEmphasis};
    ${(props) => props.theme.typo.familyGoogle.semiBold};

    .multilang-select-buton-text {
      color: ${(props) =>
        props.isFromListingDetailPage ? props.theme.color.background : props.theme.color.onBackgroundHighEmphasis};
    }

    .desk-nav-drop-down-icon {
      margin-top: -1px;
      padding: 0 10px;
      transition: transform 0.3s ease;
      ${(props) => props.theme.typo.style.captionRegular};
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }
  }

  .multilang-select-buton-text,
  .multilang-select-buton {
    color: ${(props) => {
      if (
        (props.isOpenSubMenu && props.isFromListingDetailPage) ||
        (props.isFromMobile && props.isFromListingDetailPage)
      ) {
        return `${props.theme.color.background} !important`;
      }
      if (props.isOpenSubMenu) {
        return `${props.theme.color.primaryBase} !important`;
      }
      return props.theme.color.onBackgroundHighEmphasis;
    }};
  }

  .desk-nav-sub-menu {
    z-index: 1000;
    background-color: ${(props) => props.theme.color.background};
    display: ${(props) => (props.isOpenSubMenu ? 'block' : 'none')};
    position: absolute;
    top: 40px;
    right: 0;
    min-width: 147px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.color.background};
    box-shadow: ${(props) => props.theme.color.boxShadow.primaryFocus};
  }

  &::after {
    content: '';
    position: absolute;
    width: 110%;
    height: 50px;
    left: 0;
    bottom: -30px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0.5px;
    height: 40%;
    right: 0;
    background-color: ${(props) => (props.isFromListingDetailPage ? props.theme.color.background : 'unset')};
  }

  @media only screen and (min-width: 1280px) {
    border-left: solid 0.5px ${(props) => props.theme.color.onBackgroundLowEmphasis};
    padding-left: 8px;
  }
`;

const StyledFloatSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgb(33 33 33 / 50%) 0px 4px 25px;

  .multi-language-item {
    background: ${(props) => props.theme.color.background};
    ${(props) => props.theme.typo.familyGoogle.regular};
    ${(props) => props.theme.typo.style.body};
    padding: 8px 13px;
    width: 100%;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.color.surfaceLight};
    }

    &.activeLang {
      background: ${(props) => props.theme.color.lightMode};
    }
  }
`;

const OverridePopoverStyle = createGlobalStyle`
.lang-popover {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      .ant-popover-inner-content {
        padding: 0;
        border-radius: 12px;
      }
    }
  }
`;

interface MultiLanguageProps {
  isFromListingDetailPage?: boolean;
}

const MultiLanguage: FC<MultiLanguageProps> = ({ isFromListingDetailPage }) => {
  const ref = useRef<HTMLInputElement | null>();

  const { i18n } = useTranslation();
  const {
    countryConfig: { country },
    changeLanguage,
  } = useRegionConfig();

  const [width] = useWindowResize();
  const isMobile = width && width < 1280;
  const isDesktop = width && width >= 1280;
  const [currentLang, setCurrentLang] = useState(i18next.language);
  const [triggerMode, setTriggerMode] = useState<string | undefined>(undefined);
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [value, setValue] = useState(currentLang);

  i18next.on('languageChanged', (lng) => {
    setCurrentLang(lng);
  });

  const supportedLanguages = country ? SUPPORTED_LANGUAGES_MAP_BY_COUNTRY?.[country] : null;

  const toogleOpenSubMenu = () => {
    setIsOpenSubMenu(!isOpenSubMenu);
  };

  const hideSubmenuOnScroll = () => {
    setIsOpenSubMenu(false);
  };

  const handleChange = (language: TLanguageKey) => {
    changeLanguage(language);
    setIsOpenSubMenu(false);
  };

  if (!country || !supportedLanguages || !i18n.language || supportedLanguages.length <= 1) {
    return null;
  }

  useEffect(() => {
    const currentShortDisplayName = supportedLanguages?.find((language) => language.code === currentLang)?.displayName;
    if (currentShortDisplayName) {
      setValue(currentShortDisplayName);
    }
  }, [currentLang, supportedLanguages]);

  useEffect(() => {
    if (isMobile) {
      setTriggerMode('click');
    } else if (isDesktop) {
      setTriggerMode('hover');
    }
  }, [isDesktop, isMobile]);

  const renderFloatSubMenu = (listItems: Array<ILanguage>) => (
    <div className="desk-nav-sub-menu">
      <StyledFloatSubMenu>
        {listItems.map((language: ILanguage) => {
          const isActiveLanguage = language.code === currentLang;
          return (
            // To apply onclick on div tag
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={language.code}
              className={`multi-language-item ${isActiveLanguage ? 'activeLang' : ''}`}
              onClick={() => {
                handleChange(language.code);
              }}
            >
              {language.longDisplayName}
            </div>
          );
        })}
      </StyledFloatSubMenu>
    </div>
  );

  useEffect(() => {
    window.addEventListener('scroll', hideSubmenuOnScroll);
    return () => {
      window.removeEventListener('scroll', hideSubmenuOnScroll);
    };
  }, [ref]);

  return (
    <Popover
      overlayClassName="lang-popover"
      content={() => renderFloatSubMenu(supportedLanguages)}
      placement="bottomRight"
      visible={isOpenSubMenu}
      onOpenChange={(visible) => {
        setIsOpenSubMenu(visible);
      }}
      ref={ref}
      trigger={triggerMode}
      overlayInnerStyle={{ borderRadius: 12 }}
    >
      <StyledLanguageContainer
        isFromListingDetailPage={isFromListingDetailPage}
        isOpenSubMenu={isOpenSubMenu}
        onClick={toogleOpenSubMenu}
        isFromMobile={isMobile}
      >
        <div className="multilang-select-buton">
          <Typography className="multilang-select-buton-text" variant="mainButtonOrLink">
            {value.toUpperCase()}
          </Typography>
          <div className="desk-nav-drop-down-icon">
            <CaretDownOutlined />
          </div>
        </div>
      </StyledLanguageContainer>
      <OverridePopoverStyle />
    </Popover>
  );
};

MultiLanguage.defaultProps = {
  isFromListingDetailPage: false,
};
export default MultiLanguage;
