import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';
import { API_URL } from '@source/constants/api';
import type { AppState } from '@source/redux/index';

const sliceName = 'lineChat';

interface LineSliceProps {
  isLoading: boolean;
  imgQRSource: string;
  error: any;
}

const INITIAL_STATE: LineSliceProps = {
  isLoading: true,
  imgQRSource: '',
  error: null,
};

export const fetchLineQRCodedDetails = createAsyncThunk(
  `${sliceName}/fetchLineQRCode`,
  async ({ region, slug, data }: any) => {
    const { lineChat: sheduleLinepath } = API_URL(region);
    const query = qs.stringify(data);
    const response = await axios.get(`${sheduleLinepath}/${slug}/line-qr-code?${query}`);
    return response?.data?.data;
  },
);

export const lineChatSlice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    // To update new data of Line chat by cases
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchLineQRCodedDetails.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchLineQRCodedDetails.fulfilled, (state, { payload }) => {
      state.imgQRSource = payload;
      state.isLoading = false;
    });
    builder.addCase(fetchLineQRCodedDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const getLineQRCodeData = (state: AppState) => state[sliceName];

export default lineChatSlice.reducer;
