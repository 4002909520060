'use strict';

if (typeof window === 'undefined') {
  import('./server').then(({ server }) => {
    server.listen({ onUnhandledRequest: 'bypass' });
  });
} else {
  const { client } = require('./client');

  client.start({
    onUnhandledRequest: 'bypass',
  });
}
