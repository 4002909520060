/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Popover } from 'antd';

import AgentWarrantyPopoverContent from '@design-system/components/BrowsingCard/AgentWarrantyPopoverContent';
import useGTMEvent from '@source/hooks/useGTMEvent';

interface IFeatureFlag {
  showBadge?: boolean;
  label?: string;
  color?: string;
  icon?: string;
  name?: string;
}

interface IFeatureBadge {
  featureFlags?: Array<IFeatureFlag>;
  carTitle?: any;
  href?: string;
  agentWarranty?: {
    expired_date: string;
    mileage: number;
    warranty_by: string;
  };
  insideBadges: {
    id?: string;
    title?: string;
    description?: string;
    icon?: string;
  }[];
}

const StyledFeatureBadgeWrapper = styled.div`
  min-height: 22px;
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const StyledFeatureFlags = styled.span`
  &:last-child {
    margin-right: 0px;
  }

  span {
    margin-right: 2px !important;
  }

  .feature-flag {
    font-size: 9px;
    ${(props) => props.theme.typo.familyGoogle.medium};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 4.5px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.color};
    color: ${(props) => props.color};
  }

  .agent-warranty {
    ${(props) => props.theme.typo.familyGoogle.medium};
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    color: ${(props) => props.theme.color.primaryBase};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #ffede5;

    span {
      flex-shrink: 0;
      width: 14px !important;
      height: 14px !important;
    }

    img {
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

const FeatureBadge: React.FC<IFeatureBadge> = ({
  featureFlags = [],
  carTitle,
  href,
  agentWarranty,
  insideBadges = [],
}) => {
  const { pushGTMEvent } = useGTMEvent();

  const handleClickWA = () => {
    pushGTMEvent({
      event: 'click_whatsapp_aw',
    });
  };

  const renderAgentWarrantyTag = (icon: any, label: string) => (
    <Popover
      placement="bottom"
      overlayClassName="agent-warranty-popover"
      content={
        <AgentWarrantyPopoverContent
          carTitle={carTitle}
          href={href}
          agentWarranty={agentWarranty}
          onClickWA={handleClickWA}
        />
      }
      zIndex={900}
      trigger={['hover', 'click']}
    >
      <StyledFeatureFlags>
        <div className="agent-warranty">
          {icon && <Image src={icon} alt="label" width="14" height="14" />}
          {label}
        </div>
      </StyledFeatureFlags>
    </Popover>
  );

  const hasAgentWarranty = featureFlags.some((flag) => flag.name === 'agent_warranty' && flag.showBadge);
  const unShowedTags = insideBadges.length - (hasAgentWarranty ? 1 : 2);
  const hasBadges = featureFlags.some((flag) => flag.showBadge) || insideBadges.length > 0;

  if (!hasBadges) {
    return null;
  }

  return (
    <StyledFeatureBadgeWrapper>
      {featureFlags?.map((flag: IFeatureFlag) => {
        const { showBadge, label, color, icon, name } = flag;
        if (showBadge) {
          return name !== 'agent_warranty' ? (
            <StyledFeatureFlags color={color}>
              <div className="feature-flag">
                {icon && <Image src={icon} alt="label" width="11" height="11" />}
                {label}
              </div>
            </StyledFeatureFlags>
          ) : (
            <div
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {renderAgentWarrantyTag(icon, label as string)}
            </div>
          );
        }

        return null;
      })}

      {insideBadges.slice(0, hasAgentWarranty ? 1 : 2).map((badge) => (
        <StyledFeatureFlags>
          <div className="agent-warranty">
            {badge.icon && <Image src={badge.icon} alt="label" width={14} height={14} />}
            {badge.title}
          </div>
        </StyledFeatureFlags>
      ))}
      {unShowedTags > 0 && (
        <StyledFeatureFlags>
          <div className="agent-warranty">+{unShowedTags} more</div>
        </StyledFeatureFlags>
      )}
    </StyledFeatureBadgeWrapper>
  );
};

export default FeatureBadge;
