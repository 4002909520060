// disable assignment to object on state
/* eslint-disable no-param-reassign */
import jsCookie from 'js-cookie';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { AxiosError } from 'axios';

import {
  WS_SELLER_PORTAL_ACCESS_TOKEN_KEY,
  WS_USER_ACCESS_TOKEN_KEY,
  WS_USER_CONTACT_ID_KEY,
  WS_USER_PROFILE_COUNTRY_KEY,
  WS_USER_PROFILE_KEY,
} from '@source/constants/common';
import UserProfile from '@source/services/UserProfile';
import { TRegionKey } from '@source/interface';
import {
  IProfileContactDefaultResponse,
  IProfileDefaultPayload,
  IProfileResponseData,
  IProfileResponsePromise,
} from '@source/interface/userProfile';

import { requestOTP, verifyOTP } from '@source/redux/slice/otp';
import { IErrorResponse, IRejectedValue } from '@source/interface/apiResponse';

const sliceName = 'USER_PROFILE';

type TInitialState = {
  loading: boolean;
  error: string | null;
  profile: IProfileResponseData | null;
  contact: IProfileContactDefaultResponse | null;
  loginAccessToken: string | null;
  loaded: boolean;
  loginModalVisible: boolean;
  redirectTo?: string | null;
};

const INITIAL_STATE: TInitialState = {
  loading: false,
  error: null,
  profile: null,
  loginAccessToken: null,
  contact: null,
  loaded: false,
  loginModalVisible: false,
  redirectTo: null,
};

// ------------------------------------------- ACTIONS -------------------------------------------

export const logOut = createAsyncThunk(`${sliceName}/LOG_OUT`, ({ country }: { country: TRegionKey }) =>
  UserProfile.logOut({ country }),
);

export const fetchProfile = createAsyncThunk<
  IProfileResponsePromise,
  { country: TRegionKey; slug: string },
  IRejectedValue
>(
  `${sliceName}/FETCH_PROFILE`,
  async ({ country, slug }: { country: TRegionKey; slug: string }, { rejectWithValue }) => {
    try {
      const res = await UserProfile.fetchProfile({ country, slug });
      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err; // cast the error for access

      if (!error.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfile = createAsyncThunk<
  IProfileResponsePromise,
  { country: TRegionKey; slug: string; payload: object },
  IRejectedValue
>(
  `${sliceName}/UPDATE_PROFILE`,
  async ({ country, slug, payload }: { country: TRegionKey; slug: string; payload: object }, { rejectWithValue }) => {
    try {
      const res = await UserProfile.updateProfile({ country, slug, payload });
      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err; // cast the error for access

      if (!error.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

// ------------------------------------------- REDUCER -------------------------------------------
const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    setUserFromCookie: (state) => {
      const cookiesJSON = jsCookie.get();
      const cookieProfile =
        typeof cookiesJSON[WS_USER_PROFILE_KEY] === 'string'
          ? JSON.parse(cookiesJSON[WS_USER_PROFILE_KEY])
          : cookiesJSON[WS_USER_PROFILE_KEY];

      const cookieToken = cookiesJSON?.[WS_USER_ACCESS_TOKEN_KEY];

      const newProfile = {
        ...(state.profile || {}),
        ...cookieProfile,
      };

      state.profile = isEmpty(newProfile) ? null : newProfile;
      state.loginAccessToken = isEmpty(cookieToken) ? null : cookieToken;
    },
    saveUserToCookie: (state, { payload }: { payload: IProfileDefaultPayload }) => {
      if (payload.login_access_token) {
        jsCookie.set(WS_USER_ACCESS_TOKEN_KEY, payload.login_access_token, {
          expires: 1,
        });
        jsCookie.set(WS_SELLER_PORTAL_ACCESS_TOKEN_KEY, payload.login_access_token, {
          expires: 1,
        });
      }

      if (payload.profile) {
        jsCookie.set(WS_USER_PROFILE_KEY, JSON.stringify(payload.profile), {
          expires: 1,
        });
        jsCookie.set(WS_USER_CONTACT_ID_KEY, JSON.stringify(payload.profile.id), {
          expires: 1,
        });
      }

      if (payload.profile_country) {
        jsCookie.set(WS_USER_PROFILE_COUNTRY_KEY, payload.profile_country, {
          expires: 1,
        });
      }
    },
    showLoginModal: (state) => {
      state.loginModalVisible = true;
    },
    hideLoginModal: (state) => {
      state.loginModalVisible = false;
    },
    redirectAfterVerified: (state, { payload }: { payload: { url: string | null } }) => {
      state.redirectTo = payload.url;
    },
  },
  extraReducers(builder) {
    // Requesting OTP
    builder.addCase(requestOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestOTP.fulfilled, (state, { payload }) => {
      const { contact } = payload?.data || {};
      state.loading = false;
      if (contact) state.contact = contact?.data;
    });
    builder.addCase(requestOTP.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.error = error || null;
    });

    // Verifying OTP
    builder.addCase(verifyOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOTP.fulfilled, (state, { payload, meta }) => {
      // eslint-disable-next-line camelcase
      const { user, access_token } = payload?.data || {};

      // Skip store user when using buy online after verifying OTP
      // https://carrotech.atlassian.net/browse/CCV-1241
      const { storeUser = true } = meta?.arg || {};

      if (storeUser) {
        state.profile = user;
      }
      // eslint-disable-next-line camelcase
      state.loginAccessToken = access_token;

      state.loading = false;
      state.loaded = false;
    });
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.error = error || null;
    });

    // Fetching Profile
    builder.addCase(fetchProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      state.profile = payload?.data;
      state.loading = false;
      state.loaded = true;
    });
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.error = error || null;
      state.loaded = true;
    });

    // Update Profile
    builder.addCase(updateProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.profile = payload?.data;
      state.loading = false;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.error = error || null;
    });

    // LogOut
    builder.addCase(logOut.fulfilled, (state) => {
      state.loading = false;
      state.contact = null;
      state.profile = null;
      state.error = null;
      state.loginAccessToken = null;
    });

    builder.addCase(logOut.rejected, (state) => {
      state.loading = false;
      state.contact = null;
      state.profile = null;
      state.error = null;
      state.loginAccessToken = null;
    });
  },
});

const { setUserFromCookie, saveUserToCookie, showLoginModal, hideLoginModal, redirectAfterVerified } = slice.actions;

export { setUserFromCookie, saveUserToCookie, showLoginModal, hideLoginModal, redirectAfterVerified };

export default slice.reducer;
