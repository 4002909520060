import { useCallback, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';
import jsCookie from 'js-cookie';
import isEmpty from 'lodash/isEmpty';

import * as userProfileActions from '@source/redux/slice/userProfile';

import { useAppDispatch, useAppSelector } from '@source/hooks/useStateHook';

import { TRegionKey } from '@source/interface';
import { IProfileDefaultPayload } from '@source/interface/userProfile';
import { URL_CONFIG } from '@source/constants/urlConfig';
import {
  WS_SELLER_PORTAL_ACCESS_TOKEN_KEY,
  WS_USER_ACCESS_TOKEN_KEY,
  WS_USER_CONTACT_ID_KEY,
  WS_USER_PROFILE_KEY,
} from '@source/constants/common';
import { useTranslation } from 'react-i18next';
import useRegionConfig from './useRegionConfig';

const useUserProfileServices = () => {
  const router = useRouter();
  const { query, pathname, replace } = router;
  const dispatch = useAppDispatch();
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();
  const { i18n } = useTranslation();

  /** Selectors */
  const userProfileState = useAppSelector((state) => state.userProfile, shallowEqual);

  /** Dispatched Funcs  */

  const saveUserToCookie = useCallback(
    (data: IProfileDefaultPayload) => dispatch(userProfileActions.saveUserToCookie(data)),
    [dispatch],
  );
  const setUserFromCookie = useCallback(() => dispatch(userProfileActions.setUserFromCookie()), [dispatch]);

  const fetchProfile = useCallback(
    ({ country, slug }: { country: TRegionKey; slug: string }) =>
      dispatch(userProfileActions.fetchProfile({ country, slug })),
    [dispatch],
  );

  const updateProfile = useCallback(
    ({ country, slug, payload }: { country: TRegionKey; slug: string; payload: object }) =>
      dispatch(userProfileActions.updateProfile({ country, slug, payload })),
    [dispatch],
  );

  const logOut = useCallback(
    ({ country, isBackToHomePage = true }: { country: TRegionKey; isBackToHomePage?: boolean }) => {
      // clear cookie
      jsCookie.remove(WS_USER_PROFILE_KEY);
      jsCookie.remove(WS_USER_ACCESS_TOKEN_KEY);
      jsCookie.remove(WS_SELLER_PORTAL_ACCESS_TOKEN_KEY);
      jsCookie.remove(WS_USER_CONTACT_ID_KEY);

      // Back to home
      if (isBackToHomePage) {
        router.push(URL_CONFIG({ region: country || 'sg', lang: i18n.language }).formatted);
      }

      return dispatch(userProfileActions.logOut({ country }));
    },
    [dispatch],
  );

  const showLoginModal = useCallback(() => dispatch(userProfileActions.showLoginModal()), [dispatch]);
  const hideLoginModal = useCallback(() => dispatch(userProfileActions.hideLoginModal()), [dispatch]);

  const redirectAfterVerified = useCallback(
    (url: { url: string | null }) => dispatch(userProfileActions.redirectAfterVerified(url)),
    [dispatch],
  );

  useEffect(() => {
    (async () => {
      const openLogin = query.openLoginPopup;
      if (openLogin && openLogin === 'true' && countryCode) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { openLoginPopup, lang, ...restQuery } = query || {};
        let queryString = new URLSearchParams(restQuery as Record<string, string>).toString();
        if (queryString.includes('%252F')) queryString = decodeURIComponent(queryString);
        await replace(`/${countryCode ?? 'sg'}/${i18n.language}/${pathname}/${queryString ? `?${queryString}` : ''}`);

        showLoginModal();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, countryCode]);

  const isAuthenticated = () => {
    let userProfileCookie = jsCookie.get(WS_USER_PROFILE_KEY);
    userProfileCookie = typeof userProfileCookie === 'string' ? JSON.parse(userProfileCookie) : userProfileCookie;

    const accessTokenCookie = jsCookie.get(WS_USER_ACCESS_TOKEN_KEY);

    const { profile: userProfile, loginAccessToken: accessToken } = userProfileState;

    return (
      (!isEmpty(userProfile) && !isEmpty(accessToken)) || (!isEmpty(userProfileCookie) && !isEmpty(accessTokenCookie))
    );
  };

  return {
    userProfileState,
    saveUserToCookie,
    setUserFromCookie,
    fetchProfile,
    updateProfile,
    logOut,
    showLoginModal,
    hideLoginModal,
    isAuthenticated,
    redirectAfterVerified,
  };
};

export default useUserProfileServices;
