import React, { FC, ReactNode } from 'react';
import { Button, ModalProps, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';

interface StyledModalHeaderProps {
  hasStickyHeader?: boolean;
  hasTitle?: React.ReactNode;
  noHeaderBorder?: boolean;
}

interface StyledModalBodyProps {
  hasFooter?: boolean;
  hasFixHeight?: boolean;
}

const StyledModal = styled(Modal)<StyledModalBodyProps>`
  .ant-modal-content {
    position: relative;
    background-color: ${(props) => props.theme.color.background};
    background-clip: padding-box;
    border: 0;
    border-radius: 20px !important;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  .ant-modal-body {
    max-height: 90%;
    position: relative;
    padding: 0px;
    text-align: center;

    ${(props) =>
      props.hasFixHeight &&
      css`
        height: calc(100vh - 150px);
      `}
  }
`;

const ModalHeaderWrapper = styled.div<StyledModalHeaderProps>`
  width: 100%;
  height: 65px;
  padding: 20px 40px;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  font-size: 14px;
  ${(props) =>
    props.hasTitle &&
    !props.noHeaderBorder &&
    css`
      border-bottom: 1px solid #bababa;
    `}
  display: grid;
  position: ${(props) => (props.hasStickyHeader ? 'sticky' : 'relative')};
  top: 0;
  grid-template-columns: 1fr 10fr 1fr;
  place-items: center;
  background-color: ${(props) => props.theme.color.background};
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;

  .modal-title-text {
    grid-column-start: 2;
  }
`;

const ModalBodyWrapper = styled.div<StyledModalBodyProps>`
  overflow: auto;

  ${(props) =>
    props.hasFooter &&
    css`
      height: calc(100% - 65px - 88px);
    `}

  ${(props) =>
    props.hasFixHeight &&
    css`
      height: calc(100% - 65px);
    `}
`;

const ModalFooterWrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  background-color: ${(props) => props.theme.color.background};
  position: sticky;
  bottom: 0px;
  box-shadow: 0px 0px 13px -6px rgb(0 0 0 / 75%);
  border-radius: 20px !important;
`;

const StyledApplyButton = styled(Button)`
  background-color: ${(props) => props.theme.color.primaryBase};
  color: ${(props) => props.theme.color.onPrimary};
  font-weight: 600;
  font-size: 14px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  border-radius: 10px;
  height: 48px;
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  font-size: 21px;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

export type MobileModalProps = ModalProps & {
  confirmBtnLabel?: string;
  hasHeader?: boolean;
  hasFooter?: boolean;
  width?: string | number;
  hasFixHeight?: boolean;
  hasStickyHeader?: boolean;
  maskClosable?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  customHeader?: () => JSX.Element | ReactNode | undefined;
  customFooter?: () => JSX.Element | ReactNode | undefined;
  maskStyle?: React.CSSProperties;
  destroyOnClose?: boolean;
  noHeaderBorder?: boolean;
};

const MobileModal: FC<MobileModalProps> = ({
  children,
  title,
  confirmBtnLabel,
  visible,
  hasHeader,
  hasFooter,
  width,
  hasFixHeight,
  hasStickyHeader,
  maskClosable,
  customHeader,
  customFooter,
  onConfirm,
  onClose,
  maskStyle,
  destroyOnClose = false,
  noHeaderBorder = false,
}) => {
  const renderHeader = (
    <ModalHeaderWrapper hasStickyHeader={hasStickyHeader} hasTitle={!!title} noHeaderBorder={noHeaderBorder}>
      <span className="modal-title-text">{title}</span>
      <StyledCloseIcon onClick={onClose} />
    </ModalHeaderWrapper>
  );

  const renderCustomHeader = typeof customHeader === 'function' ? customHeader() : customHeader;

  return (
    <StyledModal
      visible={visible}
      closable={false}
      footer={null}
      onCancel={onClose}
      maskClosable={maskClosable}
      width={width}
      maskStyle={maskStyle}
      destroyOnClose={destroyOnClose}
    >
      {hasHeader && (customHeader ? renderCustomHeader : renderHeader)}
      <ModalBodyWrapper hasFooter={hasFooter} hasFixHeight={hasFixHeight}>
        {children}
      </ModalBodyWrapper>
      {hasFooter &&
        (customFooter ? (
          customFooter()
        ) : (
          <ModalFooterWrapper>
            <StyledApplyButton block onClick={onConfirm}>
              {confirmBtnLabel}
            </StyledApplyButton>
          </ModalFooterWrapper>
        ))}
    </StyledModal>
  );
};

MobileModal.defaultProps = {
  confirmBtnLabel: '',
  hasHeader: true,
  hasFooter: true,
  width: 520,
  hasFixHeight: true,
  hasStickyHeader: true,
  maskClosable: false,
  onConfirm: undefined,
  onClose: undefined,
  customHeader: undefined,
  customFooter: undefined,
  maskStyle: undefined,
};

export default MobileModal;
