import axios from 'axios';
import qs from 'qs';

import { API_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
// import { WHOLESALE_AXIOS_USER_AUTH } from './UserProfile';

export default {
  getConfigOptions: (country: TRegionKey, configs: Record<string, any>) => {
    const queryString = qs.stringify(configs);
    const configOptionsAPI = API_URL(country).configOptions;

    // if (configs?.types?.[0]?.name?.includes('inspection_center_provinces')) {
    //   return axios.get(`http://localhost:8000/configs/locations?${queryString}`);
    // }

    return axios.get(`${configOptionsAPI}?${queryString}`);
  },
  getInspectionLocation: (country: TRegionKey, provinceId?: number | null) => {
    const queryString = qs.stringify({ province_id: provinceId || undefined });
    const { regionPathUrl } = API_URL(country);

    return axios.get(`${regionPathUrl}/inspection-location?${queryString}`);
    // return axios.get(`http://localhost:8000/configs/locations?${queryString}`);
  },
};
