import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '@source/constants/config';

import homeEn from '@source/pages/Home/lang/en';
import homeId from '@source/pages/Home/lang/id';
import homeMy from '@source/pages/Home/lang/my';
import homeTh from '@source/pages/Home/lang/th';
// import homeTw from '@source/pages/Home/lang/tw';
import homeJa from '@source/pages/Home/lang/ja'; // ja is language code of JP
import homeZhSg from '@source/pages/Home/lang/zhSg';
import homeMsSg from '@source/pages/Home/lang/msSg';
import homeZhHanT from '@source/pages/Home/lang/zhHanT.json';
import homeZhHanS from '@source/pages/Home/lang/zhHanS.json';

import sellCarEn from '@source/pages/SellCar/lang/en';
import sellCarId from '@source/pages/SellCar/lang/id';
import sellCarMy from '@source/pages/SellCar/lang/my';
import sellCarTh from '@source/pages/SellCar/lang/th';
// import sellCarTw from '@source/pages/SellCar/lang/tw';
import sellCarJa from '@source/pages/SellCar/lang/ja';
import sellCarZhSg from '@source/pages/SellCar/lang/zhSg';
import sellCarMsSg from '@source/pages/SellCar/lang/msSg';
import sellCarZhHanS from '@source/pages/SellCar/lang/zhHanS.json';
import sellCarZhHanT from '@source/pages/SellCar/lang/zhHanT.json';

import appointmentEn from '@source/pages/Appointments/lang/en';
import appointmentMy from '@source/pages/Appointments/lang/my';
import appointmentZhSg from '@source/pages/Appointments/lang/zhSg';

import paymentEn from '@source/pages/Payments/lang/en';
import paymentMy from '@source/pages/Payments/lang/my';

import sellConfirmationEn from '@source/pages/SellConfirmation/lang/en';
import sellConfirmationId from '@source/pages/SellConfirmation/lang/id';
import sellConfirmationMy from '@source/pages/SellConfirmation/lang/my';
import sellConfirmationTh from '@source/pages/SellConfirmation/lang/th';
// import sellConfirmationTw from '@source/pages/SellConfirmation/lang/tw';
import sellConfirmationJa from '@source/pages/SellConfirmation/lang/ja';
import sellConfirmationZhSg from '@source/pages/SellConfirmation/lang/zhSg';
import sellConfirmationMsSg from '@source/pages/SellConfirmation/lang/msSg';
import sellConfirmationZhHanS from '@source/pages/SellConfirmation/lang/zhHanS.json';
import sellConfirmationZhHanT from '@source/pages/SellConfirmation/lang/zhHanT.json';

import sharedEn from '@source/lang/en.json';
import sharedId from '@source/lang/id.json';
import sharedMy from '@source/lang/my.json';
import sharedTh from '@source/lang/th.json';
// import sharedTw from '@source/lang/tw.json';
import sharedJa from '@source/lang/ja.json';
import sharedZhSg from '@source/lang/zhSg';
import sharedMsSg from '@source/lang/msSg';
import sharedZhHanS from '@source/lang/zhHanS.json';
import sharedZhHanT from '@source/lang/zhHanT.json';

import carDetailEn from '@source/pages/CarDetail/lang/en';
import carDetailId from '@source/pages/CarDetail/lang/id';
import carDetailMy from '@source/pages/CarDetail/lang/my';
import carDetailTh from '@source/pages/CarDetail/lang/th';
// import carDetailTw from '@source/pages/CarDetail/lang/tw';
import carDetailJa from '@source/pages/CarDetail/lang/ja';
import carDetailZhSg from '@source/pages/CarDetail/lang/zhSg';
import carDetailMsSg from '@source/pages/CarDetail/lang/msSg';
import carDetailZhHanT from '@source/pages/CarDetail/lang/zhHanT.json';
import carDetailZhHanS from '@source/pages/CarDetail/lang/zhHanS.json';

import errorEn from '@source/pages/Error/lang/en';
import errorId from '@source/pages/Error/lang/id';
import errorMy from '@source/pages/Error/lang/my';
import errorTh from '@source/pages/Error/lang/th';
// import errorTw from '@source/pages/Error/lang/tw';
import errorJa from '@source/pages/Error/lang/ja';
import errorZhSg from '@source/pages/Error/lang/zhSg';
import errorMsSg from '@source/pages/Error/lang/msSg';
import errorZhHanS from '@source/pages/Error/lang/zhHanS.json';
import errorZhHanT from '@source/pages/Error/lang/zhHanT.json';

import inspectionEn from '@source/pages/Inspection/lang/en';
import inspectionId from '@source/pages/Inspection/lang/id';
import inspectionMy from '@source/pages/Inspection/lang/my';
import inspectionTh from '@source/pages/Inspection/lang/th';
// import inspectionTw from '@source/pages/Inspection/lang/tw';
import inspectionJa from '@source/pages/Inspection/lang/ja';
import inspectionZhSg from '@source/pages/Inspection/lang/zhSg';
import inspectionMsSg from '@source/pages/Inspection/lang/msSg';
import inspectionZhHanT from '@source/pages/Inspection/lang/zhHanT.json';
import inspectionZhHanS from '@source/pages/Inspection/lang/zhHanS.json';

import userProfileEn from '@source/pages/UserProfile/lang/en';
import userProfileId from '@source/pages/UserProfile/lang/id';
import userProfileMy from '@source/pages/UserProfile/lang/my';
import userProfileTh from '@source/pages/UserProfile/lang/th';
// import userProfileTw from '@source/pages/UserProfile/lang/tw';
import userProfileJa from '@source/pages/UserProfile/lang/ja';
import userProfileZhSg from '@source/pages/UserProfile/lang/zhSg';
import userProfileMsSg from '@source/pages/UserProfile/lang/msSg';
import userProfileZhHanS from '@source/pages/UserProfile/lang/zhHanS';
import userProfileZhHanT from '@source/pages/UserProfile/lang/zhHanT';

import promotionsEn from '@source/pages/Promotions/lang/en';
import promotionsMy from '@source/pages/Promotions/lang/my';
import promotionsZhMy from '@source/pages/Promotions/lang/zh-my.json';

import viewingHistoryEn from '@source/pages/ViewingHistory/lang/en';
import viewingHistoryMY from '@source/pages/ViewingHistory/lang/my';
import viewingHistoryID from '@source/pages/ViewingHistory/lang/id';
import viewingHistoryTH from '@source/pages/ViewingHistory/lang/th';
import viewingHistoryTW from '@source/pages/ViewingHistory/lang/tw';
import viewingHistoryJP from '@source/pages/ViewingHistory/lang/ja';
import viewingHistoryMsSg from '@source/pages/ViewingHistory/lang/msSg';
import viewingHistoryZhHanS from '@source/pages/ViewingHistory/lang/zhHanS';
import viewingHistoryZhHanT from '@source/pages/ViewingHistory/lang/zhHanT';

import contactUsEn from '@source/pages/ContactUs/lang/en';
import contactUsId from '@source/pages/ContactUs/lang/id';
import contactUsMy from '@source/pages/ContactUs/lang/my';
import contactUsTh from '@source/pages/ContactUs/lang/th';
// import contactUsTw from '@source/pages/ContactUs/lang/tw';
import contactUsJa from '@source/pages/ContactUs/lang/ja';
import contactUsZhSg from '@source/pages/ContactUs/lang/zhSg';
import contactUsMsSg from '@source/pages/ContactUs/lang/msSg';
import contactUsZhHanS from '@source/pages/ContactUs/lang/zhHanS.json';
import contactUsZhHanT from '@source/pages/ContactUs/lang/zhHanT.json';

import locateUsEn from '@source/pages/LocateUs/lang/en';
import locateUsId from '@source/pages/LocateUs/lang/id';
import locateUsMy from '@source/pages/LocateUs/lang/my';
import locateUsTh from '@source/pages/LocateUs/lang/th';
// import locateUsTw from '@source/pages/LocateUs/lang/tw';
import locateUsZhSg from '@source/pages/LocateUs/lang/zhSg';
import locateUsMsSg from '@source/pages/LocateUs/lang/msSg';
import locateUsZhHanT from '@source/pages/LocateUs/lang/zhHanT.json';
import locateUsZhHanS from '@source/pages/LocateUs/lang/zhHanS.json';

import ccpoEn from '@source/pages/CarroCertified/lang/en';
import ccpoId from '@source/pages/CarroCertified/lang/id';
import ccpoMy from '@source/pages/CarroCertified/lang/my';
import ccpoTh from '@source/pages/CarroCertified/lang/th';
// import ccpoTw from '@source/pages/CarroCertified/lang/tw';
import ccpoJa from '@source/pages/CarroCertified/lang/ja';
import ccpoZhSg from '@source/pages/CarroCertified/lang/zhSg';
import ccpoMsSg from '@source/pages/CarroCertified/lang/msSg';
import ccpoZhHanS from '@source/pages/CarroCertified/lang/zhHanS.json';
import ccpoZhHanT from '@source/pages/CarroCertified/lang/zhHanT.json';

import aboutUsEn from '@source/pages/AboutUs/lang/en';
import aboutUsJa from '@source/pages/AboutUs/lang/ja';
import aboutUsZhHanS from '@source/pages/AboutUs/lang/zhHanS.json';
import aboutUsZhHanT from '@source/pages/AboutUs/lang/zhHanT.json';

import PromotionsNFCEn from '@source/pages/PromotionsNFC/lang/en.json';
import PromotionsNFCId from '@source/pages/PromotionsNFC/lang/id.json';
import PromotionsNFCMy from '@source/pages/PromotionsNFC/lang/my.json';
import PromotionsNFCTh from '@source/pages/PromotionsNFC/lang/th.json';
// import PromotionsNFCTw from '@source/pages/PromotionsNFC/lang/tw.json';
import PromotionsNFCJa from '@source/pages/PromotionsNFC/lang/ja.json';
import PromotionsNFCZhSg from '@source/pages/PromotionsNFC/lang/zhSg.json';

import termsConditionsEn from '@source/pages/TermsAndConditions/lang/en';
import termsConditionsId from '@source/pages/TermsAndConditions/lang/id';
import termsConditionsMy from '@source/pages/TermsAndConditions/lang/my';
import termsConditionsTh from '@source/pages/TermsAndConditions/lang/th';
import termsConditionsZhSg from '@source/pages/TermsAndConditions/lang/zhSg';
import termsConditionsZhHanS from '@source/pages/TermsAndConditions/lang/zhHanS.json';
import termsConditionsZhHanT from '@source/pages/TermsAndConditions/lang/zhHanT.json';

import refurbEn from '@source/pages/Refurb/lang/en';
import refurbMy from '@source/pages/Refurb/lang/my';
import refurbZhMy from '@source/pages/Refurb/lang/zhMy';

import buyNewEn from '@source/pages/BuyNew/lang/en';
import buyNewZhSg from '@source/pages/BuyNew/lang/zhSg';
import buyNewMsSg from '@source/pages/BuyNew/lang/msSg';
import buyNewDetailEn from '@source/pages/BuyNewDetail/lang/en';
import buyNewDetailZhSg from '@source/pages/BuyNewDetail/lang/zhSg';
import buyNewDetailMsSg from '@source/pages/BuyNewDetail/lang/msSg';

import financeEn from '@source/pages/Finance/lang/en';
import financeZhHanT from '@source/pages/Finance/lang/zhHanT';
import financeZhHanS from '@source/pages/Finance/lang/zhHanS';

const resources = {
  [SUPPORTED_LANGUAGES.EN.code]: {
    translation: {
      ...homeEn,
      ...sellCarEn,
      ...sellConfirmationEn,
      shared: sharedEn,
      details: carDetailEn,
      error: errorEn,
      inspection: inspectionEn,
      userProfile: userProfileEn,
      promotions: promotionsEn,
      viewingHistory: viewingHistoryEn,
      contactUs: contactUsEn,
      ccpo: ccpoEn,
      locateUs: locateUsEn,
      aboutUs: aboutUsEn,
      promotionsNFC: PromotionsNFCEn,
      appointment: appointmentEn,
      payment: paymentEn,
      termsConditions: termsConditionsEn,
      refurb: refurbEn,
      buyNew: buyNewEn,
      buyNewDetail: buyNewDetailEn,
      finance: financeEn,
    },
  },
  // Bahasa Indonesia
  [SUPPORTED_LANGUAGES.ID.code]: {
    translation: {
      ...homeId,
      ...sellCarId,
      ...sellConfirmationId,
      shared: sharedId,
      details: carDetailId,
      error: errorId,
      inspection: inspectionId,
      userProfile: userProfileId,
      contactUs: contactUsId,
      viewingHistory: viewingHistoryID,
      ccpo: ccpoId,
      locateUs: locateUsId,
      promotionsNFC: PromotionsNFCId,
      termsConditions: termsConditionsId,
    },
  },
  // Bahasa Maylaysia
  [SUPPORTED_LANGUAGES.MY.code]: {
    translation: {
      ...homeMy,
      ...sellCarMy,
      ...sellConfirmationMy,
      shared: sharedMy,
      details: carDetailMy,
      error: errorMy,
      inspection: inspectionMy,
      promotions: promotionsMy,
      userProfile: userProfileMy,
      viewingHistory: viewingHistoryMY,
      contactUs: contactUsMy,
      ccpo: ccpoMy,
      locateUs: locateUsMy,
      promotionsNFC: PromotionsNFCMy,
      appointment: appointmentMy,
      payment: paymentMy,
      termsConditions: termsConditionsMy,
      refurb: refurbMy,
    },
  },
  // Thai
  [SUPPORTED_LANGUAGES.TH.code]: {
    translation: {
      ...homeTh,
      ...sellCarTh,
      ...sellConfirmationTh,
      shared: sharedTh,
      details: carDetailTh,
      error: errorTh,
      inspection: inspectionTh,
      userProfile: userProfileTh,
      contactUs: contactUsTh,
      viewingHistory: viewingHistoryTH,
      ccpo: ccpoTh,
      locateUs: locateUsTh,
      promotionsNFC: PromotionsNFCTh,
      termsConditions: termsConditionsTh,
    },
  },
  // Taiwanese Mandarin (Note: i18next support zh-TW not zh-tw)
  // [SUPPORTED_LANGUAGES.TW.code]: {
  //   translation: {
  //     ...homeTw,
  //     ...sellCarTw,
  //     ...sellConfirmationTw,
  //     shared: sharedTw,
  //     details: carDetailTw,
  //     error: errorTw,
  //     inspection: inspectionTw,
  //     userProfile: userProfileTw,
  //     contactUs: contactUsTw,
  //     viewingHistory: viewingHistoryTW,
  //     ccpo: ccpoTw,
  //     locateUs: locateUsTw,
  //     promotionsNFC: PromotionsNFCTw,
  //   },
  // },
  // Singapore Mandarin (Note: i18next support zh-SG)
  [SUPPORTED_LANGUAGES['ZH-SG'].code]: {
    translation: {
      ...homeZhSg,
      ...sellCarZhSg,
      ...sellConfirmationZhSg,
      shared: sharedZhSg,
      details: carDetailZhSg,
      error: errorZhSg,
      inspection: inspectionZhSg,
      userProfile: userProfileZhSg,
      contactUs: contactUsZhSg,
      viewingHistory: viewingHistoryTW,
      ccpo: ccpoZhSg,
      locateUs: locateUsZhSg,
      promotionsNFC: PromotionsNFCZhSg,
      termsConditions: termsConditionsZhSg,
      appointment: appointmentZhSg,
      refurb: refurbZhMy,
      buyNew: buyNewZhSg,
      buyNewDetail: buyNewDetailZhSg,
    },
  },
  // Malaysia Mandarin (Note: i18next support zh-MY)
  [SUPPORTED_LANGUAGES['ZH-MY'].code]: {
    translation: {
      ...homeZhSg,
      ...sellCarZhSg,
      ...sellConfirmationZhSg,
      shared: sharedZhSg,
      details: carDetailZhSg,
      error: errorZhSg,
      inspection: inspectionZhSg,
      promotions: promotionsZhMy,
      userProfile: userProfileZhSg,
      contactUs: contactUsZhSg,
      viewingHistory: viewingHistoryTW,
      ccpo: ccpoZhSg,
      locateUs: locateUsZhSg,
      promotionsNFC: PromotionsNFCZhSg,
      termsConditions: termsConditionsZhSg,
      appointment: appointmentZhSg,
      refurb: refurbZhMy,
    },
  },
  // Japanese
  [SUPPORTED_LANGUAGES.JA.code]: {
    // TODO - UPDATE JP Translation files
    translation: {
      ...homeJa,
      ...sellCarJa,
      ...sellConfirmationJa,
      shared: sharedJa,
      details: carDetailJa,
      error: errorJa,
      inspection: inspectionJa,
      userProfile: userProfileJa,
      viewingHistory: viewingHistoryJP,
      contactUs: contactUsJa,
      ccpo: ccpoJa,
      aboutUs: aboutUsJa,
      promotionsNFC: PromotionsNFCJa,
    },
  },
  // Bahasa Maylaysia for SG region
  [SUPPORTED_LANGUAGES['MS-SG'].code]: {
    translation: {
      ...homeMsSg,
      ...sellCarMsSg,
      ...sellConfirmationMsSg,
      shared: sharedMsSg,
      details: carDetailMsSg,
      error: errorMsSg,
      inspection: inspectionMsSg,
      promotions: promotionsMy,
      userProfile: userProfileMsSg,
      viewingHistory: viewingHistoryMsSg,
      contactUs: contactUsMsSg,
      ccpo: ccpoMsSg,
      locateUs: locateUsMsSg,
      buyNew: buyNewMsSg,
      buyNewDetail: buyNewDetailMsSg,
    },
  },
  [SUPPORTED_LANGUAGES['ZH-HANS-HK'].code]: {
    // TODO - UPDATE SC hongkong Translation files
    translation: {
      ...homeZhHanS,
      ...sellCarZhHanS,
      ...sellConfirmationZhHanS,
      shared: sharedZhHanS,
      details: carDetailZhHanS,
      error: errorZhHanS,
      inspection: inspectionZhHanS,
      userProfile: userProfileZhHanS,
      viewingHistory: viewingHistoryZhHanS,
      contactUs: contactUsZhHanS,
      ccpo: ccpoZhHanS,
      aboutUs: aboutUsZhHanS,
      promotionsNFC: PromotionsNFCEn,
      locateUs: locateUsZhHanS,
      finance: financeZhHanS,
      termsConditions: termsConditionsZhHanS,
    },
  },
  [SUPPORTED_LANGUAGES['ZH-HANT-HK'].code]: {
    // TODO - UPDATE TC hongkong Translation files
    translation: {
      ...homeZhHanT,
      ...sellCarZhHanT,
      ...sellConfirmationZhHanT,
      shared: sharedZhHanT,
      details: carDetailZhHanT,
      error: errorZhHanT,
      inspection: inspectionZhHanT,
      userProfile: userProfileZhHanT,
      viewingHistory: viewingHistoryZhHanT,
      contactUs: contactUsZhHanT,
      ccpo: ccpoZhHanT,
      aboutUs: aboutUsZhHanT,
      promotionsNFC: PromotionsNFCEn,
      locateUs: locateUsZhHanT,
      finance: financeZhHanT,
      termsConditions: termsConditionsZhHanT,
    },
  },
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
