/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@design-system/components';
import Image from 'next/image';
import React, { FC } from 'react';
import styled from 'styled-components';
import applestore from '@source/pages/Home/assets/images/apple-store.png';
import googleplay from '@source/pages/Home/assets/images/google-play.png';

const StyleContainer = styled.div`
  .side-navigation-download {
    .side-navigation-download-app {
      display: flex;
      margin-top: 10px;
    }

    .side-navigation-download-image {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

interface DownloadComponentProps {
  onClose?: () => void;
  content?: any;
}

const DownloadComponent: FC<DownloadComponentProps> = ({ onClose, content }) => (
  <StyleContainer>
    <div className="side-navigation-download">
      <Typography variant="mainButtonOrLink" className="side-navigation-download-content-color">
        {content}
      </Typography>
      <div className="side-navigation-download-app">
        <div
          className="side-navigation-download-image"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <a
            href="https://itunes.apple.com/us/app/trustycars-buy-sell-direct/id1011686446?mt=8&utm_source=web&utm_medium=carro&utm_campaign=homepage_footer"
            rel="noreferrer"
            target="_blank"
          >
            <Image src={applestore} alt="apple" width={118} height={38} />
          </a>
        </div>
        <div
          className="side-navigation-download-image"
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=sg.carro&utm_source=web&utm_medium=carro&utm_campaign=footer"
            rel="noreferrer"
            target="_blank"
          >
            <Image src={googleplay} alt="apple" width={118} height={38} />
          </a>
        </div>
      </div>
    </div>
  </StyleContainer>
);

DownloadComponent.defaultProps = {
  onClose: () => {},
  content: null,
};

export default DownloadComponent;
