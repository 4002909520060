import { rest } from 'msw';

import { NEXT_PUBLIC_STRAPI_URL_TH } from '@source/constants/env';

export const images = {
  xs: '/mock-assets/small.png',
  sm: '/mock-assets/small.png',
  md: '/mock-assets/big.png',
  lg: '/mock-assets/big.png',
  xl: '/mock-assets/big.png',
  xxl: '/mock-assets/big.png',
};

export const data = [
  {
    id: 5,
    json: {
      order: 1,
      images,
      modal: {
        detail:
          "<div><br /><div className='paragraph' style='padding: 0 12px 0 40px;'>*เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด / โปรดตรวจสอบรายละเอียดเพิ่มเติม ณ จุดขาย<br /> สอบถามเพิ่มเติมได้ทุกวัน 9.30-18.30 น.<br />ไลน์ : @carrothai <br /> เฟซบุ๊ก : <a href='https://www.facebook.com/CARROThai'>Carro Thailand</a> <br /> โทร : 02-460-9380</div><div style='font-family:Poppins Bold;font-size:14px;font-weight:900;margin:24px 0 8px 16px;'>เงื่อนไข</div><div className='paragraph'><br /><ol><li className='mt-8 mb-8'>สำหรับลูกค้าที่เซ็นสัญญาซื้อรถยนต์มือสองไม่เกินวันที่ 31 ธันวาคม 2565 และออกรถไม่เกินวันที่ 31 มกราคม 2566</li><li className='mt-8 mb-8'>เงื่อนไขทั้งหมดเป็นไปตามที่บริษัทฯกำหนด และไม่สามารถเปลี่ยนหรือทอนเป็นเงินสดได้ </li><li className='mt-8 mb-8'>บริษัทฯขอสงวนสิทธิ์ยกเลิกหรือเปลี่ยนแปลงเงื่อนไขโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li><li className='mt-8 mb-8'>การตัดสินของบริษัทฯ ถือเป็นที่สิ้นสุด และไม่สามารถใช้ร่วมกับรายการส่งเสริมการขาย หรือส่วนลดอื่น ๆ ได้</li></ol></div></div>",
        style: {
          default: { maxWidth: '353px', fontSize: '11px', lineHeight: '1.5715' },
          lg: { fontSize: '14px' },
          xl: { fontSize: '14px' },
          xxl: { fontSize: '14px' },
        },
      },
    },
    created_by: { id: 13, firstname: 'Andy', lastname: 'Quang', username: '' },
    updated_by: { id: 13, firstname: 'Andy', lastname: 'Quang', username: '' },
    created_at: '2022-12-14T10:57:30.000Z',
    updated_at: '2023-02-21T08:13:44.000Z',
    locale: 'en',
    page: 'home_page',
  },
];

const ENDPOINT = `${NEXT_PUBLIC_STRAPI_URL_TH}/crazy-rabbit-banners`;

const handler = rest.all(ENDPOINT, (req, res, ctx) => {
  return res(ctx.json(data));
});

export default handler;
