// Include the pattern for custom label
/* eslint-disable no-template-curly-in-string */

import { FilterProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import { getStaticCDN } from '@design-system/utils/utils';

import {
  getDataConfigsCountry,
  getFiltersTranslationPath,
} from '@source/pages/Home/components/Filters/configs/constants';

import FILTER_CONFIGS_ID from './id';
import FILTER_CONFIGS_MY from './my';
import FILTER_CONFIGS_SG from './sg';
import FILTER_CONFIGS_TH from './th';
import FILTER_CONFIGS_JP from './jp';
import FILTER_CONFIGS_HK from './hk';

const countryCode = 'sg';

const DEFAULT_FILTER_SETTINGS = [
  {
    name: 'category',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('quick_search'),
    algoliaFetching: false,
    filters: [
      {
        header: getFiltersTranslationPath('categories'),
        name: 'categories',
        type: 'button',
        filterSelectedHeader: '[label]',
        isShowTagMobile: false,
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'CATEGORIES',
          listFields: [
            'low_mileage',
            'under_9k_depre',
            'deluxe_and_classy',
            'budget_friendly',
            'renewed_coe',
            'fun_to_drive',
            '7_seaters',
            '1_owner_only',
            'family_friendly',
            'high_horsepower',
            'fits_more',
            'small_and_nimble',
          ],
        }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'fuel_button',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('fuelType'),
    group: 'fuel_button',
    groupHeader: getFiltersTranslationPath('fuelType'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        header: getFiltersTranslationPath('fuelType'),
        headerMobile: getFiltersTranslationPath('fuelType'),
        name: 'inventory.fuel_type',
        type: 'button',
        filterSelectedHeader: '[label]',
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'FUEL_TYPE',
          listFields: [
            'diesel',
            'diesel-hijau',
            'petrol',
            'petrol-electric',
            'petrol-with-ngv',
            'petrol-with-hybrid',
            'hybrid-electric',
            'electric',
            'cng',
          ],
        }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'transmission',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('transmission'),
    group: 'transmission',
    groupHeader: getFiltersTranslationPath('transmission'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        header: getFiltersTranslationPath('transmission'),
        headerMobile: getFiltersTranslationPath('transmission'),
        name: 'inventory.transmission',
        filterSelectedHeader: '[label]',
        type: 'button',
        tag: '{0}',
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'TRANSMISSION',
        }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'body_button',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('bodyType'),
    group: 'body_button',
    groupHeader: getFiltersTranslationPath('bodyType'),
    groupHeaderMobile: getFiltersTranslationPath('bodyType'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        header: getFiltersTranslationPath('selectBodyType'),
        headerMobile: getFiltersTranslationPath('selectBodyType'),
        name: 'inventory.car_type',
        type: 'button',
        filterSelectedHeader: '[label]',
        parentStyles: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          placeItems: 'center',
        },
        parentStylesMobile: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          placeItems: 'center',
        },
        imagePath: getStaticCDN('/assets/shared/body-type-cars/{0}.svg'),
        imageStyle: {
          width: '85px',
        },
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'BODY_TYPE',
          listFields: [
            'hatchback',
            'hybrid',
            'luxury_sedan',
            'mid_sized_sedan',
            'mpv',
            'sedan',
            'sports',
            'stationwagon',
            'suv',
            'electric',
            'van',
            'pickup',
            'eco_car',
          ],
        }),
        selectedOptions: null,
        buttonsType: 'primary',
        colResponsive: {
          xs: 12,
          sm: 12,
          md: 8,
        },
      },
    ],
  },
  {
    name: 'seats',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('seats'),
    group: 'seats',
    groupMobile: null,
    groupHeader: getFiltersTranslationPath('seats'),
    showHeader: true,
    showSubheader: true,
    algoliaFetching: false,
    filters: [
      {
        header: getFiltersTranslationPath('selectSeats'),
        headerMobile: getFiltersTranslationPath('selectSeats'),
        name: 'inventory.seats',
        type: 'button',
        filterSelectedHeader: '[label]-Seater',
        isNumeric: true,
        options: getDataConfigsCountry({ countryCode, dataName: 'SEATS_OPTIONS' }),
        selectedOptions: null,
        buttonShape: 'rectangle-no-border',
        parentStyles: {
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          placeItems: 'center',
        },
        buttonWrapperStyles: {
          padding: 0,
        },
        buttonStyles: {
          width: '100%',
        },
        colStyles: {
          padding: '4px',
          width: '100%',
        },
        rowGutter: [8, 8],
        colResponsive: {
          xs: 8,
          sm: 8,
          md: 8,
        },
        selectedCustomLabel: getFiltersTranslationPath('seatsCustomLabel'),
      },
    ],
  },
  {
    name: 'colours',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('colours'),
    showHeader: true,
    group: 'colours',
    groupHeader: getFiltersTranslationPath('colours'),
    showSubheader: true,
    algoliaFetching: false,
    joinCondition: 'AND',
    filters: [
      {
        header: getFiltersTranslationPath('selectColours'),
        headerMobile: getFiltersTranslationPath('selectColours'),
        name: 'inventory.color',
        type: 'button',
        filterSelectedHeader: '[label]',
        parentStyles: {
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          placeItems: 'center',
        },
        imagePath: '/assets/img/buy-car/icon/{0}.png',
        isNumeric: false,
        options: [
          { label: getFiltersTranslationPath('options.black'), value: 'black', name: 'black' },
          { label: getFiltersTranslationPath('options.white'), value: 'white', name: 'white' },
          { label: getFiltersTranslationPath('options.brown'), value: 'brown', name: 'brown' },
          { label: getFiltersTranslationPath('options.grey'), value: 'grey', name: 'grey' },
          { label: getFiltersTranslationPath('options.silver'), value: 'silver', name: 'silver' },
          { label: getFiltersTranslationPath('options.blue'), value: 'blue', name: 'blue' },
          { label: getFiltersTranslationPath('options.red'), value: 'red', name: 'red' },
          { label: getFiltersTranslationPath('options.green'), value: 'green', name: 'green' },
          { label: getFiltersTranslationPath('options.orange'), value: 'orange', name: 'orange' },
          { label: getFiltersTranslationPath('options.beige'), value: 'beige', name: 'beige' },
        ],
        selectedOptions: null,
        buttonsType: 'primary',
        rowGutter: [8, 8],
        colResponsive: {
          xs: 8,
          sm: 8,
          md: 8,
        },
      },
    ],
  },
  {
    name: 'mileage',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('mileage'),
    group: 'history',
    groupHeader: getFiltersTranslationPath('mileage'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    requireDefaults: true,
    filters: [
      {
        header: getFiltersTranslationPath('mileage'),
        headerMobile: getFiltersTranslationPath('mileage'),
        headerFormat: true,
        name: 'listing.mileage',
        filterSelectedHeader: '[selectedMinInput] KM - [selectedMaxInput] KM',
        detailsText: {
          average: getFiltersTranslationPath('mileageAverage'),
        },
        requireCommaFormat: true,
        suffix: 'KM',
        type: 'range_input',
        isNumeric: true,
        selectedMinInput: null,
        selectedMaxInput: null,
        holderMin: 'Min. distance',
        placeholderMax: 'Max. distance',
        selectedCustomLabel: getFiltersTranslationPath('mileageCustomLabel'),
      },
    ],
  },
  {
    name: 'owner',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('pastOwners'),
    group: 'history',
    groupHeader: getFiltersTranslationPath('ownershipHistory'),
    showSubheader: true,
    algoliaFetching: false,
    filters: [
      {
        headerMobile: getFiltersTranslationPath('pastOwners'),
        name: 'inventory.number_of_owners',
        filterSelectedHeader: 'Max [selectedNumber] Owner(s)',
        type: 'number',
        condition: '<=',
        isNumeric: true,
        selectedNumber: null,
        header: getFiltersTranslationPath('pastOwners'),
        headerFormat: true,
        defaultValue: 1,
        selectedCustomLabel: getFiltersTranslationPath('ownerCustomLabel'),
      },
    ],
  },
  {
    name: 'year',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('regYear'),
    headerMobile: getFiltersTranslationPath('registrationYear'),
    group: 'history',
    groupHeader: getFiltersTranslationPath('ownershipHistory'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        header: getFiltersTranslationPath('registrationYear'),
        headerMobile: getFiltersTranslationPath('registrationYear'),
        name: 'inventory.first_registration_year',
        filterSelectedHeader: '[selectedMinInput] - [selectedMaxInput]',
        requireCommaFormat: false,
        type: 'range_input',
        isNumeric: true,
        headerFormat: false,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          min: 1990,
          max: 2022,
          avg: 2018,
          sum: 446102,
        },
        placeholderMin: 'From',
        placeholderMax: 'To',
        selectedCustomLabel: getFiltersTranslationPath('rangeInputCustomLabel'),
      },
    ],
  },
  {
    name: 'manufacture_year',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('manufactureYear'),
    group: 'history',
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        header: getFiltersTranslationPath('manufactureYear'),
        headerMobile: getFiltersTranslationPath('selectYear'),
        name: 'inventory.manufacture_year',
        filterSelectedHeader: '[selectedMinInput] - [selectedMaxInput]',
        requireCommaFormat: false,
        type: 'range_input',
        isNumeric: true,
        headerFormat: false,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          min: 1990,
          max: 2022,
          avg: 2018,
          sum: 446102,
        },
        placeholderMin: 'From',
        placeholderMax: 'To',
        selectedCustomLabel: getFiltersTranslationPath('rangeInputCustomLabel'),
      },
    ],
  },
  {
    name: 'coe_left',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('coeLeft'),
    group: 'history',
    groupHeader: getFiltersTranslationPath('ownershipHistory'),
    showSubheader: true,
    algoliaFetching: false,
    filters: [
      {
        header: getFiltersTranslationPath('coeLeftYear'),
        headerMobile: getFiltersTranslationPath('coeLeftYear'),
        filterSelectedHeader: '[selectedMinInput] - [selectedMaxInput] Year(s) Left',
        filterSelectedHeaderForSameRangeInput: '[selectedMinInput] Year(s) Left',
        isShowOnceIfSameRangeInput: true,
        name: 'listing.months_left',
        type: 'range_input',
        isNumeric: true,
        requireCommaFormat: false,
        valueMultiplier: 12,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: 9,
          min: 1,
          sum: null,
        },
        placeholderMin: 'Min. year(s)',
        placeholderMax: 'Max. year(s)',
        selectedCustomLabel: getFiltersTranslationPath('coeLeftCustomLabel'),
      },
    ],
  },
  {
    name: 'price_range',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('price'),
    group: 'cost_details',
    groupMobile: 'cost_details',
    groupHeader: getFiltersTranslationPath('price'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    hasDivider: true,
    filters: [
      {
        name: 'listing.asking_price',
        type: 'range_input',
        header: getFiltersTranslationPath('budgetPricing'),
        headerMobile: getFiltersTranslationPath('budgetPricing'),
        prefix: '$',
        headerFormat: true,
        requireCommaFormat: true,
        filterSelectedHeader: '$[selectedMinInput] - $[selectedMaxInput]',
        detailsText: {
          average: getFiltersTranslationPath('priceRangeAverage'),
        },
        selectedMinInput: null,
        selectedMaxInput: null,
        placeholderMin: 'Min. price',
        placeholderMax: 'Max. price',
        selectedCustomLabel: getFiltersTranslationPath('priceRangeCustomLabel'),
      },
    ],
  },
  {
    name: 'depreciation',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('depreciation'),
    group: 'depreciation',
    groupMobile: 'cost_details',
    groupHeader: getFiltersTranslationPath('depreciation'),
    groupHeaderMobile: getFiltersTranslationPath('costingDetails'),
    showSubheader: true,
    algoliaFetching: true,
    requireDefaults: true,
    hasDivider: true,
    filters: [
      {
        header: getFiltersTranslationPath('depreciation'),
        headerMobile: getFiltersTranslationPath('depreciation'),
        headerFormat: true,
        name: 'listing.current_depreciation',
        filterSelectedHeader: '$[selectedMinInput] - $[selectedMaxInput]',
        detailsText: {
          average: getFiltersTranslationPath('depreciationAverage'),
        },
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        requireCommaFormat: true,
        prefix: '$',
        type: 'range_input',
        isNumeric: true,
        selectedMinInput: null,
        selectedMaxInput: null,
        placeholderMin: 'Min',
        placeholderMax: 'Max',
        // eslint-disable-next-line no-template-curly-in-string
        selectedCustomLabel: getFiltersTranslationPath('depreciationCustomLabel'),
      },
    ],
  },
  {
    name: 'installment',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('installmentMth'),
    group: 'cost_details',
    groupMobile: 'cost_details',
    groupHeader: getFiltersTranslationPath('costingDetails'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        header: getFiltersTranslationPath('monthlyInstalment'),
        headerMobile: getFiltersTranslationPath('monthlyInstalment'),
        headerFormat: true,
        name: 'listing.installment',
        filterSelectedHeader: '$[selectedMinInput] - $[selectedMaxInput]',
        requireCommaFormat: true,
        detailsText: {
          average: getFiltersTranslationPath('installmentAverage'),
        },
        prefix: '$',
        type: 'range_input',
        isNumeric: true,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          min: 0,
          max: 6219600,
          avg: 31900.4,
          sum: 7305190,
        },
        placeholderMin: 'Min. price',
        placeholderMax: 'Max. price',
        selectedCustomLabel: getFiltersTranslationPath('installmentCustomLabel'),
      },
    ],
  },
  {
    name: 'make',
    active: true,
    activeMobile: true,
    hideInFilters: true,
    header: getFiltersTranslationPath('brand'),
    filters: [
      {
        name: 'inventory.make',
        type: 'button',
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'make_model',
    header: getFiltersTranslationPath('brandModel'),
    active: true,
    activeMobile: true,
    algoliaFetching: false,
    filters: [
      {
        name: 'inventory.make_model',
        filterSelectedHeader: '[label]',
        header: getFiltersTranslationPath('allBrandModel'),
        headerMobile: getFiltersTranslationPath('allBrandModel'),
        type: 'make_model',
        colSize: 12,
        colResponsive: {
          xs: 12,
          sm: 12,
          md: 8,
        },
        selectedOptions: null,
        selectedCustomLabel: getFiltersTranslationPath('makeModelCustomLabel'),
      },
    ],
  },
  {
    name: 'promotions',
    active: true,
    activeMobile: false,
    header: getFiltersTranslationPath('promotions'),
    algoliaFetching: true,
    filters: [
      {
        header: getFiltersTranslationPath('promotions'),
        name: 'promotion_name',
        type: 'button',
        filterSelectedHeader: '[label]',
        isNumeric: false,
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'promotions_button',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('promotions'),
    group: 'exclusives',
    algoliaFetching: true,
    filterSelectedHeader: getFiltersTranslationPath('promotions'),
    isBooleanFilter: true,
    // showOnFacetsAttribute: 'true'
    filters: [
      {
        header: getFiltersTranslationPath('promotions'),
        name: 'is_promo',
        type: 'boolean',
        selectedBoolean: null,
        facets: { true: 0, false: 0 },
      },
    ],
  },
  {
    name: '360view',
    active: true,
    activeMobile: false,
    header: getFiltersTranslationPath('360view'),
    group: 'exclusives',
    groupHeader: getFiltersTranslationPath('carroExclusives'),
    algoliaFetching: false,
    isBooleanFilter: true,
    filterSelectedHeader: getFiltersTranslationPath('360view'),
    joinCondition: 'OR',
    filters: [
      {
        header: getFiltersTranslationPath('360view'),
        name: 'listing.has_360_interior_photos',
        type: 'boolean',
        isNumeric: false,
        selectedBoolean: null,
      },
      {
        header: getFiltersTranslationPath('360view'),
        name: 'listing.has_360_exterior_photos',
        type: 'boolean',
        isNumeric: false,
        selectedBoolean: null,
      },
    ],
  },
  {
    name: 'availability',
    active: true,
    activeMobile: false,
    header: getFiltersTranslationPath('availability'),
    algoliaFetching: false,
    filterSelectedHeader: getFiltersTranslationPath('availability'),
    filters: [
      {
        header: getFiltersTranslationPath('availability'),
        headerMobile: getFiltersTranslationPath('availability'),
        name: 'listing',
        type: 'checkbox',
        filterSelectedHeader: '[label]',
        isNumeric: false,
        isTrueOption: true,
        options: [
          {
            label: 'Available',
            value: 'listing.is_available',
          },
          {
            label: 'Coming Soon',
            value: 'listing.is_coming_soon',
          },
          {
            label: 'Pending Sale',
            value: 'listing.is_pending_sale',
          },
        ],
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'availability_button',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('availability'),
    algoliaFetching: false,
    filters: [
      {
        header: getFiltersTranslationPath('availability'),
        headerMobile: getFiltersTranslationPath('availability'),
        name: 'listing',
        type: 'button',
        filterSelectedHeader: '[label]',
        isShowTagMobile: false,
        colSize: 24,
        colResponsive: {
          xs: 24,
          sm: 12,
          md: 12,
        },
        isTrueOption: true,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'FILTER_AVAILABLE',
          listFields: ['listing.is_available', 'listing.is_coming_soon', 'listing.is_pending_sale'],
        }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'mechanic_video',
    active: true,
    activeMobile: false,
    header: getFiltersTranslationPath('mechanic_video'),
    group: 'exclusives',
    groupHeader: getFiltersTranslationPath('carroExclusives'),
    algoliaFetching: false,
    isBooleanFilter: true,
    filterSelectedHeader: getFiltersTranslationPath('mechanic_video'),
    filters: [
      {
        name: 'listing.has_mechanic_video_notes',
        type: 'boolean',
        isNumeric: false,
        selectedBoolean: null,
      },
    ],
  },

  {
    name: 'tags',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('tags'),
    headerMobile: getFiltersTranslationPath('carroExclusives'),
    group: 'exclusives',
    groupHeader: getFiltersTranslationPath('carroExclusives'),
    groupHeaderMobile: getFiltersTranslationPath('carroExclusives'),
    algoliaFetching: false,
    filters: [
      {
        name: 'listing',
        type: 'button',
        filterSelectedHeader: '[label]',
        isNumeric: false,
        isTrueOption: true,
        header: getFiltersTranslationPath('exclusives'),
        headerMobile: getFiltersTranslationPath('exclusives'),
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'TAGS',
          listFields: [
            'listing.is_ccpo',
            'listing.is_agent_warranty',
            'listing.is_agent_serviced',
            'listing.is_360',
            'listing.mechanic_video',
            'listing.is_cpa',
          ],
        }),
        selectedOptions: null,
        colSize: 12,
      },
    ],
  },
];

export const getInitialFilterConfigs = (customFilterConfigs?: SettingProps[], filterConfigs?: SettingProps[]) => {
  let newUpdatedFilterSettings: SettingProps[] = [...(filterConfigs || DEFAULT_FILTER_SETTINGS)];

  if (!customFilterConfigs || customFilterConfigs.length === 0) {
    // eslint-disable-next-line no-self-assign
    newUpdatedFilterSettings = newUpdatedFilterSettings;
  } else {
    customFilterConfigs.forEach((customConfig: SettingProps) => {
      const foundConfig = newUpdatedFilterSettings.find((f: SettingProps) => f.name === customConfig.name);
      const foundIndex = newUpdatedFilterSettings.findIndex((f: SettingProps) => f.name === customConfig.name);
      if (foundConfig) {
        const newFilters = foundConfig.filters?.map((defaultFilter: FilterProps) => {
          const customFilter = customConfig.filters?.find(
            (f: FilterProps) =>
              [f?.name, f?.customName].includes(defaultFilter.name) ||
              [f?.name, f?.customName].includes('inventory.base_color_key'), // to support HK filter color by inventory.base_color_key
          );

          if (customFilter) {
            return { ...defaultFilter, ...customFilter };
          }

          return defaultFilter;
        });

        const newCustomConfig = { ...customConfig, filters: newFilters };
        newUpdatedFilterSettings[foundIndex] = { ...foundConfig, ...newCustomConfig };
      } else {
        newUpdatedFilterSettings.push(customConfig);
      }
    });
  }

  return newUpdatedFilterSettings;
};

export type FilterConfigProps = {
  initialFilterSettings: SettingProps[];
  hitsPerPage?: number;
  attributesToRetrieve?: string[];
  mobileFilters?: string[];
  otherFilters?: string[];
  desktopFilters?: string[];
};

const FILTER_CONFIGS: Record<string, FilterConfigProps> = {
  sg: { ...FILTER_CONFIGS_SG, initialFilterSettings: getInitialFilterConfigs(FILTER_CONFIGS_SG.initialFilterSettings) },
  th: { ...FILTER_CONFIGS_TH, initialFilterSettings: getInitialFilterConfigs(FILTER_CONFIGS_TH.initialFilterSettings) },
  id: { ...FILTER_CONFIGS_ID, initialFilterSettings: getInitialFilterConfigs(FILTER_CONFIGS_ID.initialFilterSettings) },
  my: { ...FILTER_CONFIGS_MY, initialFilterSettings: getInitialFilterConfigs(FILTER_CONFIGS_MY.initialFilterSettings) },
  jp: { ...FILTER_CONFIGS_JP, initialFilterSettings: getInitialFilterConfigs(FILTER_CONFIGS_JP.initialFilterSettings) },
  hk: { ...FILTER_CONFIGS_HK, initialFilterSettings: getInitialFilterConfigs(FILTER_CONFIGS_HK.initialFilterSettings) },
};

export default FILTER_CONFIGS;
