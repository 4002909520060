/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { getLoginTypesOptionsByCountry, ILoginTypeOption } from '@design-system/components/Login/constants';
import CustomButton from '@source/components/CustomButton/CustomButton';
import useOTPService from '@source/hooks/useOTPService';
import { TRegionKey } from '@source/interface';

const StyledOTPForm = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-size: 14px;

  .mobile-numb {
    display: inline-block;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }
`;

const StyledButton = styled(CustomButton)`
  width: 100%;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  font-weight: 600;
`;

const StyledDesc = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

const StyledNote = styled.div`
  text-align: left;
  margin-top: 8px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-size: 12px;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis}; ;
`;

const StyledListOption = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 14px;
  list-style: none;
  padding: 0;

  .verify-list-item {
    padding: 24px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
    }

    &.selected {
      .checkbox {
        border: 1px solid ${(props) => props.theme.color.linkHighEmphasis};
        &:after {
          display: block;
        }
      }
    }

    .checkbox {
      display: block;
      width: 24px;
      height: 24px;
      border: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
      border-radius: 24px;
      position: relative;

      &:after {
        display: none;
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 13px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${(props) => props.theme.color.linkHighEmphasis};
      }
    }

    .list-item-wrapper {
      display: flex;
      align-items: center;

      .item-text {
        margin-left: 16px;

        .item-text-title {
          ${(props) => props.theme.typo.familyGoogle.bold};
          font-size: 16px;
        }

        .item-text-desc {
          ${(props) => props.theme.typo.familyGoogle.regular};
          font-size: 14px;
          color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
        }
      }
    }
  }
`;

interface ILoginStepAnotherType {
  country: TRegionKey;
  loading?: boolean;
  onFormSubmit: (type: string) => void;
}

const LoginStepAnotherType = ({ country, loading, onFormSubmit }: ILoginStepAnotherType) => {
  const { t } = useTranslation();
  const { otpState } = useOTPService();
  const { type, internationalPhone } = otpState;
  const loginTypesOptions = getLoginTypesOptionsByCountry(country) as ILoginTypeOption[];

  const [currentVerifyType, setCurrentVerifyType] = useState<string>(type || 'sms');
  // const { pushGTMEvent } = useGTMEvent();

  const onHandleSubmitWithAnotherType = () => {
    onFormSubmit(currentVerifyType || 'sms');
  };

  const onHandleChangeType = (value: string) => {
    setCurrentVerifyType(value);
  };

  const renderListVerifyType = () =>
    loginTypesOptions?.map((item) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={`verify-list-item ${item?.value === currentVerifyType ? `selected` : ''}`}
        key={item?.value}
        onClick={() => onHandleChangeType(item?.value)}
      >
        <div className="list-item-wrapper">
          <img src={item?.imgIcon} width={24} height={24} />
          <div className="item-text">
            <div className="item-text-title">{item?.title}</div>
            <div className="item-text-desc">{t(item?.desc)}</div>
          </div>
        </div>
        <div className="checkbox" />
      </div>
    ));

  return (
    <StyledOTPForm>
      <StyledDesc>
        <Trans
          defaults="shared.login.otherOptionsDesc"
          components={{
            span: <span />,
          }}
          values={{
            mobileNumb: internationalPhone,
          }}
        />
      </StyledDesc>
      <StyledNote>{t('shared.login.otherOptionsNote')}</StyledNote>
      <StyledListOption className="verify-list">{renderListVerifyType()}</StyledListOption>
      <div className="step-actions">
        <StyledButton onClick={onHandleSubmitWithAnotherType} loading={loading}>
          {t('shared.login.continue')}
        </StyledButton>
      </div>
    </StyledOTPForm>
  );
};

LoginStepAnotherType.defaultProps = {
  loading: false,
};

export default LoginStepAnotherType;
