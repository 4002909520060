import axios from 'axios';
import qs from 'qs';

import { API_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import { WHOLESALE_AXIOS_USER_AUTH } from './UserProfile';

export default {
  getWorkShopServices: (payload: Record<string, any>) => {
    const queryString = qs.stringify(payload);
    const dataQueryPath = API_URL().dataQuery;
    return axios.get(`${dataQueryPath}/workshop_services?${queryString}`);
  },
  getCarroExclusiveFilters: (country: string, payload?: Record<string, any>) => {
    const queryString = qs.stringify(payload);
    const dataQueryPath = API_URL().dataQuery;
    return axios.get(`${dataQueryPath}/${country}/filters/carro_exclusives?${queryString}`);
  },
  getWorkShopLocations: (payload?: Record<string, any>) => {
    const dataQueryPath = API_URL().dataQuery;
    return axios.get(`${dataQueryPath}/locations`, { params: payload });
  },
  createWorkshopTicket: (country: TRegionKey, payload: Record<string, unknown>, isAuthenticated?: boolean) => {
    const dataQueryPath = API_URL(country).workshopTickets;
    if (isAuthenticated) {
      return WHOLESALE_AXIOS_USER_AUTH.post(`${dataQueryPath}`, payload);
    }
    return axios.post(`${dataQueryPath}`, payload);
  },
};
