import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL } from '@source/constants/api';
import type { AppState } from '@source/redux/index';

const sliceName = 'modal';

interface LineSliceProps {
  isOpenLocationModal: boolean;
}

const INITIAL_STATE: LineSliceProps = {
  isOpenLocationModal: false,
};

export const fetchLineQRCodedDetails = () => {};

export const modalSlice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  // reducers: {},
  reducers: {
    // onShowHidePopups: (state = INITIAL_STATE, popup) => {
    //   state = {
    //     ...state,
    //     isOpenLocationModal: true,
    //   };
    // },
    onOpen: (state = INITIAL_STATE) => {
      const newState = {
        ...state,
        isOpenLocationModal: true,
      };
      return newState;
    },
    onClose: (state = INITIAL_STATE) => {
      const newState = {
        ...state,
        isOpenLocationModal: false,
      };
      return newState;
    },
  },
});

export const getModalConfig = (state: AppState) => state[sliceName];
export const { onOpen, onClose } = modalSlice.actions;
export default modalSlice.reducer;
