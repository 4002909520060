import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import { SliderNextArrow, SliderPrevArrow } from '@design-system/components/Slider/components/SliderArrow';
import { ShowAllButton } from '@design-system/components/Slider/components/Action';
import 'react-image-gallery/styles/css/image-gallery.css';

import ImageGallery from 'react-image-gallery';

const StyledWrapper = styled.div``;
const StyledContainer = styled.div`
  // dots position
  &.dots-position-left {
    .image-gallery-bullets-container {
      text-align: left;
    }
  }
  // dots container
  .image-gallery-bullets {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  // active dot
  .image-gallery-bullet.active {
    background-color: ${(props) => props.theme.color.primaryBase};
    border-radius: 20px;
    width: 24px;
    height: 8px;
    border: ${(props) => props.theme.color.primaryBase};
    transform: none;
  }
  .image-gallery-bullet {
    background-color: ${(props) => props.theme.color.background};
    width: 8px;
    height: 8px;
    border: ${(props) => props.theme.color.background};
    box-shadow: none;
  }
  // thumbnail
  .image-gallery-thumbnails-wrapper {
    margin-top: 11px;
  }

  .image-gallery-thumbnail {
    width: 120px;
    height: 70px;
  }
  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .image-gallery-thumbnail-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // unset-max-height
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: initial;
    height: auto;
  }
  .image-gallery-slides {
    &:hover {
      cursor: pointer;
    }
  }
`;

const StyledActionWrapper = styled.div``;

export interface SliderProps {
  items: any;
  lazyLoad?: boolean;
  showPlayButton?: boolean;
  showBullets?: boolean;
  showThumbnails?: boolean;
  showFullscreenButton?: boolean;
  showNav?: boolean;
  autoPlay?: boolean;
  slideDuration?: number;
  showAll?: boolean;
  dotsPosition?: string;
  externalContent?: ReactNode;
  onSlide?: any;
  onClickImg?: any;
  onImageLoad?: any;
  externalDots?: ReactNode;
}

const Slider: FC<SliderProps> = ({
  items,
  lazyLoad,
  showPlayButton,
  showBullets,
  showThumbnails,
  showFullscreenButton,
  showNav,
  autoPlay,
  slideDuration,
  showAll,
  dotsPosition,
  externalContent,
  onSlide,
  onClickImg,
  onImageLoad,
  externalDots,
}) => {
  const onShowAll = () => {};
  const onSlideItem = (slideIndex: number) => {
    if (onSlide && typeof onSlide === 'function') {
      onSlide(slideIndex);
    }
  };
  const onClickItem = (event: any) => {
    const isImage = event?.target.classList.contains('image-gallery-image');

    if (isImage && onClickImg && typeof onClickImg === 'function') {
      onClickImg();
    }
  };
  return (
    <StyledWrapper className="GalleyWrapper">
      <StyledContainer className={`GalleryContainer dots-position-${dotsPosition}`}>
        <ImageGallery
          items={items}
          lazyLoad={lazyLoad}
          showPlayButton={showPlayButton}
          showBullets={showBullets}
          showThumbnails={showThumbnails}
          showFullscreenButton={showFullscreenButton}
          showNav={showNav}
          autoPlay={autoPlay}
          slideDuration={slideDuration}
          renderLeftNav={(onClick, disabled) => (
            <SliderPrevArrow onClick={onClick} disabled={disabled} showNav={showNav} />
          )}
          renderRightNav={(onClick, disabled) => (
            <SliderNextArrow onClick={onClick} disabled={disabled} showNav={showNav} />
          )}
          onSlide={onSlideItem}
          onClick={onClickItem}
          onImageLoad={onImageLoad}
        />
        {!!externalDots && externalDots}
        <StyledActionWrapper>{showAll && <ShowAllButton onClick={onShowAll} />}</StyledActionWrapper>
      </StyledContainer>
      {/* External content */}
      {externalContent && externalContent}
    </StyledWrapper>
  );
};

Slider.defaultProps = {
  lazyLoad: true,
  showPlayButton: false,
  showBullets: true,
  showThumbnails: false,
  showFullscreenButton: true,
  showNav: true,
  autoPlay: false,
  slideDuration: 450,
  showAll: false,
  dotsPosition: 'center',
  externalContent: null,
  onSlide: null,
  onClickImg: null,
  onImageLoad: null,
  externalDots: null,
};
export default Slider;
