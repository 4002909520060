import { TRegionKey } from '@source/interface';
import {
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY as RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_RUDDER_STACK_DATA_PLAN_URL as RUDDER_STACK_DATA_PLAN_URL,
} from '@source/constants/env';

interface TRegionScript {
  gtm: {
    gtmId: string | undefined;
    gtmAuth: string | undefined;
    gtmPreview: string | undefined;
  };
  rudderStackWriteKey: string | undefined;
  hotJarId: string | undefined;
  googleSiteVerificationKey: string | undefined;
  fbDomainVerificationKey: string | undefined;
  gtag?: {
    gtagId: string | undefined;
  };
}

const SCRIPT: Record<TRegionKey, TRegionScript> = {
  sg: {
    gtm: {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_SG,
      gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH_SG,
      gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW_SG,
    },
    rudderStackWriteKey: process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY_SG,
    hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID_SG,
    googleSiteVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_SG,
    fbDomainVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_SG,
    gtag: {
      gtagId: process.env.NEXT_PUBLIC_GTAG_ID_SG,
    },
  },
  id: {
    gtm: {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_ID,
      gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH_ID,
      gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW_ID,
    },
    rudderStackWriteKey: process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY_ID,
    hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID_ID,
    googleSiteVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_ID,
    fbDomainVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_ID,
    gtag: {
      gtagId: process.env.NEXT_PUBLIC_GTAG_ID_ID,
    },
  },
  th: {
    gtm: {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_TH,
      gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH_TH,
      gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW_TH,
    },
    rudderStackWriteKey: process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY_TH,
    hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID_TH,
    googleSiteVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_TH,
    fbDomainVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_TH,
    gtag: {
      gtagId: process.env.NEXT_PUBLIC_GTAG_ID_TH,
    },
  },
  my: {
    gtm: {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_MY,
      gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH_MY,
      gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW_MY,
    },
    rudderStackWriteKey: process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY_MY,
    hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID_MY,
    googleSiteVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_MY,
    fbDomainVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_MY,
    gtag: {
      gtagId: process.env.NEXT_PUBLIC_GTAG_ID_MY,
    },
  },
  jp: {
    gtm: {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_JP,
      gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH_JP,
      gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW_JP,
    },
    rudderStackWriteKey: process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY_TW,
    hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID_TW,
    googleSiteVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_TW,
    fbDomainVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_TW,
    gtag: {
      gtagId: process.env.NEXT_PUBLIC_GTAG_ID_JP,
    },
  },
  // hk updated following CRB-51
  hk: {
    gtm: {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_HK,
      gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH_HK,
      gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW_HK,
    },
    rudderStackWriteKey: process.env.NEXT_PUBLIC_RUDDER_STACK_WRITE_KEY_TW,
    hotJarId: process.env.NEXT_PUBLIC_HOTJAR_ID_TW,
    googleSiteVerificationKey: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_HK,
    fbDomainVerificationKey: process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_TW,
    gtag: {
      gtagId: process.env.NEXT_PUBLIC_GTAG_ID_HK,
    },
  },
};

export { SCRIPT, RECAPTCHA_SITE_KEY, RUDDER_STACK_DATA_PLAN_URL };
