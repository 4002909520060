import { BLOG_URL } from '@source/constants/config';

const ANNOUNCEMENT_CONTENT = {
  my: {
    href: 'https://carro.co/my/blog/mytukar-renames-carro',
    mainText: 'home.temporaryBanner.my.mainText',
    subText: 'home.temporaryBanner.my.subText',
    isShowTemporaryBanner: true,
  },
  hk: {
    href: `${BLOG_URL.hk}/beyond-cars-更名為-carro`,
    mainText: 'home.temporaryBanner.hk.mainText',
    subText: 'home.temporaryBanner.hk.subText',
    isShowTemporaryBanner: true,
  },
};

export default ANNOUNCEMENT_CONTENT;
