import React, { FC } from 'react';
import styled from 'styled-components';
import PrimaryMobileNav from '@source/components/TopNavigation/MobileNavigation/PrimaryMobileNav';
import SecondMobileNav from '@source/components/TopNavigation/MobileNavigation/SecondMobileNav';
import { TRegionKey } from '@source/interface';

const StyledMobileTopNavWrapper = styled.div``;
export interface MobileNavbarProps {
  showDrawer: () => void;
  isSecondaryHeaderMobile?: boolean;
  onSecondaryHeaderMobileBack?: () => void;
  countryCode: TRegionKey;
}

const MobileNavigation: FC<MobileNavbarProps> = ({
  showDrawer,
  isSecondaryHeaderMobile,
  onSecondaryHeaderMobileBack,
  countryCode,
}) => (
  <StyledMobileTopNavWrapper className="MobileTopNav">
    {isSecondaryHeaderMobile ? (
      <SecondMobileNav
        showDrawer={showDrawer}
        countryCode={countryCode}
        onSecondaryHeaderMobileBack={onSecondaryHeaderMobileBack}
      />
    ) : (
      <PrimaryMobileNav showDrawer={showDrawer} />
    )}
  </StyledMobileTopNavWrapper>
);

MobileNavigation.defaultProps = {
  isSecondaryHeaderMobile: false,
};
export default MobileNavigation;
