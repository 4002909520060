import axios from 'axios';

import { API_URL, BASE_CONTACT_URL, WHOLESALE_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import { getAxiosInstance, getAccessToken } from '@source/utils/utils';

export default {
  requestOTP: ({ country, payload }: { country: TRegionKey; payload: object }) => {
    const { requestOTP } = API_URL(country);
    return axios.post(requestOTP, payload);
  },

  verifyOTP: ({ country, payload }: { country: TRegionKey; payload: object }) => {
    const { verifyOTP } = API_URL(country);
    return axios.post(verifyOTP, payload);
  },

  requestOTT: () => {
    const authAxios = getAxiosInstance(`${WHOLESALE_URL}/api/v1`, getAccessToken);
    return authAxios.post(`${BASE_CONTACT_URL}/ott`);
  },
  loginByOTT: ({ payload }: { payload: object }) => {
    const { loginByOTT } = API_URL();
    const ottData = axios.post(loginByOTT, payload);
    return ottData;
  },

  verifyOTT: ({ payload }: { payload: object }) => {
    const { verifyOTT } = API_URL();
    const ottData = axios.post(verifyOTT, payload);
    return ottData;
  },
};
