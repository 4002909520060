/* eslint-disable import/prefer-default-export */
import { ENV_URL } from '@source/constants/common';
import { TLanguageKey, TRegionKey } from '@source/interface';
import { URL_CONFIG } from '@source/constants/urlConfig';

import {
  ID_MORE_DROPDOWN_MENU,
  MORE_DROPDOWN_MENU,
  MY_MORE_DROPDOWN_MENU,
  TH_MORE_DROPDOWN_MENU,
  SELL_MENU,
  JP_MORE_DROPDOWN_MENU,
  HK_MORE_DROPDOWN_MENU,
  BUY_MENU,
} from '@source/components/TopNavigation/constants';
import { NEXT_PUBLIC_DEALER_PORTAL_URL_MY, NEXT_PUBLIC_WEB_CR } from '@source/constants/env';

const sideNavigationConfig = (region: TRegionKey, lang: TLanguageKey) => ({
  sg: [
    {
      titleTranslate: 'home.topNavigation.account.login',
      key: 'login',
      hasBackground: true,
    },
    {
      titleTranslate: 'home.sideNavigation.buyCar',
      key: 'buy',
      url: URL_CONFIG({ region, lang }).buy,
      // Items that need the submenu dropdown can use with structure as below children:[]
      // children: [...BUY_DROPDOWN_MENU.SG],
    },
    {
      titleTranslate: 'home.sideNavigation.buyNew',
      key: 'buyNew',
      url: URL_CONFIG({ region, lang }).buyNew,
    },
    {
      ...SELL_MENU(region, lang),
    },
    {
      titleTranslate: 'home.sideNavigation.lease',
      key: 'lease',
      url: `${ENV_URL.sg}/leap`,
    },
    {
      titleTranslate: 'home.sideNavigation.certified',
      key: 'certified',
      url: URL_CONFIG({ region: 'sg', lang, path: 'carro-certified' }).formatted,
    },
    ...MORE_DROPDOWN_MENU(lang),
  ],
  my: [
    {
      titleTranslate: 'home.topNavigation.account.dealer',
      key: 'dealer',
      hasBackground: true,
      url: `${NEXT_PUBLIC_DEALER_PORTAL_URL_MY}/login`,
    },
    {
      titleTranslate: 'home.topNavigation.account.customer',
      key: 'customer',
      hasBackground: true,
      showLoginModal: true,
    },
    {
      titleTranslate: 'home.topNavigation.account.seller',
      key: 'seller',
      hasBackground: true,
      showLoginModal: true,
      redirectTo: `${NEXT_PUBLIC_WEB_CR}/my/en/seller/home`,
    },
    {
      titleTranslate: 'home.sideNavigation.buyCar',
      key: 'buy',
      url: URL_CONFIG({ region, lang }).buy,
    },
    {
      ...SELL_MENU(region, lang),
    },
    {
      titleTranslate: 'home.sideNavigation.workshop',
      key: 'workshop',
      url: URL_CONFIG({ region: 'my', lang, path: 'workshop' }).formatted,
    },
    ...MY_MORE_DROPDOWN_MENU(lang),
  ],
  id: [
    {
      titleTranslate: 'home.topNavigation.account.login',
      key: 'login',
      hasBackground: true,
    },
    {
      titleTranslate: 'home.sideNavigation.buyCar',
      key: 'buy',
      url: URL_CONFIG({ region, lang }).buy,
    },
    {
      ...SELL_MENU(region, lang),
    },
    {
      titleTranslate: 'home.sideNavigation.certified',
      key: 'certified',
      url: URL_CONFIG({ region: 'id', lang, path: 'carro-certified' }).formatted,
    },
    ...ID_MORE_DROPDOWN_MENU(lang),
  ],
  th: [
    {
      titleTranslate: 'home.topNavigation.account.login',
      key: 'login',
      hasBackground: true,
    },
    {
      titleTranslate: 'home.sideNavigation.buyCar',
      key: 'buy',
      url: URL_CONFIG({ region, lang }).buy,
    },
    {
      ...SELL_MENU(region, lang),
    },
    {
      titleTranslate: 'home.sideNavigation.certified',
      key: 'certified',
      url: URL_CONFIG({ region: 'th', lang, path: 'carro-certified' }).formatted,
    },
    ...TH_MORE_DROPDOWN_MENU(lang),
  ],
  jp: [
    // todo: update jp header
    {
      titleTranslate: 'home.topNavigation.account.login',
      key: 'login',
      hasBackground: true,
    },
    {
      titleTranslate: 'home.sideNavigation.buyCar',
      key: 'buy',
      url: URL_CONFIG({ region, lang }).buy,
    },
    {
      ...SELL_MENU(region, lang),
    },
    {
      titleTranslate: 'home.sideNavigation.leasing',
      key: 'leasing',
      url: 'https://carrojapan.com',
      isExternal: true,
    },
    {
      titleTranslate: 'home.sideNavigation.certified',
      key: 'certified',
      url: URL_CONFIG({ region: 'jp', lang, path: 'carro-certified' }).formatted,
    },
    ...JP_MORE_DROPDOWN_MENU(lang),
  ],
  hk: [
    {
      titleTranslate: 'home.topNavigation.account.login',
      key: 'login',
      hasBackground: true,
    },
    { ...BUY_MENU({ region, lang }) },
    {
      ...SELL_MENU(region, lang),
    },
    {
      titleTranslate: 'home.sideNavigation.certified',
      key: 'certified',
      url: URL_CONFIG({ region: 'hk', lang, path: 'carro-certified' }).formatted,
    },
    {
      titleTranslate: 'home.topNavigation.finance',
      key: 'finance',
      url: URL_CONFIG({ region: 'hk', lang }).finance,
      activeLink: '/finance',
    },
    ...HK_MORE_DROPDOWN_MENU(lang),
  ],
});
export default sideNavigationConfig;
