import { rest } from 'msw';

export const title = 'THIS IS MOCKED';

export const data = {
  hits: [
    {
      id: 9307,
      title: title,
      objectTH: 'EQMVRD',
      updated_at: '2023-04-07T01:00:56.000000Z',
      listed_at: '2023-04-06 05:58:11',
      promo_label: 'promonew',
      is_promo: true,
      created_at: '2023-02-24 04:36:29',
      created_at_timestamp: 1677213389,
      inventory: {
        id: 345376,
        make: 'Honda',
        model: 'ACCORD',
        make_id: 29,
        model_id: 60,
        make_model: 'Honda ACCORD',
        submodel: '2.0 EL',
        car_type: 'hatchback',
        transmission: 'automatic',
        fuel_type: 'petrol',
        license_plate_number: null,
        manufacture_year: 2018,
        color: 'Red',
        seats: 5,
        gas_type: 'none',
        engine_capacity: 1300,
        mileage: 50320,
        number_of_owners: '0',
        year_of_manufacture: 2018,
        make_th: 'Honda',
        make_model_th: 'Honda ACCORD',
        model_th: 'ACCORD',
        submodel_th: '2.0 EL',
        city: { id: 539, name: 'krabi', display_name: 'กระบี่' },
      },
      listing: {
        seller_id: '',
        slug: 'EQMVRD',
        title: title,
        installment: null,
        current_depreciation: null,
        months_left: null,
        downpayment: null,
        paper_value: null,
        scrap_value: null,
        mileage: 50320,
        asking_price: 300000000,
        promotional_price: 300000000,
        original_price: 0,
        availability: 'sold',
        allow_chat: true,
        description: null,
        photos: [
          'https://s3-ap-southeast-1.amazonaws.com/carro.co/development/2023/02/Inventory/283947/-maytestpostman--honda-jazz-2018-EQMVRD-carro-001.jpg',
        ],
        thumbnail: null,
        body_type: null,
        inspection_summary: null,
        has_360_exterior_photos: false,
        has_360_interior_photos: false,
        flags: {
          is_trending: false,
          is_good_deal: false,
          is_carro_owned: true,
          is_urgent_sale: false,
          is_no_downpayment: false,
          is_home_car: false,
          is_finance: false,
          is_carro_assure: false,
          is_carro_assure_plus: false,
          is_featured_car: true,
          is_new: false,
          is_pending_sale: false,
          is_sold: false,
          is_ccpo: true,
          is_coming_soon: false,
          is_available: true,
          is_new_arrival: true,
          has_mechanic_video_notes: false,
        },
        status: { id: 23, name: 'listings-draft', display_name: 'Draft' },
      },
      group: {
        id: 2,
        name: 'carro',
        display_name: 'Trusty Cars Ltd',
        address: 'Sin Ming Lane, Singapore',
        location_text: '26 Sin Ming Lane #01-111 Midview City Singapore 573971',
        postal_code: '573971',
        company_registration_number: '201525411C',
        published: false,
        email: 'help@carro.co',
        contact_number: '67146652',
        is_internal: 0,
      },
      location: {
        id: 105,
        name: 'don-mueang',
        display_name: 'Don Mueang',
        is_on_site: 1,
        address: 'Thailand street 00483',
        can_car_in: 0,
      },
      objectID: '9307',
      _highlightResult: {
        inventory: {
          make: { value: 'Honda', matchLevel: 'none', matchedWords: [] },
          model: { value: 'ACCORD', matchLevel: 'none', matchedWords: [] },
          make_model: { value: 'Honda ACCORD', matchLevel: 'none', matchedWords: [] },
        },
        listing: {
          slug: { value: 'EQMVRD', matchLevel: 'none', matchedWords: [] },
          title: { value: '[MayTestPostman] Honda Jazz 2018', matchLevel: 'none', matchedWords: [] },
        },
      },
    },
  ],
  nbHits: 246,
  page: 0,
  nbPages: 11,
  hitsPerPage: 24,
  facets: {
    is_promo: { false: 228, true: 18 },
    promo_label: { 'Value Saver': 4, promonew: 1 },
    'location.name': { 'don-mueang': 242, 'suanluang-rama-9-/-srinakarin': 1, 'th-head-office': 1 },
    'inventory.make': {
      Honda: 91,
      TOYOTA: 50,
      MAZDA: 31,
      NISSAN: 19,
      MITSUBISHI: 16,
      SUZUKI: 13,
      'MERCEDES-BENZ': 10,
      ISUZU: 6,
      BMW: 5,
      FORD: 3,
      HYUNDAI: 1,
      VOLKSWAGEN: 1,
    },
    'promotion.name': { 'Feb 2023 Api': 8, 'WW Testing Stg': 5 },
    'inventory.color': {
      เทา: 64,
      ขาว: 56,
      Red: 45,
      ดำ: 42,
      แดง: 14,
      น้ำตาล: 9,
      น้ำเงิน: 3,
      ฟ้า: 3,
      ส้ม: 3,
      Black: 2,
      'เทา-ดำ': 2,
      silver: 1,
      'ขาว-ดำ': 1,
      เขียว: 1,
    },
    'inventory.model': {
      ACCORD: 47,
      '2': 19,
      JAZZ: 15,
      VIOS: 15,
      CIVIC: 11,
      CAMRY: 7,
      CIAZ: 7,
      MIRAGE: 7,
      YARIS: 7,
      ALMERA: 6,
      CITY: 6,
      'HILUX REVO': 6,
      '3': 5,
      MARCH: 5,
      TRITON: 5,
      ATTRAGE: 4,
      CRV: 4,
      FORTUNER: 4,
      'HILUX VIGO': 4,
      SWIFT: 4,
      BRIO: 3,
      'BT-50': 3,
      'BT-50 PRO': 2,
      CELERIO: 2,
      'D-MAX': 2,
      E300: 2,
      E350E: 2,
      EVEREST: 2,
      MOBILIO: 2,
      'MU-X': 2,
      NAVARA: 2,
      'X-TRAIL': 2,
      'YARIS ATIV': 2,
      '118I': 1,
      '320D': 1,
      '520D': 1,
      BRV: 1,
      'C-HR': 1,
      C350E: 1,
      CARAVELLE: 1,
      CHR: 1,
      CLA250: 1,
      'CLS-CLASS': 1,
      COROLLA: 1,
      'CR-V': 1,
      'CX-3': 1,
      'CX-5': 1,
      'D-MAX ALL NEW BLUE POWER': 1,
      'D-MAX HI-LANDER': 1,
      ECOSPORT: 1,
      GLA200: 1,
      GLA250: 1,
      GLC250D: 1,
      'H-1': 1,
      HRV: 1,
      INNOVA: 1,
      NOTE: 1,
      'NP300 NAVARA': 1,
      'SERIES 5': 1,
      SIENTA: 1,
      SYLPHY: 1,
      TEANA: 1,
      X3: 1,
    },
    'inventory.mileage': {
      '50320': 44,
      '150000': 2,
      '100671': 1,
      '100745': 1,
      '101163': 1,
      '101478': 1,
      '102147': 1,
      '102345': 1,
      '104707': 1,
      '104783': 1,
      '106412': 1,
      '106539': 1,
      '107268': 1,
      '107789': 1,
      '108990': 1,
      '111366': 1,
      '111629': 1,
      '112088': 1,
      '112224': 1,
      '112292': 1,
      '114668': 1,
      '115000': 1,
      '115285': 1,
      '115975': 1,
      '116221': 1,
      '116899': 1,
      '117362': 1,
      '117558': 1,
      '120291': 1,
      '120847': 1,
      '121084': 1,
      '122485': 1,
      '122494': 1,
      '122758': 1,
      '123296': 1,
      '123770': 1,
      '125329': 1,
      '12615': 1,
      '126494': 1,
      '127294': 1,
      '129569': 1,
      '129936': 1,
      '131326': 1,
      '131896': 1,
      '133554': 1,
      '135376': 1,
      '137408': 1,
      '141195': 1,
      '143588': 1,
      '144782': 1,
      '14629': 1,
      '146536': 1,
      '147188': 1,
      '150808': 1,
      '151535': 1,
      '152788': 1,
      '157362': 1,
      '159307': 1,
      '161834': 1,
      '16196': 1,
      '162786': 1,
      '163058': 1,
      '163501': 1,
      '164222': 1,
      '165257': 1,
      '165736': 1,
      '165885': 1,
      '175700': 1,
      '175715': 1,
      '17576': 1,
      '178451': 1,
      '178641': 1,
      '178665': 1,
      '180383': 1,
      '181866': 1,
      '182465': 1,
      '18279': 1,
      '189926': 1,
      '190081': 1,
      '196046': 1,
      '198393': 1,
      '199233': 1,
      '20650': 1,
      '21030': 1,
      '21481': 1,
      '24016': 1,
      '25402': 1,
      '26812': 1,
      '285022': 1,
      '28633': 1,
      '29143': 1,
      '30462': 1,
      '31557': 1,
      '31627': 1,
      '31983': 1,
      '32703': 1,
      '33114': 1,
      '33374': 1,
      '33691': 1,
      '33974': 1,
    },
    'inventory.fuel_type': { petrol: 189, diesel: 46, 'petrol-electric': 10, electric: 1 },
    'inventory.make_model': {
      'Honda ACCORD': 47,
      'MAZDA 2': 19,
      'Honda JAZZ': 15,
      'TOYOTA VIOS': 15,
      'HONDA CIVIC': 11,
      'MITSUBISHI MIRAGE': 7,
      'SUZUKI CIAZ': 7,
      'TOYOTA CAMRY': 7,
      'TOYOTA YARIS': 7,
      'HONDA CITY': 6,
      'NISSAN ALMERA': 6,
      'TOYOTA HILUX REVO': 6,
      'MAZDA 3': 5,
      'MITSUBISHI TRITON': 5,
      'NISSAN MARCH': 5,
      'HONDA CRV': 4,
      'MITSUBISHI ATTRAGE': 4,
      'SUZUKI SWIFT': 4,
      'TOYOTA FORTUNER': 4,
      'TOYOTA HILUX VIGO': 4,
      'HONDA BRIO': 3,
      'MAZDA BT-50': 3,
      'FORD EVEREST': 2,
      'HONDA MOBILIO': 2,
      'ISUZU D-MAX': 2,
      'ISUZU MU-X': 2,
      'MAZDA BT-50 PRO': 2,
      'MERCEDES-BENZ E300': 2,
      'MERCEDES-BENZ E350E': 2,
      'NISSAN NAVARA': 2,
      'NISSAN X-TRAIL': 2,
      'SUZUKI CELERIO': 2,
      'TOYOTA YARIS ATIV': 2,
      'BMW 118I': 1,
      'BMW 320D': 1,
      'BMW 520D': 1,
      'BMW SERIES 5': 1,
      'BMW X3': 1,
      'FORD ECOSPORT': 1,
      'HONDA BRV': 1,
      'HONDA CR-V': 1,
      'HONDA HRV': 1,
      'HYUNDAI H-1': 1,
      'ISUZU D-MAX ALL NEW BLUE POWER': 1,
      'ISUZU D-MAX HI-LANDER': 1,
      'MAZDA CX-3': 1,
      'MAZDA CX-5': 1,
      'MERCEDES-BENZ C350E': 1,
      'MERCEDES-BENZ CLA250': 1,
      'MERCEDES-BENZ CLS-CLASS': 1,
      'MERCEDES-BENZ GLA200': 1,
      'MERCEDES-BENZ GLA250': 1,
      'MERCEDES-BENZ GLC250D': 1,
      'NISSAN NOTE': 1,
      'NISSAN NP300 NAVARA': 1,
      'NISSAN SYLPHY': 1,
      'NISSAN TEANA': 1,
      'TOYOTA CHR': 1,
      'TOYOTA COROLLA': 1,
      'TOYOTA INNOVA': 1,
      'TOYOTA SIENTA': 1,
      'Toyota C-HR': 1,
      'VOLKSWAGEN CARAVELLE': 1,
    },
    'listing.asking_price': {
      '300000000': 43,
      '419000': 14,
      '409000': 9,
      '319000': 8,
      '379000': 7,
      '519000': 7,
      '309000': 6,
      '399000': 6,
      '489000': 6,
      '499000': 6,
      '329000': 5,
      '339000': 5,
      '389000': 5,
      '429000': 5,
      '449000': 5,
      '249000': 4,
      '289000': 4,
      '299000': 4,
      '349000': 4,
      '539000': 4,
      '369000': 3,
      '459000': 3,
      '469000': 3,
      '479000': 3,
      '529000': 3,
      '549000': 3,
      '559000': 3,
      '649000': 3,
      '699000': 3,
      '759000': 3,
      '799000': 3,
      '1029000': 2,
      '1189000': 2,
      '359000': 2,
      '5000000': 2,
      '589000': 2,
      '709000': 2,
      '769000': 2,
      '819000': 2,
      '829000': 2,
      '889000': 2,
      '1089000': 1,
      '1169000': 1,
      '138889': 1,
      '1499000': 1,
      '1629000': 1,
      '1709000': 1,
      '1729000': 1,
      '1809000': 1,
      '1859000': 1,
      '1892228': 1,
      '1929000': 1,
      '1999000': 1,
      '2299000': 1,
      '239000': 1,
      '2390000': 1,
      '259000': 1,
      '269000': 1,
      '279000': 1,
      '3090000': 1,
      '314000': 1,
      '325000': 1,
      '394000': 1,
      '569000': 1,
      '619000': 1,
      '629000': 1,
      '689000': 1,
      '729000': 1,
      '739000': 1,
      '779000': 1,
      '789000': 1,
      '859000': 1,
      '9000000': 1,
      '919000': 1,
      '939000': 1,
      '959000': 1,
      '969000': 1,
    },
    'listing.flags.is_new': { false: 246 },
    'listing.flags.is_ccpo': { true: 233, false: 13 },
    'listing.flags.is_sold': { false: 243, true: 3 },
    'listing.flags.is_finance': { true: 194, false: 52 },
    'listing.flags.is_home_car': { false: 246 },
    'listing.flags.is_trending': { false: 246 },
    'listing.flags.is_available': { true: 237, false: 9 },
    'listing.flags.is_good_deal': { false: 243, true: 3 },
    'listing.flags.is_carro_owned': { true: 246 },
    'listing.flags.is_coming_soon': { false: 242, true: 4 },
    'listing.flags.is_new_arrival': { false: 218, true: 28 },
    'listing.flags.is_urgent_sale': { false: 246 },
    'listing.flags.is_carro_assure': { false: 246 },
    'listing.flags.is_featured_car': { false: 200, true: 46 },
    'listing.flags.is_pending_sale': { false: 243, true: 3 },
    'listing.flags.is_no_downpayment': { false: 246 },
    'listing.flags.is_carro_assure_plus': { false: 246 },
    'listing.flags.has_mechanic_video_notes': { false: 246 },
  },
  facets_stats: {
    'inventory.model': { min: 0, max: 3, avg: 2.03846, sum: 53 },
    'inventory.mileage': { min: 500, max: 502203, avg: 85522, sum: 21038477 },
    'listing.asking_price': { min: 138889, max: 300000000, avg: 52991671, sum: 13035951117 },
  },
  exhaustiveFacetsCount: true,
  exhaustiveNbHits: true,
  exhaustiveTypo: true,
  exhaustive: { facetsCount: true, nbHits: true, typo: true },
  query: '',
  params: 'page=0&facets=%5B%22*%22%5D&hitsPerPage=24&attributesToRetrieve=%5B%22*%22%5D',
  processingTimeMS: 3,
  processingTimingsMS: {
    afterFetch: { format: { total: 1 }, total: 2 },
    getIdx: { load: { total: 1 }, total: 1 },
    request: { roundTrip: 39 },
    total: 3,
  },
  serverTimeMS: 3,
};

const ENDPOINT = 'https://*.algolia.net/*/indexes/*/query';

const handler = rest.all(ENDPOINT, (req, res, ctx) => {
  return res(ctx.json(data));
});

export default handler;
