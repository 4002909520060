import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import useGTMEvent from '@source/hooks/useGTMEvent';

import { TRegionKey } from '@source/interface';
import { getStaticCDN } from '@design-system/utils/utils';
import { useWhatsappPrefillMessage } from '@source/utils/useSocialPrefillMessage';

interface WhatsappButtonProps {
  countryCode: TRegionKey;
  whatsappNumber: string;
}

const StyledContentElement = styled.div`
  width: calc(100% - 50px);
`;

const StyledImgContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
`;

const WhatsappButton: React.FC<WhatsappButtonProps> = ({ countryCode, whatsappNumber }) => {
  const { t } = useTranslation();
  const { pushGTMEvent } = useGTMEvent();

  const { whatsappLink } = useWhatsappPrefillMessage({ country: countryCode, whatsappNumber });

  const handleClick = async () => {
    pushGTMEvent({
      event: 'click_whatsapp',
    });
  };

  return (
    <a href={whatsappLink} target="_blank" rel="noreferrer" onClick={handleClick}>
      <StyledImgContainer className="img-container">
        <Image src={getStaticCDN('/static/img/whatsapp.svg')} alt="whatsapp" layout="fill" objectFit="contain" />
      </StyledImgContainer>
      <StyledContentElement className="content-element">
        <div>
          {t('home.whatsapp.line1')} <br /> {t('home.whatsapp.line2')}
        </div>
      </StyledContentElement>
    </a>
  );
};

export default WhatsappButton;
