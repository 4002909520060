import { combineReducers } from '@reduxjs/toolkit';

import browseReducer from '@source/redux/slice/browseSlice';
import sellReducer from '@source/redux/slice/sellSlice';
import bookingInspectionReducer from '@source/redux/slice/bookingInspectionSlice';
import appointmentReducer from '@source/redux/slice/appointmentSlice';
import configOptionsReducer from '@source/redux/slice/configOptionsSlice';
import userProfileReducer from '@source/redux/slice/userProfile';
import otpReducer from '@source/redux/slice/otp';
import viewingHistoryReducer from '@source/redux/slice/viewingHistorySlice';
import searchFilterHistoryReducer from '@source/redux/slice/searchFilterHistorySlice';
import workshopSlice from '@source/redux/slice//workshopSlice';
import paymentSlice from '@source/redux/slice//paymentSlice';

import { listingSlice } from '@source/redux/slice/listingSlice';
import { lineChatSlice } from '@source/redux/slice/lineChatSlice';
import { modalSlice } from '@source/redux/slice/modalSlice';

const reducer = combineReducers({
  browse: browseReducer,
  sell: sellReducer,
  bookingInspection: bookingInspectionReducer,
  appointment: appointmentReducer,
  configOptions: configOptionsReducer,
  userProfile: userProfileReducer,
  otp: otpReducer,
  viewingHistory: viewingHistoryReducer,
  searchFilterHistory: searchFilterHistoryReducer,
  workshop: workshopSlice,
  payment: paymentSlice,
  [listingSlice.name]: listingSlice.reducer,
  [lineChatSlice.name]: lineChatSlice.reducer,
  [modalSlice.name]: modalSlice.reducer,
});

export default reducer;
