/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';

import { IInspectionLocationItem } from '@source/interface/sell';
import {
  ICallBackProps,
  ICallBackResponse,
  IDateOptions,
  IGetTimeSlotsProps,
  // ITimeSlot,
  ITimeSlotResponse,
} from '@source/interface/bookingInspection';

import BookingInspection from '@source/services/BookingInspection';
import { dayjs, getDefaultDateOptions, getDefaultSelectedDate, isDisabledDate } from '@source/pages/Appointments/utils';
import { IErrorResponse, IRejectedValue } from '@source/interface/apiResponse';

const sliceName = 'BOOKING_INSPECTION';

interface BookingInspectionProps {
  carPlate: null;
  currentLocation: IInspectionLocationItem | null;
  // timeSlots: {
  //   [key: string]: ITimeSlot[] | [];
  // } | null;
  dateOptions: IDateOptions[] | null;
  loading: boolean | null;
  defaultSelectedDate: string | null;
}

const INITIAL_STATE: BookingInspectionProps = {
  carPlate: null,
  currentLocation: null,
  // timeSlots: null,
  dateOptions: null,
  loading: null,
  defaultSelectedDate: null,
};

// Todo_S: Might need to clean below async thunks. Forgot why I did that.
export const getTimeSlots = createAsyncThunk<ITimeSlotResponse, IGetTimeSlotsProps, IRejectedValue>(
  `${sliceName}/getTimeSlots`,
  async ({ country, startDate, endDate, locationId }: IGetTimeSlotsProps, { rejectWithValue }) => {
    try {
      const res = await BookingInspection.getTimeSlots(country, startDate, endDate, locationId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);
export const callback = createAsyncThunk<ICallBackResponse, ICallBackProps, IRejectedValue>(
  `${sliceName}/callback`,
  async ({ country, ticketSlug }: ICallBackProps, { rejectWithValue }) => {
    try {
      const res = await BookingInspection.callback(country, ticketSlug);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    setCarPlate(state, { payload }) {
      state.carPlate = payload;
    },
    setCurrentLocation(state, { payload }) {
      state.currentLocation = payload;
    },
    clearTimeSlots(state) {
      // state.timeSlots = null;
      state.defaultSelectedDate = null;
      state.dateOptions = getDefaultDateOptions();
    },
    setDefaultSelectedDate(state, { payload }) {
      state.defaultSelectedDate = payload;
    },
    setDateOptions(state, { payload }) {
      state.dateOptions = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeSlots.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTimeSlots.fulfilled, (state, { payload }) => {
      // const { startDate } = meta?.arg || {};
      const data = payload?.data;

      // const newTimeSlots = { ...state.timeSlots, [`${startDate}`]: data };
      // state.timeSlots = newTimeSlots;
      const today = dayjs();
      const currentTime = today.format('HH:mm');

      const updatedDates = Object.keys(data);

      if (updatedDates) {
        updatedDates?.forEach((date) => {
          const index = state.dateOptions?.findIndex((i) => i.value === date);
          if (typeof index !== 'undefined' && index >= 0 && state.dateOptions) {
            let newData = data[date as keyof typeof data];

            // Check if the date is the current date
            const isTodayTimeSlot = date === today.format('YYYY-MM-DD');

            // Get the last time slot from the newData array, or use '18:00' if newData is empty
            const lastTimeSlot = !isEmpty(newData) ? newData[newData.length - 1].value : '18:00';

            // Check if the current time is after the last time slot of the day
            const afterLastTimeSlot = today.isAfter(dayjs(lastTimeSlot, 'HH:mm'));

            // If the date is today, filter newData to only include time slots after or equal to the current time
            // If the date is disabled, set newData to an empty array
            if (isTodayTimeSlot) {
              newData = afterLastTimeSlot ? [] : newData.filter((i) => i.value >= currentTime);
              newData = isDisabledDate(newData) ? [] : newData;
            }

            // Check if today is disabled (i.e. there are no available time slots)
            // This is true if today is the current date AND either the last time slot has passed or the date is disabled
            const isTodayDisabled = isTodayTimeSlot && (afterLastTimeSlot || isDisabledDate(newData));

            const newItem = {
              ...state.dateOptions[index],
              disabled: isDisabledDate(newData),
              options: newData,
              isHidden: isTodayDisabled,
            };

            state.dateOptions[index] = newItem;
          }
        });
      }

      state.defaultSelectedDate = getDefaultSelectedDate(state.dateOptions || []) || null;
      state.loading = false;
    });
    builder.addCase(getTimeSlots.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(callback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(callback.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(callback.rejected, (state) => {
      state.loading = false;
    });
  },
});

const { setCarPlate, setCurrentLocation, clearTimeSlots, setDefaultSelectedDate, setDateOptions } = slice.actions;
export { setCarPlate, setCurrentLocation, clearTimeSlots, setDefaultSelectedDate, setDateOptions };

export default slice.reducer;
