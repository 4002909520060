import { getStaticCDN } from '@design-system/utils/utils';

const inspectionVideoThumbnail = `/static/img/details-sg/carro-inspection-thumbnail.jpeg`;
const icon360 = `/static/img/car-details/360-degree-dark-icon.svg`;
const icon360Mobile = `/static/img/car-details/360-degree.svg`;
const engineSoundDesktop = `/assets/shared/car-details/engine-sound-desktop.svg`;
const engineSoundMobile = `/assets/shared/car-details/engine-sound-mobile.svg`;
const iconAllImages = `/static/img/car-details/menu-grid-dark-icon.svg`;
const iconAllImagesMobile = `/static/img/car-details/overlap-image-icon.svg`;
// const mytukarLogo = `/my/img/myTukar_logo_v3.0.svg`;
const carroLogo = `/static/img/carro_logo_v2.svg`;
const hamburgerIcon = `/static/img/icons/menu-2line.svg`;
const userIcon = `/static/img/icons/user-icon.svg`;

export default {
  videoThumbnail: () => getStaticCDN(inspectionVideoThumbnail),
  icon360View: (isMobile?: boolean) => getStaticCDN(isMobile ? icon360Mobile : icon360),
  iconEngineSound: (isMobile?: boolean) => getStaticCDN(isMobile ? engineSoundMobile : engineSoundDesktop),
  iconAllImages: (isMobile?: boolean) => getStaticCDN(isMobile ? iconAllImagesMobile : iconAllImages),
  carroLogo: getStaticCDN(carroLogo),
  // mytukarLogo: getStaticCDN(mytukarLogo),
  hamburgerIcon: getStaticCDN(hamburgerIcon),
  userIcon: getStaticCDN(userIcon),
};
