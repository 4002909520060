/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import get from 'lodash/get';

import { IGetConfigResponse, IGetInspectionResponse } from '@source/interface/sell';

import { DefaultOptionProps } from '@design-system/components/FiltersV1/FilterProps';

import { TitleValueProps } from '@source/types';
import ConfigOptions from '@source/services/ConfigOptions';
import { mapLocationFieldOptions, mapProvinceFieldOptions } from '@source/pages/Appointments/utils';
import { IErrorResponse, IRejectedValue } from '@source/interface/apiResponse';
import { IConfigOptionsProps, IGetInspectionLocationProps } from '@source/interface/configOptions';
import { ILocationSelectOption } from '@source/interface/bookingInspection';

const sliceName = 'SELL_CARS';

type SELL_STATE_PROPS = {
  loading: boolean;
  optionsLoading: boolean;
  makes: TitleValueProps[] | null;
  models: {
    [key: number]: TitleValueProps[];
  } | null;
  submodels: {
    [key: number]: TitleValueProps[];
  } | null;
  manufactureYears: {
    [key: number]: TitleValueProps[];
  } | null;
  carViewingPoints: DefaultOptionProps[] | null;
  inspectionLocationProvinces: DefaultOptionProps[] | null;
  inspectionLocationCities: DefaultOptionProps[] | null;
  inspectionLocations: {
    [key: number]: DefaultOptionProps[];
  } | null;
  inspectionLocationsMY: ILocationSelectOption[] | null;
};

const INITIAL_STATE: SELL_STATE_PROPS = {
  loading: false,
  optionsLoading: false,
  makes: null,
  models: null,
  manufactureYears: null,
  submodels: null,
  carViewingPoints: null,
  inspectionLocationProvinces: null,
  inspectionLocationCities: null,
  inspectionLocations: null,
  inspectionLocationsMY: null,
};

// Todo_S: Might need to clean below async thunks. Forgot why I did that.
export const getConfigOptions = createAsyncThunk<IGetConfigResponse, IConfigOptionsProps, IRejectedValue>(
  `${sliceName}/getConfigOptions`,
  async ({ country, configs }: IConfigOptionsProps, { rejectWithValue }) => {
    try {
      const res = await ConfigOptions.getConfigOptions(country, configs);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getInspectionLocation = createAsyncThunk<
  IGetInspectionResponse,
  IGetInspectionLocationProps,
  IRejectedValue
>(
  `${sliceName}/getInspectionLocation`,
  async ({ country, provinceId }: IGetInspectionLocationProps, { rejectWithValue }) => {
    try {
      const res = await ConfigOptions.getInspectionLocation(country, provinceId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConfigOptions.pending, (state) => {
      state.optionsLoading = true;
    });
    builder.addCase(getConfigOptions.fulfilled, (state, { payload, meta }) => {
      const type = meta?.arg?.configs?.types?.[0]?.name;
      const data = payload?.data?.[type]?.data || payload?.data?.[type];

      if (type === 'makes') {
        state.makes = data as TitleValueProps[];
      } else if (type === 'models' || type === 'family_models') {
        const makeId: number = get(meta, 'arg.configs.types.[0].filter', 0);
        state.models = { ...state.models, [makeId || 0]: data };
      } else if (type === 'manufacture_years') {
        const modelId: number = get(meta, 'arg.configs.types.[0].filter', 0);
        const yearListFormatted = data?.map((year) => ({
          title: `${year}`,
          value: year,
        }));
        state.manufactureYears = { ...state.manufactureYears, [modelId || 0]: yearListFormatted };
      } else if (type === 'submodels' || type === 'submodel_variants') {
        const modelId: number = get(meta, 'arg.configs.types.[0].filter', 0);
        state.submodels = { ...state.models, [modelId || 0]: data };
      } else if (type === 'inspection_locations') {
        const isCarViewingPoints = !meta?.arg?.configs?.types?.[0]?.filter && meta?.arg?.country !== 'my';
        if (isCarViewingPoints) {
          state.carViewingPoints = data;
        } else {
          const inspectionLocationCityId: number = get(meta, 'arg.configs.types.[0].filter', 0);
          state.inspectionLocations = { ...state.inspectionLocations, [inspectionLocationCityId || 0]: data };
        }
      } else if (type === 'inspection_location_cities') {
        state.inspectionLocationCities = data;
      } else if (type === 'inspection_center_provinces') {
        state.inspectionLocationProvinces = data ? mapProvinceFieldOptions(data?.filter((x) => x.value)) : data;
      }
      state.optionsLoading = false;
    });
    builder.addCase(getConfigOptions.rejected, (state) => {
      state.optionsLoading = false;
    });
    builder.addCase(getInspectionLocation.pending, (state) => {
      state.optionsLoading = true;
    });
    builder.addCase(getInspectionLocation.fulfilled, (state, { payload }) => {
      const data = payload?.data;
      state.inspectionLocationsMY = mapLocationFieldOptions(data) || null;
      state.optionsLoading = false;
    });
    builder.addCase(getInspectionLocation.rejected, (state) => {
      state.optionsLoading = false;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
const {} = slice.actions;
export {};

export default slice.reducer;
