import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { getTrimedS3Url } from '@design-system/utils';
import { IMAGE_KIT_URL } from '@design-system/utils/utils';

interface IPromoLabel {
  promoLabel?: string | null;
  isPromo?: boolean;
  isDisablePromoLabel?: boolean;
  promoImg?: string;
}

// TODO: Teh refactor color, stop using fixed color code
// Change from P to typography component
// .promo should be more meaningful name and pls add component prefix
// Box shadow should check if already exist in theme
const StyledPromoRibbon = styled.div`
  position: absolute;
  top: 18px;
  left: -11px;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);

  .promo {
    margin-bottom: 0;
    padding: 8px 12px;
    background: linear-gradient(180deg, #ff4249 0%, #ff8003 87.5%);
    box-shadow: 0px 1.58683px 9.52095px rgba(0, 0, 0, 0.25);
    border-radius: 13.5419px 13.5419px 13.5419px 0px;
    color: #fff;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125;
  }
`;

const StyledPromotionImage = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
`;

const StyledPromoRibbonTriangle = styled.div`
  border-top: 10px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 11px solid #a97b35;
  position: absolute;
  top: 42px;
  left: -11px;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
`;

const PromoLabel: React.FC<IPromoLabel> = ({ promoLabel, isPromo, isDisablePromoLabel, promoImg = '' }) => {
  if (isDisablePromoLabel) {
    return null;
  }

  if (isPromo && promoImg) {
    const promoImgUrl = `${IMAGE_KIT_URL}/${getTrimedS3Url(promoImg)}`;

    return (
      <StyledPromotionImage>
        <Image src={promoImgUrl} alt="promo-logo promo-image" width={60} height={60} />
      </StyledPromotionImage>
    );
  }

  if (isPromo && promoLabel) {
    return (
      <>
        <StyledPromoRibbon>
          <p className="promo promo-lable">{promoLabel}</p>
        </StyledPromoRibbon>
        <StyledPromoRibbonTriangle />
      </>
    );
  }
  return null;
};

PromoLabel.defaultProps = {
  promoLabel: undefined,
  isPromo: false,
  isDisablePromoLabel: false,
  promoImg: '',
};

export default PromoLabel;
