import { getDataConfigsCountry, getFiltersTranslationPath } from './constants';

const countryCode = 'id';
/* eslint-disable no-template-curly-in-string */
const INITIAL_FILTER_SETTINGS = [
  {
    name: 'promotions',
    active: true,
    activeMobile: true,
    algoliaFetching: true,
    requireDefaults: true,
    filters: [
      {
        name: 'promotion_name',
        customName: 'promotion_name',
        type: 'button',
        filterSelectedHeader: '[label]',
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'make_model',
    active: true,
    activeMobile: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        name: 'inventory.make_model',
        filterSelectedHeader: '[label]',
        headerFormat: true,
        type: 'make_model',
        // makeOptions: getDataConfigsCountry({ countryCode, dataName: 'BRANDS_OPTIONS' }),
        colSize: 12,
        colResponsive: {
          xs: 12,
          sm: 12,
          md: 8,
        },
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'price_range',
    active: true,
    activeMobile: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    hasDivider: true,
    filters: [
      {
        name: 'listing.asking_price',
        type: 'range_input',
        prefix: 'Rp',
        headerFormat: true,
        requireCommaFormat: true,
        filterSelectedHeader: 'Rp[selectedMinInput] - Rp[selectedMaxInput]',
        detailsText: {
          average: getFiltersTranslationPath('priceRangeAverageId'),
        },
        sliderStep: 1000000,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        selectedCustomLabel: getFiltersTranslationPath('priceRangeCustomLabelId'),
      },
    ],
  },
  {
    name: 'manufacture_year',
    groupMobile: null,
    filters: [
      {
        name: 'inventory.year_of_manufacture',
        customName: 'inventory.manufacture_year',
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
      },
    ],
  },
  {
    name: 'mileage',
    active: true,
    activeMobile: true,
    groupMobile: null,
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        headerFormat: true,
        name: 'listing.mileage',
        filterSelectedHeader: '[selectedMinInput] KM - [selectedMaxInput] KM',
        requireCommaFormat: true,
        suffix: 'KM',
        type: 'range_input',
        isNumeric: true,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        selectedCustomLabel: getFiltersTranslationPath('mileageCustomLabel'),
      },
    ],
  },
  {
    name: 'transmission',
    active: true,
    activeMobile: true,
    group: 'transmission',
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        name: 'inventory.transmission',
        filterSelectedHeader: '[label]',
        type: 'button',
        tag: '{0}',
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({ countryCode: 'default', dataName: 'TRANSMISSION' }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'tags',
    active: true,
    activeMobile: true,
    algoliaFetching: false,
    header: getFiltersTranslationPath('carroExclusives'),
    headerMobile: getFiltersTranslationPath('carroExclusives'),
    filters: [
      {
        name: 'listing',
        header: getFiltersTranslationPath('exclusives'),
        headerMobile: getFiltersTranslationPath('exclusives'),
        type: 'button',
        filterSelectedHeader: '[label]',
        isNumeric: false,
        isTrueOption: true,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'TAGS',
          listFields: ['listing.is_ccpo', 'listing.is_great_buy', 'listing.is_value_buy'],
        }),
        selectedOptions: null,
        colSize: 12,
      },
    ],
  },
  {
    name: 'fuel_button',
    active: true,
    activeMobile: true,
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        name: 'inventory.fuel_type',
        type: 'button',
        filterSelectedHeader: '[label]',
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'FUEL_TYPE',
          listFields: ['diesel', 'petrol', 'petrol-electric', 'cng'],
        }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'plate_odd_even',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('plateNumber'),
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: false,
    filters: [
      {
        header: getFiltersTranslationPath('plateNumber'),
        headerMobile: getFiltersTranslationPath('plateNumber'),
        name: 'inventory.plate_number_odd_even',
        type: 'button',
        filterSelectedHeader: '[label]',
        colSize: 12,
        isNumeric: false,
        options: getDataConfigsCountry({ countryCode, dataName: 'PLATE_ODD_EVEN' }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'body_button',
    active: true,
    activeMobile: true,
    group: 'body_button',
    showSubheader: true,
    headerFormat: true,
    algoliaFetching: true,
    filters: [
      {
        name: 'inventory.car_type',
        type: 'button',
        filterSelectedHeader: '[label]',
        isCDN: true,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'BODY_TYPE',
          listFields: [
            'hatchback',
            'hybrid',
            'luxury_sedan',
            'mid_sized_sedan',
            'mpv',
            'sedan',
            'sports',
            'stationwagon',
            'suv',
            'electric',
          ],
        }),
        selectedOptions: null,
        buttonsType: 'primary',
        colResponsive: {
          xs: 12,
          sm: 12,
          md: 8,
        },
      },
    ],
  },
  {
    name: 'installment',
    active: true,
    activeMobile: true,
    header: getFiltersTranslationPath('installment'),
    groupMobile: 'cost_details',
    groupHeaderMobile: getFiltersTranslationPath('price'),
    algoliaFetching: false,
    requireDefaults: true,
    filters: [
      {
        header: getFiltersTranslationPath('installment'),
        headerMobile: getFiltersTranslationPath('installment'),
        headerFormat: true,
        name: 'listing.installment',
        filterSelectedHeader: 'Rp[selectedMinInput] - Rp[selectedMaxInput]',
        detailsText: {
          average: getFiltersTranslationPath('installmentAverageId'),
        },
        prefix: 'Rp',
        // suffix: 'Jt',
        type: 'range_input',
        isNumeric: true,
        requireFormatValue: true,
        requireCommaFormat: true,
        displayMultiplier: 1000000,
        selectedMinInput: null,
        selectedMaxInput: null,
        facetStats: {
          avg: null,
          max: null,
          min: null,
          sum: null,
        },
        placeholderMin: 'Min. harga',
        placeholderMax: 'Maks. harga',
        selectedCustomLabel: getFiltersTranslationPath('installmentCustomLabelId'),
      },
    ],
  },
  {
    name: 'seats',
    active: true,
    activeMobile: true,
    group: 'seats',
    showHeader: true,
    showSubheader: true,
    algoliaFetching: false,
    filters: [
      {
        name: 'inventory.seats',
        type: 'button',
        isNumeric: true,
        options: getDataConfigsCountry({ countryCode, dataName: 'SEATS_OPTIONS' }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: 'colours',
    active: true,
    activeMobile: true,
    showHeader: true,
    group: 'colours',
    showSubheader: true,
    algoliaFetching: false,
    joinCondition: 'AND',
    filters: [
      {
        name: 'inventory.color',
        type: 'button',
        filterSelectedHeader: '[label]',
        parentStyles: {
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          placeItems: 'center',
        },
        imagePath: '/static/icon/{0}.png',
        isNumeric: false,
        options: [
          { label: getFiltersTranslationPath('options.black'), value: 'hitam', name: 'black' },
          { label: getFiltersTranslationPath('options.white'), value: 'putih', name: 'white' },
          { label: getFiltersTranslationPath('options.brown'), value: 'coklat', name: 'brown' },
          { label: getFiltersTranslationPath('options.grey'), value: 'abu-abu', name: 'grey' },
          { label: getFiltersTranslationPath('options.silver'), value: 'silver', name: 'silver' },
          { label: getFiltersTranslationPath('options.blue'), value: 'biru', name: 'blue' },
          { label: getFiltersTranslationPath('options.red'), value: 'merah', name: 'red' },
          { label: getFiltersTranslationPath('options.green'), value: 'hijau', name: 'green' },
          { label: getFiltersTranslationPath('options.orange'), value: 'oranye', name: 'orange' },
          { label: getFiltersTranslationPath('options.beige'), value: 'beige', name: 'beige' },
        ],
        selectedOptions: null,
        buttonsType: 'primary',
        rowGutter: [8, 8],
        colResponsive: {
          xs: 8,
          sm: 8,
          md: 8,
        },
      },
    ],
  },
  {
    name: 'availability_button',
    active: true,
    activeMobile: true,
    algoliaFetching: false,
    filters: [
      {
        name: 'listing',
        type: 'button',
        filterSelectedHeader: '[label]',
        isShowTagMobile: false,
        colSize: 24,
        isTrueOption: true,
        isNumeric: false,
        options: getDataConfigsCountry({
          countryCode,
          dataName: 'FILTER_AVAILABLE',
          listFields: ['listing.is_available', 'listing.is_coming_soon', 'listing.is_pending_sale'],
        }),
        selectedOptions: null,
      },
    ],
  },
  {
    name: '360view',
    active: true,
    activeMobile: false,
    group: 'exclusives',
    algoliaFetching: false,
    isBooleanFilter: true,
    joinCondition: 'OR',
    filters: [
      {
        name: 'listing.has_360_interior_photos',
        type: 'boolean',
        isNumeric: false,
        selectedBoolean: null,
      },
      {
        name: 'listing.has_360_exterior_photos',
        type: 'boolean',
        isNumeric: false,
        selectedBoolean: null,
      },
    ],
  },
];

const ATTRIBUTES_TO_RETRIEVE = [
  // attribute will be returned
  'created_at',
  'promo_label',
  'promo_images',
  'promotion',
  'promotion.promo_blurb.url',
  'hits',
  'categories',
  'original_price',
  'is_promo',
  'listing.title',
  'listing.slug',
  'listing.mileage',
  'listing.asking_price',
  'listing.photos',
  'listing.current_depreciation',
  'listing.installment',
  'listing.is_certified',
  'listing.is_carro_certified',
  'listing.is_coming_soon',
  'listing.is_pending_sale',
  'listing.is_sold',
  'listing.is_great_buy',
  'listing.is_value_buy',
  'listing.is_agent_serviced',
  'listing.is_agent_warranty',
  'listing.is_carro_anywhere',
  'listing.is_ccpo',
  'listing.is_new_arrival',
  'listing.thumbnail',
  'listing.promotional_price',
  'listing.key_words',
  'listing.months_left',
  'listing.paper_value',
  'listing.status',
  'listing.is_carro_assure',
  'listing.is_carro_assure_plus',
  'listing.has_360_interior_photos',
  'listing.has_360_exterior_photos',
  'listing.maximum_loan_percentage',
  'listing.downpayment_percentage',
  'listing.last_service_date',
  'inventory.make',
  'inventory.model',
  'inventory.original_registration_date',
  'inventory.number_of_owners',
  'inventory.year_of_manufacture',
  'inventory.seats',
  'inventory.color',
  'inventory.transmission',
  'inventory.car_type',
  'inventory.fuel_type',
  'inventory.plate_number_odd_even',
  'inventory.road_tax_expiry_date',
  'inventory.tax_expiration_date',
  'inventory.engine_capacity',
  'inventory.fuel_type',
  'inventory.bpkb_available',
  'inventory.owner_type',
  'location.name',
  'city.name',
  'city.display_name',
  'region.display_name',
  'registration_area.display_name',
  'inside_badges',
];

const OTHER_FILTERS_ORDER = [
  'plate_odd_even',
  'fuel_button',
  'transmission',
  'body_button',
  'seats',
  'colours',
  'tags',
];

const DESKTOP_FILTERS_ORDER = [
  'make',
  'make_model',
  'mileage',
  'installment',
  'price_range',
  'manufacture_year',
  '360view',
  'availability_button',
];

const MOBILE_FILTERS_ORDER = [
  'make',
  'make_model',
  'availability_button',
  'plate_odd_even',
  'fuel_button',
  'transmission',
  'body_button',
  'seats',
  'colours',
  'mileage',
  'manufacture_year',
  'installment',
  'price_range',
  'tags',
];

const FILTER_CONFIGS_ID = {
  loading: false,
  initialFilterSettings: INITIAL_FILTER_SETTINGS,
  attributesToRetrieve: ATTRIBUTES_TO_RETRIEVE,
  mobileFilters: MOBILE_FILTERS_ORDER,
  otherFilters: OTHER_FILTERS_ORDER,
  desktopFilters: DESKTOP_FILTERS_ORDER,
  isAlgoliaOptionsLoaded: true,
  isInitalSettingsLoaded: true,
  autocompleteHits: [],
  query: '',
  hitsPerPage: 24,
};

export default FILTER_CONFIGS_ID;
