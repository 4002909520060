/* eslint-disable react/require-default-props */
import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import styled from 'styled-components';
import { Row, Col, Input, Statistic, Spin } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import useRegionConfig from '@source/hooks/useRegionConfig';
import { PHONE_COUNTRY_CODES } from '@source/pages/CarDetail/constants';
import { Button } from '@design-system/components/Button';
import { IBuyNowReducer } from '@source/pages/CarDetail/containers/BuyNow/BuyNowReducer';
import LockedCountdown from '@source/pages/CarDetail/components/BuyNow/LockedCountdown';
import { IOTP } from '@source/interface/otp';

const { Group } = Input;
const { Countdown } = Statistic;

interface IOTPVerification {
  maxNumberOfAttempts: number;
  state: IBuyNowReducer & IOTP;
  isSubmitting?: boolean;
  onCountdownTimerEnd: () => void;
  onVerifyOTP: ({ otpCode }: { otpCode: string }) => void;
  onRequestOTP: () => void;
  hideHeader?: boolean;
  hasBorder?: boolean;
  hideDescription?: boolean;
  customRenderResendCountdown?: ReactElement | null;
  customRenderLockCountdown?: ReactElement | null;
  customRenderResendCTA?: ReactElement | null;
  skipRedirectAfterLockCountdown?: boolean;
}

type TOTPVerification = {
  isError?: boolean;
};

const StyledWrapper = styled.div<TOTPVerification>`
  margin: 12px 0;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .attempts-container {
    display: flex;
    justify-content: space-between;
  }

  .attempts-text {
    ${(props) => props.theme.typo.style.captionRegular}
    margin-bottom: 20px;

    strong {
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }
  }

  .digits-text {
    /* https://carrotech.atlassian.net/browse/CCV-1479 The req is not highlight the text  */
    /* color: ${(props) => props.theme.color.primaryBase}; */
    /* ${(props) => props.theme.typo.familyGoogle.bold}; */
    /* ${(props) => props.theme.typo.style.mainButtonOrLink}; */
  }

  .digits-code {
    .ant-input {
      font-size: 18px;
      ${(props) => props.theme.typo.familyGoogle.medium};
      color: ${(props) => props.isError && props.theme.color.errorHighEmphasis};
    }

    &.bordered {
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.color.linkHighEmphasis};
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 40px;
      gap: 40px;
      height: 42px;

      &.has-error {
        border: 1px solid ${(props) => props.theme.color.errorHighEmphasis};
      }

      .ant-input {
        position: relative;
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        ${(props) => props.theme.typo.familyGoogle.bold};
        color: ${(props) => props.isError && props.theme.color.errorHighEmphasis};
        border-bottom: none;

        &.has-value {
          + .short-line {
            display: none;
          }
        }

        &:focus {
          + .short-line {
            display: none;
          }
        }
      }

      .col-input {
        position: relative;

        .short-line {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 25px;
          border: 2px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
        }
      }
    }
  }

  .error-message {
    text-align: left;
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-size: 12px;
    color: ${(props) => props.theme.color.errorHighEmphasis};
  }

  .spinning-icon {
    text-align: center;
  }

  .ant-input-borderless {
    border-bottom: 1px solid
      ${(props) => (props.isError ? props.theme.color.errorHighEmphasis : props.theme.color.onBackgroundMediumEmphasis)};
    border-radius: 0;
    text-align: center;

    &[value=''] {
      border-color: ${(props) =>
        props.isError ? props.theme.color.errorHighEmphasis : props.theme.color.onBackgroundLowEmphasis};
    }
  }
`;

const StyledResendCode = styled(Countdown)`
  margin: 16px 0 20px;
  display: inline;

  .ant-statistic-content {
    display: inline;
    color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
    ${(props) => props.theme.typo.style.smallButtonOrLink}
  }
`;

const DIGIT_LENGTH = 6;

const KEY_CODE = {
  BACK_SPACE: 8,
  LEFT: 37,
  RIGHT: 39,
};

const OTPVertification: React.FC<IOTPVerification> = ({
  maxNumberOfAttempts,
  state,
  isSubmitting,
  onCountdownTimerEnd,
  onVerifyOTP,
  onRequestOTP,
  hideHeader,
  hasBorder,
  hideDescription,
  customRenderResendCTA,
  customRenderLockCountdown,
  customRenderResendCountdown,
  skipRedirectAfterLockCountdown = false,
  // https://carrotech.atlassian.net/browse/CCV-1433
  // Add a temp option to skip redirect after lock countdown is done.
  // This is temp solution. Will refactor by treating the lock countdown as a login step instead of by state
}) => {
  const { t } = useTranslation();
  const {
    countryConfig: { country },
  } = useRegionConfig();
  const [hasError, setHasError] = useState<boolean>(false);
  const [digits, setDigits] = useState<Array<string>>([]);

  const phoneCountryCode = PHONE_COUNTRY_CODES[country || 'sg'];
  const { phone, isShowValidation, isCountdownOTP, countdownTimer, loading, error, numberOfAttempts, lockSeconds } =
    state;

  const attempts = maxNumberOfAttempts - (numberOfAttempts || 0);

  const getElementSelector = (selectorName: string) => document.querySelector(selectorName) as HTMLInputElement;

  useEffect(() => {
    // autofocus doesn't work on Drawer, need to do manualy
    setTimeout(() => {
      const firstEl = getElementSelector(`#digit-input-0`);
      if (firstEl) {
        firstEl.focus();
      }
    }, 0);
  }, []);

  useEffect(() => {
    const otpCode = digits.join('');
    if (otpCode.length >= DIGIT_LENGTH) {
      onVerifyOTP({ otpCode });
    }
  }, [digits]);

  useEffect(() => {
    setHasError(Boolean(error));
    if (!loading) setHasError(Boolean(error));
  }, [loading, error]);

  useEffect(() => {
    if (hasError) {
      setDigits([]);
      const firstEl = getElementSelector(`#digit-input-0`);
      if (firstEl) {
        firstEl.focus();
        firstEl.select();
      }
    }
  }, [hasError]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e?.target?.dataset?.id || '0', 10);
    let currentValue = e.target.value;
    currentValue = currentValue.replace(/[^\d]/gi, '');
    if (currentValue === '') return;

    let nextEl;

    const clonedDigits = [...digits];

    if (currentValue.length > 1) {
      let nextIndex = currentValue.length + index - 1;
      if (nextIndex >= DIGIT_LENGTH) {
        nextIndex = DIGIT_LENGTH - 1;
      }
      nextEl = getElementSelector(`#digit-input-${nextIndex}`);

      currentValue.split('').forEach((digit: string, digitIndex: number) => {
        const cursor = index + digitIndex;
        if (cursor < DIGIT_LENGTH) {
          clonedDigits[cursor] = digit;
        }
      });
    } else {
      nextEl = getElementSelector(`#digit-input-${index + 1}`);
      clonedDigits[index] = currentValue;
    }

    setDigits(clonedDigits);
    setHasError(false);

    if (nextEl) {
      nextEl.focus();
      nextEl.select();
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const index = parseInt(e?.target?.dataset?.id || '0', 10);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevEl = getElementSelector(`#digit-input-${prevIndex}`);
    const nextEl = getElementSelector(`#digit-input-${nextIndex}`);
    const clonedDigits = [...digits];

    switch (e?.keyCode) {
      case KEY_CODE.BACK_SPACE:
        if (clonedDigits[index]) {
          clonedDigits[index] = '';
        } else if (prevEl) {
          clonedDigits[prevIndex] = '';
          prevEl.focus();
        }
        setDigits(clonedDigits);
        break;
      case KEY_CODE.LEFT:
        if (prevEl) prevEl.focus();
        break;
      case KEY_CODE.RIGHT:
        if (nextEl) nextEl.focus();
        break;
      default:
        break;
    }
  };

  const renderDigitInput = () => (
    <Group size="large">
      <Row gutter={8}>
        {Array(DIGIT_LENGTH)
          .fill(1)
          .map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col span={4} key={`digit-input-${index}`} className="col-input">
              <Input
                type="number"
                bordered={false}
                data-id={index}
                id={`digit-input-${index}`}
                onChange={onChange}
                value={digits[index]}
                onKeyDown={onKeyDown}
                inputMode="numeric"
                className={`${digits[index] ? 'has-value' : ''}`}
              />
              <div className="short-line" />
            </Col>
          ))}
      </Row>
    </Group>
  );

  const attemptsText: ReactNode = (
    <div className="attempts-text">
      <Trans
        i18nKey="details.buyNow.otp.attemptsText"
        values={{ attempts, maxAttempts: maxNumberOfAttempts }}
        components={{
          b: <b />,
        }}
      />
    </div>
  );

  // Render resend OTP section
  const renderResendOTP = () => {
    if (customRenderResendCTA) return customRenderResendCTA;

    return loading ? (
      <div className="spinning-icon mt-16">
        <Spin size="large" />
      </div>
    ) : (
      <Button
        type="primary"
        size="large"
        block
        onClick={onRequestOTP}
        disabled={(isShowValidation && isCountdownOTP) || Boolean(lockSeconds)}
        loading={isSubmitting}
      >
        {t('details.buyNow.otp.resendOTP')}
      </Button>
    );
  };

  // Render Verify OTP Section
  const renderVerifyOTP: ReactNode = (
    <>
      {!hideDescription && (
        <div className="sub-header mt-16">
          {t('details.buyNow.otp.enterAndVerifyOTPCode', { numberOfDigits: DIGIT_LENGTH })}
          &nbsp;
          <b>
            {phoneCountryCode} **** {phone?.substring(phone.length - 4)}
          </b>
        </div>
      )}

      <div className="attempts-container mt-16">
        <div className="digits-text">{t('details.buyNow.otp.digitCode', { numberOfDigits: DIGIT_LENGTH })}</div>
        {attemptsText}
      </div>
      <div className={`digits-code mb-8 ${hasBorder ? 'bordered' : ''} ${error ? 'has-error' : ''}`}>
        {renderDigitInput()}
      </div>
      {customRenderResendCountdown && isCountdownOTP && customRenderResendCountdown}
      {!customRenderResendCountdown && isCountdownOTP && (
        <div className="mb-16">
          <Trans
            i18nKey="details.buyNow.otp.resendCodeCountdown"
            components={{
              countdownTimer: <StyledResendCode value={countdownTimer} format="mm:ss" onFinish={onCountdownTimerEnd} />,
            }}
          />
        </div>
      )}
      {error && <div className="error-message mb-8">{error}</div>}
      {renderResendOTP()}
    </>
  );

  // Render Locked Countdown Section
  const renderLockedCountdown: ReactNode = customRenderLockCountdown ?? (
    <LockedCountdown state={state} onCountdownTimerEnd={onCountdownTimerEnd} />
  );

  return (
    <StyledWrapper isError={hasError}>
      {!hideHeader && <div className="header-title mt-32">{t('details.buyNow.otp.verifyToView')}</div>}
      {lockSeconds || skipRedirectAfterLockCountdown ? renderLockedCountdown : renderVerifyOTP}
    </StyledWrapper>
  );
};

OTPVertification.defaultProps = {
  isSubmitting: false,
  hideHeader: false,
  hideDescription: false,
};

export default OTPVertification;
