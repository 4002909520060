import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import * as searchFilterHistoryAction from '@source/redux/slice/searchFilterHistorySlice';
import { TRegionKey } from '@source/interface';
import { useAppDispatch, useAppSelector } from './useStateHook';

const useSearchFilterServices = () => {
  const dispatch = useAppDispatch();

  //  Selectors
  const searchFilterHistoryState = useAppSelector((state) => state.searchFilterHistory, shallowEqual);

  const uploadSearchFilterHistory = useCallback(
    ({ country, data, isAuthenticated }: { country: TRegionKey; data: any; isAuthenticated: boolean }) => {
      dispatch(searchFilterHistoryAction.uploadSearchFilterHistory({ country, data, isAuthenticated }));
    },
    [dispatch],
  );

  const resetState = useCallback(() => {
    dispatch(searchFilterHistoryAction.actions.resetState());
  }, [dispatch]);

  return {
    searchFilterHistoryState,
    uploadSearchFilterHistory,
    resetState,
  };
};

export default useSearchFilterServices;
