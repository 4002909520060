import jsCookie from 'js-cookie';
import { LOCAL_STORAGE_KEYS, COOKIE_KEYS } from '@source/constants/common';
import { TRegionKey, TLanguageKey } from '@source/interface';
import { AVAILABLE_LANGUAGES, SUPPORTED_LANGUAGES_MAP_BY_COUNTRY } from '@source/constants/config';

export const removeSelectedLocationOnStorage = (): void => {
  if (typeof window !== 'undefined') localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_LOCATION);
};

export const isSupportedLanguage = (_country: TRegionKey | null, _language: TLanguageKey | null): boolean => {
  const currentLang = _country === 'sg' && _language === 'ms' ? 'ms-SG' : _language;
  const langIsIncluded =
    SUPPORTED_LANGUAGES_MAP_BY_COUNTRY[_country || 'sg'] &&
    SUPPORTED_LANGUAGES_MAP_BY_COUNTRY[_country || 'sg'].find((language) => language.code === currentLang);

  return !!(_country && _language && langIsIncluded);
};

export const setSelectedCountryOnCookie = (value: TRegionKey): void => {
  jsCookie.set(COOKIE_KEYS.STORED_REGION, value, { expires: 365 });
};

export const getSelectedCountryFromCookie = (): TRegionKey | null => {
  const country = jsCookie.get(COOKIE_KEYS.STORED_REGION) || null;

  return country as TRegionKey | null;
};

export const setSelectedLanguageOnCookie = (value: TLanguageKey, region: TRegionKey): void => {
  jsCookie.set(`${COOKIE_KEYS.STORED_LANGUAGE_BY_REGION}_${region.toUpperCase()}`, value, { expires: 365 });
};

export const getSelectedLanguageFromCookie = (region: TRegionKey): TLanguageKey | null => {
  const language = jsCookie.get(`${COOKIE_KEYS.STORED_LANGUAGE_BY_REGION}_${region.toUpperCase()}`);
  return language as TLanguageKey;
};

export const isValidLanguage = (language: TLanguageKey, currentCountry: TRegionKey) => {
  let isValid = false;
  if (AVAILABLE_LANGUAGES.includes(language) && isSupportedLanguage(currentCountry, language)) {
    isValid = true;
  }
  return isValid;
};

const LanguageServices = {
  isSupportedLanguage,
  setSelectedCountryOnCookie,
  setSelectedLanguageOnCookie,
  getSelectedCountryFromCookie,
  getSelectedLanguageFromCookie,
  isValidLanguage,
};

export default LanguageServices;
