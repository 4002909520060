import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getStaticCDN } from '@design-system/utils/utils';
import useGTMEvent from '@source/hooks/useGTMEvent';
import { TRegionKey } from '@source/interface';
import { useLinePrefillMessage } from '@source/utils/useSocialPrefillMessage';

interface ILineButtonProps {
  countryCode: string;
}

const StyledContentElement = styled.div`
  width: calc(100% - 50px);
`;

const StyledImgContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
`;

const LineButton: React.FC<ILineButtonProps> = ({ countryCode }) => {
  const { t } = useTranslation();
  const { pushGTMEvent } = useGTMEvent();
  const { lineLink } = useLinePrefillMessage({ country: countryCode as TRegionKey });

  const handleClick = () => {
    pushGTMEvent({
      event: 'click_line',
    });
  };

  return (
    <a href={lineLink} target="_blank" rel="noreferrer" onClick={handleClick}>
      <StyledImgContainer className="img-container">
        <Image src={getStaticCDN('/assets/shared/line.svg')} alt="line" layout="fill" objectFit="contain" />
      </StyledImgContainer>
      <StyledContentElement className="content-element">
        {countryCode === 'jp' ? (
          <>
            {t('home.line.line1JP')} <br />
            {t('home.line.line2JP')}
          </>
        ) : (
          <>
            {t('home.line.line1')} <br />
            {t('home.line.line2')}
          </>
        )}
      </StyledContentElement>
    </a>
  );
};

export default LineButton;
