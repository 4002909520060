import { createContext } from 'react';

import { TLanguageKey, TRegionKey } from '@source/interface';

export interface ICountryConfig {
  isPersisted: boolean;
  isStoredInBrowser: boolean;
  country: TRegionKey | undefined;
  isFirstTimeAccess: boolean;
}

export interface IRegionContext {
  countryConfig: ICountryConfig;
  changeCountry: { (region: TRegionKey): void };
  changeLanguage: { (language: TLanguageKey): void };
}

const IRegionContext = createContext<IRegionContext>({
  countryConfig: { isPersisted: false, country: undefined, isStoredInBrowser: false, isFirstTimeAccess: false },
  // Fallback function
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeCountry: () => {},
  // Fallback function
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeLanguage: () => {},
});

export default IRegionContext;
