import React, { FC } from 'react';
import { Button, Drawer, DrawerProps } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import styled, { createGlobalStyle } from 'styled-components';

const DrawerHeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.color.background};
  border-bottom: 1px solid #bababa;
  display: grid;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  font-size: 14px;
  grid-template-columns: 1fr 11fr 1fr;
  height: 65px;
  padding: 20px 40px;
  position: sticky;
  place-items: center;
  top: 0;
  width: 100%;
  z-index: 1;

  .header-title-text {
    grid-column-start: 2;
  }
`;

const DrawerBodyWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  // Fix height content
`;

const DrawerFooterWrapper = styled.div<{ placement: string | undefined }>`
  background-color: ${(props) => props.theme.color.background};
  bottom: 0px;
  box-shadow: 0px -6px 6px rgba(161, 161, 161, 0.25);
  padding: 16px;
  position: ${(props) => (props.placement === 'bottom' ? 'fixed' : 'sticky')};
  width: 100%;
`;

const StyledApplyButton = styled(Button)`
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  background-color: ${(props) => props.theme.color.primaryBase};
  border-radius: 10px;
  color: ${(props) => props.theme.color.onPrimary};
  height: 48px;
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  cursor: pointer;
  margin-left: auto;
  font-size: 21px;

  &:hover {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const OverrideMobileModalStyle = createGlobalStyle`
.mobile-drawer {
    .ant-drawer-body {
      display: flex;
      flex-direction: column;
    }
  }
`;

export interface MobileDrawerProps extends DrawerProps {
  id?: string;
  confirmBtnLabel?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  customHeader?: () => JSX.Element | undefined | null;
  customFooter?: () => JSX.Element | undefined | null;
}

const MobileDrawer: FC<MobileDrawerProps> = ({
  children,
  className,
  height = '100%',
  width,
  title,
  confirmBtnLabel,
  visible,
  placement,
  zIndex,
  id,
  customHeader,
  customFooter,
  onConfirm,
  onClose,
}) => (
  <>
    <Drawer
      bodyStyle={{ padding: 0 }}
      height={height}
      width={width}
      placement={placement || 'bottom'}
      closable={false}
      onClose={onClose}
      visible={visible}
      closeIcon={<CloseCircleOutlined />}
      className={`mobile-drawer ${className || ''}`}
      zIndex={zIndex}
      destroyOnClose
    >
      <>
        {customHeader ? (
          customHeader()
        ) : (
          <DrawerHeaderWrapper>
            <span className="header-title-text">{title}</span>
            <StyledCloseIcon onClick={onClose} />
          </DrawerHeaderWrapper>
        )}
        <DrawerBodyWrapper id={`mobileDrawerBody${id || ''}`}>{children}</DrawerBodyWrapper>
        {customFooter ? (
          customFooter()
        ) : (
          <DrawerFooterWrapper placement={placement}>
            <StyledApplyButton block onClick={onConfirm}>
              {confirmBtnLabel}
            </StyledApplyButton>
          </DrawerFooterWrapper>
        )}
      </>
    </Drawer>
    <OverrideMobileModalStyle />
  </>
);

MobileDrawer.defaultProps = {
  id: '',
  confirmBtnLabel: '',
  onConfirm: undefined,
  onClose: undefined,
  customHeader: undefined,
  customFooter: undefined,
};

export default MobileDrawer;
