// import qs from 'qs';

import { API_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import { ICreatePaymentSignPayload } from '@source/pages/Payments/types';
import { WHOLESALE_AXIOS_USER_AUTH } from '@source/services/UserProfile';

export default {
  getCurrentPayment: (country: TRegionKey, transactionId: string) => {
    const getCurrentPaymentAPI = `${API_URL(country).paymentQuery}/transactions/${transactionId}`;
    return WHOLESALE_AXIOS_USER_AUTH.get(getCurrentPaymentAPI);
  },

  getPaymentList: (country: TRegionKey) => {
    const apiPath = `${API_URL(country).paymentQuery}/data-query/transactions`;
    return WHOLESALE_AXIOS_USER_AUTH.get(apiPath);
  },

  createPaymentSignature: ({ refundId, payload }: ICreatePaymentSignPayload) => {
    const apiPath = `${API_URL().captainGo}/rabbit/my/refunds/${refundId}/sign`;
    return WHOLESALE_AXIOS_USER_AUTH.put(apiPath, payload);
  },
};
