import React from 'react';

const ViewingHistoryIcon = ({ width = 20, height = 20 }: { width: number; height: number }) => (
  <svg
    width={width}
    height={height}
    className="viewing-history-icon"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.471 4.332h-.94a.157.157 0 00-.156.156v5.377c0 .051.024.098.065.127l3.228 2.358c.07.05.168.037.22-.034l.558-.761a.154.154 0 00-.034-.22L9.627 9.323V4.488a.157.157 0 00-.156-.156zm4.293 1.469l3.063.748a.157.157 0 00.193-.15l.016-3.155c0-.13-.15-.205-.252-.123l-3.078 2.404a.156.156 0 00.058.276zm3.276 5.88l-1.108-.38a.156.156 0 00-.197.094 6.862 6.862 0 01-1.6 2.492 6.893 6.893 0 01-4.887 2.025 6.893 6.893 0 01-4.887-2.025A6.926 6.926 0 012.88 11.69 6.903 6.903 0 012.34 9c0-.934.182-1.84.543-2.691A6.862 6.862 0 014.366 4.11 6.895 6.895 0 0111.94 2.63a6.895 6.895 0 012.756 2.113l1.168-.914A8.375 8.375 0 009.247.602 8.383 8.383 0 00.856 9.086c.047 4.6 3.787 8.312 8.394 8.312a8.397 8.397 0 007.885-5.513.16.16 0 00-.096-.203z" />
  </svg>
);
export default ViewingHistoryIcon;
