import { useEffect } from 'react';
import { useRouter } from 'next/router';
import qs from 'qs';
import Cookies from 'js-cookie';
import isEqual from 'lodash/isEqual';

interface FilteredKeys {
  [key: string]: string | string[];
}

const EXPIRED_COOKIE_UTM = 30; // 30 days
const UTM_COOKIE_NAME = 'utm';

const useUTM = () => {
  const router = useRouter();
  const { query } = router || {};

  const { utm_medium: utmMedium, utm_source: utmSource, utm_campaign: utmCampaign } = query || {};

  // Get all utm keys from query object
  const utmKeys = Object.keys(query).filter((key) => key.startsWith('utm_'));

  useEffect(() => {
    // Exclude these keys from being saved to cookie
    const excludeQueryParams = [
      'query', // browse
      'sortBy', // browse
      'v[inventory.make][setting]', // browse
      'v[inventory.make][sv]', // browse
      'slug', // browse
      'ticketSlug', // buy online
      'browser_session_id',
      'lang', // language
    ];

    // Check 3 main utm keys exist
    if (utmMedium && utmSource && utmCampaign) {
      const utmCookie = Cookies.get(UTM_COOKIE_NAME);
      let isUTMEqual = true;

      // If a previous utm cookie exists, check if the new utm is different
      if (utmCookie) {
        const parsedUTM = JSON.parse(utmCookie);

        // Compare all utm_* keys between query and cookie objects
        utmKeys.forEach((key) => {
          if (!isEqual(query[key], parsedUTM[key])) {
            isUTMEqual = false;
          }
        });

        // If the new utm is same, quit, no need to update to cookie
        if (isUTMEqual) return;
      }

      // filtered out keys that should not be saved
      const filteredKeys: Array<FilteredKeys> = [];
      Object.keys(query).forEach((queryKey) => {
        if (!excludeQueryParams.includes(queryKey)) {
          filteredKeys.push({ [queryKey]: query?.[queryKey] || '' });
        }
      });
      const filteredKeysObj = filteredKeys?.reduce((prev, curr) => ({ ...prev, ...curr }), {});

      // Save to cookie
      Cookies.set(UTM_COOKIE_NAME, JSON.stringify(filteredKeysObj), { expires: EXPIRED_COOKIE_UTM });
    }
  }, [query, utmMedium, utmSource, utmCampaign]);
};

export const getUTM = (): string => {
  const utm = Cookies.get(UTM_COOKIE_NAME);
  if (utm) {
    const parsedUTM = JSON.parse(utm);
    return `?${qs.stringify(parsedUTM)}`;
  }
  return '';
};

export default useUTM;
