/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ICountryProps, IErrorResponse, IRejectedValue, ISuccessResponse } from '@source/interface/apiResponse';
import { ICreatePaymentSignPayload, IGetCurrentPaymentProps, IPaymentProps } from '@source/pages/Payments/types';
import Payments from '@source/services/Payments';

const sliceName = 'PAYMENT';

type stateProps = {
  loading: boolean;
  payments: IPaymentProps[];
  currentPayment: any;
};

const INITIAL_STATE: stateProps = {
  loading: false,
  payments: [],
  currentPayment: [],
};

export const getPaymentList = createAsyncThunk<ISuccessResponse<IPaymentProps[]>, ICountryProps, IRejectedValue>(
  `${sliceName}/getPaymentList`,
  async ({ country }: ICountryProps, { rejectWithValue }) => {
    try {
      const res = await Payments.getPaymentList(country);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCurrentPayment = createAsyncThunk<
  ISuccessResponse<IPaymentProps>,
  IGetCurrentPaymentProps,
  IRejectedValue
>(
  `${sliceName}/getCurrentPayment`,
  async ({ country, transactionId }: IGetCurrentPaymentProps, { rejectWithValue }) => {
    try {
      const res = await Payments.getCurrentPayment(country, transactionId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPaymentSignature = createAsyncThunk(
  `${sliceName}/createPaymentSignature`,
  async ({ refundId, payload }: ICreatePaymentSignPayload, { rejectWithValue }) => {
    try {
      const res = await Payments.createPaymentSignature({ refundId, payload });
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    // getPaymentServices
    builder.addCase(getPaymentList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.payments = payload.data;
    });
    builder.addCase(getPaymentList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCurrentPayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCurrentPayment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentPayment = payload.data;
    });
    builder.addCase(getCurrentPayment.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createPaymentSignature.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPaymentSignature.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(createPaymentSignature.rejected, (state) => {
      state.loading = false;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
const {} = slice.actions;
export {};

export default slice.reducer;
