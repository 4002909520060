/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, FC, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styled from 'styled-components';

import { Typography } from '@design-system/components';
import UserIcon from '@source/components/TopNavigation/assets/UserIcon';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import { IProfileResponseData } from '@source/interface/userProfile';
import { URL_CONFIG } from '@source/constants/urlConfig';
import useRegionConfig from '@source/hooks/useRegionConfig';
import AuthSmallItem from '@source/components/TopNavigation/DesktopNavigation/AuthSmallItem';
import CustomTextWithFontFamily from '@source/components/CustomTextWithFontFamily';

interface BrandLogoProps {
  title?: string;
  key: string;
  icon?: any;
  titleTranslate?: any;
  url?: string;
  hasBackground?: boolean;
  showLoginModal?: boolean;
  isExternal?: boolean;
  redirectTo?: string;
}

interface CarItem {
  title?: string;
  titleTranslate?: any;
  url?: string;
  key?: string;
  icon?: string;
  children?: Array<BrandLogoProps>;
  subMenus?: Array<BrandLogoProps>;
  hasBackground?: boolean;
}

const StyledSmallItemWithDropDown = styled.div`
  width: 100%;
  position: relative;

  ::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    border-top: 0.5px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
  }

  .desktop-nav-dropdown {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* margin-left: 10px; */
    ${(props) => props.theme.typo.style.body}
    padding: 13px 10px 13px 30px;

    .desktop-nav-icon {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 10px;
    }

    .desktop-nav-title {
      margin-right: 8px;
    }

    .chevron-down-icon {
      position: absolute;
      right: 20px;
      transition: transform 0.3s ease;
      ${(props) => props.theme.typo.style.smallButtonOrLink}
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};

      &.collapsed {
        transform: rotate(180deg);
      }
    }

    &:hover .desktop-nav-title {
      color: ${(props) => props.theme.color.primaryBase};
    }

    &:hover .chevron-down-icon {
      color: ${(props) => props.theme.color.primaryBase};
    }
  }

  .desk-nav-icon-dropdown {
    display: none;

    &.desk-nav-icon-dropdown-show {
      display: block;
    }

    .desk-nav-icon-dropdown-title {
      ${(props) => props.theme.typo.familyGoogle.regular};
      color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
      margin-left: 55px;
      padding: 13px 10px 13px 0px;
      display: flex;
      align-items: center;
      ${(props) => props.theme.typo.style.body};
      border-top: 1px solid;
      border-color: ${(props) => props.theme.color.onBackgroundLowEmphasis};
      width: calc(100% - 55px - 20px);

      .desktop-nav-icon-image {
        margin-right: 10px;
        display: flex;
        align-content: center;
      }

      &:hover {
        color: ${(props) => props.theme.color.primaryBase};
      }
    }

    .header {
      display: none;
    }

    .content {
      padding: 8px 20px 14px 20px;

      .content-item {
        padding: 15px 10px;
      }

      .action-container {
        .ant-btn {
          width: 100%;
        }
      }
    }
  }

  &.has-bg {
    background-color: ${(props) => props.theme.color.primaryBase};

    .user-icon {
      fill: ${(props) => props.theme.color.background};
    }

    .desktop-nav-icon {
      a {
        line-height: 0;
      }
    }

    .desktop-nav-title {
      color: ${(props) => props.theme.color.background};

      a {
        color: ${(props) => props.theme.color.background};
      }
    }

    .chevron-down-icon {
      color: ${(props) => props.theme.color.background};
    }

    .desk-nav-icon-dropdown {
      background-color: ${(props) => props.theme.color.primarySubtle};

      .desk-nav-icon-dropdown-title {
        border-color: transparent;
        margin: 0;
        width: 100%;
        padding: 13px 10px 13px 30px;
      }
    }

    &:after {
      border-top: 0.5px solid transparent;
    }

    &:hover {
      /* background-color: ${(props) => props.theme.color.primarySubtle};

      .user-icon {
        fill: ${(props) => props.theme.color.primaryBase};
      }

      .desktop-nav-title {
        color: ${(props) => props.theme.color.primaryBase};

        a {
          color: ${(props) => props.theme.color.primaryBase};
        }
      } */
      .chevron-down-icon {
        color: ${(props) => props.theme.color.background};
      }
    }

    + a,
    + div {
      &:after {
        border-top: 0.5px solid transparent;
      }
    }
  }
`;

const StyledSmallItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  border-top: 1px solid;
  border-color: ${(props) => props.theme.color.onBackgroundLowEmphasis};

  &:first-child {
    border-top: unset;
  }

  ${(props) => props.theme.typo.style.body}
  padding: 13px 10px 13px 30px;

  .desktop-nav-icon {
    display: flex;
    align-items: center;
    ${(props) => props.theme.typo.style.sHeader}
    height: 100%;
    margin-right: 10px;
  }

  &:hover .desktop-nav-title {
    color: ${(props) => props.theme.color.primaryBase};
  }

  &.has-bg {
    background-color: ${(props) => props.theme.color.primaryBase};

    .user-icon {
      fill: ${(props) => props.theme.color.background};
    }

    .desktop-nav-icon {
      a {
        line-height: 0;
      }
    }

    .desktop-nav-title {
      color: ${(props) => props.theme.color.background};
    }

    &:hover {
      background-color: ${(props) => props.theme.color.primaryHover};
    }
  }
`;

const StyledFloatSubMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  top: -1px;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.color.primaryBase};
  }

  .dropdown-menu-item-a {
    width: 100%;
    position: relative;

    ::after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 40px);
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
    }
  }
`;

const SmallItemWithDropdown = ({
  subItem,
  isAuthUserItem,
  profile,
}: {
  subItem: CarItem;
  isAuthUserItem: boolean;
  profile: IProfileResponseData | null;
}) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const { hasBackground } = subItem;
  const { t: translation } = useTranslation();
  const title = subItem?.title || translation(subItem?.titleTranslate as string);

  const {
    countryConfig: { country },
  } = useRegionConfig();

  const toggleNavBar = () => {
    setIsCollapse(!isCollapse);
  };

  return (
    <StyledSmallItemWithDropDown className={`${hasBackground ? 'has-bg' : ''}`}>
      <div
        className="desktop-nav-dropdown"
        onClick={() => {
          toggleNavBar();
        }}
      >
        {subItem.icon && (
          <div className="desktop-nav-icon">
            <Image
              src={subItem.icon}
              alt={subItem.title}
              width={16}
              height={16}
              className="desktop-nav-icon-image"
              layout="fixed"
            />
          </div>
        )}
        {isAuthUserItem && (
          <a
            href={URL_CONFIG({ region: country || 'sg', lang: i18next.language, path: 'profile' }).formatted}
            className="desktop-nav-icon"
            onClick={(e) => e.stopPropagation()}
          >
            <UserIcon width={15} height={15} />
          </a>
        )}
        <Typography variant="mainButtonOrLink" className="desktop-nav-title">
          {isAuthUserItem ? (
            <div>
              <a
                href={URL_CONFIG({ region: country || 'sg', lang: i18next.language, path: 'profile' }).formatted}
                onClick={(e) => e.stopPropagation()}
              >
                {profile?.name}
              </a>
            </div>
          ) : (
            <div>{title && title}</div>
          )}
        </Typography>
        <div className={`chevron-down-icon ${isCollapse ? 'collapsed' : ''}`}>
          <DownOutlined className="desktop-nav-icon-2" />
        </div>
      </div>

      <div className={`${isCollapse ? 'desk-nav-icon-dropdown-show' : ''} desk-nav-icon-dropdown`}>
        {isAuthUserItem ? (
          <AuthSmallItem profile={profile} />
        ) : (
          <div>
            {subItem.children?.map((item) => {
              const titleItem = item?.titleTranslate ? translation(item?.titleTranslate as string) : item?.title;

              return item.url ? (
                // <Link href={item.url} passHref>
                <a href={item.url}>
                  <div className="desk-nav-icon-dropdown-title" key={item.key}>
                    {item.icon && (
                      <div className="desktop-nav-icon-image">
                        <Image src={item.icon} alt={item.title} width={16} height={16} layout="fixed" />
                      </div>
                    )}
                    <span>{titleItem && titleItem} </span>
                  </div>
                </a>
              ) : (
                // </Link>
                <div className="desk-nav-icon-dropdown-title" key={item.key}>
                  {item.icon && (
                    <div className="desktop-nav-icon-image">
                      <Image src={item.icon} alt={item.title} width={16} height={16} layout="fixed" />
                    </div>
                  )}
                  <span>{titleItem && titleItem} </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </StyledSmallItemWithDropDown>
  );
};

const SmallNormalItem = ({ subItem }: { subItem: CarItem }) => {
  const { t: translation } = useTranslation();

  const title = subItem?.title || <CustomTextWithFontFamily text={translation(subItem?.titleTranslate as string)} />;
  const { hasBackground, key } = subItem;
  const { showLoginModal } = useUserProfileServices();
  const isLoginItem = key === 'login';

  return (
    <StyledSmallItem
      onClick={() => {
        if (isLoginItem) showLoginModal();
      }}
      className={`${hasBackground ? 'has-bg' : ''}`}
    >
      {subItem.icon && (
        <div className="desktop-nav-icon">
          <Image
            src={subItem.icon}
            alt={subItem.title}
            width={16}
            height={16}
            className="desktop-nav-icon-image"
            layout="fixed"
          />
        </div>
      )}
      {isLoginItem && (
        <div className="desktop-nav-icon">
          <UserIcon width={15} height={15} />
        </div>
      )}
      <Typography variant="mainButtonOrLink" className="desktop-nav-title">
        {title && title}
      </Typography>
    </StyledSmallItem>
  );
};

const SmallItem: FC<{ subItem: CarItem }> = ({ subItem }) => {
  const { key } = subItem;
  const { userProfileState, isAuthenticated } = useUserProfileServices();
  const { profile } = userProfileState;
  const isAuth = isAuthenticated();

  const isLoginItem = key === 'login';

  const isAuthUserItem = (isAuth && isLoginItem) || false;

  return subItem.children || isAuthUserItem ? (
    <SmallItemWithDropdown subItem={subItem} isAuthUserItem={isAuthUserItem} profile={profile} />
  ) : (
    <SmallNormalItem subItem={subItem} />
  );
};

const CustomDropdownMenu = ({ listItems }: { listItems: Array<BrandLogoProps> }) => {
  const {
    countryConfig: { country },
  } = useRegionConfig();
  const { isAuthenticated } = useUserProfileServices();
  const isAuth = isAuthenticated();
  const isMY = country === 'my';
  const isAuthMY = isMY && isAuth;

  const removeList = ['dealer', 'customer', 'seller'];

  const authListItemsMY = useMemo(
    () => {
      const newAuthListItems = listItems?.filter((item: BrandLogoProps) => !removeList.includes(item.key));
      newAuthListItems.unshift({
        titleTranslate: 'home.topNavigation.account.login',
        key: 'login',
        hasBackground: true,
      });
      return newAuthListItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthMY],
  );

  const newListItems = isAuthMY ? authListItemsMY : listItems;

  const { showLoginModal, redirectAfterVerified } = useUserProfileServices();

  const handleClickSubItem = (item: BrandLogoProps) => {
    showLoginModal();
    if (item.redirectTo) {
      redirectAfterVerified({ url: item.redirectTo });
    }
  };

  return (
    <StyledFloatSubMenu>
      {newListItems?.map((subItem) => {
        if (subItem?.showLoginModal) {
          return (
            // <Link href={subItem.url}>
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={() => handleClickSubItem(subItem)} className="dropdown-menu-item-a">
              <SmallItem subItem={subItem} key={subItem.key} />
            </a>
          );
        }

        if (subItem?.url) {
          return (
            // <Link href={subItem.url}>
            <a
              href={subItem.url}
              className="dropdown-menu-item-a"
              target={subItem?.isExternal ? '_blank' : '_self'}
              rel="noreferrer"
            >
              <SmallItem subItem={subItem} key={subItem.key} />
            </a>
          );
        }

        return (
          // </Link>
          <SmallItem subItem={subItem} key={subItem.key} />
        );
      })}
    </StyledFloatSubMenu>
  );
};

export default CustomDropdownMenu;
