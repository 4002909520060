import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
// import { CheckCircleFilled } from '@ant-design/icons';
// import { getStaticCDN } from '@design-system/utils/utils';
// import useRegionConfig from '@source/hooks/useRegionConfig';

require('@source/pages/Home/components/CarroCertifiedLite/CarroCertifiedLite.less');

const StyledPopoverContent = styled.div`
  background-color: #ffffff;
  color: ${(props) => props.theme.color.primaryBase};
  width: 310px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  .ccpo-container {
    position: relative;
    background: #fff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px 0;
    /* box-shadow: 0px 4px 15px 0px rgba(33, 33, 33, 0.15); */
  }

  .popover-title {
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-size: 16px;
    padding-top: 16px;
    padding-left: 24px;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;

    .title-text {
      margin-top: 7px;
    }
  }

  .img-text-title {
    color: ${(props) => props.theme.color.primaryBase};
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.338px;
    display: inline-block;

    & > span:first-child {
      display: inline-block;
      font-size: 28px;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }

    div {
      display: inline-block;
    }

    .bold {
      font-weight: 600;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }
  }

  .popover-desc {
    ${(props) => props.theme.typo.familyGoogle.regular};
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-align: left;
    padding: 24px;
    border-radius: 0px 0px 12px 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0px 4px 15px 0px rgba(33, 33, 33, 0.15);

    .detail-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;

      .check-icon {
        margin-top: 2px;
        border-radius: 50%;
        font-size: 20px;
      }

      .detail {
        line-height: 160%;
      }
    }
  }
`;

interface CarroCertifiedLitePopoverContentProps {
  content?: Record<string, string | ReactNode | string[]>;
}

const CarroCertifiedLitePopoverContent: React.FC<CarroCertifiedLitePopoverContentProps> = ({ content }) => {
  const { t } = useTranslation();
  // const {
  //   countryConfig: { country },
  // } = useRegionConfig();

  return (
    <StyledPopoverContent>
      <div className="ccpo-container">
        <div className="centered">
          <span className="img-text-title">
            <Trans
              defaults={t('shared.certified_section.certifiedLite')}
              components={{ span: <span />, specificFont: <span style={{ fontFamily: 'Poppins' }} /> }}
            />
          </span>
        </div>
      </div>
      <ul className="popover-desc">
        {content?.detailContentTranslationKey instanceof Array &&
          content?.detailContentTranslationKey?.map((info, index) => (
            <li className="detail-item" key={index as number}>
              {/* <CheckCircleFilled className="check-icon" /> */}
              <span className="detail">{t(info)}</span>
            </li>
          ))}
      </ul>
    </StyledPopoverContent>
  );
};

CarroCertifiedLitePopoverContent.defaultProps = {
  content: {
    detailContentTranslationKey: [
      'home.certifiedLiteModal.detail.inspection',
      'home.certifiedLiteModal.detail.guarantee',
      'home.certifiedLiteModal.detail.warranty',
      'home.certifiedLiteModal.detail.noMileage',
      'home.certifiedLiteModal.detail.noMajor',
    ],
  },
};

export default CarroCertifiedLitePopoverContent;
