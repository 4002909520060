/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { TRegionKey } from '@source/interface';
import { ICreateSellTicketResponse } from '@source/interface/sell';

import { SELL_FORM_INFO_PROPS } from '@source/pages/SellCar/constants';
import Sell from '@source/services/Sell';
import { IErrorResponse } from '@source/interface/apiResponse';

const sliceName = 'SELL_CARS';

// Todo: Init first
type SELL_STATE_PROPS = {
  ticket: Record<string, any> | null;
  loading: boolean;
  optionsLoading: boolean;
  formInfo: SELL_FORM_INFO_PROPS;
};

const INITIAL_STATE: SELL_STATE_PROPS = {
  ticket: null,
  loading: false,
  optionsLoading: false,
  formInfo: {
    formWrapperOriginalHeight: 350,
  },
};

export const createSellTicket = createAsyncThunk<
  ICreateSellTicketResponse,
  { country: TRegionKey; payload: Record<string, any>; isAuthenticated: boolean },
  {
    rejectValue: IErrorResponse;
  }
>(
  `${sliceName}/createSellTicket`,
  async (
    {
      country,
      payload,
      isAuthenticated,
    }: { country: TRegionKey; payload: Record<string, unknown>; isAuthenticated: boolean },
    { rejectWithValue },
  ) => {
    try {
      const res = await Sell.createSellTicket({ country, payload, isAuthenticated });

      return res.data;
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {
    setFormInfo(state, { payload }) {
      const { key, value } = payload;
      state.formInfo = { ...current(state).formInfo, [key]: value };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSellTicket.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSellTicket.fulfilled, (state, { payload }) => {
      state.ticket = payload?.data;
      state.loading = false;
    });
    builder.addCase(createSellTicket.rejected, (state) => {
      state.loading = false;
    });
  },
});

const { setFormInfo } = slice.actions;
export { setFormInfo };

export default slice.reducer;
