/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, memo } from 'react';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

interface ISliderArrow {
  disabled: boolean | undefined;
  onClick: any;
  showNav: boolean | undefined;
}

export const SliderNextArrow: FC<ISliderArrow> = memo(({ onClick, showNav }) => {
  if (showNav) {
    return (
      <span className="image-gallery-icon image-gallery-right-nav" onClick={onClick} aria-label="Next Slide">
        <RightOutlined />
      </span>
    );
  }
  return null;
});

SliderNextArrow.displayName = 'SliderNextArrow';

SliderNextArrow.defaultProps = {
  onClick: null,
  showNav: true,
};

export const SliderPrevArrow: FC<ISliderArrow> = memo(({ onClick, showNav }) => {
  if (showNav) {
    return (
      <span className="image-gallery-icon image-gallery-left-nav" onClick={onClick} aria-label="Left Slide">
        <LeftOutlined />
      </span>
    );
  }
  return null;
});

SliderPrevArrow.displayName = 'SliderPrevArrow';

SliderPrevArrow.defaultProps = {
  onClick: null,
  showNav: true,
};
export default {
  SliderNextArrow,
  SliderPrevArrow,
};
