import { useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import * as viewingHistoryAction from '@source/redux/slice/viewingHistorySlice';
import { TRegionKey } from '@source/interface';
import useViewedCars from '@source/hooks/useViewedCars';
import { useAppDispatch, useAppSelector } from './useStateHook';

const useViewingHistoryServices = () => {
  const dispatch = useAppDispatch();
  const { clearViewedCar } = useViewedCars();

  //  Selectors
  const viewingHistoryState = useAppSelector((state) => state.viewingHistory, shallowEqual);

  // Dispatch Function
  const fetchViewingHistory = useCallback(
    ({ country, data, isAppendList }: { country: TRegionKey; data: any; isAppendList?: boolean }) => {
      dispatch(viewingHistoryAction.fetchViewingHistory({ country, data, isAppendList }));
    },
    [dispatch],
  );

  const uploadViewingHistory = useCallback(
    ({ country, data }: { country: TRegionKey; data: any }) => {
      dispatch(viewingHistoryAction.uploadViewingHistory({ country, data })).then(() => {
        clearViewedCar(country);
      });
    },
    [dispatch, clearViewedCar],
  );

  const resetState = useCallback(() => {
    dispatch(viewingHistoryAction.actions.resetState());
  }, [dispatch]);

  const updateStickedDate = useCallback(
    (date: string) => {
      dispatch(viewingHistoryAction.actions.setStickedDate(date));
    },
    [dispatch],
  );

  const updateCategory = useCallback(
    (category?: string) => {
      dispatch(viewingHistoryAction.actions.setCategory(category));
    },
    [dispatch],
  );

  return {
    viewingHistoryState,
    fetchViewingHistory,
    uploadViewingHistory,
    resetState,
    updateStickedDate,
    updateCategory,
  };
};

export default useViewingHistoryServices;
