import { useState, useEffect, FC, ReactNode, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import qs from 'qs';
import i18next from 'i18next';
import jsCookie from 'js-cookie';
import { getIPLocation } from 'react-ip-location';
import isEmpty from 'lodash/isEmpty';
import {
  removeSelectedLocationOnStorage,
  isSupportedLanguage,
  setSelectedCountryOnCookie,
  getSelectedCountryFromCookie,
  setSelectedLanguageOnCookie,
  getSelectedLanguageFromCookie,
} from '@source/services/Language';
import RegionContext, { IRegionContext, ICountryConfig } from '@source/context/RegionContext';
import { TLanguageKey, TRegionKey } from '@source/interface';
import {
  DEFAULT_LANGUAGE_MAP_BY_COUNTRY,
  AVAILABLE_COUNTRIES,
  SUPPORTED_COUNTRIES,
  DEFAULT_COUNTRY_KEY,
  CR_WEB_CARRO_DOMAIN,
} from '@source/constants/config';
import {
  getLanguageInURL,
  formatLanguageCodeInUrl,
  getHTMLLang,
  getSellUrlNameByLocalLanguage,
} from '@source/utils/utils';
import { COOKIE_KEYS } from '@source/constants/common';
import useForceHtmlLangAttr from '@source/hooks/useForceHtmlLangAttr';

const RegionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const isDefaultLocale = !router?.locale || router?.locale === DEFAULT_COUNTRY_KEY;
  const cookieRegion = jsCookie.get(COOKIE_KEYS.STORED_REGION);
  const cookieUserLocation = jsCookie.get(COOKIE_KEYS.USER_REGION);
  const [countryAccess, setCountryAccess] = useState(cookieUserLocation);

  const [countryConfig, setCountryConfig] = useState<ICountryConfig>({
    isPersisted: false,
    isStoredInBrowser: false,
    country: (isDefaultLocale ? cookieRegion || '' : router?.locale) as TRegionKey,
    isFirstTimeAccess: false,
  });

  const { i18n } = useTranslation();

  // i18n add lang attr to html dynamically
  // override lang attr to format: [lang]-[REGION]
  useForceHtmlLangAttr(getHTMLLang(i18n.language as TLanguageKey, countryConfig.country));

  const getUrlQuery = (asPath: string) => {
    // Get query string without region
    let queryString = asPath.split('?')?.[1];
    const qsObject = { ...qs.parse(qs.stringify(queryString)) };
    delete qsObject.region;
    delete qsObject.lang;
    queryString = qsObject && !isEmpty(qsObject) ? `?${qs.stringify(qsObject)}` : '';
    return queryString;
  };

  const formatAsPath = (asPath: string, newLanguage: TLanguageKey) => {
    const formatLanguage = newLanguage?.toLowerCase();
    let newAsPath = '';
    if (asPath?.length > 1) {
      const prevLangId = getLanguageInURL(asPath);
      if (prevLangId) {
        newAsPath = asPath.replace(`/${prevLangId}`, `/${formatLanguage}`);
      } else {
        newAsPath = asPath;
      }
    } else {
      newAsPath = `/${formatLanguage}`;
    }
    return newAsPath;
  };
  // init region in client side

  const initRegionData = (country: TRegionKey, isFirstTimeAccessFromOutSide?: boolean) => {
    const regionStoredInCookie = getSelectedCountryFromCookie();
    if (country) {
      const defaultLangByCountry = DEFAULT_LANGUAGE_MAP_BY_COUNTRY?.[country]?.code;
      const languageStoredByRegion = getSelectedLanguageFromCookie(country);

      let currentLanguage = (router?.query?.lang as TLanguageKey) || (getLanguageInURL(router?.asPath) as TLanguageKey);

      if (!isSupportedLanguage(country, currentLanguage) && languageStoredByRegion) {
        currentLanguage = languageStoredByRegion;
      }

      currentLanguage = formatLanguageCodeInUrl(currentLanguage);

      // https://carrotech.atlassian.net/browse/CCV-1361
      // Add new ms lang for SG region
      if (country === 'sg' && currentLanguage === 'ms') currentLanguage = 'ms-SG';

      // got language in the url or in storage
      if (currentLanguage && isSupportedLanguage(country, currentLanguage)) {
        setSelectedLanguageOnCookie(currentLanguage, country);
        i18n.changeLanguage(currentLanguage);
      } else {
        setSelectedLanguageOnCookie(defaultLangByCountry, country);
        i18n.changeLanguage(defaultLangByCountry);
      }

      /** hacky solution to format sell page url
       * /sell -> /region/lang/sell
       */
      if (['/sell'].includes(router?.asPath)) {
        let targetLanguage = defaultLangByCountry.toLowerCase() as TLanguageKey;

        if (isSupportedLanguage(country, currentLanguage)) {
          targetLanguage = currentLanguage === 'ms-SG' ? 'ms' : (currentLanguage?.toLowerCase() as TLanguageKey);
        }

        router.push(`/${country}/${targetLanguage}/${getSellUrlNameByLocalLanguage(country, targetLanguage)}`);
      }

      if (!regionStoredInCookie || regionStoredInCookie !== country) {
        setSelectedCountryOnCookie(country);
        removeSelectedLocationOnStorage();
      }
      setCountryConfig({
        isPersisted: true,
        country: country as TRegionKey,
        isStoredInBrowser: !!regionStoredInCookie,
        isFirstTimeAccess: isFirstTimeAccessFromOutSide || false,
      });
    }
  };
  // fetchUserLocation - will be removed after verifying. location will be checked & returned in middleware
  const fetchUserLocation = async (webOrigin: string) => {
    const isWebCarro = CR_WEB_CARRO_DOMAIN?.includes(webOrigin);
    const ip = await getIPLocation();
    const ipLocation = ip?.country?.toLowerCase();
    jsCookie.set(COOKIE_KEYS.USER_REGION, ipLocation, { expires: 365 });
    setCountryAccess(ipLocation);
    jsCookie.set(COOKIE_KEYS.FIRST_TIME_ACCESS, 'true', { expires: 365 });
    if (isDefaultLocale && !jsCookie.get(COOKIE_KEYS.STORED_REGION)) {
      const newLocation =
        ipLocation && AVAILABLE_COUNTRIES.includes(ipLocation) ? ipLocation : SUPPORTED_COUNTRIES?.SG?.code; // if user locale is not sg | id | my | th | tw | hk -> set sg as default

      setSelectedCountryOnCookie(newLocation);

      const isFirstTimeOutSideRegionAccess = ipLocation && !AVAILABLE_COUNTRIES.includes(ipLocation) === true;
      initRegionData(newLocation, isFirstTimeOutSideRegionAccess);
    }
    // update first time access
    const isFirstTimeOutSideRegionAccess = ipLocation && !AVAILABLE_COUNTRIES.includes(ipLocation) === true;

    if (isFirstTimeOutSideRegionAccess) {
      setCountryConfig((prev) => ({ ...prev, isFirstTimeAccess: true }));
    }
  };

  useEffect(() => {
    if (router?.locale && !isDefaultLocale) {
      initRegionData(router?.locale as TRegionKey);
    }
    if (isDefaultLocale && getSelectedCountryFromCookie()) {
      initRegionData(getSelectedCountryFromCookie() as TRegionKey);
    }
  }, [router?.locale]);

  useEffect(() => {
    if (!cookieUserLocation) {
      const urlHost = window?.location?.host;
      fetchUserLocation(urlHost);
    }
  }, []);

  const changeCountry = useCallback(
    (_country: TRegionKey) => {
      if (_country === countryConfig.country) {
        return;
      }
      setSelectedCountryOnCookie(_country);
      removeSelectedLocationOnStorage();

      const defaultLang = DEFAULT_LANGUAGE_MAP_BY_COUNTRY?.[_country as TRegionKey]?.code;
      const href = `/${_country}/${defaultLang?.toLowerCase()}${getUrlQuery(router.asPath)}`;
      window.location.href = href;
    },
    [countryConfig, router],
  );

  const changeLanguage = useCallback(
    (_language: TLanguageKey) => {
      const prevLangId = i18next.language;
      if (_language !== prevLangId) {
        setSelectedLanguageOnCookie(_language, countryConfig?.country as TRegionKey);
        const asPath = router?.asPath;
        // https://carrotech.atlassian.net/browse/CCV-1361
        // For new ms lang for SG, we use ms-SG code
        // but in the url, /sg/ms is better looking than /sg/ms-SG
        const newHref = countryConfig?.country
          ? `/${countryConfig?.country}${formatAsPath(asPath, _language === 'ms-SG' ? 'ms' : _language)}`
          : '';

        if (newHref) {
          window.location.href = newHref; // page reload when change languages for SEO
        } else {
          i18n.changeLanguage(_language);
        }
      }
    },
    [i18n, router],
  );

  const providerValue = useMemo<IRegionContext>(
    () => ({
      countryConfig,
      changeCountry,
      changeLanguage,
    }),
    [countryConfig, changeCountry, changeLanguage],
  );

  return <RegionContext.Provider value={providerValue}>{children}</RegionContext.Provider>;
};

export default RegionProvider;
