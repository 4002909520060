import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { useRouter } from 'next/router';
import { CaretDownOutlined } from '@ant-design/icons';
import styled, { createGlobalStyle } from 'styled-components';
import { MenuItem } from '@source/interface';

const StyledDropdownText = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  ${(props) => props.theme.typo.style.mainButtonOrLink}
  ${(props) => props.theme.typo.familyGoogle.poppinsSemiBold};

  &:hover {
    color: ${(props) => props.theme.color.primaryBase};
  }

  &.active {
    color: ${(props) => props.theme.color.primaryBase};
  }

  .submenu-icon {
    transition: transform 0.3s ease;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'unset')};

    .anticon {
      font-size: 12px;
    }
  }
`;

const StyledDropdownMenu = styled.div`
  background: ${(props) => props.theme.color.background};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: rgb(33 33 33 / 50%) 0px 4px 25px;

  .submenu-item {
    ${(props) => props.theme.typo.familyGoogle.poppinsRegular};
    ${(props) => props.theme.typo.style.body};
    color: ${(props) => props.theme.color.onSurface};
    background: ${(props) => props.theme.color.background};
    padding: 10px 20px;
    display: block;

    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.color.surfaceLight};
    }

    &.active {
      background: ${(props) => props.theme.color.lightMode};
    }
  }
`;

const OverrideDropdownStyle = createGlobalStyle`
  .submenu-dropdown {
    top: 55px !important;
  }
`;

interface PrimaryMobileNavSubmenuProps {
  item: MenuItem;
}

const PrimaryMobileNavSubmenu = ({ item }: PrimaryMobileNavSubmenuProps) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { pathname } = router;

  const menu = (
    <StyledDropdownMenu>
      {item.subMenus.map((subItem: MenuItem) => (
        <a
          href={subItem.url}
          key={subItem.key}
          className={`submenu-item ${pathname === subItem.pathName ? 'active' : ''}`}
        >
          {subItem.titleTranslate}
        </a>
      ))}
    </StyledDropdownMenu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottom"
        overlayClassName="submenu-dropdown"
        trigger={['click']}
        onOpenChange={setOpen}
      >
        <StyledDropdownText open={open}>
          {item.titleTranslate}{' '}
          <div className="submenu-icon">
            <CaretDownOutlined />
          </div>
        </StyledDropdownText>
      </Dropdown>
      <OverrideDropdownStyle />
    </>
  );
};

export default PrimaryMobileNavSubmenu;
