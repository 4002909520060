/* eslint-disable camelcase */
import { TRegionKey } from '@source/interface';
import { LOCAL_STORAGE_KEYS } from '@source/constants/common';

interface IViewedCarProps {
  listing_id: string | number;
  car_title: string;
  timestamp: Date;
  source: string;
}

const useViewedCars = () => {
  const setViewedCar = ({ listingId, carTitle, source, region }: any) => {
    const viewedCarsLocalStorage = localStorage.getItem(`${LOCAL_STORAGE_KEYS.VIEWED_CARS}_${region}`);

    const existingViewedCars = viewedCarsLocalStorage ? JSON.parse(viewedCarsLocalStorage) : [];
    const isExistingIndex = existingViewedCars.findIndex((item: any) => item.listing_id === listingId);

    let viewedCars = existingViewedCars;
    // the car has already existed
    if (isExistingIndex !== -1) {
      viewedCars[isExistingIndex] = {
        listing_id: listingId,
        car_title: carTitle,
        timestamp: new Date(),
        source,
      };
    } else {
      // the car hasn't exist yet
      viewedCars = [
        ...viewedCars,
        {
          listing_id: listingId,
          car_title: carTitle,
          timestamp: new Date(),
          source,
        },
      ];
    }

    localStorage.setItem(`${LOCAL_STORAGE_KEYS.VIEWED_CARS}_${region}`, JSON.stringify(viewedCars));
  };

  const clearViewedCar = (region: TRegionKey) => {
    const viewedCars: Array<IViewedCarProps> = [];
    localStorage.setItem(`${LOCAL_STORAGE_KEYS.VIEWED_CARS}_${region}`, JSON.stringify(viewedCars));
  };

  const getViewedCars = (region: TRegionKey) => {
    let listViewdCars = [];
    if (typeof window !== 'undefined') {
      const viewedCars = localStorage.getItem(`${LOCAL_STORAGE_KEYS.VIEWED_CARS}_${region}`);
      if (viewedCars) {
        listViewdCars = JSON.parse(viewedCars);
      }
    }
    return listViewdCars;
  };

  return {
    setViewedCar,
    getViewedCars,
    clearViewedCar,
  };
};

export default useViewedCars;
