/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Image from 'next/image';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { SUPPORTED_COUNTRIES } from '@source/constants/config';
import { ICountry } from '@source/interface';
import { getStaticCDN } from '@design-system/utils/utils';

interface IFirstTimeAccess {
  onClose: any;
}

const StyledWrapper = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  ${(props) => props.theme.typo.familyGoogle.regular};
  .or {
    text-align: center;
  }
`;

const StyledFooter = styled.div`
  text-align: center;
  .ant-btn {
    box-shadow: ${(props) => props.theme.color.boxShadow.primaryFocus};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }
`;

const StyledContentHeader = styled.div`
  background: ${(props) => props.theme.color.primarySubtle};
  position: relative;
  padding: 49px 16px;
  font-weight: 600;
  font-size: 14px;
  @media screen and (min-width: 768px) {
    font-size: 16px;
    padding: 54px 35px;
  }
`;

const StyledContentWrap = styled.div`
  padding: 20px 35px 5px 35px;
`;

const StyledRegionItems = styled.div``;

const StyledRegionItem = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  margin-bottom: 20px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .country {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .region-item-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledSecondDesc = styled.div``;
const FlagText = styled.span`
  display: flex;
`;
const StyledCountryText = styled.span`
  color: ${(props) => props.theme.color.onSurface};
`;
const StyledMainDecs = styled.div`
  position: relative;
`;
const StyledOutsideImgWrap = styled.div`
  position: absolute;
  top: -30px;
  right: 0;
  .img {
    width: 68px;
    height: 50px;
  }
  @media screen and (min-width: 768px) {
    .img {
      width: 82px;
      height: 60px;
    }
  }
`;
const StyledMainTextWrap = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
`;
const StyledMainText = styled.span``;
interface IFlagItem extends ICountry {
  onClick?: any;
}
const FlagItem = ({ code, webUrl, iconV2, onClick, displayName }: IFlagItem) => {
  const { t } = useTranslation();
  const onClickLink = () => {
    if (onClick && typeof onClick === 'function') {
      onClick(code);
    } else if (webUrl) {
      window.location.href = webUrl as string;
    }
  };
  return (
    <StyledRegionItem key={code}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={onClickLink} className="region-item-link">
        <FlagText aria-hidden="true">
          <Image
            src={iconV2}
            alt="apple"
            className="side-navigation-region-flag-image icon-v2"
            width={50}
            height={25}
          />
          <StyledCountryText>{displayName || t(`home.firstTimeAccess.country.${code}`)}</StyledCountryText>
        </FlagText>
      </div>
    </StyledRegionItem>
  );
};
const FirstTimeAccessContent: React.FC<IFirstTimeAccess> = ({ onClose }) => {
  const {
    countryConfig: { country },
  } = useRegionConfig();
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledContentHeader>
        <StyledMainDecs>
          <StyledMainTextWrap className=" mb-16">
            <StyledMainText>
              {t('home.firstTimeAccess.mainDesc', {
                carroBrand: t(`home.firstTimeAccess.carroBrand.${country}`),
              })}
            </StyledMainText>
          </StyledMainTextWrap>
          <StyledOutsideImgWrap>
            <img
              className="img"
              src={getStaticCDN('/assets/shared/icons/outside-region.svg')}
              alt="outside the region"
            />
          </StyledOutsideImgWrap>
        </StyledMainDecs>
      </StyledContentHeader>

      <StyledContentWrap>
        <StyledSecondDesc>
          <div className="mb-16">{t('home.firstTimeAccess.secondDesc')}</div>
        </StyledSecondDesc>
        <StyledRegionItems>
          {Object.values(SUPPORTED_COUNTRIES).map((region) => {
            if (region.code !== country) {
              return <FlagItem {...region} displayName={t(`home.firstTimeAccess.country.${region.code}`)} />;
            }
            return null;
          })}
        </StyledRegionItems>
        <div className="or">{t('home.firstTimeAccess.or')}</div>
        <StyledFooter className="mt-32">
          {Object.values(SUPPORTED_COUNTRIES).map((region) => {
            const updateProps = {
              ...region,
              webUrl: '',
            };
            if (region.code === country) {
              return <FlagItem {...updateProps} displayName={t('home.firstTimeAccess.stayOn')} onClick={onClose} />;
            }
            return null;
          })}
        </StyledFooter>
      </StyledContentWrap>
    </StyledWrapper>
  );
};

FirstTimeAccessContent.defaultProps = {};

export default FirstTimeAccessContent;
