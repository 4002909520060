/* eslint-disable prefer-destructuring */
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG;
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY;
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID;
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH;
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_TW = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_TW;
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP;
const NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK = process.env.NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_SG = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_SG;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_MY = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_MY;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_ID = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_ID;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TH = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TH;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TW = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TW;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_JP = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_JP;
const NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_HK = process.env.NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_HK;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_SG = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_SG;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_MY = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_MY;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_ID = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_ID;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TH = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TH;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TW = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TW;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_JP = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_JP;
const NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_HK = process.env.NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_HK;

const NEXT_PUBLIC_ALGOLIA_API_KEY_SG = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_SG;
const NEXT_PUBLIC_ALGOLIA_API_KEY_MY = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_MY;
const NEXT_PUBLIC_ALGOLIA_API_KEY_ID = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_ID;
const NEXT_PUBLIC_ALGOLIA_API_KEY_TH = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_TH;
const NEXT_PUBLIC_ALGOLIA_API_KEY_TW = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_TW; // todo: update key for jp in env config
const NEXT_PUBLIC_ALGOLIA_API_KEY_JP = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_TW;
const NEXT_PUBLIC_ALGOLIA_API_KEY_HK = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY_TW;
const NEXT_PUBLIC_WHOLESALE_WEB_URL = process.env.NEXT_PUBLIC_WHOLESALE_WEB_URL;
const NEXT_PUBLIC_ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
const NEXT_PUBLIC_RUDDER_STACK_DATA_PLAN_URL = process.env.NEXT_PUBLIC_RUDDER_STACK_DATA_PLAN_URL;
const NEXT_PUBLIC_STRAPI_URL_SG = process.env.NEXT_PUBLIC_STRAPI_URL_SG;
const NEXT_PUBLIC_STRAPI_URL_MY = process.env.NEXT_PUBLIC_STRAPI_URL_MY;
const NEXT_PUBLIC_STRAPI_URL_ID = process.env.NEXT_PUBLIC_STRAPI_URL_ID;
const NEXT_PUBLIC_STRAPI_URL_TH = process.env.NEXT_PUBLIC_STRAPI_URL_TH;
const NEXT_PUBLIC_STRAPI_URL_TW = process.env.NEXT_PUBLIC_STRAPI_URL_TW;
const NEXT_PUBLIC_STRAPI_URL_JP = process.env.NEXT_PUBLIC_STRAPI_URL_JP;
const NEXT_PUBLIC_STRAPI_URL_HK = process.env.NEXT_PUBLIC_STRAPI_URL_HK;
const NEXT_PUBLIC_APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
const NEXT_PUBLIC_WHATSAPP_NUMBER_SG = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER_SG;
const NEXT_PUBLIC_WHATSAPP_NUMBER_MY = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER_MY;
const NEXT_PUBLIC_WHATSAPP_NUMBER_HK = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER_HK;
export const NEXT_PUBLIC_WHATSAPP_CONTACT_NUMBER_MY = process.env.NEXT_PUBLIC_WHATSAPP_CONTACT_NUMBER_MY;
const NEXT_PUBLIC_WHATSAPP_NUMBER_ID = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER_ID;
const NEXT_PUBLIC_PHONE_NUMBER_SG = process.env.NEXT_PUBLIC_PHONE_NUMBER_SG;
const NEXT_PUBLIC_PHONE_NUMBER_MY = process.env.NEXT_PUBLIC_PHONE_NUMBER_MY;
const NEXT_PUBLIC_PHONE_NUMBER_ID = process.env.NEXT_PUBLIC_PHONE_NUMBER_ID;
const NEXT_PUBLIC_PHONE_NUMBER_TH = process.env.NEXT_PUBLIC_PHONE_NUMBER_TH;
const NEXT_PUBLIC_PHONE_NUMBER_JP = process.env.NEXT_PUBLIC_PHONE_NUMBER_JP;

const NEXT_PUBLIC_ENV_URL_SG = process.env.NEXT_PUBLIC_ENV_URL_SG;
const NEXT_PUBLIC_ENV_URL_MY = process.env.NEXT_PUBLIC_ENV_URL_MY;
const NEXT_PUBLIC_ENV_URL_ID = process.env.NEXT_PUBLIC_ENV_URL_ID;
const NEXT_PUBLIC_ENV_URL_TH = process.env.NEXT_PUBLIC_ENV_URL_TH;
const NEXT_PUBLIC_ENV_URL_TW = process.env.NEXT_PUBLIC_ENV_URL_TW;
const NEXT_PUBLIC_ENV_URL_JP = process.env.NEXT_PUBLIC_ENV_URL_JP;
const NEXT_PUBLIC_WHOLESALE_URL = process.env.NEXT_PUBLIC_WHOLESALE_URL;
const NEXT_PUBLIC_LINE_APP_ID_TH = process.env.NEXT_PUBLIC_LINE_APP_ID_TH;
const NEXT_PUBLIC_LINE_APP_ID_TW = process.env.NEXT_PUBLIC_LINE_APP_ID_TW;
const NEXT_PUBLIC_LINE_APP_ID_JP = process.env.NEXT_PUBLIC_LINE_APP_ID_JP;
const NEXT_PUBLIC_CARRO_WEB_CDN = process.env.NEXT_PUBLIC_CARRO_WEB_CDN;
const NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH = process.env.NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH;
const NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TW = process.env.NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TW;
const NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP = process.env.NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP;

const NEXT_PUBLIC_SHORT_URL = process.env.NEXT_PUBLIC_SHORT_URL;
const NEXT_PUBLIC_SHORT_URL_KEY = process.env.NEXT_PUBLIC_SHORT_URL_KEY;
const NEXT_PUBLIC_CR_WEB_ = process.env.NEXT_PUBLIC_CR_WEB_;
const NEXT_PUBLIC_ALGOLIA_PROMOTION_CAR_MY = process.env.NEXT_PUBLIC_ALGOLIA_PROMOTION_CAR_MY;
const NEXT_PUBLIC_GOOGLE_SCRIPT_URL = process.env.NEXT_PUBLIC_GOOGLE_SCRIPT_URL;
const NEXT_PUBLIC_PROMOTION_GOOGLE_SHEET_KEY_MY = process.env.NEXT_PUBLIC_PROMOTION_GOOGLE_SHEET_KEY_MY;
const NEXT_PUBLIC_DEALER_PORTAL_URL_MY = process.env.NEXT_PUBLIC_DEALER_PORTAL_URL_MY;

const NEXT_PUBLIC_WEB_CR = process.env.NEXT_PUBLIC_WEB_CR;
const NEXT_PUBLIC_WEB_CR_MY = process.env.NEXT_PUBLIC_WEB_CR_MY;
const NEXT_PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const NEXT_PUBLIC_SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

const NEXT_PUBLIC_INSURANCE_URL_SG = process.env.NEXT_PUBLIC_INSURANCE_URL_SG;
const NEXT_PUBLIC_INSURANCE_URL_MY = process.env.NEXT_PUBLIC_INSURANCE_URL_MY;

const NEXT_PUBLIC_INSURANCE_OMNI_URL_SG = process.env.NEXT_PUBLIC_INSURANCE_OMNI_URL_SG;
const NEXT_PUBLIC_INSURANCE_OMNI_URL_MY = process.env.NEXT_PUBLIC_INSURANCE_OMNI_URL_MY;

const NEXT_PUBLIC_SELLER_PORTAL_URL_MY = process.env.NEXT_PUBLIC_SELLER_PORTAL_URL_MY;

const NEXT_PUBLIC_BLOG_URL_MY = process.env.NEXT_PUBLIC_BLOG_URL_MY;
const NEXT_PUBLIC_BLOG_URL_HK = process.env.NEXT_PUBLIC_BLOG_URL_HK;

const NEXT_PUBLIC_IS_MAINTENANCE_MODE = process.env.NEXT_PUBLIC_IS_MAINTENANCE_MODE;

const NEXT_PUBLIC_ALGOLIA_NEW_CAR_SG = process.env.NEXT_PUBLIC_ALGOLIA_NEW_CAR_SG;

const NEXT_PUBLIC_GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

export {
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_SG,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_MY,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_ID,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_TH,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_TW,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_JP,
  NEXT_PUBLIC_ALGOLIA_SHOWROOM_HK,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_SG,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_MY,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_ID,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TH,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_TW,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_JP,
  NEXT_PUBLIC_ALGOLIA_POPULAR_CAR_HK,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_SG,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_MY,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_ID,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TH,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_TW,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_JP,
  NEXT_PUBLIC_ALGOLIA_SIMILAR_CAR_HK,
  NEXT_PUBLIC_ALGOLIA_API_KEY_SG,
  NEXT_PUBLIC_ALGOLIA_API_KEY_MY,
  NEXT_PUBLIC_ALGOLIA_API_KEY_ID,
  NEXT_PUBLIC_ALGOLIA_API_KEY_TH,
  NEXT_PUBLIC_ALGOLIA_API_KEY_TW,
  NEXT_PUBLIC_ALGOLIA_API_KEY_JP,
  NEXT_PUBLIC_ALGOLIA_API_KEY_HK,
  NEXT_PUBLIC_WHOLESALE_WEB_URL,
  NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_RUDDER_STACK_DATA_PLAN_URL,
  NEXT_PUBLIC_STRAPI_URL_SG,
  NEXT_PUBLIC_STRAPI_URL_MY,
  NEXT_PUBLIC_STRAPI_URL_ID,
  NEXT_PUBLIC_STRAPI_URL_TH,
  NEXT_PUBLIC_STRAPI_URL_TW,
  NEXT_PUBLIC_STRAPI_URL_JP,
  NEXT_PUBLIC_STRAPI_URL_HK,
  NEXT_PUBLIC_APP_ENV,
  NEXT_PUBLIC_WHATSAPP_NUMBER_SG,
  NEXT_PUBLIC_WHATSAPP_NUMBER_MY,
  NEXT_PUBLIC_WHATSAPP_NUMBER_ID,
  NEXT_PUBLIC_PHONE_NUMBER_SG,
  NEXT_PUBLIC_PHONE_NUMBER_TH,
  NEXT_PUBLIC_PHONE_NUMBER_ID,
  NEXT_PUBLIC_PHONE_NUMBER_MY,
  NEXT_PUBLIC_PHONE_NUMBER_JP,
  NEXT_PUBLIC_ENV_URL_SG,
  NEXT_PUBLIC_ENV_URL_MY,
  NEXT_PUBLIC_ENV_URL_ID,
  NEXT_PUBLIC_ENV_URL_TH,
  NEXT_PUBLIC_ENV_URL_TW,
  NEXT_PUBLIC_ENV_URL_JP,
  NEXT_PUBLIC_WHOLESALE_URL,
  NEXT_PUBLIC_LINE_APP_ID_TH,
  NEXT_PUBLIC_LINE_APP_ID_TW,
  NEXT_PUBLIC_LINE_APP_ID_JP,
  NEXT_PUBLIC_CARRO_WEB_CDN,
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH,
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TW,
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP,
  NEXT_PUBLIC_SHORT_URL,
  NEXT_PUBLIC_SHORT_URL_KEY,
  NEXT_PUBLIC_CR_WEB_,
  NEXT_PUBLIC_ALGOLIA_PROMOTION_CAR_MY,
  NEXT_PUBLIC_PROMOTION_GOOGLE_SHEET_KEY_MY,
  NEXT_PUBLIC_GOOGLE_SCRIPT_URL,
  NEXT_PUBLIC_DEALER_PORTAL_URL_MY,
  NEXT_PUBLIC_WEB_CR,
  NEXT_PUBLIC_WEB_CR_MY,
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  NEXT_PUBLIC_INSURANCE_URL_SG,
  NEXT_PUBLIC_INSURANCE_URL_MY,
  NEXT_PUBLIC_INSURANCE_OMNI_URL_SG,
  NEXT_PUBLIC_INSURANCE_OMNI_URL_MY,
  NEXT_PUBLIC_SELLER_PORTAL_URL_MY,
  NEXT_PUBLIC_BLOG_URL_MY,
  NEXT_PUBLIC_IS_MAINTENANCE_MODE,
  NEXT_PUBLIC_ALGOLIA_NEW_CAR_SG,
  NEXT_PUBLIC_WHATSAPP_NUMBER_HK,
  NEXT_PUBLIC_BLOG_URL_HK,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
};
