import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import Image from 'next/image';
import { WhatsAppOutlined } from '@ant-design/icons';

import { getStaticCDN } from '@design-system/utils/utils';
import { useWhatsappPrefillMessage } from '@source/utils/useSocialPrefillMessage';
import { CRAZY_RABBIT_WEB_URL, WHATSAPP_NUMBER } from '@source/constants/common';
import dayjs from 'dayjs';
import { getUTM } from '@source/hooks/useUTM';

require('@design-system/components/BrowsingCard/AgentWarrantyPopover.less');

const AgentWarrantyWrapper = styled.div`
  ${props => props.theme.typo.familyGoogle.regular};
  cursor: auto;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;

    .text-title {
      color: #ff4c00;
      ${props => props.theme.typo.familyGoogle.semiBold};
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .desc {
    margin-top: 12px;
    color: #363636;
    font-size: 14px;
    line-height: 140%;
  }

  .info {
    margin-top: 12px;

    .label {
      color: #a1a1a1;
      font-size: 12px;
      line-height: 20px;
    }

    .value {
      color: #363636;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .whatsapp {
    margin-top: 12px;
    border: 1px solid #3bd876;
    padding: 5px 10px;
    border-radius: 8px;
  }
`;

const StyledWhatsAppButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3bd876;

  .whatsapp-text {
    margin-left: 4px;
  }
`;

interface AgentWarrantyPopoverContentProps {
  carTitle: any;
  href: string | undefined;
  agentWarranty?: {
    expired_date: string;
    mileage: number;
    warranty_by: string;
  };
  onClickWA?: () => void;
}

const AGENT_WARRANTY = getStaticCDN('/assets/sg/icons/agent-warranty-icon.svg');

const AgentWarrantyPopoverContent: React.FC<AgentWarrantyPopoverContentProps> = ({
  carTitle,
  href,
  agentWarranty,
  onClickWA,
}) => {
  const { t } = useTranslation();

  // Get the query string by using current window location search
  let queryString = window ? window?.location?.search : '';

  // If the query string is empty, get utm from session storage
  if (queryString?.length === 0) {
    queryString = getUTM();
  }

  const currentConfig = {
    prefilled: t('home.whatsapp.enquireNow', { carTitle }),
    defaultLink: window
      ? `${window.location.origin}${href}${queryString}`
      : `${CRAZY_RABBIT_WEB_URL?.sg}${href}${queryString}`,
    phoneNumber: WHATSAPP_NUMBER?.sg,
  };
  const { whatsappLink } = useWhatsappPrefillMessage({
    country: 'sg',
    whatsappNumber: currentConfig?.phoneNumber,
    customWhatsappMsg: `${currentConfig.prefilled}${
      window ? `${window.location.origin}${href}${queryString}` : `${CRAZY_RABBIT_WEB_URL?.sg}${href}${queryString}`
    }`,
    defaultLink: currentConfig?.defaultLink,
  });

  const handleClick = () => {
    onClickWA?.();
    window.open(whatsappLink, '_blank', 'noreferrer');
  };

  return (
    <AgentWarrantyWrapper>
      <div className="title">
        <Image src={AGENT_WARRANTY} alt="label" width="20" height="20" />
        <div className="text-title">{t('home.listingCard.agentWarranty.title')}</div>
      </div>
      <div className="desc">{t('home.listingCard.agentWarranty.desc')}</div>
      <div className="info">
        <div className="label">{t('home.listingCard.agentWarranty.validTillLabel')}</div>
        <div className="value">
          {t('home.listingCard.agentWarranty.validTillValue', {
            expired_date: dayjs(agentWarranty?.expired_date).format('DD/MM/YYYY'),
            mileage: agentWarranty?.mileage,
          })}
        </div>
      </div>
      <div className="info">
        <div className="label">{t('home.listingCard.agentWarranty.warrantyByLabel')}</div>
        <div className="value">{agentWarranty?.warranty_by}</div>
      </div>
      <div className="whatsapp">
        <a href={whatsappLink} style={{ marginTop: '12px' }} target="_blank" rel="noreferrer" onClick={handleClick}>
          <StyledWhatsAppButton>
            <div className="whatapp-icon-image">
              <WhatsAppOutlined color="#3BD876" width={16} height={16} />
            </div>
            <div className="whatsapp-text">{t('home.listingCard.agentWarranty.enquireNow')}</div>
          </StyledWhatsAppButton>
        </a>
      </div>
    </AgentWarrantyWrapper>
  );
};

export default AgentWarrantyPopoverContent;
