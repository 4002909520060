import { useCallback } from 'react';
import { useAppDispatch } from '@source/hooks/useStateHook';
import { onOpen, onClose } from '@source/redux/slice/modalSlice';

const usePopupService = () => {
  const dispatch = useAppDispatch();

  const openLocationPopup = useCallback(() => {
    dispatch(onOpen());
  }, [dispatch]);

  const closeLocationPopup = useCallback(() => {
    dispatch(onClose());
  }, [dispatch]);
  return {
    openLocationPopup,
    closeLocationPopup,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { usePopupService };
