import axios from 'axios';
import qs from 'qs';
import { API_URL, CAR_DETAILS_INCLUDES } from '@source/constants/api';

const fetchCarDetails = async ({ region, slug, language }: any) => {
  const { listings: listingURL } = API_URL(region);
  const query = qs.stringify({ include: CAR_DETAILS_INCLUDES, locale: language });
  const response = await axios.get(`${listingURL}/${slug}?${query}`);
  return response?.data?.data;
};

export default {
  fetchCarDetails,
};

export const fetchLineQRCode = async ({ region, slug, data }: any) => {
  const { listings: listingURL } = API_URL(region);
  const query = qs.stringify(data);
  return axios.get(`${listingURL}/${slug}/line-qr-code?${query}`);
};

export const fetchOptionAddons = async ({ region, slug, lang }: any) => {
  const { listings: optionAddonsListingURL } = API_URL(region);

  return axios.get(`${optionAddonsListingURL}/${slug}?lang=${lang}&include=option_addons`);
};
