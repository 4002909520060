import React, { FC } from 'react';
import styled from 'styled-components';
import { MobileNavigation, DesktopNavigation } from '@source/components/TopNavigation';
import useRegionConfig from '@source/hooks/useRegionConfig';

// - To solved flasing header issue and rendered header at first load!
const StyledTopNav = styled.div`
  [class^='SharedDesktopNavigation__StyleContainer-'] {
    display: none;
    @media screen and (min-width: 1280px) {
      min-height: 64px;
      display: block;
    }
  }
  .MobileTopNav {
    display: block;
    @media screen and (min-width: 1280px) {
      display: none;
    }
  }

  @media screen and (max-width: 1279px) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
  }

  &.no-sticky {
    position: static;
  }
`;
interface TopNavigationProps {
  showDrawer: () => void;
  isSecondaryHeaderMobile: boolean;
  onSecondaryHeaderMobileBack?: () => void;
}

const TopNavigation: FC<TopNavigationProps> = ({
  showDrawer,
  isSecondaryHeaderMobile,
  onSecondaryHeaderMobileBack,
}) => {
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();

  if (!countryCode) {
    return null;
  }

  return (
    <StyledTopNav className={`${isSecondaryHeaderMobile ? 'no-sticky' : ''}`}>
      <MobileNavigation
        showDrawer={showDrawer}
        isSecondaryHeaderMobile={isSecondaryHeaderMobile}
        onSecondaryHeaderMobileBack={onSecondaryHeaderMobileBack}
        countryCode={countryCode}
      />
      <DesktopNavigation countryCode={countryCode} />
    </StyledTopNav>
  );
};

export default TopNavigation;
