import React, { FC } from 'react';
import i18next from 'i18next';
import { TRegionKey } from '@source/interface';
import SharedDesktopNavigation from '@source/components/TopNavigation/DesktopNavigation/SharedDesktopNavigation';
import desktopHeaderConfigs from '@source/components/TopNavigation/constants';

interface DesktopNavigationProps {
  countryCode: TRegionKey | undefined;
}

const DesktopNavigation: FC<DesktopNavigationProps> = ({ countryCode }) => {
  const headerConfigs = countryCode && desktopHeaderConfigs(countryCode, i18next.language);
  return countryCode ? (
    <SharedDesktopNavigation headerConfigs={headerConfigs[countryCode]} countryCode={countryCode} />
  ) : null;
};

export default DesktopNavigation;
