import { API_URL } from '@source/constants/api';
import { getAccessToken, getAxiosInstance } from '@source/utils/utils';
import { TRegionKey } from '@source/interface';
import qs from 'qs';

/* eslint-disable import/prefer-default-export */
export const WHOLESALE_AXIOS_USER_AUTH = getAxiosInstance(`${process.env.WHOLESALE_URL}/api/v1`, getAccessToken);

export default {
  updateProfile: async ({ country, slug, payload }: { country: TRegionKey; slug: string; payload: object }) => {
    // Todo: add ?include=tickets.car_image,tickets.listing later when the relate feature is ready
    const { contacts } = API_URL(country);
    return WHOLESALE_AXIOS_USER_AUTH.put(
      `${contacts}/${slug}/personal-info?exclude=tickets
    `,
      payload,
    );
  },

  fetchProfile: async ({ country, slug }: { country: TRegionKey; slug: string }) => {
    // Todo: add ?include=tickets.car_image,tickets.listing later when the relate feature is ready
    const { contacts } = API_URL(country);
    return WHOLESALE_AXIOS_USER_AUTH.get(`${contacts}/${slug}/personal-info?exclude=tickets`);
  },

  logOut: async ({ country }: { country: TRegionKey }) => {
    const { logOut } = API_URL(country);
    return WHOLESALE_AXIOS_USER_AUTH.get(logOut);
  },

  fetchViewingHistory: async ({ country, data }: { country: TRegionKey; data: any }) => {
    const { contactId, ...rest } = data;
    const resquestObj = { ...rest, contact_id: contactId };
    const { viewingHistory } = API_URL(country);
    return WHOLESALE_AXIOS_USER_AUTH.get(`${viewingHistory}?${qs.stringify(resquestObj)}`);
  },

  uploadViewingHistory: ({ country, data }: { country: TRegionKey; data: any }) => {
    const { viewingHistory } = API_URL(country);
    return WHOLESALE_AXIOS_USER_AUTH.post(`${viewingHistory}`, data);
  },

  uploadSearchFilterHistory: ({
    country,
    data,
    isAuthenticated,
  }: {
    country: TRegionKey;
    data: any;
    isAuthenticated: boolean;
  }) => {
    const { searchFilterHistory } = API_URL(country);
    const timestamp = new Date();
    const withTimestamp = [
      {
        ...data,
        search_time: timestamp,
      },
    ];

    return WHOLESALE_AXIOS_USER_AUTH.post(`${searchFilterHistory}`, {
      search_filter_history: isAuthenticated ? withTimestamp : data,
    });
  },
};
