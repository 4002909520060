import React, { FC, useEffect, useState, useMemo } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Typography } from '@design-system/components';
import { getStaticCDN } from '@design-system/utils/utils';
import { TLanguageKey, TRegionKey } from '@source/interface';
import MultiLanguage from '@source/pages/Home/components/MultiLanguage';
import { URL_CONFIG } from '@source/constants/urlConfig';
import StyledContainer from '@design-system/styled/Container';

const StyledWrapper = styled(StyledContainer)`
  display: flex;
  background: ${(props) => props.theme.color.primaryBase};
  height: 45px;
  justify-content: space-between;

  .mobile-nav-right {
    display: flex;
    align-items: center;
  }
`;

const StyledItemsLeft = styled.div`
  display: flex;
  align-items: center;

  .mobile-nav-icon {
    color: ${(props) => props.theme.color.onPrimary};
    ${(props) => props.theme.typo.familyGoogle.medium};
  }

  .mobile-nav-back {
    ${(props) => props.theme.typo.familyGoogle.regular};
    ${(props) => props.theme.typo.style.body};
    margin-left: 4px;
    color: ${(props) => props.theme.color.onPrimary};
    cursor: pointer;
  }

  :hover {
    cursor: pointer;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: unset;
  border: unset;
  margin-left: 3px;

  &:hover {
    cursor: pointer;
  }
`;

interface MobileNavbarProps {
  showDrawer: () => void;
  countryCode: TRegionKey;
  onSecondaryHeaderMobileBack?: () => void;
}

const SecondMobileNav: FC<MobileNavbarProps> = ({ showDrawer, countryCode, onSecondaryHeaderMobileBack }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const prevUrl = useMemo(() => (typeof window !== 'undefined' && document?.referrer) || '', []);

  const [isFromListingDetailPage, setIsFromListingDetailPage] = useState(false);

  const isFromFilteredResult = useMemo(() => {
    const hasQS = prevUrl?.includes('query=');
    const hasSort = prevUrl?.includes('sortBy=');
    const hasFilter = prevUrl?.includes('/buy');
    return hasQS || hasSort || hasFilter;
  }, [prevUrl]);

  const { url, text } = useMemo(() => {
    const isViewingHistory = router.pathname.includes('viewing-history');
    const isProfileEdit = router.pathname.includes('profile/edit');
    const isAppointmentList = router.pathname.includes('profile/appointments');
    const isPaymentsList = router.pathname.includes('profile/payments');

    // if (isViewingHistory) {
    //   return {
    //     url: URL_CONFIG({ region: countryCode, lang: i18next.language as TLanguageKey }).profile,
    //     text: t('viewingHistory.mobileNavbar.back'),
    //   };
    // }

    if (isProfileEdit || isViewingHistory || isAppointmentList || isPaymentsList) {
      return {
        url: URL_CONFIG({ region: countryCode, lang: i18next.language as TLanguageKey }).profile,
        text: t('userProfile.backToDashboard'),
      };
    }

    return {
      url: URL_CONFIG({ region: countryCode, lang: i18next.language as TLanguageKey }).home,
      text: t('details.mobileNavbar.back'),
    };
  }, [countryCode, router.pathname, t]);

  useEffect(() => {
    setIsFromListingDetailPage(true);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // no-record-search-history flag use for prevent sending history on listing page
      if (isFromFilteredResult) {
        window.localStorage.setItem('no-record-search-history', 'true');
      }
    }
  }, [isFromFilteredResult]);

  const onClickBack = () => {
    if (onSecondaryHeaderMobileBack) {
      onSecondaryHeaderMobileBack();
      return;
    }

    if (isFromFilteredResult) {
      router.back();
      return;
    }
    router.push(url);
  };

  return (
    <StyledWrapper>
      <StyledItemsLeft onClick={onClickBack}>
        <LeftOutlined className="mobile-nav-icon" />
        <Typography className="mobile-nav-back">{text}</Typography>
      </StyledItemsLeft>

      <div className="mobile-nav-right">
        <MultiLanguage isFromListingDetailPage={isFromListingDetailPage} />
        <StyledButton type="button" className="mobile-nav-hamburger" onClick={showDrawer}>
          <Image alt="menu" src={getStaticCDN('/assets/shared/icons/2Line.svg')} width={17} height={10} />
        </StyledButton>
      </div>
    </StyledWrapper>
  );
};

export default SecondMobileNav;
