import jsCookie from 'js-cookie';
import { useState, useEffect, FC } from 'react';
import useRegionConfig from '@source/hooks/useRegionConfig';
import FirstTimeAccessModalContainer from '@source/pages/Home/components/FistTimeAccess/FirstTimeAccessModalContainer';
import { usePopupService } from '@source/pages/Home/hooks/usePopupService';
import { COOKIE_KEYS } from '@source/constants/common';

const ModalProvider: FC = () => {
  const [isOpenOutSideRegionAccess, setIsOpenOutSideRegionAccess] = useState(false);
  const {
    countryConfig: { isFirstTimeAccess },
  } = useRegionConfig();
  const { openLocationPopup } = usePopupService();
  const onCloseFirstTimeAccessModal = () => {
    jsCookie.set(COOKIE_KEYS.FIRST_TIME_ACCESS, 'false', { expires: 365 });
    openLocationPopup();
  };

  useEffect(() => {
    const cookieFirstTimeVisit = jsCookie.get(COOKIE_KEYS.FIRST_TIME_ACCESS);
    if (isFirstTimeAccess && (!cookieFirstTimeVisit || cookieFirstTimeVisit !== 'false')) {
      setIsOpenOutSideRegionAccess(true);
    }
  }, [isFirstTimeAccess]);
  return (
    <div className="ModalContainer">
      {/* First Time Access From Outside CR Regions Modal */}
      {isOpenOutSideRegionAccess && <FirstTimeAccessModalContainer isOpen onClose={onCloseFirstTimeAccessModal} />}
    </div>
  );
};

export default ModalProvider;
