/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import Image, { ImageLoaderProps } from 'next/image';
import dynamic, { LoaderComponent } from 'next/dynamic';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BellOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Navigation, Swiper as SwiperOptions } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import CustomSliderDots from '@design-system/components/Slider/components/CustomSliderDots';
import { getIsAdjacentToActiveIndex } from '@design-system/components/BrowsingCard/utils';

// CSS
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getTrimedS3Url } from '@design-system/utils';
import { getImageKitURLFixedRatioByWidth } from '@source/utils/utils';
import { IMAGE_ASPECT_RATIO } from '@design-system/constants';
import NextImageWithFallback from './NextImageWithFallback';

export interface PhotoCarouselProps extends PropsWithChildren {
  id?: string;
  imageWidth?: number;
  imageHeight?: number;
  carPlaceholderImg?: string;
  isImagePreload?: boolean;
  photos?: string[];
  onPhotoChange?: () => void;
  onPhotoClick?: (index: number) => void;
  hasNotifyMeButton?: boolean;
  alt?: string;
  hasLazyLoadImageEffect?: boolean;
  photoSwiperOptions?: SwiperProps;
  onOtherOnClickAction?: (params: any) => void;
  isExternal?: boolean;
}

const StyledPhotoWrapper = styled.div<{ minHeight?: number }>`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  min-height: ${(props) => `${props.minHeight}px`};
`;

const PhotoCarouselWrapper = styled.div`
  .browse-card-swiper-button {
    width: 50px;
    height: 50px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    -webkit-transform: translate3d(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 70px;

    .anticon {
      opacity: 0.8;

      &:hover {
        opacity: 1 !important;
      }
    }
  }

  .browse-card-next-button {
    right: 0;
  }

  .browse-card-prev-button {
    left: 0;
  }

  .next-car-image {
    cursor: pointer;
  }
`;

const StyledSliderWrapper = styled.div`
  position: relative;
  line-height: 0;
  z-index: 0;
  /* border-radius: 12px; */
  &.hide-arrow-btn {
    .anticon-left,
    .anticon-right {
      display: none;
    }
  }
`;

const StyledImg = styled.div`
  object-fit: cover;
  line-height: 0;
  .lazy-load-image-loaded {
    img {
      width: 100% !important;
    }
  }
`;

const StyledBellOutlined = styled(BellOutlined)`
  margin-right: 4px;
  font-size: 16px;
`;

const StyledNotifyMeButton = styled.button`
  min-width: 101px;
  height: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  z-index: 5;
  -webkit-transform: translate3d(0, 0, 0);
  top: 20px;
  right: 20px;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.color.onPrimary};
  background-color: ${(props) => props.theme.color.primaryBase};
  box-shadow: 0px 4px 4px 0px rgba(161, 161, 161, 0.25);
  ${(props) => props.theme.typo.style.smallButtonOrLink}
`;

const loader = ({ src, width }: ImageLoaderProps) => {
  const trimmedPhotoUrl = getTrimedS3Url(src);
  const imageUrlPrefix = getImageKitURLFixedRatioByWidth(IMAGE_ASPECT_RATIO.width, IMAGE_ASPECT_RATIO.height, width);
  return `${imageUrlPrefix || '/'}${trimmedPhotoUrl}`;
};

const PhotoCarousel: FC<PhotoCarouselProps> = ({
  id,
  imageWidth,
  imageHeight,
  isImagePreload,
  carPlaceholderImg,
  photos,
  onPhotoChange,
  onPhotoClick,
  hasNotifyMeButton,
  alt,
  hasLazyLoadImageEffect,
  photoSwiperOptions,
  onOtherOnClickAction,
  isExternal,
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { t } = useTranslation();

  const photosToRender = photos?.slice(0, 10);

  const numOfPhotos = photosToRender ? photosToRender.length : 0;

  const images = useMemo(() => {
    if (!numOfPhotos)
      return (
        <SwiperSlide key={carPlaceholderImg}>
          <StyledImg>
            <Image
              loader={loader}
              width={500}
              height={281} // 16/9
              alt=""
              src={carPlaceholderImg || ''}
              layout="responsive"
              sizes={`(max-width: 768px) 100vw,
              (max-width: 1280px) 50vw,
              382px`}
            />
          </StyledImg>
        </SwiperSlide>
      );

    return photosToRender?.map((photo, index) => (
      <SwiperSlide key={photo}>
        <StyledImg
          onClick={() => {
            if (onPhotoClick) {
              onPhotoClick(index);
            }
            if (onOtherOnClickAction) {
              const params = { carId: id, isPromo: isExternal };
              onOtherOnClickAction(params);
            }
          }}
        >
          <NextImageWithFallback
            loader={loader}
            fallbackUrl={carPlaceholderImg}
            src={photo}
            alt={alt}
            width={500}
            height={281} // 16/9
            loading={isImagePreload || getIsAdjacentToActiveIndex(index, photoIndex, numOfPhotos) ? 'eager' : 'lazy'}
            layout="responsive"
            sizes={`(max-width: 768px) 100vw,
              (max-width: 1280px) 50vw,
              382px`}
            placeholder={hasLazyLoadImageEffect ? 'blur' : undefined}
            blurDataURL={hasLazyLoadImageEffect ? loader({ src: photo, width: 5 }) : undefined}
          />
        </StyledImg>
      </SwiperSlide>
    ));
  }, [
    alt,
    id,
    isExternal,
    onOtherOnClickAction,
    carPlaceholderImg,
    onPhotoClick,
    photosToRender,
    hasLazyLoadImageEffect,
    numOfPhotos,
    isImagePreload,
    photoIndex,
  ]);

  const onSlideChange = (swiper: SwiperOptions) => {
    setPhotoIndex(swiper.realIndex);
    if (onPhotoChange) onPhotoChange();
  };

  return (
    <StyledPhotoWrapper minHeight={imageHeight}>
      <PhotoCarouselWrapper>
        {hasNotifyMeButton && (
          <StyledNotifyMeButton>
            <StyledBellOutlined />
            {t('home.listingCard.notifyMe')}
          </StyledNotifyMeButton>
        )}

        <StyledSliderWrapper className={`StyledSliderWrapper ${numOfPhotos === 1 ? 'hide-arrow-btn' : ''}`}>
          <Swiper
            autoplay={false}
            enabled={numOfPhotos > 1}
            loop={numOfPhotos > 1}
            modules={[Navigation]}
            navigation={{
              prevEl: `.prev-${id}`,
              nextEl: `.next-${id}`,
            }}
            slidesPerView={1}
            onSlideChange={onSlideChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...photoSwiperOptions}
          >
            {images}
            <CustomSliderDots length={numOfPhotos} activeIndex={photoIndex} />
          </Swiper>

          {numOfPhotos > 0 && (
            <>
              <div
                className={`browse-card-swiper-button browse-card-next-button next-${id}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                aria-hidden="true"
              >
                <RightOutlined />
              </div>
              <div
                className={`browse-card-swiper-button browse-card-prev-button prev-${id}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                aria-hidden="true"
              >
                <LeftOutlined />
              </div>
            </>
          )}
        </StyledSliderWrapper>
      </PhotoCarouselWrapper>
    </StyledPhotoWrapper>
  );
};

PhotoCarousel.defaultProps = {
  id: '',
  isImagePreload: false,
  imageWidth: 0,
  imageHeight: 0,
  carPlaceholderImg: '',
  onPhotoChange: () => {},
  onPhotoClick: (index) => {},
  photos: [],
  hasNotifyMeButton: false,
  alt: '',
  hasLazyLoadImageEffect: true,
  photoSwiperOptions: {},
  onOtherOnClickAction: undefined,
  isExternal: false,
};

export default PhotoCarousel;
