// Prefer not to export default here

import { getStaticCDN } from '@design-system/utils/utils';
import { TRegionKey } from '@source/interface';
import { Inspector } from './type';

/* eslint-disable import/prefer-default-export */

export const INSPECTION_CONTENTS = {
  certifiedName: 'details.inspection.certifiedName',
  certifiedLiteName: 'details.inspection.certifiedLiteName',
  title: 'details.inspection.title',
  titleLite: 'details.inspection.titleLite',
  description: 'details.inspection.description',
  descriptionLite: 'details.inspection.descriptionLite',
  descriptionLiteMobile: 'details.inspection.descriptionLiteMobile',
  items: [
    {
      image: getStaticCDN('/assets/sg/listing-detail/no_accidents.svg?v2'),
      title: 'details.inspection.items.noAccident',
    },
    {
      image: getStaticCDN('/assets/sg/listing-detail/no_fire.svg?v2'),
      title: 'details.inspection.items.noFireDamge',
    },
    {
      image: getStaticCDN('/assets/sg/listing-detail/no_flood.svg?v2'),
      title: 'details.inspection.items.noFloodDamge',
    },
  ],
  liteItems: [
    {
      image: getStaticCDN('/assets/sg/listing-detail/no_accidents.svg?v2'),
      title: 'details.inspection.itemsLite.noAccident',
    },
    {
      image: getStaticCDN('/assets/sg/listing-detail/no_fire.svg?v2'),
      title: 'details.inspection.itemsLite.noFireDamge',
    },
    {
      image: getStaticCDN('/assets/sg/listing-detail/no_flood.svg?v2'),
      title: 'details.inspection.itemsLite.noFloodDamge',
    },
  ],
  readMoreText: 'details.inspection.readMoreText',
  readLessText: 'details.inspection.readLessText',
};

export const OPTION_CAR_CARD_MIN_WIDTH = 258;

export const INSPECTION_REPORT_CONTENTS = {
  title: 'details.inspection.report.title',
  items: [
    {
      id: 'road_test',
      name: 'details.inspection.report.items.roadTestsChecks',
      icon: getStaticCDN('/assets/shared/details/inspection/road-tests-checks.svg'),
    },
    {
      id: 'exterior',
      name: 'details.inspection.report.items.exteriorChecks',
      icon: getStaticCDN('/assets/shared/details/inspection/exterior-checks.svg'),
    },
    {
      id: 'interior',
      name: 'details.inspection.report.items.interiorChecks',
      icon: getStaticCDN('/assets/shared/details/inspection/interior-checks.svg'),
    },
    {
      id: 'underbody_under_the_hood',
      name: 'details.inspection.report.items.underbodyChecks',
      icon: getStaticCDN('/assets/shared/details/inspection/underbody-and-under-the-hood-checks.svg'),
    },
  ],
  downloadButton: {
    label: {
      mobile: 'details.inspection.report.downloadButton.label.mobile',
      desktop: 'details.inspection.report.downloadButton.label.desktop',
    },
    icon: getStaticCDN('/assets/shared/details/inspection-web/download-icon-v2.svg'),
  },
  downloadNowButton: {
    label: 'details.inspection.report.downloadNowButton',
    icon: getStaticCDN('/assets/shared/details/inspection-web/download-icon-v2.svg'),
  },
  documents: {
    sectionTitle: 'details.inspection.report.documents.sectionTitle',
    exclusiveReportDesc: 'details.inspection.report.documents.exclusiveReportDesc',
    authorisedDealerReport: {
      title: 'details.inspection.report.documents.authorisedDealerReport.title',
      content: 'details.inspection.report.documents.authorisedDealerReport.content',
    },
    vehicleEvaluationReport: {
      title: 'details.inspection.report.documents.vehicleEvaluationReport.title',
      content: 'details.inspection.report.documents.vehicleEvaluationReport.content',
    },
    accidentHistoryReport: {
      title: 'details.inspection.report.documents.accidentHistoryReport.title',
      content: 'details.inspection.report.documents.accidentHistoryReport.content',
    },
    certifiedBy: 'details.inspection.report.documents.certifiedBy',
  },
  viewFullButtonLabel: 'details.inspection.report.viewFullButtonLabel',
};

export const DEFAULT_INSPECTOR: Record<TRegionKey, Record<string, Inspector>[]> = {
  sg: [
    {
      en: {
        name: 'Loh Wei Chen',
        image: getStaticCDN('/assets/sg/listing-detail/inspector/tr:w-96,h-96,fo-auto/default-avatar-sg-v2.jpeg'),
        mechanic_title: 'Head of Inspections',
        mechanic_description: 'Ensures every inspection conducted meets the highest standards',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
      'zh-SG': {
        name: 'Loh Wei Chen',
        image: getStaticCDN('/assets/sg/listing-detail/inspector/tr:w-96,h-96,fo-auto/default-avatar-sg-v2.jpeg'),
        mechanic_title: '检查主管',
        mechanic_description: '确保每次检查都符合最高标准',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
      'ms-SG': {
        name: 'Loh Wei Chen',
        image: getStaticCDN('/assets/sg/listing-detail/inspector/tr:w-96,h-96,fo-auto/default-avatar-sg-v2.jpeg'),
        mechanic_title: 'Ketua Pemeriksaan',
        mechanic_description: 'Memastikan setiap pemeriksaan yang dijalankan memenuhi piawaian tertinggi',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
    },
  ],
  my: [
    {
      en: {
        name: 'Choong Jien Dinq',
        image: getStaticCDN('/assets/my/listing-detail/inspector/inspector-choong.jpg'),
        mechanic_title: 'Head Of Reconditioning',
        mechanic_description: 'Oversees Car Reconditioning Operations in Malaysia',
        message_description:
          'Your safety is our top priority. All <b>Carro Certified</b> cars have undergone a rigorous 160-point inspection to ensure they are reconditioned to be in the best roadworthy state possible. Also, we have listed all damage found, major or minor, in our detailed <b>Inspection Report</b> below for your peace of mind.<br/><br/>Please contact us if you have any more questions. We will be more than happy to assist you!',
        message_description_lite:
          'Your safety is our top priority. All <b>Carro Certified Lite</b> cars have undergone a rigorous 160-point inspection to ensure they are reconditioned to be in the best roadworthy state possible. Also, we have listed all damage found, major or minor, in our detailed <b>Inspection Report</b> below for your peace of mind.<br/><br/>Please contact us if you have any more questions. We will be more than happy to assist you!',
      },
      ms: {
        name: 'Choong Jien Dinq',
        image: getStaticCDN('/assets/my/listing-detail/inspector/inspector-choong.jpg'),
        mechanic_title: 'Ketua Pemulihan',
        mechanic_description: 'Memastikan pemulihan kereta di seluruh Malaysia',
        message_description:
          'Semua kereta Carro Certified telah menjalani pemeriksaan 160 poin untuk memastikan ia dibaikpulih dalam kondisi sebaik yang baru bagi menjamin keselamatan dan keselesaan pemanduan anda. Semua keputusan pemeriksaan telah disenaraikan dalam laporan lanjut kami untuk anda semak agar tiada lagi keraguan.',
        message_description_lite:
          'Semua kereta Carro Certified Lite telah menjalani pemeriksaan 160 poin untuk memastikan ia dibaikpulih dalam kondisi sebaik yang baru bagi menjamin keselamatan dan keselesaan pemanduan anda. Semua keputusan pemeriksaan telah disenaraikan dalam laporan lanjut kami untuk anda semak agar tiada lagi keraguan.',
      },
      'zh-MY': {
        name: 'Choong Jien Dinq',
        image: getStaticCDN('/assets/my/listing-detail/inspector/inspector-choong.jpg'),
        mechanic_title: '林修复中心总监',
        mechanic_description: '监督全马来西亚汽车修复运作',
        message_description:
          '所有 Carro 官方认证的汽车都经过严格的 160项专业检测，确保它们处于最佳行驶状态，以确保您的安全。所有损坏，无论是大或小，都会列在我们详细的报告中；所以，您可以高枕无忧。<br/><br/>如果您还有其他疑问，请欢迎联系我们。我们随时为您提供协助。',
        message_description_lite:
          '所有 Carro 官方认证 Lite的汽车都经过严格的 160项专业检测，确保它们处于最佳行驶状态，以确保您的安全。所有损坏，无论是大或小，都会列在我们详细的报告中；所以，您可以高枕无忧。<br/><br/>如果您还有其他疑问，请欢迎联系我们。我们随时为您提供协助。',
      },
    },
    {
      en: {
        name: 'Yong Onn Foo',
        image: getStaticCDN('/assets/my/listing-detail/inspector/inspector-marcus.jpg'),
        mechanic_title: 'Head Of Reconditioning',
        mechanic_description: 'Oversees Car Reconditioning Operations in Malaysia',
        message_description:
          'Your safety is our top priority. All <b>Carro Certified</b> cars have undergone a rigorous 160-point inspection to ensure they are reconditioned to be in the best roadworthy state possible. Also, we have listed all damage found, major or minor, in our detailed <b>Inspection Report</b> below for your peace of mind.<br/><br/>Please contact us if you have any more questions. We will be more than happy to assist you!',
        message_description_lite:
          'Your safety is our top priority. All <b>Carro Certified Lite</b> cars have undergone a rigorous 160-point inspection to ensure they are reconditioned to be in the best roadworthy state possible. Also, we have listed all damage found, major or minor, in our detailed <b>Inspection Report</b> below for your peace of mind.<br/><br/>Please contact us if you have any more questions. We will be more than happy to assist you!',
      },
      ms: {
        name: 'Yong Onn Foo',
        image: getStaticCDN('/assets/my/listing-detail/inspector/inspector-marcus.jpg'),
        mechanic_title: 'Ketua Pemulihan',
        mechanic_description: 'Memastikan pemulihan kereta di seluruh Malaysia',
        message_description:
          'Semua kereta Carro Certified telah menjalani pemeriksaan 160 poin untuk memastikan ia dibaikpulih dalam kondisi sebaik yang baru bagi menjamin keselamatan dan keselesaan pemanduan anda. Semua keputusan pemeriksaan telah disenaraikan dalam laporan lanjut kami untuk anda semak agar tiada lagi keraguan.',
        message_description_lite:
          'Semua kereta Carro Certified Lite telah menjalani pemeriksaan 160 poin untuk memastikan ia dibaikpulih dalam kondisi sebaik yang baru bagi menjamin keselamatan dan keselesaan pemanduan anda. Semua keputusan pemeriksaan telah disenaraikan dalam laporan lanjut kami untuk anda semak agar tiada lagi keraguan.',
      },
      'zh-MY': {
        name: 'Yong Onn Foo',
        image: getStaticCDN('/assets/my/listing-detail/inspector/inspector-marcus.jpg'),
        mechanic_title: '林修复中心总监',
        mechanic_description: '监督全马来西亚汽车修复运作',
        message_description:
          '所有 Carro 官方认证的汽车都经过严格的 160项专业检测，确保它们处于最佳行驶状态，以确保您的安全。所有损坏，无论是大或小，都会列在我们详细的报告中；所以，您可以高枕无忧。<br/><br/>如果您还有其他疑问，请欢迎联系我们。我们随时为您提供协助。',
        message_description_lite:
          '所有 Carro 官方认证 Lite的汽车都经过严格的 160项专业检测，确保它们处于最佳行驶状态，以确保您的安全。所有损坏，无论是大或小，都会列在我们详细的报告中；所以，您可以高枕无忧。<br/><br/>如果您还有其他疑问，请欢迎联系我们。我们随时为您提供协助。',
      },
    },
  ],
  id: [
    {
      en: {
        name: 'Gerry Ricardo Suryawijaya',
        image: getStaticCDN('/assets/id/listing-detail/inspector/default-avatar.png'),
        mechanic_title: 'Head of Inspections',
        mechanic_description: 'Ensures every inspection conducted meets the highest standards',
        message_description:
          'Setiap kendaraan berlabel Carro Certified telah lulus 160 titik inspeksi untuk memastikan konsumen mendapatkan gambaran kondisi kendaraan secara utuh dan transparan. Kendaraan berlabel Carro Certified juga memiliki garansi berupa 5 hari uang kembali, 1 tahun garansi mesin dan transmisi, bebas manipulasi jarak tempuh, serta bebas dari banjir, kecelakaan dan kebakaran.',
      },
      id: {
        name: 'Gerry Ricardo Suryawijaya',
        image: getStaticCDN('/assets/id/listing-detail/inspector/default-avatar.png'),
        mechanic_title: 'Ketua Inspeksi',
        mechanic_description: 'Memastikan setiap inspeksi dilakukan adalah standar tertinggi.',
        message_description:
          'Setiap kendaraan berlabel Carro Certified telah lulus 160 titik inspeksi untuk memastikan konsumen mendapatkan gambaran kondisi kendaraan secara utuh dan transparan. Kendaraan berlabel Carro Certified juga memiliki garansi berupa 5 hari uang kembali, 1 tahun garansi mesin dan transmisi, bebas manipulasi jarak tempuh, serta bebas dari banjir, kecelakaan dan kebakaran.',
      },
    },
  ],
  th: [
    {
      en: {
        name: 'Sakkarapol Chulapita',
        image: getStaticCDN('/assets/th/car-details/default-avatar-2.png'),
        mechanic_title: 'Head of Reconditioning',
        mechanic_description: 'Oversees vehicle reconditioning process to achieve the highest quality standards',
        message_description:
          'รถ Carro Certified ทุกคันของเราผ่านการตรวจสอบกว่า 160 จุด โดยผู้เชี่ยวชาญ โดยจะวิเคราะห์ทุกแง่มุมของรถ และบันทึกข้อบกพร่องที่รถอาจมี ไม่ว่าจะเล็กน้อยแค่ไหนก็ตาม วิธีนี้ช่วยให้คุณได้ภาพที่ชัดเจนของสภาพรถที่คุณได้รับ และจะไม่ถูกมองข้ามหรือแปลกใจในระหว่างการดูหรือซื้อ หากมีข้อบกพร่องที่สำคัญใดๆ กับรถที่อาจส่งผลต่อประสิทธิภาพหรือความทนทาน เราจะแก้ไขให้ตรงจุดก่อนที่จะลงรายการรถเพื่อความอุ่นใจของคุณ โปรดดูรายงานโดยละเอียดของเราด้านล่างและแจ้งให้เราทราบหากคุณมีคำถามใดๆ',
      },
      th: {
        name: 'ศักรพล จูฬะปิตะ',
        image: getStaticCDN('/assets/th/car-details/default-avatar-2.png'),
        mechanic_title: 'ผู้จัดการฝ่ายปรับสภาพ',
        mechanic_description: 'ควบคุมและดูและกระบวนการปรับสภาพ เพื่อให้เป็นไปตามมาตรฐานด้านคุณภาพสูงสุด',
        message_description:
          'รถ Carro Certified ทุกคันของเราผ่านการตรวจสอบกว่า 160 จุด โดยผู้เชี่ยวชาญ โดยจะวิเคราะห์ทุกแง่มุมของรถ และบันทึกข้อบกพร่องที่รถอาจมี ไม่ว่าจะเล็กน้อยแค่ไหนก็ตาม วิธีนี้ช่วยให้คุณได้ภาพที่ชัดเจนของสภาพรถที่คุณได้รับ และจะไม่ถูกมองข้ามหรือแปลกใจในระหว่างการดูหรือซื้อ หากมีข้อบกพร่องที่สำคัญใดๆ กับรถที่อาจส่งผลต่อประสิทธิภาพหรือความทนทาน เราจะแก้ไขให้ตรงจุดก่อนที่จะลงรายการรถเพื่อความอุ่นใจของคุณ โปรดดูรายงานโดยละเอียดของเราด้านล่างและแจ้งให้เราทราบหากคุณมีคำถามใดๆ',
      },
    },
  ],
  // Todo_JP: Update meta for car details for JP
  jp: [
    {
      en: {
        name: '',
        image: getStaticCDN('/static/img/car-details/default-avatar.svg'),
        mechanic_title: 'Senior Vehicle Evaluator',
        mechanic_description: 'Oversees the vehicle inspection unit for the Carro Workshop',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
      ja: {
        name: '',
        image: getStaticCDN('/static/img/car-details/default-avatar.svg'),
        mechanic_title: '評価者',
        mechanic_description: 'Carroの整備工場の査定責任者',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
    },
  ],
  hk: [
    {
      en: {
        name: '',
        image: getStaticCDN('/static/img/car-details/default-avatar.svg'),
        mechanic_title: 'Senior Vehicle Evaluator',
        mechanic_description: 'Oversees the vehicle inspection unit for the Carro Workshop',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
      'zh-Hant-HK': {
        name: '',
        image: getStaticCDN('/static/img/car-details/default-avatar.svg'),
        mechanic_title: 'Senior Vehicle Evaluator',
        mechanic_description: 'Oversees the vehicle inspection unit for the Carro Workshop',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
      'zh-Hans-HK': {
        name: '',
        image: getStaticCDN('/static/img/car-details/default-avatar.svg'),
        mechanic_title: 'Senior Vehicle Evaluator',
        mechanic_description: 'Oversees the vehicle inspection unit for the Carro Workshop',
        message_description:
          "All Carro Certified cars has gone through a 160 point inspection where we analyse every aspect of the car and record down any defects the car may have, no matter how minor they are. This allows you to have a very clear picture of the condition of the car you are getting, and won't be caught off guard or surprised during the viewing or purchase. Should there be any major defects to the car that would affect its performance or durability we rectify those on the spot before listing the car for your peace of mind. So do take a look at our detailed report below and let us know if you have any questions!",
      },
    },
  ],
};

export const PHONE_COUNTRY_CODES: Record<TRegionKey, string> = {
  sg: '+65',
  id: '+62',
  th: '+66',
  my: '+60',
  jp: '+81',
  hk: '+852',
};

export const BUY_ONLINE_TICKET_CONTEXT: Record<TRegionKey, string> = {
  sg: 'buy-online-carro-web-sg',
  id: 'buy-online-carro-web-id',
  th: 'buy-online-carro-web-th',
  my: 'buy-online-mytukar-web-my',
  jp: 'buy-online-carro-web-jp',
  hk: 'buy-online-carro-web-hk', // todo: check hk buy-online ticket type
};

export const BUY_ONLINE_TICKET_NEGOTIATION_CONTEXT: Array<string> = ['buy-online-carro-web-sg', 'buy-app-sg'];

export const BUY_NOW_OTP_MAX_ATTEMPTS = 5;

// This variable is used to indicate the effective date of 160 inspection point,
// refer to https://carrotech.atlassian.net/browse/CX-746 for more information.
export const INSPECTION160_EFFECTIVE_DATE = '2022-04-04';

export const INSPECTION_DENT_MAP_CATEGORIES = ['road_test', 'exterior', 'interior', 'underbody_under_the_hood'];

export const FINANCING_OPTIONS = {
  loanPercent: [
    { title: '0%', value: 0 },
    { title: '10%', value: 10 },
    { title: '20%', value: 20 },
    { title: '30%', value: 30 },
    { title: '40%', value: 40 },
    { title: '50%', value: 50 },
    { title: '60%', value: 60 },
    { title: '70%', value: 70 },
    { title: '80%', value: 80 },
    { title: '90%', value: 90 },
    { title: '100%', value: 100 },
  ],
  downPaymentPercent: [
    { title: '0%', value: 0 },
    { title: '10%', value: 10 },
    { title: '20%', value: 20 },
    { title: '30%', value: 30 },
    { title: '40%', value: 40 },
    { title: '50%', value: 50 },
    { title: '60%', value: 60 },
    { title: '70%', value: 70 },
    { title: '80%', value: 80 },
    { title: '90%', value: 90 },
    { title: '100%', value: 100 },
  ],
  ncd: [
    { title: '0%', value: 0 },
    { title: '25%', value: 25 },
    { title: '30%', value: 30 },
    { title: '38.33%', value: 38.33 },
    { title: '45%', value: 45 },
    { title: '55%', value: 55 },
  ],
};

export const FINANCING_SELECT_INPUT_CLASSNAME = {
  loanPercent: 'loan-percent-selection',
  downPaymentPercent: 'down-payment-percent-selection',
  loanTenure: 'loan-tenure-selection',
  interestRate: 'interest-rate-selection',
  ncd: 'ncd-selection',
};

export const INSTALLMENT_TO_FINANCING_ID = 'financing';
