import { useEffect } from 'react';

const useForceHtmlLangAttr = (desiredLang: string) => {
  useEffect(() => {
    document.documentElement.lang = desiredLang;

    const langObserver = new MutationObserver(() => {
      if (document.documentElement.lang !== desiredLang) {
        document.documentElement.lang = desiredLang;
      }
    });
    langObserver.observe(document.documentElement, {
      attributeFilter: ['lang'],
    });

    return () => {
      langObserver.disconnect();
    };
  }, [desiredLang]);
};

export default useForceHtmlLangAttr;
