import Image, { ImageProps } from 'next/image';
import React, { useState } from 'react';

const NextImageWithFallback: React.FC<
  ImageProps & {
    fallbackUrl?: string;
  }
> = ({ fallbackUrl, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <Image
      {...props}
      src={imgSrc}
      onError={() => {
        if (fallbackUrl) {
          setImgSrc(fallbackUrl);
        }
      }}
    />
  );
};

export default NextImageWithFallback;
