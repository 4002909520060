import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

interface AllInPriceProps {
  isCCPOLite?: boolean;
}

const StyledWrapper = styled.div`
  border-radius: 12px;
  background-color: ${(props) => props.theme.color.primarySubtle};
  padding: 12px;
  margin: 16px 0 0 0;

  .price-title {
    ${(props) => props.theme.typo.style.body};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
  }

  .price-desc {
    ${(props) => props.theme.typo.style.label};
    ${(props) => props.theme.typo.familyGoogle.regular};
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    white-space: pre-line;
  }

  .price-includes-title {
    ${(props) => props.theme.typo.style.captionRegular};
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
    margin-top: 16px;
  }

  .price-divider {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const AllInPrice: FC<AllInPriceProps> = ({ isCCPOLite }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <div className="price-title">{t('shared.allInPrice.title')}</div>
      <div className="price-desc">{t('shared.allInPrice.description')}</div>
      <div className="price-includes-title">{t('shared.allInPrice.priceIncludesTitle')}</div>
      <div className="price-desc">
        <Trans
          defaults={isCCPOLite ? 'shared.allInPrice.priceIncludesDescLite' : 'shared.allInPrice.priceIncludesDesc'}
          components={{
            span: <span />,
          }}
        />
      </div>
    </StyledWrapper>
  );
};

export default AllInPrice;
