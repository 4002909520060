// Catch clause variable type annotation must be 'any' or 'unknown' if specified
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TRegionKey } from '@source/interface';
import UserProfile from '@source/services/UserProfile';

const sliceName = 'USER_PROFILE/SEARCH_FILTER_HISTORY';

export const uploadSearchFilterHistory = createAsyncThunk(
  `${sliceName}/UPLOAD_SEARCH_FILTER_HISTORY`,
  async ({ country, data, isAuthenticated }: { country: TRegionKey; data: any; isAuthenticated: boolean }) => {
    await UserProfile.uploadSearchFilterHistory({ country, data, isAuthenticated });
  },
);

interface SearchFilterHistoryProps {
  searchFilterList: any;
}

const initialState = {
  searchFilterList: [],
} as SearchFilterHistoryProps;

const searchFilterHistorySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
  },
});

export const { actions } = searchFilterHistorySlice;

export default searchFilterHistorySlice.reducer;
