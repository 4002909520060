import { getDataConfigsCountry, getFiltersTranslationPath } from './constants';

/* eslint-disable no-template-curly-in-string */
const countryCode = 'jp';

const FILTER_CONFIGS_JP = {
  loading: false,
  initialFilterSettings: [
    {
      name: 'promotions',
      active: true,
      activeMobile: true,
      algoliaFetching: true,
      requireDefaults: true,
      filters: [
        {
          name: 'promotion_name',
          customName: 'promotion_name',
          type: 'button',
          filterSelectedHeader: '[label]',
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'price_range',
      algoliaFetching: true,
      requireDefaults: true,
      filters: [
        {
          name: 'listing.asking_price',
          type: 'range_input',
          prefix: '',
          detailsText: {
            average: '',
          },
          selectedMinInput: null,
          selectedMaxInput: null,
          selectedCustomLabel: getFiltersTranslationPath('priceRangeCustomLabelJa'),
        },
      ],
    },
    {
      name: 'manufacture_year',
      algoliaFetching: true,
      requireDefaults: true,
      filters: [
        {
          name: 'inventory.year_of_manufacture',
          customName: 'inventory.manufacture_year',
          type: 'range_input',
        },
      ],
    },

    {
      name: 'mileage',
      filters: [
        {
          name: 'listing.mileage',
          suffix: getFiltersTranslationPath('mileageSuffix'),
          detailsText: {
            average: '',
          },
          facetStats: {
            avg: null,
            max: null,
            min: null,
            sum: null,
          },
        },
      ],
    },
    {
      name: 'fuel_button',
      filters: [
        {
          name: 'inventory.fuel_type',
          type: 'button',
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'FUEL_TYPE',
            listFields: ['petrol', 'petrol-electric', 'diesel', 'cng', 'electric'],
          }),
          selectedOptions: null,
        },
      ],
    },
  ],
  attributesToRetrieve: [
    'id',
    'created_at',
    'promo_label',
    'promo_images',
    'promotion',
    'promotion.promo_blurb.url',
    'hits',
    'categories',
    'is_promo',
    'listing.title',
    'listing.slug',
    'listing.mileage',
    'listing.asking_price',
    'listing.photos',
    'listing.current_depreciation',
    'listing.installment',
    'listing.is_coming_soon',
    'listing.is_pending_sale',
    'listing.is_sold',
    'listing.is_agent_serviced',
    'listing.is_agent_warranty',
    'listing.is_carro_anywhere',
    'listing.is_available',
    'listing.is_ccpo',
    'listing.is_new_arrival',
    'listing.thumbnail',
    'listing.promotional_price',
    'listing.key_words',
    'listing.months_left',
    'listing.paper_value',
    'listing.status',
    'listing.is_carro_assure',
    'listing.is_carro_assure_plus',
    'listing.maximum_loan_percentage',
    'listing.downpayment_percentage',
    'listing.original_price',
    'listing.scrap_value',
    'listing.paper_value',
    'listing.downpayment',
    'listing.additional_data',
    'inventory.original_registration_date',
    'inventory.number_of_owners',
    'inventory.year_of_manufacture',
    'inventory.make',
    'inventory.model',
    'inventory.coe_expiry_date',
    'inventory.car_type',
    'inventory.first_registration_year',
    'inventory.transmission',
    'inventory.fuel_type',
    'inventory.color',
    'inventory.seats',
    'inventory.engine_capacity',
    'inventory.open_market_value',
    'inventory.arf_paid',
    'inventory.coe',
    'inventory.maximum_power_output',
    'inventory.road_tax',
    'inside_badges',
  ],

  mobileFilters: ['mileage', 'make', 'make_model', 'price_range', 'manufacture_year', 'wheel_drives', 'fuel_button'],
  otherFilters: [],
  desktopFilters: ['mileage', 'make', 'make_model', 'price_range', 'manufacture_year', 'wheel_drives', 'fuel_button'],
  isAlgoliaOptionsLoaded: true,
  isInitalSettingsLoaded: true,
  autocompleteHits: [],
  query: '',
  hitsPerPage: 24,
};

export default FILTER_CONFIGS_JP;
