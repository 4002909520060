import { useEffect, useState } from 'react';

const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState<Array<number>>();

  useEffect(() => {
    if (typeof window === 'undefined') return () => {};

    const handleResize = (): void => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    if (!windowSize) handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize || [];
};

export default useWindowResize;
