import { useContext } from 'react';

import RegionContext, { IRegionContext } from '@source/context/RegionContext';

const useRegionConfig = (): IRegionContext => {
  const regionContext = useContext(RegionContext);

  return regionContext;
};

export default useRegionConfig;
