import styled from 'styled-components';
import Image from 'next/image';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import AppleStoreButton from '@source/pages/Home/assets/icons/footer-apple-badge.svg';
import GooglePlayButton from '@source/pages/Home/assets/icons/google-play-badge.svg';

const StyledWhiteHeader = styled.p`
  color: white !important;
  margin-top: 20px;
  font-size: 16px;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
`;

const StyledJustifyDiv = styled.div`
  display: flex;
  align-items: stretch;

  .apple-icon {
    margin-right: 8px;
  }

  a {
    display: inline-block;
  }
`;

const DownloadApp = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledWhiteHeader className="title is-spaced is-6">{t('home.footer.download')}</StyledWhiteHeader>
      <Row>
        <Col xs={24} md={18} lg={16}>
          <StyledJustifyDiv>
            <a
              className="gtm-appdownload apple-icon"
              href="https://itunes.apple.com/us/app/trustycars-buy-sell-direct/id1011686446?mt=8&utm_source=web&utm_medium=carro&utm_campaign=homepage_footer"
              rel="noreferrer"
              target="_blank"
            >
              <Image width="135px" height="40px" alt="apple-store_app" src={AppleStoreButton} />
            </a>
            <a
              className="gtm-appdownload"
              href="https://play.google.com/store/apps/details?id=sg.carro&utm_source=web&utm_medium=carro&utm_campaign=footer"
              rel="noreferrer"
              target="_blank"
            >
              <Image width="135px" height="40px" alt="google_play_app" src={GooglePlayButton} />
            </a>
          </StyledJustifyDiv>
        </Col>
      </Row>
    </>
  );
};

export default DownloadApp;
