import React, { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import i18next from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

import useMobileView from '@design-system/hooks/useMobileView';
import StyledContainer from '@design-system/styled/Container';
import { TitleTypography } from '@design-system/components';

import useRegionConfig from '@source/hooks/useRegionConfig';
import BackToTop from '@source/components/BackToTop';
import { TLanguageKey, TRegionKey } from '@source/interface';
import getCountryConfigs from './constants';
import LineIcon from './assets/LineIcon';
import CustomTextWithFontFamily from '../CustomTextWithFontFamily';

interface StyledFooterDataProps {
  noGrid?: boolean;
}

const StyledWrapper = styled.footer`
  background-color: #212121;
  /* ${(props) => props.theme.typo.familyGoogle.regular}; */

  ${(props) => props.theme.typo.familyGoogle.regular}

  color: #fff;

  padding: 28px 0 56px 0;

  @media screen and (min-width: 576px) {
    padding: 28px 0 113px 0;
  }

  @media screen and (min-width: 768px) {
    padding: 40px 0 20px 0;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #fff;
  }

  .footer-title {
    /* ${(props) => props.theme.typo.familyGoogle.bold}; */
    ${(props) => props.theme.typo.familyGoogle.bold}
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(props) => props.theme.color.lightOrange};
    width: fit-content;
    color: #fff;
  }

  .footer-link {
    font-size: 12px;
    /* ${(props) => props.theme.typo.familyGoogle.semiBold}; */
    ${(props) => props.theme.typo.familyGoogle.semiBold}
    margin-bottom: 15px;
    @media screen and (max-width: 576px) {
      max-width: 170px;
    }
  }

  .container {
    @media screen and (min-width: 375px) {
      padding: 0 15px;
    }

    @media screen and (min-width: 576px) {
      padding: 0;
    }

    @media screen and (min-width: 1600px) {
      max-width: 1200px;
    }
  }
`;

const StyledDescWrapper = styled.div`
  margin-top: 32px;

  .footerDescription {
    font-size: 12px;
    /* ${(props) => props.theme.typo.familyGoogle.regular}; */
    ${(props) => props.theme.typo.familyGoogle.regular}
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 32px;
    }
  }
`;

const StyledHr = styled.hr`
  margin: 22px 0 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.color.lightOrange};
`;

const StyledBottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFooterBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;

  .copy-right,
  .social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .social-media {
      margin-top: 10px;
    }
  }

  .terms-and-policy {
    font-size: 13px;
    a:hover {
      color: #a6a6a6;
    }
  }

  @media screen and (max-width: 991px) {
    display: block;
    text-align: center;
    margin-top: 32px;

    .carro-footer-logo {
      display: none;
    }

    .copy-right,
    .terms-and-policy {
      margin-bottom: 20px;
    }
  }
`;

const StyledFooterDataWrapper = styled.div<StyledFooterDataProps>`
  display: block;
  @media screen and (max-width: 576px) {
    display: ${(props) => (props.noGrid ? 'block' : 'grid')};
  }
  grid-template-columns: 1fr 1fr;

  .footerContent {
    .contentDesc {
      font-size: 12px;
      ${(props) => props.theme.typo.familyGoogle.regular};
    }
  }
`;

const CURRENT_YEAR = new Date().getFullYear();

const renderDescription = (textContent: any) => {
  const { desc1, desc2 } = textContent || {};
  return (
    <>
      {desc1 && (
        <p className="footerDescription">
          <CustomTextWithFontFamily text={desc1} />
        </p>
      )}
      {desc2 && (
        <p className="footerDescription">
          <CustomTextWithFontFamily text={desc2} />
        </p>
      )}
    </>
  );
};

const renderDimText = (dimText: Array<string>, urls: Array<string>) =>
  dimText.map((text, index) => (
    <Link key={text} href={urls[index]} prefetch={false}>
      <a href={urls[index]} target="_blank" className="dim-text" style={{ marginRight: '15px' }} rel="noreferrer">
        {text}
      </a>
    </Link>
  ));

const renderBottom = (textContent: any, country: TRegionKey) => {
  const {
    copyRight,
    dimText,
    dimUrl,
    social,
    secondaryCopyRight,
    tertiaryCopyRight,
    copyRightMY,
    secondaryCopyRight2nd,
  } = textContent || {};

  return (
    <StyledFooterBottomWrapper>
      <div className="copy-right">
        <Trans
          defaults={copyRight}
          values={{
            year: CURRENT_YEAR,
          }}
          components={{
            specificFont: <span style={{ fontFamily: 'Poppins' }} />,
          }}
        />
        {copyRightMY && <div>{copyRightMY}</div>}
        {secondaryCopyRight && <div>{secondaryCopyRight}</div>}
        {secondaryCopyRight2nd && <div>{secondaryCopyRight2nd}</div>}
        {tertiaryCopyRight && <div>{tertiaryCopyRight}</div>}
      </div>

      {/* Todo_JP: hide in jp first and wait for PM to get prod social link */}
      <div className="social">
        {dimText && <div className="terms-and-policy">{renderDimText(dimText, dimUrl)}</div>}
        <div className="social-media">
          {social.facebook && (
            <a href={social.facebook} target="_blank" style={{ marginRight: '5px' }} rel="noreferrer">
              <FacebookOutlined style={{ fontSize: '25px' }} />
            </a>
          )}
          {social.twitter && (
            <a href={social.twitter} target="_blank" style={{ marginRight: '5px' }} rel="noreferrer">
              <TwitterOutlined style={{ fontSize: '25px' }} />
            </a>
          )}
          {social.instagram && (
            <a href={social.instagram} target="_blank" style={{ marginRight: '5px' }} rel="noreferrer">
              <InstagramOutlined style={{ fontSize: '25px' }} />
            </a>
          )}
          {social.youtube && (
            <a href={social.youtube} target="_blank" style={{ marginRight: '5px' }} rel="noreferrer">
              <YoutubeOutlined style={{ fontSize: '25px' }} />
            </a>
          )}
          {social.line && (
            <a href={social.line} target="_blank" style={{ marginRight: '5px' }} rel="noreferrer">
              <LineIcon style={{ fontSize: '25px' }} />
            </a>
          )}
          {social.linkedin && (
            <a href={social.linkedin} target="_blank" rel="noreferrer">
              <LinkedinOutlined style={{ fontSize: '25px' }} />
            </a>
          )}
        </div>
      </div>
    </StyledFooterBottomWrapper>
  );
};

const Footer: FC = () => {
  const { countryConfig } = useRegionConfig();
  const country = countryConfig.country || 'sg';

  const isMobile = useMobileView(576);

  const configs = getCountryConfigs({ isMobile, language: i18next.language as TLanguageKey })?.[country] as any;
  const { TEXT_CONTENT, LINK_CONTENT } = configs || {};

  const { t } = useTranslation();
  return (
    <StyledWrapper id="main-footer">
      <StyledContainer>
        <Row gutter={[16, 16]}>
          {LINK_CONTENT?.map((content: any, index: number) => {
            const { title, items, key } = content;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={index} xs={24} md={8}>
                <TitleTypography variant="sHeader" className="footer-title" level={3}>
                  {country === 'hk' ? (
                    <div style={{ textTransform: 'uppercase' }}>
                      <CustomTextWithFontFamily text={t(title)} />
                    </div>
                  ) : (
                    t(title)
                  )}
                </TitleTypography>

                <StyledFooterDataWrapper noGrid={key === 'countryFlags' || key === 'noGrid'}>
                  {items?.map((item: any, itemIndex: number) => {
                    const { title: itemTitle, url, content: itemContent, desc, isExternal } = item;
                    return itemContent || desc ? (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="footerContent" key={itemIndex}>
                        <div className="contentDesc">{t(desc)}</div>
                        {itemContent}
                      </div>
                    ) : (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="footer-link" key={itemIndex}>
                        <a href={url} target={isExternal ? '_blank' : '_self'} rel="noreferrer">
                          {country === 'hk' ? <CustomTextWithFontFamily text={t(itemTitle)} /> : t(itemTitle)}
                        </a>
                      </div>
                    );
                  })}
                </StyledFooterDataWrapper>
              </Col>
            );
          })}
        </Row>

        {TEXT_CONTENT && <StyledDescWrapper>{renderDescription(TEXT_CONTENT)}</StyledDescWrapper>}

        <StyledHr />

        {TEXT_CONTENT && country && <StyledBottomWrapper>{renderBottom(TEXT_CONTENT, country)}</StyledBottomWrapper>}
      </StyledContainer>

      <BackToTop />
    </StyledWrapper>
  );
};

export default Footer;
