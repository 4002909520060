/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import ceil from 'lodash/ceil';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { TLanguageKey, TRegionKey } from '@source/interface';
import { IInspectionLocationItem } from '@source/interface/sell';
import { IDateOptions, ITimeSlot } from '@source/interface/bookingInspection';
import { ENV_URL, WHATSAPP_NUMBER } from '@source/constants/common';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { getSellUrlNameByLocalLanguage } from '@source/utils/utils';
import { IGroupedOptions } from '@source/interface/configOptions';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(utc);
dayjs.extend(timezone);

const isHoliday = (options: ITimeSlot[]) => (options ? options.every((o) => o.has_inspection === false) : true);
const isBlocked = (options: ITimeSlot[]) => (options ? options.every((o) => o.block_time === true) : true);

const isDisabledDate = (options: ITimeSlot[]) => isHoliday(options) || isBlocked(options);
const isDisabledTime = (slot: ITimeSlot) => !!slot.block_time || slot.slots_left === 0 || !slot.has_inspection;

// Get default 14 days options
const getDefaultDateOptions = (t?: any) =>
  Array.from(Array(14).keys()).map((i: number) => {
    const dateObj = dayjs().add(i, 'day').startOf('day');

    let ordinalNumber = dateObj.format('D');
    if (dateObj.isToday()) ordinalNumber = 'Today';
    if (dateObj.isTomorrow()) ordinalNumber = 'Tomorrow';
    const daysOfWeek = t ? t(`appointment.daysOfWeek.${dateObj.day()}`) : `appointment.daysOfWeek.${dateObj.day()}`;

    return {
      days: i,
      key: dateObj.format('YYYY-MM-DD'),
      value: dateObj.format('YYYY-MM-DD'),
      ordinalNumber,
      daysOfWeek,
    };
  });

const getDefaultSelectedDate = (dateOptions: IDateOptions[]) => dateOptions?.find((i) => i.disabled === false)?.value;

// Get default time slot options
const getDefaultTimeSlotOptions = () => {
  const start = '09:00';
  const end = '19:00';
  const timeJump = 30;
  const timeSlots: ITimeSlot[] = [];

  let currentTime = dayjs(start, 'hh:mm');
  const endTime = dayjs(end, 'hh:mm');
  while (currentTime <= endTime) {
    timeSlots.push({
      title: currentTime.format('h:mm a'),
      value: currentTime.format('h:mm'),
      slots_left: 0,
      slots_set: 0,
      has_inspection: true,
      block_time: false,
    });
    currentTime = currentTime.add(timeJump, 'minute');
  }

  return timeSlots;
};

export const useBeforeUnloadUnsaved = ({ region }: { region: TRegionKey }) => {
  const router = useRouter();
  const { i18n } = useTranslation();

  useEffect(() => {
    // https://www.sitepoint.com/community/t/need-the-custom-message-to-display-instead-of-changes-you-made-may-not-have-been-saved-on-page-refresh/273973/7
    const listener = (event: any) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'Changes you made may not be saved.';
    };

    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      // Update browser history only if the route change was not shallow
      if (!shallow) {
        window.history.pushState({ url }, '', url);
      }
    };

    // Add event listener to handle Next.js route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    const handlePopstate = (event: PopStateEvent) => {
      // handle popstate event
      const { url } = event.state || {};

      if (url && window) {
        // Ask the user to confirm before redirecting to the home page
        const confirmedMessage =
          'You will return to the home page when you go back.\nPlease contact us if you would like to make any changes. ';
        // eslint-disable-next-line no-alert
        const confirmed = window.confirm(confirmedMessage);
        if (confirmed) {
          // Use Router.replace() to change the route without reloading the page
          const path = getSellUrlNameByLocalLanguage(region, i18n.language as TLanguageKey);
          router.replace(URL_CONFIG({ region, lang: i18n.language, path }).formatted, undefined, { shallow: true });
        } else {
          const newUrl = !url.includes(region) ? `/${region}/${url}` : url;
          // Prevent the default back behavior
          event.preventDefault();
          // Add the current URL back to the browser history so the back button works correctly
          window.history.pushState({ url: newUrl }, '', newUrl);
        }
      }
    };

    // Listen for beforeUnload event
    window.addEventListener('beforeunload', listener);
    // Listen for the popstate event
    window.addEventListener('popstate', handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      window.removeEventListener('beforeunload', listener);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
};

const getDefaultTimeSlot = (timeSlots: ITimeSlot[]) => timeSlots?.find((time) => !isDisabledTime(time))?.value || null;

const mapProvinceFieldOptions = (options: any) => {
  let newOptions = [...options];

  newOptions = newOptions?.map((j: any) => ({
    ...j,
    title: `${j?.title} (${j?.count})`,
  }));

  return newOptions;
};

const mapLocationFieldOptions = (options: any) => {
  let newOptions = [...options];

  const grouped = newOptions.reduce(
    (groups: IGroupedOptions<IInspectionLocationItem>[], item: IInspectionLocationItem) => {
      // Find the index of the object with the same `label` property as the current item's province display name
      const { province } = item || {};
      const provinceIndex = groups.findIndex(
        (obj: IGroupedOptions<IInspectionLocationItem>) => obj.label === province?.display_name,
      );

      if (provinceIndex < 0) {
        // If not exist, push a new item inclduing title is province display_name and options is list of location items
        groups.push({
          label: province?.display_name || '',
          options: [item],
        });
      } else {
        // If already exists add the current item to its `options` array
        groups[provinceIndex].options.push(item);
      }

      return groups;
    },
    [],
  );

  // Sort by province id
  grouped.sort(
    (a: IGroupedOptions<IInspectionLocationItem>, b: IGroupedOptions<IInspectionLocationItem>) =>
      (a?.options?.[0]?.province?.id || 0) - (b?.options?.[0]?.province?.id || 0),
  );

  // Sort each item's `options` array by location id
  grouped.forEach((obj: IGroupedOptions<IInspectionLocationItem>) => {
    obj.options.sort((a, b) => a.value - b.value);
  });

  // return new options with label is province display_name + number of options and the options is list of children location items
  newOptions = grouped.map((item: IGroupedOptions<IInspectionLocationItem>) => ({
    label: `${item?.label} (${item.options?.length})`,
    options: item?.options,
  }));

  return newOptions;
};

const getAppointmentRemainingTime = (date?: string | Dayjs) => {
  if (!date || !dayjs(date).isValid()) return '';

  let result = '';
  // eslint-disable-next-line no-shadow
  const today = dayjs();
  const dateObj = typeof date === 'string' ? dayjs(date) : date;

  if (dateObj.isToday()) {
    const hours = dateObj.diff(today, 'hours', true);
    if (hours > 4) {
      result = `today ${dateObj.format('hh:mmA')}`;
    } else {
      result = `in ${ceil(hours)} hours`;
    }
  } else if (dateObj.isTomorrow()) {
    result = `tomorrow ${dateObj.format('hh:mmA')}`;
  } else {
    const days = ceil(dateObj.diff(today, 'days', true));
    result = `in ${days} days`;
  }

  return result;
};

const getLinkForWhatsappMessage = (country: TRegionKey) => {
  const querystring = typeof window !== 'undefined' ? window?.location?.search : '';
  const link =
    typeof window !== 'undefined'
      ? `${window?.location?.origin}${window.location.pathname}${querystring}`
      : ENV_URL?.[country];

  return link;
};

const useWhatsappPrefillMessage = (country: TRegionKey, customWhatsappMsg?: string | null) => {
  const { t } = useTranslation();
  const whatsappScheme = `https://wa.me/${WHATSAPP_NUMBER?.[country]}/?text=`;
  const [whatsappLink, setWhatsappLink] = useState('');

  useEffect(() => {
    const link = getLinkForWhatsappMessage(country);
    const defaultPrefillContent = customWhatsappMsg || t(`shared.prefillShareMessage.whatsappMY`, { link });
    const encodedDefaultPrefillContent = encodeURIComponent(defaultPrefillContent || '');
    setWhatsappLink(`${whatsappScheme}${encodedDefaultPrefillContent}`);
  }, []);

  return { whatsappLink };
};

const getDefaultTimeZone = (region: TRegionKey) => {
  const DEFAULT_TIME_ZONE_BY_COUNTRY = {
    my: 'Asia/Kuala_Lumpur',
  };

  return (
    DEFAULT_TIME_ZONE_BY_COUNTRY?.[region as keyof typeof DEFAULT_TIME_ZONE_BY_COUNTRY] ||
    DEFAULT_TIME_ZONE_BY_COUNTRY?.my
  );
};

const alphaNumericValidator = (str: string) => {
  const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/i;
  return reg.test(str);
};

export {
  isHoliday,
  isBlocked,
  isDisabledDate,
  isDisabledTime,
  getDefaultDateOptions,
  getDefaultSelectedDate,
  getDefaultTimeSlotOptions,
  getDefaultTimeSlot,
  mapProvinceFieldOptions,
  mapLocationFieldOptions,
  getAppointmentRemainingTime,
  dayjs,
  useWhatsappPrefillMessage,
  getLinkForWhatsappMessage,
  getDefaultTimeZone,
  alphaNumericValidator,
};
