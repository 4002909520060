import { FC } from 'react';
import styled from 'styled-components';

interface ITotalPaymentPopoverContent {
  listedPrice: string;
  allInPrice: string;
  content: {
    title: string;
    desc: string;
    listedPriceLabel: string;
    allInPriceLabel: string;
  };
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .title {
    ${(props) => props.theme.typo.familyGoogle.semiBold}
    ${(props) => props.theme.typo.style.mainButtonOrLink}
  }

  .desc {
    ${(props) => props.theme.typo.familyGoogle.regular}
    ${(props) => props.theme.typo.style.body}
  }

  .priceWrapper {
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background-color: ${(props) => props.theme.color.surface};

    .priceItem {
      display: flex;
      justify-content: space-between;
      .label {
        ${(props) => props.theme.typo.familyGoogle.regular}
        ${(props) => props.theme.typo.style.captionRegular}
      }
      .price {
        ${(props) => props.theme.typo.style.captionRegular}
        ${(props) => props.theme.typo.familyGoogle.medium}

        &.allIn {
          ${(props) => props.theme.typo.familyGoogle.bold}
        }
      }
    }
  }
`;

const TotalPaymentPopoverContent: FC<ITotalPaymentPopoverContent> = ({ listedPrice, allInPrice, content }) => (
  <StyledWrapper>
    <div className="title">{content.title}</div>
    <div className="desc">{content.desc}</div>
    <div className="priceWrapper">
      <div className="priceItem">
        <span className="label">{content.listedPriceLabel}</span>
        <span className="price">{listedPrice}</span>
      </div>
      <div className="priceItem">
        <span className="label">{content.allInPriceLabel}</span>
        <span className="price allIn">{allInPrice}</span>
      </div>
    </div>
  </StyledWrapper>
);

export default TotalPaymentPopoverContent;
