/* eslint-disable import/prefer-default-export */
import { TRegionKey } from '@source/interface';
import { getAllRegionalUrls } from '@source/utils/utils';

export const seoSchemaOrganization: { [key in TRegionKey]: { [k: string]: any } } = {
  sg: {
    '@context': 'http://schema.org',
    '@id': 'https://carro.co/#organization',
    '@type': 'Organization',
    name: 'Carro',
    url: 'https://carro.co/',
    logo: 'https://cdn.carro.co/carro-webs/static/img/carro_logo_v2.svg',
    subOrganization: {
      '@type': 'Organization',
      name: 'Carro Singapore',
      alternateName: 'The Best Place to Buy and Sell Second-Hand & Used Cars in Singapore',
      url: 'https://carro.co/sg/en',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '6567146652',
        contactType: 'customer service',
        areaServed: 'SG',
        availableLanguage: ['en', 'Chinese', 'Malay'],
      },
      sameAs: [
        'https://www.facebook.com/carro.singapore/',
        'https://twitter.com/carro_sg',
        'https://www.instagram.com/carro.sg/',
        'https://www.youtube.com/c/CarroSingapore',
        'https://www.linkedin.com/company/carrogroup/',
        'https://www.pinterest.com/carrosingapore/',
        'https://soundcloud.com/carroofficial',
        'https://wikitia.com/wiki/CARRO',
      ],
    },
  },
  th: {
    '@context': 'http://schema.org',
    '@id': 'https://carro.co/#organization',
    '@type': 'Organization',
    name: 'Carro',
    url: 'https://carro.co/',
    logo: 'https://cdn.carro.co/carro-webs/static/img/carro_logo_v2.svg',
    subOrganization: {
      '@type': 'Organization',
      name: 'Carro Thailand',
      alternateName: 'Carro เว็บซื้อ-ขายรถมือสอง สะดวก ปลอดภัย เชื่อถือได้ บริการฟรี!',
      url: 'https://carro.co/th/',
      logo: 'https://cdn.carro.co/carro-webs/static/img/carro_logo_v2.svg',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '024609380',
        contactType: 'customer service',
        areaServed: 'TH',
        availableLanguage: ['en', 'Thai'],
      },
      sameAs: [
        'https://www.facebook.com/CARROThai/',
        'https://www.instagram.com/carrothai/',
        'https://www.youtube.com/c/CARROThai',
      ],
    },
  },
  id: {
    '@context': 'http://schema.org',
    '@id': 'https://carro.co/#organization',
    '@type': 'Organization',
    name: 'Carro',
    url: 'https://carro.co/',
    logo: 'https://cdn.carro.co/carro-webs/static/img/carro_logo_v2.svg',
    subOrganization: {
      '@type': 'Organization',
      name: 'Carro Indonesia',
      alternateName: 'Platform mobil Bekas Terbesar Di Indonesia | Carro Indonesia',
      url: 'https://carro.co/id/',
      logo: 'https://cdn.carro.co/carro-webs/static/img/carro_logo_v2.svg',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '02150996789',
        contactType: 'customer service',
        areaServed: 'ID',
        availableLanguage: ['en', 'Indonesian'],
      },
      sameAs: [
        'https://www.facebook.com/carro.indonesia',
        'https://www.instagram.com/carroindonesia/',
        'https://www.youtube.com/channel/UCxZQ-LJ9sEfJSDAAeyCT0SA',
      ],
    },
  },
  my: {},
  jp: {},
  hk: {},
};

export const commonSeoSchemaOrganization = {
  '@context': 'http://schema.org',
  '@id': 'https://carro.co/#organization',
  '@type': 'Organization',
  name: 'Carro',
  url: 'https://carro.co/',
  logo: 'https://cdn.carro.co/carro-webs/static/img/carro_logo_v2.svg',
  sameAs: getAllRegionalUrls(),
};

export const commonSeoSchemaWebsite = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Carro',
  url: 'https://carro.co/',
};
