export const ID_DP_15_INTEREST_RATE = {
  12: 8.39,
  24: 9.02,
  36: 10.25,
  48: 10.92,
  60: 11.59,
};

export const ID_DONW_PAYMENT_RATE_BREAK_POINT = 30;

export const ID_DEFAULT_LOAN_TENURE = 60;
export const ID_DEFAULT_DOWNPAYMENT_RATE = 30;

export const ID_DP_SPECIAL_INTEREST_RATE = {
  12: 7.4,
  24: 7.2,
  36: 8.8,
  48: 9.4,
  60: 10.0,
};

export const ID_RULES_INSURANCE_AMOUNT = [
  { rate: 100, month: 12 },
  { rate: 85, month: 24 },
  { rate: 75, month: 36 },
  { rate: 70, month: 48 },
  { rate: 65, month: 60 },
];

export const ID_INSURANCE_RATE = 0.65;

export const ID_CALCULATED_INTEREST_RATE = ID_DP_SPECIAL_INTEREST_RATE[ID_DEFAULT_LOAN_TENURE];
