import React from 'react';
import styled from 'styled-components';
import { Statistic } from 'antd';
import type { countdownValueType } from 'antd/es/statistic/utils';

const { Countdown } = Statistic;

interface ICountdownTimer {
  value: countdownValueType;
  format: string;
  onFinish?: () => void;
  onChange?: (val?: countdownValueType) => void;
}

const StyledCountdown = styled(Countdown)`
  margin: 16px 0 20px;
  display: inline;

  .ant-statistic-content {
    display: inline;
    ${(props) => props.theme.typo.style.smallButtonOrLink}
  }
`;

const CountdownTimer: React.FC<ICountdownTimer> = ({ value, format, onFinish, onChange }) => (
  <StyledCountdown value={value} format={format} onFinish={onFinish} onChange={onChange} />
);

CountdownTimer.defaultProps = {
  onFinish: () => {},
  onChange: () => {},
};

export default CountdownTimer;
