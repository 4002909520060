import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@source/hooks/useStateHook';
import { getRecaptchaToken } from '@design-system/utils/utils';
import useRegionConfig from '@source/hooks/useRegionConfig';
import * as actions from '@source/redux/slice/otp';
import { IRequestOTPPayload, IVerifyOTPRequestPayload } from '@source/interface/otp';

const useOTPService = () => {
  const dispatch = useAppDispatch();
  const otpState = useAppSelector((state) => state.otp, shallowEqual);

  const {
    countryConfig: { country },
  } = useRegionConfig();
  const { isReady, pathname } = useRouter();

  // Current lang
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  /** Actions */

  const requestOTP = useCallback(
    ({ payload }: { payload: IRequestOTPPayload }) => {
      const currentPage = pathname.replace(/[^a-zA-Z]/g, '');
      const recaptchaAction = `${country}_${currentPage}_requestOtp`;

      const requestOTPWithToken = (token: string) =>
        dispatch(
          actions.requestOTP({
            country: country || 'sg',
            payload: {
              ...payload,
              locale: currentLang,
              g_recaptcha_response: token,
              g_recaptcha_action: recaptchaAction,
            },
          }),
        );
      return getRecaptchaToken(requestOTPWithToken, requestOTPWithToken, recaptchaAction);
    },
    [dispatch, isReady, currentLang],
  );

  const verifyOTP = useCallback(
    ({ payload, storeUser }: IVerifyOTPRequestPayload) =>
      dispatch(
        actions.verifyOTP({ country: country || 'sg', payload: { ...payload, locale: currentLang }, storeUser }),
      ),
    [dispatch, currentLang],
  );

  const requestOTT = useCallback(() => dispatch(actions.requestOTT()), [dispatch]);

  const otpCountdownDone = useCallback(() => dispatch(actions.otpCountdownDone()), [dispatch]);
  const resetOTPState = useCallback(() => dispatch(actions.resetState()), [dispatch]);

  const setInternationalPhone = useCallback(
    (phone: string | null) => dispatch(actions.setInternationalPhone(phone)),
    [dispatch],
  );
  const setPhone = useCallback((phone: string | null) => dispatch(actions.setPhone(phone)), [dispatch]);
  const setType = useCallback((type: string | null) => dispatch(actions.setType(type)), [dispatch]);
  const clearError = useCallback(() => dispatch(actions.clearError()), [dispatch]);

  return {
    otpState,
    requestOTP,
    verifyOTP,
    requestOTT,
    otpCountdownDone,
    resetOTPState,
    setPhone,
    setInternationalPhone,
    setType,
    clearError,
  };
};
export default useOTPService;
