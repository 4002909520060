import Logger from '@source/services/Logger';
import React from 'react';
import { TRegionKey } from '@source/interface';
import { logSentryError } from '@source/utils/utils';
import dynamic from 'next/dynamic';

const Error = dynamic(() => import('@source/pages/Error'));

interface IProps {
  children: any;
  region: TRegionKey;
}

interface IState {
  hasError: boolean;
}

// const fallbackComponent = <h1>Something went wrong.</h1>;
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    Logger.log(error, errorInfo);
    const { region } = this.props;
    logSentryError({
      region,
      errorMessage: `ErrorBoundary | ${error || ''} | ${errorInfo || ''}`,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, region } = this.props;
    if (hasError) {
      return <Error region={region} errorClassName="ErrorBoundary" />;
    }

    return children;
  }
}

export default ErrorBoundary;
