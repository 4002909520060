import React from 'react';
import { Trans } from 'react-i18next';

interface ICustomTextWithFontFamily {
  text?: string;
  fontFamily?: string;
}

const CustomTextWithFontFamily: React.FC<ICustomTextWithFontFamily> = ({ text, fontFamily }) =>
  text ? <Trans defaults={text} components={{ specificFont: <span style={{ fontFamily }} /> }} /> : null;

CustomTextWithFontFamily.defaultProps = {
  text: '',
  fontFamily: 'Poppins',
};

export default CustomTextWithFontFamily;
