import React, { FC } from 'react';
import useRegionConfig from '@source/hooks/useRegionConfig';
import SharedSideNavigation from '@source/components/SideNavigation/SharedSideNavigation';
import sideNavigationConfig from '@source/components/SideNavigation/constants';
import i18next from 'i18next';
import { TLanguageKey } from '@source/interface';

interface SideNavigationProps {
  visibleDrawer: boolean;
  onClose: () => void;
  isPlacementRightDrawer: boolean;
}

const SideNavigation: FC<SideNavigationProps> = ({ visibleDrawer, onClose, isPlacementRightDrawer }) => {
  const {
    countryConfig: { country: currentRegion },
  } = useRegionConfig();

  if (!currentRegion) {
    return null;
  }

  const currentCountryConfig = sideNavigationConfig(currentRegion, i18next.language as TLanguageKey);

  return (
    <SharedSideNavigation
      currentCountryConfig={currentCountryConfig[currentRegion]}
      onClose={onClose}
      isPlacementRightDrawer={isPlacementRightDrawer}
      visibleDrawer={visibleDrawer}
    />
  );
};

export default SideNavigation;
