import React, { FC, PropsWithChildren } from 'react';
import { Typography as AntTypo } from 'antd';
import styled from 'styled-components';

// Type
type TypoVariant =
  | 'xlHeader'
  | 'lHeader'
  | 'mHeader'
  | 'sHeader'
  | 'body'
  | 'captionRegular'
  | 'mainButtonOrLink'
  | 'smallButtonOrLink'
  | 'captionBold'
  | 'label';

export interface TitleTypographyProps extends PropsWithChildren {
  variant?: TypoVariant;
  className?: string;
  level?: 1 | 2 | 3 | 4 | 5;
}

// Style
const { Title } = AntTypo;

const StyledTitle = styled(Title)<TitleTypographyProps>`
  &.xlHeader {
    ${(props) => props.theme.typo.style.xlHeader}
  }

  &.lHeader {
    ${(props) => props.theme.typo.style.lHeader}
  }

  &.mHeader {
    ${(props) => props.theme.typo.style.mHeader}
  }

  &.sHeader {
    ${(props) => props.theme.typo.style.sHeader}
  }

  &.body {
    ${(props) => props.theme.typo.style.body}
  }

  &.captionRegular {
    ${(props) => props.theme.typo.style.captionRegular}
  }

  &.mainButtonOrLink {
    ${(props) => props.theme.typo.style.mainButtonOrLink}
  }

  &.smallButtonOrLink {
    ${(props) => props.theme.typo.style.smallButtonOrLink}
  }

  &.captionBold {
    ${(props) => props.theme.typo.style.captionBold}
  }

  &.label {
    ${(props) => props.theme.typo.style.label}
  }
`;

const Typography: FC<TitleTypographyProps> = ({ children, variant, className, level }) => (
  <StyledTitle className={`${className} ${variant}`} variant={variant} level={level}>
    {children}
  </StyledTitle>
);

Typography.defaultProps = {
  variant: 'mHeader',
  className: '',
  level: 1,
};

export default Typography;
