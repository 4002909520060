/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CRAZY_RABBIT_WEB_URL, WHATSAPP_NUMBER } from '@source/constants/common';
import { TLanguageKey, TRegionKey } from '@source/interface';
import useWindowResize from '@source/hooks/useWindowResize';
import {
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP,
  NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH,
  NEXT_PUBLIC_LINE_APP_ID_JP,
  NEXT_PUBLIC_LINE_APP_ID_TH,
} from '@source/constants/env';
import { URL_CONFIG } from '@source/constants/urlConfig';
import { getUTM } from '@source/hooks/useUTM';

export const getCurrentPageUrl = ({
  country,
  language,
  customDefaultUrl,
}: {
  country: TRegionKey;
  language: TLanguageKey | string;
  customDefaultUrl?: string;
}) => {
  if (typeof window === 'undefined') return '';

  // Get the query string by using current window location search
  let queryString = window ? window?.location?.search : '';

  // If the query string is empty, get utm from session storage
  if (queryString?.length === 0) {
    queryString = getUTM();
  }

  // Set a default URL to use if window is not ready
  const defaultUrl =
    customDefaultUrl ||
    `${CRAZY_RABBIT_WEB_URL?.[country as TRegionKey]}${
      URL_CONFIG({ region: country as TRegionKey, lang: language }).formatted
    }`;

  // construct the link
  const link = window ? `${window.location.origin}${window.location.pathname}${queryString}` : defaultUrl;

  return link;
};

export const useWhatsappPrefillMessage = ({
  country,
  whatsappNumber,
  customWhatsappMsg,
  defaultLink,
}: {
  country: TRegionKey;
  whatsappNumber?: string;
  customWhatsappMsg?: string | null;
  defaultLink?: string;
}) => {
  const { t, i18n } = useTranslation();

  // The default whatsapp URL scheme: https://wa.me/1XXXXXXXXXX?text=xxxxxxx
  const whatsappScheme = `https://wa.me/${whatsappNumber || WHATSAPP_NUMBER?.[country]}/?text=`;

  const [whatsappLink, setWhatsappLink] = useState('');

  useEffect(() => {
    const link = getCurrentPageUrl({ country, language: i18n.language, customDefaultUrl: defaultLink });

    // Define a default prefill message for WhatsApp
    const defaultPrefillContent =
      customWhatsappMsg || t(`shared.prefillShareMessage.whatsapp${country === 'my' ? 'MY' : ''}`, { link });

    // Encode using encodeURIComponent
    const encodedDefaultPrefillContent = encodeURIComponent(defaultPrefillContent || '');

    setWhatsappLink(`${whatsappScheme}${encodedDefaultPrefillContent}`);
  }, [country, customWhatsappMsg, whatsappNumber, defaultLink, i18n.language]);

  return { whatsappLink };
};

export const useLinePrefillMessage = ({
  country,
  customAddFriendId,
  customLineAppId,
  customLineMessage,
  defaultLink,
}: {
  country: TRegionKey;
  customAddFriendId?: string;
  customLineAppId?: string;
  customLineMessage?: string | null;
  defaultLink?: string;
}) => {
  const [width] = useWindowResize();
  const { t, i18n } = useTranslation();

  const [lineLink, setLineLink] = useState('');

  // Config
  const lineAppIdByCountry = {
    th: NEXT_PUBLIC_LINE_APP_ID_TH,
    jp: NEXT_PUBLIC_LINE_APP_ID_JP,
  };
  const lineAddFriendByCountry = {
    th: NEXT_PUBLIC_LINE_APP_ADD_FRIEND_TH,
    jp: NEXT_PUBLIC_LINE_APP_ADD_FRIEND_JP,
  };
  const lineAppId =
    customLineAppId || lineAppIdByCountry?.[country as keyof typeof lineAppIdByCountry] || lineAppIdByCountry?.th;
  const lineAddFriendId =
    customAddFriendId ||
    lineAddFriendByCountry?.[country as keyof typeof lineAddFriendByCountry] ||
    lineAddFriendByCountry?.th;

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!width) return;

    // Populate the link
    const link = getCurrentPageUrl({ country, language: i18n.language, customDefaultUrl: defaultLink });

    // Construct the Line scheme URL based on whether the device is mobile or not
    const isMobile = width < 1280;
    if (isMobile) {
      const lineScheme = `https://line.me/R/oaMessage/@${lineAppId}/?`;
      // Construct the default prefill message
      const defaultPrefillContent =
        customLineMessage || t(`shared.prefillShareMessage.line${country === 'my' ? 'MY' : ''}`, { link });

      // Encode using encodeURIComponent
      const encodedDefaultPrefillContent = encodeURIComponent(defaultPrefillContent || '');

      setLineLink(`${lineScheme}${encodedDefaultPrefillContent}`);
    } else {
      setLineLink(`https://lin.ee/${lineAddFriendId}`);
    }
  }, [width]);

  return { lineLink };
};
