import { getStaticCDN } from '@design-system/utils/utils';
import { getDataConfigsCountry, getFiltersTranslationPath } from './constants';

const countryCode = 'hk';
/* eslint-disable no-template-curly-in-string */
const FILTER_CONFIGS_HK = {
  loading: false,
  initialFilterSettings: [
    {
      name: 'price_range',
      active: true,
      activeMobile: true,
      group: 'cost_details',
      groupMobile: 'cost_details',
      showSubheader: true,
      headerFormat: true,
      algoliaFetching: false,
      requireDefaults: true,
      hasDivider: true,
      filters: [
        {
          name: 'listing.asking_price',
          type: 'range_input',
          prefix: '$',
          headerFormat: true,
          requireCommaFormat: true,
          filterSelectedHeader: '$[selectedMinInput] - $[selectedMaxInput]',
          detailsText: {
            average: getFiltersTranslationPath('priceRangeAverage'),
          },
          selectedMinInput: null,
          selectedMaxInput: null,
          facetStats: {
            avg: null,
            max: null,
            min: null,
            sum: null,
          },
          placeholderMin: 'Min. price',
          placeholderMax: 'Max. price',
          selectedCustomLabel: getFiltersTranslationPath('priceRangeCustomLabelHK'),
        },
      ],
    },
    {
      name: 'category',
      active: true,
      activeMobile: true,
      header: getFiltersTranslationPath('quick_search'),
      algoliaFetching: false,
      filters: [
        {
          header: getFiltersTranslationPath('categories'),
          name: 'categories',
          type: 'button',
          filterSelectedHeader: '[label]',
          isShowTagMobile: false,
          colSize: 12,
          isNumeric: false,
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'CATEGORIES',
            listFields: [
              'low_mileage',
              'deluxe_and_classy',
              'budget_friendly',
              'fun_to_drive',
              '7_seaters',
              '0_owner_only',
              'family_friendly',
              'high_horsepower',
              'small_and_nimble',
            ],
          }),
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'body_button',
      filters: [
        {
          name: 'inventory.car_type',
          options: getDataConfigsCountry({
            countryCode,
            dataName: 'BODY_TYPE',
            listFields: [
              'convertible',
              'coupe',
              'electric',
              'hatchback',
              'hybrid',
              'luxury_sedan',
              'mid_sized_sedan',
              'mpv',
              'sports',
              'sports_hatchback',
              'stationwagon',
              'suv',
              'van',
            ],
          }),
        },
      ],
    },
    {
      name: 'owner',
      active: true,
      activeMobile: true,
      header: getFiltersTranslationPath('pastOwners'),
      group: 'history',
      groupHeader: getFiltersTranslationPath('ownershipHistory'),
      showSubheader: true,
      algoliaFetching: false,
      filters: [
        {
          headerMobile: getFiltersTranslationPath('pastOwners'),
          name: 'inventory.number_of_owners',
          filterSelectedHeader: 'Max [selectedNumber] Owner(s)',
          type: 'number',
          condition: '<=',
          isNumeric: true,
          selectedNumber: null,
          header: getFiltersTranslationPath('pastOwners'),
          headerFormat: true,
          defaultValue: 0,
          selectedCustomLabel: getFiltersTranslationPath('ownerCustomLabel'),
        },
      ],
    },
    {
      name: 'year',
      active: true,
      activeMobile: true,
      header: getFiltersTranslationPath('regYear'),
      headerMobile: getFiltersTranslationPath('registrationYear'),
      group: 'history',
      groupHeader: getFiltersTranslationPath('registrationYear'),
      showSubheader: true,
      headerFormat: true,
      algoliaFetching: false,
      requireDefaults: true,
      filters: [
        {
          header: getFiltersTranslationPath('registrationYear'),
          headerMobile: getFiltersTranslationPath('registrationYear'),
          name: 'inventory.first_registration_year',
          filterSelectedHeader: '[selectedMinInput] - [selectedMaxInput]',
          requireCommaFormat: false,
          type: 'range_input',
          isNumeric: true,
          headerFormat: false,
          selectedMinInput: null,
          selectedMaxInput: null,
          facetStats: {
            min: 1990,
            max: 2022,
            avg: 2018,
            sum: 446102,
          },
          placeholderMin: 'From',
          placeholderMax: 'To',
          selectedCustomLabel: getFiltersTranslationPath('rangeInputCustomLabel'),
        },
      ],
    },
    {
      name: 'colours',
      active: true,
      activeMobile: true,
      showHeader: true,
      group: 'colours',
      showSubheader: true,
      algoliaFetching: false,
      joinCondition: 'AND',
      filters: [
        {
          name: 'inventory.base_color_key',
          type: 'button',
          filterSelectedHeader: '[label]',
          parentStyles: {
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            placeItems: 'center',
          },
          imagePath: '/static/icon/{0}.png',
          isNumeric: false,
          options: [
            { label: getFiltersTranslationPath('options.black'), value: 'black', name: 'black', colorCode: '#363636' },
            { label: getFiltersTranslationPath('options.white'), value: 'white', name: 'white', colorCode: '#FFFFFF' },
            { label: getFiltersTranslationPath('options.brown'), value: 'brown', name: 'brown', colorCode: '#553418' },
            { label: getFiltersTranslationPath('options.grey'), value: 'grey', name: 'grey', colorCode: '#6E6E6E' },
            {
              label: getFiltersTranslationPath('options.silver'),
              value: 'silver',
              name: 'silver',
              colorCode: '#CCCCCC',
            },
            { label: getFiltersTranslationPath('options.blue'), value: 'blue', name: 'blue', colorCode: '#0075FF' },
            { label: getFiltersTranslationPath('options.red'), value: 'red', name: 'red', colorCode: '#FF0007' },
            { label: getFiltersTranslationPath('options.green'), value: 'green', name: 'green', colorCode: '#00801D' },
            {
              label: getFiltersTranslationPath('options.orange'),
              value: 'orange',
              name: 'orange',
              colorCode: '#FFA22B',
            },
            { label: getFiltersTranslationPath('options.beige'), value: 'beige', name: 'beige', colorCode: '#FCE7B5' },
            {
              label: getFiltersTranslationPath('options.bronze'),
              value: 'bronze',
              name: 'bronze',
              colorCode: '#CD7F32',
            },
            {
              label: getFiltersTranslationPath('options.champagne'),
              value: 'champagne',
              name: 'champagne',
              colorCode: '#F7E7CE',
            },
            { label: getFiltersTranslationPath('options.cream'), value: 'cream', name: 'cream', colorCode: '#FCFAD9' },
            { label: getFiltersTranslationPath('options.cyan'), value: 'cyan', name: 'cyan', colorCode: '#1A98A6' },
            { label: getFiltersTranslationPath('options.gold'), value: 'gold', name: 'gold', colorCode: '#F8D100' },
            {
              label: getFiltersTranslationPath('options.maroon'),
              value: 'maroon',
              name: 'maroon',
              colorCode: '#800000',
            },
            {
              label: getFiltersTranslationPath('options.pearl_white'),
              value: 'pearl_white',
              name: 'pearl_white',
              colorCode: '#F8F6F0',
            },
            { label: getFiltersTranslationPath('options.pink'), value: 'pink', name: 'pink', colorCode: '#FFC0CB' },
            {
              label: getFiltersTranslationPath('options.purple'),
              value: 'purple',
              name: 'purple',
              colorCode: '#A020F0',
            },
            {
              label: getFiltersTranslationPath('options.yellow'),
              value: 'yellow',
              name: 'yellow',
              colorCode: '#F8E08E',
            },
            {
              label: getFiltersTranslationPath('options.others'),
              value: 'others',
              name: 'others',
              colorImgSrc: getStaticCDN('/assets/shared/icons/other-colors-icon.svg'),
            },
          ],
          selectedOptions: null,
          buttonsType: 'primary',
          rowGutter: [8, 8],
          colResponsive: {
            xs: 8,
            sm: 8,
            md: 8,
          },
        },
      ],
    },
  ],
  attributesToRetrieve: [
    'id',
    'created_at',
    'promo_label',
    'promo_images',
    'promotion',
    'promotion.promo_blurb.url',
    'hits',
    'categories',
    'is_promo',
    'listing.title',
    'listing.slug',
    'listing.mileage',
    'listing.asking_price',
    'listing.photos',
    'listing.current_depreciation',
    'listing.installment',
    'listing.is_coming_soon',
    'listing.is_pending_sale',
    'listing.is_sold',
    'listing.is_agent_serviced',
    'listing.is_agent_warranty',
    'listing.is_carro_anywhere',
    'listing.is_available',
    'listing.is_ccpo',
    'listing.is_cpa',
    'listing.is_new_arrival',
    'listing.thumbnail',
    'listing.promotional_price',
    'listing.key_words',
    'listing.months_left',
    'listing.paper_value',
    'listing.status',
    'listing.is_carro_assure',
    'listing.is_carro_assure_plus',
    'listing.agent_warranty',
    'listing.maximum_loan_percentage',
    'listing.downpayment_percentage',
    'listing.original_price',
    'listing.scrap_value',
    'listing.paper_value',
    'listing.downpayment',
    'inventory.original_registration_date',
    'inventory.number_of_owners',
    'inventory.year_of_manufacture',
    'inventory.manufacture_year',
    'inventory.make',
    'inventory.model',
    'inventory.coe_expiry_date',
    'inventory.car_type',
    'inventory.first_registration_year',
    'inventory.transmission',
    'inventory.fuel_type',
    'inventory.color',
    'inventory.seats',
    'inventory.engine_capacity',
    'inventory.open_market_value',
    'inventory.arf_paid',
    'inventory.coe',
    'inventory.maximum_power_output',
    'inventory.road_tax',
    'inventory.base_color_key',
    'inventory.color_variation_key',
    'inside_badges',
  ],
  mobileFilters: [
    'category',
    'price_range',
    'make',
    'make_model',
    'body_button',
    'seats',
    'fuel_button',
    'manufacture_year',
    'mileage',
    'owner',
    'colours',
    'transmission',
    'year',
    'availability_button',
  ],
  otherFilters: ['category', 'colours', 'transmission', 'year', 'availability_button'],
  desktopFilters: [
    'price_range',
    'make_model',
    'body_button',
    'seats',
    'fuel_button',
    'manufacture_year',
    'mileage',
    'owner',
  ],
  isAlgoliaOptionsLoaded: true,
  isInitalSettingsLoaded: true,
  autocompleteHits: [],
  query: '',
  hitsPerPage: 24,
};

export default FILTER_CONFIGS_HK;
