import React, { ReactNode } from 'react';
import { Divider } from 'antd';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { TRegionKey } from '@source/interface';
import { mappingCountryCurrency } from '../utils';

const StyledContent = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular};
  padding: 30px;
  max-width: 370px;
  font-size: 14px;
  line-height: 140%;
  color: #000000;

  &.content-wrapper-v2 {
    min-width: 311px;
    padding: 20px;
    .price-label {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .price-divider {
      margin: 6px 0;
    }

    .price-wrapper {
      background-color: ${(props) => props.theme.color.surface};
      border-radius: 12px;
      padding: 11px 10px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      > div {
        display: flex;
        justify-content: space-between;
        > span {
          &:first-child {
            font-size: 12px;
            color: ${(props) => props.theme.color.onBackgroundHighEmphasis};
          }
          &:last-child {
            font-size: 14px;
            ${(props) => props.theme.typo.familyGoogle.semiBold};
          }
        }
        &.price_final-price {
          > span {
            &:first-child {
              font-size: 14px;
            }
            &:last-child {
              font-size: 16px;
            }
          }
        }
      }
    }

    .desc-wrapper {
      font-size: 14px;
      font-weight: 400;
      ul {
        padding-left: 1rem;
      }
    }

    .installment-wrapper {
      margin: 16px 0;
      .installment-label {
        margin-bottom: 4px;
        font-size: 12px;
      }
      .installment {
        margin-bottom: 0;
      }
    }
  }

  .installment {
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.theme.color.primaryBase};
    margin-bottom: 24px;
    ${(props) => props.theme.typo.familyGoogle.bold};
  }

  .breakdown-desc {
    margin-bottom: 20px;
  }

  .breakdown-list {
    margin-bottom: 20px;

    ol,
    li {
      padding-left: 6px !important;
      margin-left: 6px !important;
    }
  }

  .appendix {
    font-size: 12px;
    color: #67696f;
  }

  .footerImage {
    margin-top: 24px;
  }

  .tapToClose {
    margin-top: 20px;
    text-align: center;
    color: #a1a1a1;
    font-size: 12px;
  }
`;

const StyledNote = styled.div``;

interface PriceInfoPopupProps {
  data: any;
  bottomText?: boolean;
}

const PriceInfoContent = ({ data, bottomText }: PriceInfoPopupProps): ReactNode => {
  const { t } = useTranslation();
  const {
    countryConfig: { country },
  } = useRegionConfig();

  const currency = mappingCountryCurrency[country as TRegionKey];

  if (country === 'th') {
    return (
      <StyledContent className="content-wrapper-v2">
        <p className="price-label">{t('home.listingCard.priceInfo.price')}</p>
        <div className="price-wrapper">
          <div className="price_original-price">
            <span>{t('home.listingCard.priceInfo.carPrice')}</span>
            <span>
              {currency}
              {data.price}
            </span>
          </div>
          <div className="price_vat-price">
            <span>{t('home.listingCard.priceInfo.vatPrice', { vatPrice: '(7%)' })}</span>
            <span>
              {currency}
              {data.vatPrice}
            </span>
          </div>
          <Divider className="price-divider" />
          <div className="price_final-price">
            <span>{t('home.listingCard.priceInfo.finalPrice')}</span>
            <span>
              {currency}
              {data.finalPrice}
            </span>
          </div>
        </div>
        <div className="installment-wrapper">
          <p className="installment-label">{t('home.listingCard.priceInfo.monthlyInstallment')}</p>
          <p className="installment">{data?.installment}</p>
        </div>
        <p className="breakdown-desc">{data?.breakdownDesc}</p>
        {data?.breakdownList && (
          <div className="breakdown-list">
            <ol>
              {data.breakdownList.map((item: any, index: any) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{item}</li>
              ))}
            </ol>
          </div>
        )}
        {data?.notes && (
          <StyledNote>
            <p className="appendix">{data.notes}</p>
          </StyledNote>
        )}
        {data?.description && (
          <div className="desc-wrapper">
            <Trans
              defaults={data?.description}
              components={{
                div: <div />,
                ul: <ul />,
                li: <li />,
              }}
            />
          </div>
        )}

        {data?.imageUrl && <Image src={data.imageUrl} width={117} height={26} className="footerImage" />}

        {bottomText && <div className="tapToClose">{t('home.listingCard.priceInfo.tapToClose')}</div>}
      </StyledContent>
    );
  }

  return (
    <StyledContent>
      <p>{data?.driveAway || t('home.listingCard.priceInfo.driveAway')}</p>
      <p className="installment">{data?.installment}</p>
      <p className="breakdown-desc">{data?.breakdownDesc}</p>
      {data?.breakdownList && (
        <div className="breakdown-list">
          <ol>
            {data.breakdownList.map((item: any, index: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{item}</li>
            ))}
          </ol>
        </div>
      )}
      {data?.notes && (
        <StyledNote>
          <p className="appendix">{data.notes}</p>
        </StyledNote>
      )}

      {data?.imageUrl && <Image src={data.imageUrl} width={117} height={26} className="footerImage" />}

      {bottomText && <div className="tapToClose">{t('home.listingCard.priceInfo.tapToClose')}</div>}
    </StyledContent>
  );
};

PriceInfoContent.defaultProps = {
  bottomText: false,
};

export default PriceInfoContent;
