import React, { FC, PropsWithChildren } from 'react';
import { Typography as AntTypo } from 'antd';
import styled from 'styled-components';

// Type
type TypoVariant = 'mainButtonOrLink' | 'smallButtonOrLink';

export interface LinkButtonTypographyProps extends PropsWithChildren {
  className?: string;
  href?: string;
  openInNewTab?: boolean;
  variant?: TypoVariant;
}

// Style
const { Link } = AntTypo;

const StyledLink = styled(Link)<LinkButtonTypographyProps>`
  &.mainButtonOrLink {
    ${(props) => props.theme.typo.style.mainButtonOrLink}
  }

  &.smallButtonOrLink {
    ${(props) => props.theme.typo.style.smallButtonOrLink}
  }
`;

const LinkButtonTypography: FC<LinkButtonTypographyProps> = ({ variant, children, href, openInNewTab, className }) => (
  <StyledLink
    className={`${className} ${variant}`}
    href={href}
    target={openInNewTab ? '_blank' : '_self'}
    variant={variant}
  >
    {children}
  </StyledLink>
);

LinkButtonTypography.defaultProps = {
  className: '',
  href: '',
  openInNewTab: false,
  variant: 'mainButtonOrLink',
};

export default LinkButtonTypography;
