import React, { FC, PropsWithChildren } from 'react';
import { Typography as AntTypo } from 'antd';
import styled from 'styled-components';

// Type
type TypoVariant =
  | 'xlHeader'
  | 'lHeader'
  | 'mHeader'
  | 'sHeader'
  | 'header'
  | 'body'
  | 'captionRegular'
  | 'mainButtonOrLink'
  | 'smallButtonOrLink'
  | 'captionBold'
  | 'label';

export interface TypographyProps extends PropsWithChildren {
  variant?: TypoVariant;
  className?: string;
  onClick?: () => void;
}

// Style
const { Text } = AntTypo;

const StyledText = styled(Text)<TypographyProps>`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};

  &.xlHeader {
    ${(props) => props.theme.typo.style.xlHeader}
  }

  &.lHeader {
    ${(props) => props.theme.typo.style.lHeader}
  }

  &.mHeader {
    ${(props) => props.theme.typo.style.mHeader}
  }

  &.sHeader {
    ${(props) => props.theme.typo.style.sHeader}
  }

  &.header {
    ${(props) => props.theme.typo.style.header}
  }

  &.body {
    ${(props) => props.theme.typo.style.body}
  }

  &.captionRegular {
    ${(props) => props.theme.typo.style.captionRegular}
  }

  &.mainButtonOrLink {
    ${(props) => props.theme.typo.style.mainButtonOrLink}
  }

  &.smallButtonOrLink {
    ${(props) => props.theme.typo.style.smallButtonOrLink}
  }

  &.captionBold {
    ${(props) => props.theme.typo.style.captionBold}
  }

  &.label {
    ${(props) => props.theme.typo.style.label}
  }
`;

const Typography: FC<TypographyProps> = ({ children, variant, className, onClick }) => (
  <StyledText onClick={onClick} className={`${className} ${variant}`} variant={variant}>
    {children}
  </StyledText>
);

Typography.defaultProps = {
  variant: 'body',
  className: '',
  onClick: undefined,
};

export default Typography;
