import React from 'react';

const PersonalIcon = ({ width = 20, height = 20 }: { width: number; height: number }) => (
  <svg
    width={width}
    height={height}
    className="personal-icon"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_336_79798)">
      <path d="M15.4286 1.71106H0.571429C0.255357 1.71106 0 1.96642 0 2.28249V13.7111C0 14.0271 0.255357 14.2825 0.571429 14.2825H15.4286C15.7446 14.2825 16 14.0271 16 13.7111V2.28249C16 1.96642 15.7446 1.71106 15.4286 1.71106ZM14.7143 12.9968H1.28571V2.99677H14.7143V12.9968ZM9.75536 7.35392H11.9589C11.9821 7.35392 12 7.28963 12 7.21106V6.35392C12 6.27535 11.9821 6.21106 11.9589 6.21106H9.75536C9.73214 6.21106 9.71429 6.27535 9.71429 6.35392V7.21106C9.71429 7.28963 9.73214 7.35392 9.75536 7.35392ZM9.84107 9.92535H13.1571C13.2268 9.92535 13.2839 9.86106 13.2839 9.78249V8.92535C13.2839 8.84677 13.2268 8.78249 13.1571 8.78249H9.84107C9.77143 8.78249 9.71429 8.84677 9.71429 8.92535V9.78249C9.71429 9.86106 9.77143 9.92535 9.84107 9.92535V9.92535ZM2.85714 10.8718H3.64107C3.71607 10.8718 3.77679 10.8128 3.78214 10.7378C3.85 9.83606 4.60357 9.12177 5.51786 9.12177C6.43214 9.12177 7.18571 9.83606 7.25357 10.7378C7.25893 10.8128 7.31964 10.8718 7.39464 10.8718H8.17857C8.19795 10.8718 8.21713 10.8679 8.23494 10.8603C8.25276 10.8526 8.26884 10.8415 8.2822 10.8274C8.29556 10.8134 8.30593 10.7968 8.31268 10.7786C8.31942 10.7605 8.3224 10.7411 8.32143 10.7218C8.27143 9.76999 7.75 8.94142 6.98929 8.46999C7.32475 8.10123 7.51012 7.6203 7.50893 7.12177C7.50893 6.01642 6.61786 5.12177 5.51964 5.12177C4.42143 5.12177 3.53036 6.01642 3.53036 7.12177C3.53036 7.64142 3.72679 8.11285 4.05 8.46999C3.66273 8.70997 3.3393 9.04012 3.10732 9.43224C2.87535 9.82436 2.74172 10.2668 2.71786 10.7218C2.71071 10.8039 2.775 10.8718 2.85714 10.8718V10.8718ZM5.51786 6.1932C6.02679 6.1932 6.44107 6.60927 6.44107 7.12177C6.44107 7.63427 6.02679 8.05035 5.51786 8.05035C5.00893 8.05035 4.59464 7.63427 4.59464 7.12177C4.59464 6.60927 5.00893 6.1932 5.51786 6.1932Z" />
    </g>
    <defs>
      <clipPath id="clip0_336_79798">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default PersonalIcon;
