import { getDataConfigsCountry, getFiltersTranslationPath } from './constants';

/* eslint-disable no-template-curly-in-string */
const FILTER_CONFIGS_SG = {
  loading: false,
  initialFilterSettings: [
    {
      name: 'promotions',
      active: true,
      activeMobile: true,
      algoliaFetching: true,
      requireDefaults: true,
      filters: [
        {
          name: 'promotion_name',
          customName: 'promotion_name',
          type: 'button',
          filterSelectedHeader: '[label]',
          selectedOptions: null,
        },
      ],
    },
    {
      name: 'tags',
      active: true,
      activeMobile: true,
      algoliaFetching: false,
      header: getFiltersTranslationPath('carroExclusives'),
      headerMobile: getFiltersTranslationPath('carroExclusives'),
      filters: [
        {
          name: 'listing',
          header: getFiltersTranslationPath('exclusives'),
          headerMobile: getFiltersTranslationPath('exclusives'),
          type: 'button',
          filterSelectedHeader: '[label]',
          isNumeric: false,
          isTrueOption: true,
          options: getDataConfigsCountry({
            countryCode: 'sg',
            dataName: 'TAGS',
            listFields: [
              'listing.is_ccpo',
              'listing.is_agent_warranty.sg',
              'listing.is_360',
              'listing.mechanic_video',
              'listing.is_cpa',
            ],
          }),
          selectedOptions: null,
          colSize: 12,
        },
      ],
    },
  ],
  attributesToRetrieve: [
    'id',
    'created_at',
    'promo_label',
    'promo_images',
    'promotion',
    'promotion.promo_blurb.url',
    'hits',
    'categories',
    'is_promo',
    'listing.title',
    'listing.slug',
    'listing.mileage',
    'listing.asking_price',
    'listing.photos',
    'listing.current_depreciation',
    'listing.installment',
    'listing.is_coming_soon',
    'listing.is_pending_sale',
    'listing.is_sold',
    'listing.is_agent_serviced',
    'listing.is_agent_warranty',
    'listing.is_carro_anywhere',
    'listing.is_available',
    'listing.is_ccpo',
    'listing.is_cpa',
    'listing.is_new_arrival',
    'listing.thumbnail',
    'listing.promotional_price',
    'listing.key_words',
    'listing.months_left',
    'listing.paper_value',
    'listing.status',
    'listing.is_carro_assure',
    'listing.is_carro_assure_plus',
    'listing.agent_warranty',
    'listing.maximum_loan_percentage',
    'listing.downpayment_percentage',
    'listing.original_price',
    'listing.scrap_value',
    'listing.paper_value',
    'listing.downpayment',
    'inventory.original_registration_date',
    'inventory.number_of_owners',
    'inventory.year_of_manufacture',
    'inventory.make',
    'inventory.model',
    'inventory.coe_expiry_date',
    'inventory.car_type',
    'inventory.first_registration_year',
    'inventory.transmission',
    'inventory.fuel_type',
    'inventory.color',
    'inventory.seats',
    'inventory.engine_capacity',
    'inventory.open_market_value',
    'inventory.arf_paid',
    'inventory.coe',
    'inventory.maximum_power_output',
    'inventory.road_tax',
    'inside_badges',
  ],
  mobileFilters: [
    'category',
    'make',
    'make_model',
    'body_button',
    'seats',
    'price_range',
    'depreciation',
    'installment',
    'mileage',
    'coe_left',
    'year',
    'owner',
    'colours',
    'fuel_button',
    'transmission',
    'availability_button',
    'tags',
  ],
  otherFilters: ['category', 'fuel_button', 'transmission', 'body_button', 'seats', 'colours', 'depreciation', 'tags'],
  desktopFilters: [
    'mileage',
    'make_model',
    'owner',
    'installment',
    'price_range',
    'year',
    'coe_left',
    'availability_button',
  ],
  isAlgoliaOptionsLoaded: true,
  isInitalSettingsLoaded: true,
  autocompleteHits: [],
  query: '',
  hitsPerPage: 24,
};

export default FILTER_CONFIGS_SG;
