import { useState, useEffect } from 'react';

export default function useMobileView(
  parameters: { maxMobileWidth: number; minMobileWidth: number } | number,
  useClient?: boolean,
) {
  let minMobileWidth = 0;
  let maxMobileWidth = parameters;

  if (typeof parameters === 'object') {
    maxMobileWidth = parameters?.maxMobileWidth;
    minMobileWidth = parameters?.minMobileWidth;
  }

  const [isMobileView, setIsMobileView] = useState(() => {
    if (!useClient || typeof window === 'undefined') {
      return false; // avoid hydration mismatching
    }
    return window.innerWidth >= minMobileWidth && window.innerWidth < maxMobileWidth;
  });

  useEffect(() => {
    function handleWindowResize() {
      setIsMobileView(window.innerWidth >= minMobileWidth && window.innerWidth < maxMobileWidth);
    }

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [maxMobileWidth]);

  return isMobileView;
}
