import jsCookie from 'js-cookie';
import { WS_USER_CONTACT_ID_KEY, isProduction } from '@source/constants/common';
import Logger from '@source/services/Logger';

const useGTMEvent = () => {
  const userContactId = jsCookie.get(WS_USER_CONTACT_ID_KEY);

  const pushGTMEvent = (payload: any, updatedUrl?: any) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      const eventPayload = {
        ...payload,
        _ga: jsCookie.get('_ga'),
        page_url: typeof updatedUrl !== 'undefined' ? `${window.location.host}${updatedUrl}` : window.location.href,
        user_id: payload.user_id || userContactId,
      };
      window.dataLayer.push(eventPayload);
      if (!isProduction) {
        Logger.log('pushGTMEvent', JSON.stringify(eventPayload));
      }
    }
  };

  return { pushGTMEvent };
};

export default useGTMEvent;
